import LazyLoad from 'vanilla-lazyload';
import throttle from 'lodash/throttle';

const lazyLoadCallbacks = [];
const lazyLoadInstanceLocal = new LazyLoad({
    elements_selector: '.lazy',
    cancel_on_exit: false,
    threshold: 80,
    callback_loaded: (el) => {
        if (lazyLoadCallbacks)
            for (let callback of lazyLoadCallbacks)
                if (typeof callback === 'function') callback(el);
    },
});

export let addLazyLoadCallback = (callback) => lazyLoadCallbacks.push(callback);

export let updateLazyLoad = throttle(
    () => {
        lazyLoadInstanceLocal.update();
    },
    100,
    {
        leading: true,
        trailing: true,
    }
);
