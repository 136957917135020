<template>
    <li
        class="lazy-hover product-list-item font-roboto-c"
        itemscope
        itemtype="https://schema.org/Product"
        @click="$emit('click', $event)"
        @mouseover="showAddInList = true"
        @mouseleave="showAddInList = false"
    >
        <meta content="Chico Rei" itemprop="brand" />
        <meta :content="product.id ? product.id : product._id" itemprop="sku" />

        <a
            :content="product.link"
            :href="productLink"
            :class="actualFabrics ? 'link_product' : ''"
            itemprop="url"
            target="_self"
            :style="{
                opacity: productAddedToCartId && productAddedToCartId !== product.id ? '0.3' : '1',
            }"
        >
            <div
                class="overflow-hidden aspect bg-default-1 product-list-item-image-hover"
                :class="{
                    'aspect-xs-4-5': shorterImage,
                    'aspect-shirt': !shorterImage,
                }"
                rel="1"
            >
                <div class="pos-relative" :class="{ 'no-pointer-events': isWishlist }">
                    <img
                        v-if="eagerLoadImage || showFirstImage"
                        :alt="product.name"
                        :class="{
                            'h-full': shorterImage && product.type.id !== 1,
                        }"
                        :src="image"
                        border="0"
                        class="product-list-item-photo w-full d-block"
                        itemprop="image"
                        width="290"
                        height="435"
                    />
                    <img
                        v-else
                        :alt="product.name"
                        :class="{
                            'h-full': shorterImage && product.type.id !== 1,
                        }"
                        :data-src="image"
                        border="0"
                        class="product-list-item-photo lazy lazy-fade w-full"
                        itemprop="image"
                        width="290"
                        height="435"
                        src=""
                    />

                    <img
                        v-if="!hideArtImage"
                        :alt="'Estampa ' + product.name"
                        :data-src="imageArt"
                        border="0"
                        class="product-list-item-art lazy lazy-fade"
                        src=""
                        width="290"
                        height="435"
                    />
                    <img
                        v-if="!hideArtImage && internalSelectedFabricId"
                        :alt="'Estampa ' + product.name"
                        :src="imageArt"
                        border="0"
                        class="product-list-item-art"
                        width="290"
                        height="435"
                    />

                    <slot
                        class="hidden-xs-down"
                        name="list-add-to-cart-desktop"
                        v-if="product.in_stock && product.variations && showAddInList"
                        :selectedFabricId="selectedFabricId"
                    ></slot>

                    <div
                        v-if="product.in_stock && product.variations"
                        class="icon-add-to-cart hidden-xs-up"
                        @click.prevent="
                            $emit('show-add-to-cart-mobile', {
                                product: product,
                                fabricId: selectedFabricId,
                            })
                        "
                    >
                        <i class="icon-cr icon-list-add-to-cart"></i>
                    </div>

                    <div v-if="!isWishlist && !options.hideSeals" class="tag-new">
                        <ProductListItemSeal
                            :small="small"
                            :product="product"
                            :show-new-seal="true"
                        />
                    </div>
                </div>
                <div v-if="isWishlist" class="tag-new">
                    <slot name="outside-link" />
                </div>
            </div>

            <div
                v-if="options.displayColors && actualFabrics && actualFabrics.length > 1"
                class="product-list-item-colors mt-3 mb-1"
                style="width: fit-content"
                @click.prevent=""
            >
                <div
                    v-for="(fabric, index) in actualFabrics"
                    :style="{
                        'background-color': fabric.product_color_hexa,
                    }"
                    class="product-list-item-color"
                    :class="{
                        'hidden-xs-down': index > 5,
                        'with-border': fabric.id === selectedFabricId,
                        'no-border': fabric.id !== selectedFabricId && fabric.color_id !== 3
                    }"
                    @click.prevent="internalSelectedFabricId = fabric.id"
                ></div>
            </div>
            <p
                v-if="!options || options.displayName"
                class=""
                :class="{
                    'product-list-item-title': !isWishlist,
                    'edit-iOS': isIOS,
                }"
                itemprop="name"
            >
                {{ product.name }}
            </p>
            <div v-if="isWishlist">
                <slot name="color-size" />
            </div>
            <meta v-else :content="product.name" itemprop="name" />

            <div
                class="product-list-item-info"
                itemprop="offers"
                itemscope
                itemtype="http://schema.org/Offer"
            >
                <link :href="product.link" itemprop="url" />
                <link href="http://schema.org/NewCondition" itemprop="itemCondition" />

                <template v-if="options && options.displayPrice && product.in_stock">
                    <ProductCatalogPrice
                        :options="options"
                        :product="product"
                        :show-promo="displayPromoBadge"
                    />
                </template>
                <template v-else-if="options && options.displayPrice">
                    <meta
                        :content="product.price_min > 0 ? product.price_min : product.price"
                        itemprop="price"
                    />
                    <meta content="BRL" itemprop="priceCurrency" />
                    <p>Esgotado</p>
                </template>
                <template v-else>
                    <meta
                        :content="product.price_min > 0 ? product.price_min : product.price"
                        itemprop="price"
                    />
                    <meta content="BRL" itemprop="priceCurrency" />
                </template>
                <link
                    :href="
                        'http://schema.org/' +
                        (product.quantity > 0 || product.virtual_stock ? 'InStock' : 'OutOfStock')
                    "
                    itemprop="availability"
                />
            </div>
            <div
                v-if="
                    product.promo_combo &&
                    !isWishlist &&
                    options &&
                    options.displayPrice &&
                    !options.hideSeals
                "
                class="mb mt"
            >
                <ProductListItemSeal
                    :small="small"
                    :product="product"
                    :show-discount="!options.displayPercent && !options.hidePromoBadge"
                    :show-promo="displayPromoBadge"
                />
            </div>

            <slot />
        </a>

        <slot name="below-image"></slot>
    </li>
</template>

<script>
    import { EventBus, appendQueryString } from '../../../common/common';
    import ProductCatalogPrice from './ProductCatalogPrice';
    import ProductListItemSeal from './ProductListItemSeal';

    export default {
        name: 'ProductListItem',

        components: { ProductListItemSeal, ProductCatalogPrice },

        props: {
            product: {
                type: Object,
                required: true,
            },
            options: {
                type: Object,
                default: () => ({}),
            },
            modelId: {
                type: Number,
                default: 0,
            },
            fabricId: {
                type: Number,
                default: 0,
            },
            selectedFilters: {
                type: Object,
            },
            isWishlist: {
                type: Boolean,
                default: false,
            },
            isFastBuying: {
                type: Boolean,
                default: false,
            },
            eagerLoadImage: {
                type: Boolean,
                default: false,
            },
            shorterImage: Boolean,
            small: Boolean,
        },

        created() {
            EventBus.$on('list-product-added-to-cart-mobile', this.addedToCartFromListing);
            EventBus.$on('selected-color-change', this.setFabric);
        },

        beforeDestroy() {
            EventBus.$off('list-product-added-to-cart-mobile', this.addedToCartFromListing);
            EventBus.$off('selected-color-change', this.setFabric);
        },

        data() {
            return {
                internalSelectedFabricId: null,
                productAddedToCartId: null,

                showAddInList: false,
                showFirstImage: false,
            };
        },

        watch: {
            isFastBuying: {
                handler(to) {
                    if (to) {
                        this.showFirstImage = true;
                    }

                    this.internalSelectedFabricId = null;
                },
            },

            showAddInList: {
                handler(to) {
                    if (!to) {
                        this.internalSelectedFabricId = null;
                    }
                },
            },
        },

        computed: {
            productLink() {
                let url = new URL(this.$scope.URL + this.product.link.substr(1));

                if (this.selectedFilters) {
                    if (this.selectedFilters.model_url) {
                        url.searchParams.set('model', this.selectedFilters.model_url);
                    }

                    if (this.selectedFilters.inPhysicalStock === 1) {
                        url.searchParams.set(
                            'inPhysicalStock',
                            this.selectedFilters.inPhysicalStock
                        );
                    }

                    if (this.selectedFilters.size) {
                        url.searchParams.set('size', this.selectedFilters.size);
                    }
                }

                if (
                    this.internalSelectedFabricId &&
                    this.internalSelectedFabricId != this.product.fabric_id
                ) {
                    url.searchParams.set('fabric', this.internalSelectedFabricId);
                }

                return url.toString();
            },

            imagesByFabric() {
                const grouped = {};

                (this.product.images_by_fabric || []).forEach((i) => {
                    if (!grouped[i.fabric_id]) {
                        grouped[i.fabric_id] = [];
                    }

                    grouped[i.fabric_id].push(i);
                });

                return grouped;
            },

            selectedImagesByFabric() {
                return this.imagesByFabric[this.selectedFabricId] || [];
            },

            selectedFabricId() {
                if (this.internalSelectedFabricId) {
                    return this.internalSelectedFabricId;
                }

                if (this.fabricId) {
                    return this.fabricId;
                }

                if (
                    (this.selectedFilters || {}).color &&
                    (!this.imagesByFabric[this.product.fabric_id] ||
                        this.imagesByFabric[this.product.fabric_id][0].color_id !==
                            this.selectedFilters.color ||
                        !this.isFabricAvailable(this.product.fabric_id)) //Se a cor selecionada é a cor da malha principal, vamos escolher ela
                ) {
                    const imageForColor = (this.product.images_by_fabric || []).find(
                        (e) =>
                            e.color_id === this.selectedFilters.color &&
                            this.isFabricAvailable(e.fabric_id)
                    );

                    if (imageForColor) {
                        return imageForColor.fabric_id;
                    }
                }

                return this.isFabricAvailable(this.product.fabric_id) ||
                    !this.actualFabrics ||
                    !this.actualFabrics[0]
                    ? this.product.fabric_id
                    : this.actualFabrics[0].id;
            },

            selectedModelId() {
                if (this.modelId) {
                    return this.modelId;
                }

                if ((this.selectedFilters || {}).model_url) {
                    const model = this.product.models.find(
                        (m) => m.url === this.selectedFilters.model_url
                    );

                    if (model) {
                        return model.id;
                    }
                }

                return this.product.img_cover_model;
            },

            image() {
                let imageUrl = this.product.img_cover;

                if (this.selectedImagesByFabric) {
                    let findImage = this.selectedImagesByFabric.find(
                        (i) => i.product_model_id === this.selectedModelId
                    );

                    if (!findImage) {
                        // Fallback para thumb da cor selecionada
                        findImage = this.selectedImagesByFabric.find(
                            (i) => i.product_model_id === null
                        );
                    }

                    if (findImage) {
                        imageUrl = findImage.url;
                    }
                }

                return imageUrl
                    ? appendQueryString(imageUrl, {
                          auto: 'compress,format',
                          q: 65,
                          fit: 'crop',
                          w:
                              this.options && this.options.imageWidth
                                  ? this.options.imageWidth
                                  : null,
                          h:
                              this.options && this.options.imageHeight
                                  ? this.options.imageHeight
                                  : null,
                      })
                    : null;
            },

            imageArt() {
                let imageArtUrl = this.product.img_thumb;
                let isFromFabric = false;

                if (this.selectedImagesByFabric) {
                    let findImage = this.selectedImagesByFabric.find(
                        (i) => i.product_model_id === null
                    );

                    if (findImage) {
                        imageArtUrl = findImage.url;
                        isFromFabric = true;
                    }
                }

                return imageArtUrl
                    ? appendQueryString(imageArtUrl, {
                          auto: 'compress,format',
                          q: 65,
                          fit: 'crop',
                          w:
                              this.options && this.options.imageWidth
                                  ? this.options.imageWidth
                                  : null,
                          h:
                              this.options && this.options.imageHeight
                                  ? this.options.imageHeight
                                  : null,
                      })
                    : null;
            },

            hideArtImage() {
                return (
                    !this.options ||
                    !this.options.displayArt ||
                    parseInt((this.product.type || {}).id) === 6
                );
            },

            displayPromoBadge() {
                if (!this.$scope.IS_REGULAR_CUSTOMER) return false;
                if (this.options && this.options.hidePromoBadge) return false;
                return !this.product.promo_group;
            },

            actualFabrics() {
                if ((this.product.fabrics || []).length <= 1) return null;

                let fabrics = this.product.fabrics;

                if (
                    this.product.variations &&
                    this.product.in_stock &&
                    (this.selectedFilters || {}).onlyInStock &&
                    (this.product.fabrics || []).length > 1
                ) {
                    const filteredIds = {};

                    const inPhysicalStockFilter = (this.selectedFilters || {}).inPhysicalStock;
                    const modelFilter = (this.selectedFilters || {}).model_url;
                    const sizeFilter = (this.selectedFilters || {}).size;

                    this.product.variations.forEach((v) => {
                        if (
                            ((inPhysicalStockFilter && v.quantity > 0) ||
                                (!inPhysicalStockFilter && (v.quantity > 0 || v.virtual_stock))) &&
                            (!modelFilter || v.product_model_url === modelFilter) &&
                            (!sizeFilter || v.size_name === sizeFilter)
                        ) {
                            filteredIds[v.fabric_id] = true;
                        }
                    });

                    fabrics = fabrics.filter((f) => !!filteredIds[f.id]);
                }

                return fabrics
                    .sort((a, b) =>
                        a.id === this.product.fabric_id ||
                        (a.color_id === (this.selectedFilters || {}).color &&
                            b.id !== this.product.fabric_id)
                            ? -1
                            : 1
                    )
                    .slice(0, 7);
            },

            actualFabricsKeyById() {
                if (!this.actualFabrics) {
                    return null;
                }

                const keyedFabrics = {};

                this.actualFabrics.forEach((f) => {
                    keyedFabrics[f.id] = f;
                });

                return keyedFabrics;
            },

            isIOS() {
                const userAgent = navigator.userAgent;
                return (
                    userAgent.includes('iPhone') ||
                    userAgent.includes('iPad') ||
                    userAgent.includes('iPod')
                );
            },
        },

        methods: {
            isFabricAvailable(fabric_id) {
                return !this.actualFabricsKeyById || this.actualFabricsKeyById[fabric_id];
            },

            setFabric(fabric, productId) {
                if ((this.showAddInList || this.isFastBuying) && this.product.id === productId) {
                    this.internalSelectedFabricId = fabric.fabric_id;
                }
            },

            addedToCartFromListing(productId) {
                this.productAddedToCartId = productId;

                setTimeout(() => {
                    this.productAddedToCartId = null;
                }, 1500);
            },
        },
    };
</script>
