<template>
    <ul
        :class="{ hidden: !(product.super_type || {}).name }"
        class="cr-breadcrumb mt"
        itemscope
        itemtype="http://schema.org/BreadcrumbList"
    >
        <li
            v-if="(product.super_type || {}).name"
            itemprop="itemListElement"
            itemscope
            itemtype="http://schema.org/ListItem"
        >
            <meta content="1" itemprop="position" />
            <a :href="`${$scope.URL}${product.super_type.url}`" itemprop="item">
                <span itemprop="name">{{ product.super_type.name }}</span>
            </a>
        </li>
        <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
            <meta content="2" itemprop="position" />
            <a
                :href="`${$scope.URL}${
                    product.type.url === 'camiseta-infantil'
                        ? 'camiseta/infantil'
                        : product.type.url
                }/`"
                itemprop="item"
            >
                <span itemprop="name">{{ product.type.name }}</span>
            </a>
        </li>
        <li
            class="hidden"
            itemprop="itemListElement"
            itemscope
            itemtype="http://schema.org/ListItem"
        >
            <meta content="3" itemprop="position" />
            <a :href="`${$scope.URL}${product.link.substring(1)}`" itemprop="item">
                <span itemprop="name">{{ product.name }}</span>
            </a>
        </li>
    </ul>
</template>

<script>
    export default {
        name: 'ProductBreadcrumb',

        props: {
            product: {
                type: Object,
                required: true,
            },
        },
    };
</script>
