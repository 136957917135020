<template>
    <div>
        <div id="sidebar-overlay" @click="hide" :class="{ show: open && actualTab }"></div>
        <div id="sidebar-content" ref="sidebarWrapper" :class="{ open: open && actualTab }">
            <div class="pos-fixed side-title" :style="{ width: sidebarWidth }">
                <div class="d-flex flex-justify-between flex-align-center">
                    <div class="d-flex flex-align-center" v-if="actualTab">
                        <span v-if="actualTab.icon" class="icon-cr mr-2" :class="actualTab.icon" />
                        <span
                            class="pt-1 text-uppercase"
                            :class="{
                                'text-bold':
                                    $scope.CUSTOMER &&
                                    actualTab &&
                                    actualTab.component === 'SideMyaccount',
                            }"
                            >{{ actualTab.title }}</span
                        >
                    </div>
                    <div class="flex-align-center pt-2">
                        <a class="ml-4" @click="hide"><i class="icon-cr icon-close"></i></a>
                    </div>
                </div>
                <div
                    v-if="
                        actualTab &&
                        actualTab.component === 'SideMyaccount' &&
                        $scope.CUSTOMER &&
                        $scope.cashback_available &&
                        $scope.IS_REGULAR_CUSTOMER
                    "
                    class="cashback-text"
                >
                    cashback disponível:<span class="cashback-value">
                        {{ $f.formatMoney($scope.cashback_available) }}</span
                    >
                </div>
            </div>
            <component
                :class="[
                    actualTab.component === `SideMyaccount`
                        ? 'side-real-content-my-account'
                        : 'side-real-content',
                ]"
                v-if="actualTab"
                :is="actualTab.component"
                :is-mobile="isMobile"
                :is-lateral-menu="true"
            />
        </div>
    </div>
</template>
<script>
    import { EventBus, resizeOrientationAware, updateLazyLoad } from '../../../common/common';
    import cr$ from '../../../common/crquery';
    import locale from '../../i18n/locale';
    import general from '../../i18n/general';
    import shop from '../../i18n/shop';
    import cart from '../../i18n/cart';
    import date from '../../i18n/date';
    import searchI from '../../i18n/search';
    import SideSearch from '../search/SideSearch.vue';
    import SideLogin from '../customer/SideLogin.vue';
    import Cart from '../cart/Cart.vue';
    import SideMyaccount from '../customer/SideMyaccount.vue';
    import HeaderSideAbout from '../header/HeaderSideAbout.vue';
    import { useSwipe } from '@vueuse/core';

    export default {
        el: '#header-sidebar',

        i18n: locale(general(), shop(), searchI(), cart(), date()),

        name: 'HeaderSidebar',

        components: {
            SideSearch,
            SideLogin,
            Cart,
            SideMyaccount,
            HeaderSideAbout,
        },

        data() {
            return {
                open: false,
                selectedTab: null,
                isMobile: false,
                sidebarWidth: '550px',
                resizeAwareRemoveCb: null,
            };
        },

        computed: {
            availableTabs() {
                return {
                    search: {
                        title: 'BUSCA',
                        icon: 'icon-search',
                        component: 'SideSearch',
                    },
                    login: {
                        title: 'ENTRAR EM MINHA CONTA',
                        icon: 'icon-indicates',
                        component: 'SideLogin',
                    },
                    cart: {
                        title: 'MINHA SACOLA',
                        icon: 'icon-bag',
                        component: 'Cart',
                    },
                    'my-account': {
                        title: 'Olá, ' + (this.$scope.CUSTOMER || {}).FIRST_NAME + ' :)',
                        component: 'SideMyaccount',
                    },
                    about: {
                        title: 'Sobre A Chico Rei',
                        icon: 'icon-logo',
                        component: 'HeaderSideAbout',
                    },
                };
            },

            actualTab() {
                if (!this.selectedTab || !this.availableTabs[this.selectedTab]) {
                    return null;
                }

                return this.availableTabs[this.selectedTab];
            },
        },

        mounted() {
            if (this.$refs.sidebarWrapper) {
                useSwipe(this.$el, {
                    onSwipeEnd: this.handleSwipe,
                });
            }

            this.$nextTick(() => updateLazyLoad());

            this.isMounted = true;
        },

        created() {
            EventBus.$on('open-sidebar', (tab) => {
                if (tab === 'cart' && this.$scope.IS_CART_PAGE) {
                    return;
                }

                this.open = true;
                this.selectedTab = tab;
            });

            EventBus.$on('close-sidebar', this.hide);

            this.resizeAwareRemoveCb = resizeOrientationAware(() => this.onResize(), true);
        },

        beforeDestroy() {
            this.resizeAwareRemoveCb();
        },

        methods: {
            onResize() {
                this.isMobile = window.innerWidth < 768;
                this.sidebarWidth = cr$.byId('sidebar-content').css('width');
            },

            hide() {
                this.open = false;
                this.selectedTab = null;
            },

            handleSwipe(e, direction) {
                if (direction !== 'RIGHT') {
                    return;
                }

                let el = e.target;
                while (el) {
                    if (el.classList.contains('product-horizontal-list')) {
                        return;
                    }

                    el = el.parentElement;
                }

                this.hide();
            },
        },
    };
</script>
