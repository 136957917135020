<template>
    <div class="text-left py pr">
        <strong class="text-nowrap">{{ $f.formatMoney(regularPrice) }}</strong>
        individualmente {{ $t('or').toLowerCase() }}
        <strong class="text-nowrap text-primary">{{
            $f.formatMoney(product.promo_combo_unit_price)
        }}</strong>
        {{ product.promo_instruction }}
    </div>
</template>

<script>
    import { productStore } from '../../common/store/product-store';

    export default {
        name: 'ProductPageComboPriceSmall',

        data() {
            return {
                productStoreState: productStore.state,
            };
        },

        computed: {
            product() {
                return this.productStoreState.product;
            },

            regularPrice() {
                return this.product.price_min > 0 ? this.product.price_min : this.product.price;
            },
        },
    };
</script>
