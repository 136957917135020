import * as filters from './../filters';

export default function (Vue) {
    Vue.f = filters;

    Object.defineProperties(Vue.prototype, {
        f: {
            get() {
                return filters;
            },
        },

        $f: {
            get() {
                return filters;
            },
        },
    });
}
