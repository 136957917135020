<script>
    import { EventBus, readUrlQuery } from '../../../common/common';

    export default {
        name: 'BaseVariations',

        props: {
            product: {
                type: Object,
                required: true,
            },
            selectedVariation: {
                type: Object,
                required: false,
            },
            initialFabricId: {
                type: Number,
                required: false,
            },
            label: String,
            reprintMode: Boolean,
            shopMode: Boolean,
            wishlistMode: Boolean,
            showReprintMessage: Boolean,
        },

        model: {
            prop: 'selectedVariation',
        },

        data() {
            return {
                onlyDeliveryReady: false,
                initialShowInPhysicalStockState: false,
                showDeliveryReadyInfo: false,

                selectedFabricId: null,
                selectedModelId: null,
                selectedSizeId: null,

                selectedPhoneModel: null,
                isMobile: true,
                shouldEmitEvent: true,
                deadlineAlert: null,
            };
        },

        created() {
            this.initialShowInPhysicalStockState = readUrlQuery('inPhysicalStock') === '1';
            this.onlyDeliveryReady =
                this.shopMode && this.initialShowInPhysicalStockState && this.hasDeliveryReadySizes;
        },

        watch: {
            initialFabricId: {
                immediate: true,
                handler(to) {
                    if (to) {
                        this.setFabric(this.initialFabricId);
                    }
                },
            },

            onlyDeliveryReady(to) {
                if (!to && this.initialShowInPhysicalStockState) {
                    this.deadlineAlert = {
                        message:
                            'Ao desmarcar esta opção, o prazo de entrega poderá sofrer alterações!',
                    };
                }
            },

            selectedPhoneModel() {
                if (this.isPhone) {
                    this.$emit('input', null);
                }
            },

            modelings(to) {
                if (to.length === 0) {
                    this.setModeling(null);
                } else if (!to.find((m) => m.id === this.selectedModelId)) {
                    this.setModeling(to[0].id);
                }
            },

            sizes(to) {
                if (to.length === 0) {
                    this.setSize(null);
                } else if (
                    this.selectedSizeId &&
                    !to.find((s) => s.size_id === this.selectedSizeId)
                ) {
                    this.setSize(to[0].size_id);
                }
            },

            fabrics(to) {
                if (this.initialFabric) {
                    return;
                }

                if (to.length === 0) {
                    this.setFabric(null);
                } else if (
                    this.selectedFabricId &&
                    !to.find((f) => f.fabric_id === this.selectedFabricId)
                ) {
                    this.setFabric(to[0].fabric_id);
                }
            },
        },

        computed: {
            isPhone() {
                return (this.product.type || {}).id === 15;
            },

            phoneSizesByModel() {
                if (!this.isPhone) return {};

                const modelSizes = {};

                this.sizes.forEach((variation) => {
                    const model = variation.size_name.split(' ')[0];

                    if (!modelSizes[model]) {
                        modelSizes[model] = [];
                    }

                    modelSizes[model].push(variation);
                });

                return modelSizes;
            },

            phoneModels() {
                return Object.keys(this.phoneSizesByModel).sort();
            },

            selectOptions() {
                if (this.isPhone) {
                    // natural sort
                    const collator = new Intl.Collator(undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    });

                    return this.selectedPhoneModel
                        ? this.phoneSizesByModel[this.selectedPhoneModel].sort((a, b) =>
                              collator.compare(a.size_name, b.size_name)
                          )
                        : [];
                }

                return this.sizes;
            },

            originalModelingsCount() {
                const searchObj = {};

                this.product.variations.forEach((v) => {
                    if (v.product_model_id && !searchObj[v.product_model_id]) {
                        searchObj[v.product_model_id] = true;
                    }
                });

                return Object.entries(searchObj).length;
            },

            hasDeliveryReadySizes() {
                return (
                    this.shopMode &&
                    this.product.in_stock &&
                    this.product.variations.some((v) => v.quantity > 0)
                );
            },

            variations() {
                return this.reprintMode
                    ? this.product.variations.filter(
                          (s) => (s.quantity === 0 && !s.virtual_stock) || !this.product.in_stock
                      )
                    : this.product.variations.filter(
                          (v) =>
                              !this.product.is_clothing || !this.onlyDeliveryReady || v.quantity > 0
                      );
            },

            modelings() {
                const modelings = [];

                if (this.product.is_clothing) {
                    this.variations
                        .filter(
                            (v) =>
                                !!v.product_model_id &&
                                (!this.isMobile || v.fabric_id === this.currentFabricId)
                        )
                        .filter(
                            (v, i, array) =>
                                i ===
                                array.findIndex((v2) => v.product_model_id === v2.product_model_id)
                        )
                        .forEach((variation) =>
                            modelings.push({
                                id: variation.product_model_id,
                                name: variation.product_model_name,
                                url: variation.product_model_url,
                                gender: variation.gender,
                                has_any: this.variations.some(
                                    (v) =>
                                        v.product_model_id === variation.product_model_id &&
                                        this.variationHasStock(v) &&
                                        (!this.isMobile || v.fabric_id === this.currentFabricId)
                                ),
                            })
                        );
                }

                return modelings.sort((a, b) => {
                    return a.id > b.id ? -1 : 1;
                });
            },

            firstModelId() {
                return this.modelings && this.modelings[0] ? this.modelings[0].id : null;
            },

            // usada pra pegar a modelagem atualmente selecionada.
            // quando o usuário ainda não selecionou usamos a primeira
            currentModelId() {
                return this.selectedModelId || this.firstModelId;
            },

            sizes() {
                if (!this.product.is_clothing) {
                    return this.variations.map((v) => ({
                        ...v,
                        has_any: this.variationHasStock(v),
                    }));
                }

                if (!this.currentModelId) return [];
                let sizes = this.variations
                    .filter(
                        (v) =>
                            this.isVariationAvailable(v) &&
                            this.currentModelId === v.product_model_id &&
                            (!this.isMobile || v.fabric_id === this.currentFabricId)
                    )
                    .filter(
                        (v, i, array) => i === array.findIndex((v2) => v.size_id === v2.size_id)
                    )
                    .map((variation) => ({
                        size_id: variation.size_id,
                        size_name: variation.size_name,
                        gender: variation.gender,
                        virtual_stock: variation.virtual_stock,
                        quantity: variation.quantity,
                        has_any: this.variations.some(
                            (v) =>
                                v.product_model_id === this.currentModelId &&
                                v.size_id === variation.size_id &&
                                this.variationHasStock(v) &&
                                (!this.isMobile || v.fabric_id === this.currentFabricId)
                        ),
                    }));

                const sizesOrder = [
                    '2',
                    '4',
                    '6',
                    '8',
                    '10',
                    '12',
                    '14',
                    'PP',
                    'P',
                    'M',
                    'G',
                    'GG',
                    '2GG',
                    '3GG',
                    '4GG',
                ];

                sizes.sort((a, b) =>
                    sizesOrder.indexOf(a.size_name) > sizesOrder.indexOf(b.size_name) ? 1 : -1
                );

                return sizes;
            },

            firstSizeId() {
                return this.sizes && this.sizes[0] ? this.sizes[0].size_id : null;
            },

            // usada pra pegar o tamanho atualmente selecionada.
            // quando o usuário ainda não selecionou usamos a primeira
            currentSizeId() {
                return this.selectedSizeId || this.firstSizeId;
            },

            fabrics() {
                if (!this.isMobile && !this.currentModelId) return [];

                return this.variations
                    .filter(
                        (v) =>
                            v.fabric_id &&
                            (this.isMobile ||
                                (!this.isMobile &&
                                    v.product_model_id === this.currentModelId &&
                                    (!this.selectedSizeId || v.size_id === this.selectedSizeId)))
                    )
                    .filter(
                        (v, i, array) => i === array.findIndex((v2) => v.fabric_id === v2.fabric_id)
                    )
                    .map((variation) => ({
                        ...variation,
                        has_any: !this.isMobile
                            ? this.variations.some(
                                  (v) =>
                                      v.product_model_id === this.currentModelId &&
                                      v.fabric_id === variation.fabric_id &&
                                      (!this.selectedSizeId || v.size_id === this.selectedSizeId) &&
                                      this.variationHasStock(v)
                              )
                            : this.variations.some(
                                  (v) =>
                                      v.fabric_id === variation.fabric_id &&
                                      this.variationHasStock(v)
                              ),
                    }))
                    .sort((a) => (a.fabric_id === this.product.fabric_id ? -1 : 1));
            },

            firstFabricId() {
                return this.fabrics && this.fabrics[0] ? this.fabrics[0].fabric_id : null;
            },

            // usada pra pegar a cor atualmente selecionada.
            // quando o usuário ainda não selecionou usamos a primeira
            currentFabricId() {
                return this.selectedFabricId || this.firstFabricId;
            },

            selectedFabric() {
                return this.selectedFabricId
                    ? (this.fabrics || []).find((f) => f.fabric_id === this.selectedFabricId)
                    : null;
            },

            selectedSize() {
                return this.selectedSizeId
                    ? (this.sizes || []).find((s) => s.size_id === this.selectedSizeId)
                    : null;
            },
        },

        methods: {
            onResize() {
                this.isMobile = window.innerWidth < 768;
            },

            variationHasStock(size) {
                return (size.quantity > 0 || size.virtual_stock) && this.product.in_stock;
            },

            isVariationAvailable(variation) {
                return !this.onlyDeliveryReady || variation.quantity > 0;
            },

            updateSelectedVariation() {
                let equivalentSize = null;

                if (!this.product.is_clothing && this.selectedSizeId) {
                    equivalentSize = this.variations.find((v) => v.size_id === this.selectedSizeId);

                    if (
                        !equivalentSize ||
                        (this.shopMode && !this.variationHasStock(equivalentSize))
                    ) {
                        equivalentSize = null;
                    }
                } else if (this.selectedSizeId && this.selectedModelId && this.selectedFabricId) {
                    equivalentSize = this.variations.find(
                        (v) =>
                            v.product_model_id === this.selectedModelId &&
                            v.size_id === this.selectedSizeId &&
                            v.fabric_id === this.selectedFabricId
                    );
                }

                this.$emit('input', equivalentSize);

                return equivalentSize;
            },

            setModeling(modelId) {
                this.selectedModelId = modelId;

                if (modelId && this.shopMode && this.shouldEmitEvent) {
                    EventBus.$emit('selected-modeling-change', modelId, this.product.id);
                }

                this.updateSelectedVariation();
            },

            setFabric(fabricId) {
                this.selectedFabricId = fabricId;

                if (this.shopMode && this.selectedFabric && this.shouldEmitEvent) {
                    EventBus.$emit('selected-color-change', this.selectedFabric, this.product.id);
                }

                this.updateSelectedVariation();
            },

            setSize(sizeId) {
                this.selectedSizeId = sizeId;

                this.updateSelectedVariation();
            },

            onSizeClick(size) {
                this.setSize(size.size_id);
            },

            resetFields() {
                this.selectedModelId = null;
                this.selectedSizeId = null;
            },
        },
    };
</script>
