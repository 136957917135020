<template>
    <div id="cashback">
        <div style="background-color: #21201e">
            <picture class="content">
                <source
                    :srcset="`${$scope.CDN_IMAGES}/images/site/hotsites/cashback/banner-mobile-cashback-dark.png?auto=compress,format&q=65`"
                    media="(max-width: 576px)"
                />
                <img
                    :src="`${$scope.CDN_IMAGES}/images/site/hotsites/cashback/banner-desktop-cashback-dark.png?auto=compress,format&q=65`"
                    alt="Cashback"
                    class="img-responsive"
                />
            </picture>
        </div>

        <div class="content mt-5">
            <div>
                <h1 class="text-uppercase">Cashback Chico Rei</h1>
                <p>
                    Você sabia que agora todos os seus pedidos geram cashback para compras futuras?
                    Com o programa de Cashback da Chico Rei é possível levar suas peças e acessórios
                    favoritos para casa e ainda ganhar o dinheiro de volta. Descubra como economizar
                    nas suas próximas compras.
                </p>
            </div>

            <div class="mt-4">
                <h2 class="text-uppercase">
                    Acompanhe em tempo real o valor do cashback dos nossos produtos
                </h2>
                <p>
                    Quanto mais você compra, menos paga! Cada tipo de produto conta com uma taxa
                    específica e esse valor é cumulativo e poderá ser usado nas próximas compras.
                    Além disso, na tabela abaixo você também confere as condições especiais de
                    cashback! Cá pra nós, comprar seus produtos preferidos nunca foi tão legal.
                </p>
                <div class="d-flex flex-column flex-align-center mt-4">
                    <div
                        v-for="item in offers"
                        v-if="offers && item.percentage > 0"
                        class="d-flex flex-justify-between flex-align-center info-item offer-item mb-3"
                    >
                        <h2 class="text-uppercase">
                            {{ item.name }}
                            <a :href="item.link"
                                ><u class="ml-2 text-no-decoration cursor-pointer"
                                    >Ver produtos</u
                                ></a
                            >
                        </h2>
                        <p class="item-percentage">
                            Cashback: <span>{{ item.percentage }}%</span>
                            <i
                                class="icon-cr icon-info cursor-pointer"
                                @click="openRules(item.regulation)"
                            ></i>
                        </p>
                    </div>
                    <div
                        v-for="item in infos"
                        v-if="item.cashback_percentage > 0"
                        class="d-flex flex-justify-between flex-align-center info-item mb-3"
                    >
                        <h2 class="text-uppercase">
                            {{ item.name }}
                            <a :href="item.link"
                                ><u class="ml-2 text-no-decoration cursor-pointer"
                                    >Ver produtos</u
                                ></a
                            >
                        </h2>
                        <p class="item-percentage">
                            Cashback: <span>{{ item.cashback_percentage }}%</span>
                        </p>
                    </div>
                </div>
            </div>

            <div class="mt-4">
                <h2 class="text-uppercase">Como fazer para ganhar o seu cashback?</h2>
                <p>
                    Essa vantagem está a poucos cliques de distância de você e selecionamos 4 pontos
                    para te explicar esse passo a passo.
                </p>
                <div class="d-flex flex-column flex-justify-center">
                    <div
                        v-for="(step, index) in stepBySteps"
                        class="item-step d-flex flex-align-center"
                    >
                        <p :class="[index !== 3 ? 'green-number' : 'orange-number']">
                            {{ index + 1 }}
                        </p>
                        <div :class="[index === 0 ? 'ml-4' : 'ml-3']">
                            <h2>{{ step.title }}</h2>
                            <p>{{ step.text }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <CashbackFaq :is-hotsite="true" />
        </div>
        <Modal v-model="openRegulation" class="small">
            <div class="card">
                <div class="card-block card-toolbar">
                    <span class="h5 card-title">Regulamento</span>
                </div>

                <div class="card-block">
                    <div v-html="regulation"></div>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
    import { cashbackService } from '../../../common/service/resource';
    import CashbackFaq from './CashbackFaq.vue';
    import Modal from '../common/container/Modal.vue';

    export default {
        name: 'Cashback',
        components: { CashbackFaq, Modal },
        el: '#cashback-vue',

        data() {
            return {
                infos: null,
                offers: null,
                openRegulation: false,
                regulation: null,
                stepBySteps: [
                    {
                        title: 'Finalize a sua compra na Chico Rei',
                        text: 'A gente já começa pela melhor parte: o momento em que o produto já está sendo preparado para ir pra sua casa! Após finalizar a compra o cashback entrará no estado de “Aguardando Confirmação”.',
                    },
                    {
                        title: 'Receba o seu pedido',
                        text: 'Agora sim, com os itens em mãos o seu cashback fica cada vez mais perto e mudará o status para “Disponível” em alguns dias.',
                    },
                    {
                        title: 'Adquira o cashback em até 10 dias após a entrega',
                        text: 'Após sua compra chegar o cashback ficará disponível em até 10 dias e terá validade de 90 dias corridos, então fica de olho, hein? Vale lembrar também que o valor gerado será calculado em cima do preço final de cada produto.',
                    },
                    {
                        title: 'Aproveite e economize em novas compras',
                        text: 'Lembra que a gente falou que é possível economizar comprando? Pois é, com o cashback os seus produtos favoritos saem por um precinho especial!',
                    },
                ],
            };
        },

        mounted() {
            this.fetchInfos();
        },

        methods: {
            fetchInfos() {
                cashbackService.getInfo().then((response) => {
                    this.infos = response.data.product_types;
                    this.offers = response.data.offers;
                });
            },
            openRules(regulation) {
                this.regulation = regulation;
                this.openRegulation = !this.openRegulation;
            },
        },
    };
</script>
