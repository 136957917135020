<template>
    <div class="header-product-title-menu" style="display: none">
        <ul class="shadow-1 z-1 pl-0">
            <li v-for="child in item.children">
                <a :href="child.url" class="h5 px py my-0">
                    {{ child.title }}
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'HeaderRelativeSection',

        props: {
            item: {
                type: Object,
                required: true,
            },
        },
    };
</script>
