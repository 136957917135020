<template>
    <div id="cart-empty" class="mt">
        <div v-if="isLateralMenu">
            <CartEmptySide :discount="discount" />
        </div>
        <div v-else>
            <div class="flex-grid hidden-xs-down">
                <div class="flex-xs-12 flex-sm-8">
                    <div>
                        <h1>
                            <strong>{{ $t('_cart.emptyBagDoesNotStand') }}</strong>
                        </h1>
                        <template v-if="discount">
                            <div class="frame-support">
                                <p
                                    v-html="
                                        $t('_cart.discountXWillBeAppliedValueYHtml', [
                                            discount.name,
                                            discountPrice(discount),
                                        ])
                                    "
                                >
                                    <template v-if="this.discount.date_to">
                                        ({{ $t('expiration') }}:
                                        <span class="text-bold">{{ discount.date_to }}</span
                                        >)
                                    </template>
                                    <span v-if="discount.type_id === 0">
                                        ({{ $t('_shop._discount.notCumulativePromos') }})</span
                                    >
                                </p>
                            </div>
                        </template>
                        <img
                            src="https://chico-rei.imgix.net/images/site/2018/site/carrinho-vazio.gif"
                            alt="Sacola vazia"
                            class="lazy img-responsive"
                        />
                    </div>
                </div>
                <div class="flex-xs-12 flex-sm-1">
                    <div class="vertical-row"></div>
                </div>
                <div class="flex-xs-12 flex-sm-3">
                    <div class="mt-30">
                        <div class="flex-grid">
                            <div class="mt-10 flex-xs-12 flex-sm-3 flex-lg-2">
                                <h2>
                                    <i class="icon-cr icon-wishlist"></i>
                                </h2>
                            </div>
                            <div class="mt-30 flex-xs-12 flex-sm-9 flex-lg-10">
                                <p>
                                    <strong>Lista de Desejos</strong>
                                </p>
                                <p>
                                    <a href="/minha-conta/wishlist" class="text-underline">Ver</a> a
                                    minha seleção de produtos
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="mt-30">
                        <div class="flex-grid">
                            <div class="mt-10 flex-xs-12 flex-sm-3 flex-lg-2">
                                <h3>
                                    <i class="icon-cr icon-carrier-car"></i>
                                </h3>
                            </div>
                            <div class="mt-10 flex-xs-12 flex-sm-9 flex-lg-10">
                                <p>
                                    <strong v-if="$scope.IS_REGULAR_CUSTOMER">Frete Grátis</strong>
                                    <strong v-if="!$scope.IS_REGULAR_CUSTOMER"
                                        >Envio eficiente</strong
                                    >
                                </p>
                                <p v-if="$scope.IS_REGULAR_CUSTOMER">
                                    Frete grátis a partir de R$155* <br />
                                    (Consulte condições ao adicionar um produto na sacola de
                                    compras)
                                </p>
                                <p v-if="!$scope.IS_REGULAR_CUSTOMER">
                                    Enviamos seu pedido em velocidade de foguete!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="mt-30">
                        <div class="flex-grid">
                            <div class="mt-10 flex-xs-12 flex-sm-3 flex-lg-2">
                                <h3>
                                    <i
                                        class="icon-cr icon-exchange"
                                        v-if="$scope.IS_REGULAR_CUSTOMER"
                                    ></i>
                                    <i
                                        class="icon-cr icon-credit-card"
                                        v-if="!$scope.IS_REGULAR_CUSTOMER"
                                    ></i>
                                </h3>
                            </div>
                            <div class="mt-10 flex-xs-12 flex-sm-9 flex-lg-10">
                                <p>
                                    <strong v-if="$scope.IS_REGULAR_CUSTOMER">Troca grátis</strong>
                                    <strong v-if="!$scope.IS_REGULAR_CUSTOMER"
                                        >Pagamento Facilitado</strong
                                    >
                                </p>
                                <p v-if="$scope.IS_REGULAR_CUSTOMER">
                                    Faça a sua primeira compra na Chico Rei com toda a tranquilidade
                                </p>
                                <p v-if="!$scope.IS_REGULAR_CUSTOMER">
                                    Parcele em até 4x com cartao de crédito ou boleto bancário
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-grid hidden-xs-up">
                <CartEmptySide />
            </div>
        </div>
    </div>
</template>

<script>
    import CartRecommendations from './recommendation/CartRecommendations';
    import CartEmptySide from './CartEmptySide.vue';
    import { discountService } from '../../../common/service/resource';
    import { readCookie } from '../../../common/common';

    export default {
        components: { CartRecommendations, CartEmptySide },

        props: ['recommendationsTitle', 'isLateralMenu'],

        data() {
            return {
                discount: null,
            };
        },

        mounted() {
            this.fetchDiscount();
        },

        methods: {
            fetchDiscount() {
                const voucher = readCookie('voucher');
                if (!voucher) return;

                discountService
                    .find({
                        key: voucher,
                    })
                    .then((response) => {
                        if (response.data.data) {
                            const discount = response.data.data;
                            if (discount.active && discount.quantity > 0) {
                                this.discount = discount;
                            }
                        }
                    });
            },

            discountPrice() {
                let type = '';

                if (this.discount.type_id == 2 && this.discount.value > 0) {
                    type += ' dando um desconto de ' + this.$f.formatMoney(this.discount.value);
                } else if (this.discount.type_id == 1 && this.discount.value > 0) {
                    type += ' dando um desconto de ' + parseInt(this.discount.value) + '%';
                }

                if (this.discount.frete_gratis) {
                    type += (this.discount.value > 0 ? ' +' : ' gerando') + ' Frete Grátis';
                    if (this.discount.cep_frete_gratis) {
                        type += 'para o CEP' + this.discount.cep_frete_gratis;
                    }
                }

                return type;
            },
        },
    };
</script>
