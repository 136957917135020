<template>
    <div v-if="giftOffer && giftOffer.cart_product">
        <template v-if="giftHidden">
            <a class="center-block text-left mb" @click.prevent="show">
                <p class="cart-security">
                    <i class="icon-cr icon-offer-gift mr-1" style="width: 1.8em; height: 1.2em"></i>
                    <strong>Brinde</strong>
                </p>
            </a>
        </template>
        <template v-else>
            <div class="cart-gift-offer" :class="{ 'lateral-bg': isLateralMenu }">
                <template v-if="!giftOffer.cart_product.has_quantity">
                    <p class="text-center">Poxa. Este brinde já esgotou :/</p>
                    <p class="text-center mt-3">
                        Mas não se preocupe, a Chico Rei está sempre trazendo novidades e promoções
                    </p>
                    <div class="d-flex flex-align-center flex-justify-center">
                        <button
                            class="btn mt"
                            style="
                                background-color: white;
                                color: black;
                                font-size: 13px;
                                border: 1px solid;
                                border-radius: unset;
                            "
                            @click.prevent="hide"
                        >
                            Ocultar
                        </button>
                    </div>
                </template>
                <template v-else>
                    <div class="hide-gift pos-absolute pos-right px-1 py-1 pos-top">
                        <a title="Ocultar" @click="hide"> Ocultar </a>
                    </div>
                    <p
                        class="text-center"
                        v-if="
                            !giftOffer.cart_meets_requiremente &&
                            giftOffer.cart_product.total_price <= 1
                        "
                    >
                        Faltam {{ $f.formatMoney(remaining) }} para desbloquear um
                        <strong>BRINDE EXCLUSIVO</strong>
                    </p>
                    <p class="text-center" v-else-if="giftOffer.cart_product.total_price <= 1">
                        Parabéns! Você desbloqueou um <strong>BRINDE EXCLUSIVO</strong>
                    </p>
                    <p class="text-center" v-else-if="!giftOffer.cart_meets_requiremente">
                        Faltam {{ $f.formatMoney(remaining) }} para desbloquear uma
                        <strong>OFERTA ESPECIAL</strong>
                    </p>
                    <p class="text-center" v-else>
                        Parabéns! Você desbloqueou uma <strong>OFERTA ESPECIAL</strong>
                    </p>

                    <div class="d-flex flex-align-center flex-justify-center">
                        <div class="frame-outline py-1">
                            <div class="progress-scroll">
                                <div v-bind:style="{ width: progress + '%' }"></div>
                            </div>
                        </div>
                    </div>

                    <p class="text-center" v-if="!giftOffer.cart_meets_requiremente">
                        {{ giftOffer.call_to_add_more_products }}
                    </p>
                    <p class="text-center" v-else>{{ giftOffer.call_to_add_gift }}</p>

                    <div
                        class="d-flex flex-align-center flex-justify-center"
                        v-if="giftOffer.cart_meets_requiremente"
                    >
                        <button
                            class="btn mt"
                            style="
                                background-color: white;
                                color: black;
                                font-size: 13px;
                                border: 1px solid;
                                border-radius: unset;
                            "
                            @click.prevent="$emit('add-gift')"
                        >
                            Adicionar à Sacola
                        </button>
                    </div>
                </template>
            </div>
            <CartProduct
                :key="`${giftOffer.cart_product.produto_id}_${giftOffer.cart_product.variation_id}`"
                :cart-product="giftOffer.cart_product"
                :is-lateral-menu="isLateralMenu"
                class="product-list-item"
            />
        </template>
    </div>
</template>

<script>
    import { cartService } from '../../../../common/service/resource';
    import CartProduct from '../CartProduct.vue';
    import { readCookie, setCookie } from '../../../../common/common';

    export default {
        name: 'CartGiftOffer',
        components: { CartProduct },

        props: {
            giftOffer: {
                type: Object,
                required: true,
            },
            isLateralMenu: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                giftHidden: readCookie('_chGiftOfferHidden'),
            };
        },

        watch: {},

        computed: {
            progress() {
                const progress = (this.giftOffer.cart_total * 100) / this.giftOffer.spent_value;

                return progress > 100 ? 100 : progress;
            },

            remaining() {
                return this.giftOffer.spent_value - this.giftOffer.cart_total;
            },
        },

        methods: {
            hide() {
                let d = new Date();
                d.setTime(d.getTime() + 2 * 24 * 60 * 60 * 1000);
                setCookie('_chGiftOfferHidden', '1', d.toUTCString(), '/'); //Esconde por 2 dias
                this.giftHidden = true;
            },

            show() {
                setCookie('_chGiftOfferHidden', '', -1);
                this.giftHidden = false;
            },
        },
    };
</script>
