<template>
    <div>
        <Spinner v-if="requests" />
        <template v-else>
            <div class="card-block">
                <div class="form-group mb-0" @click="toggleShippingDays($event)">
                    <input
                        id="prazo-automatico"
                        :checked="fixedShippingDays === null"
                        class="cr-checkbox"
                        type="checkbox"
                    />
                    <label for="prazo-automatico">Utilizar prazo caculado pelo sistema</label>
                </div>
                <p v-if="fixedShippingDays === null" class="alert-warning mt">
                    Ao marcar a caixa acima, o sistema irá disponibilizar somente produtos
                    disponíveis para produção imediata.
                </p>
            </div>
            <div class="card-block">
                <NumberField
                    v-model="fixedShippingDays"
                    v-tooltip="
                        fixedShippingDays === null
                            ? 'Para definir um prazo é necessário descarmar a caixa acima.'
                            : null
                    "
                    :disabled="fixedShippingDays === null"
                    :error="daysError"
                    :min="0"
                    class="mb-0"
                    label="Prazo para envio(dias)"
                />
            </div>
            <div class="card-block text-right">
                <button class="btn btn-light" @click="$emit('done')">Cancelar</button>
                <button
                    :disabled="fixedShippingDays !== null && fixedShippingDays < 20"
                    class="btn"
                    @click="submit"
                >
                    Alterar
                </button>
            </div>
        </template>
    </div>
</template>

<script>
    import SelectField from '@bit/chicorei.chicomponents.input.select-field';
    import { cartStore } from '../../common/store/cart-store';
    import NumberField from '@bit/chicorei.chicomponents.input.number-field';
    import Spinner from '@bit/chicorei.chicomponents.feedback.spinner';

    export default {
        name: 'SalesRepCartShippingDays',

        components: { Spinner, NumberField, SelectField },

        props: {
            initialDays: Number,
        },

        data() {
            return {
                requests: 0,
                fixedShippingDays: this.initialDays ? this.initialDays : null,
            };
        },

        computed: {
            daysError() {
                return this.fixedShippingDays !== null &&
                    (!this.fixedShippingDays || this.fixedShippingDays < 20)
                    ? 'O prazo mínimo é 20 dias'
                    : null;
            },
        },

        methods: {
            toggleShippingDays(event) {
                event.stopPropagation();
                event.preventDefault();

                if (this.fixedShippingDays === null) {
                    this.fixedShippingDays = 20;
                } else {
                    this.fixedShippingDays = null;
                }
            },

            submit() {
                if (this.fixedShippingDays !== null && this.fixedShippingDays < 20) return;

                this.requests++;
                cartStore
                    .updateProduct({
                        fixed_shipping_days: this.fixedShippingDays,
                    })
                    .then(() => {
                        this.$emit('done');
                        this.$emit('success');
                    })
                    .catch(
                        () =>
                            (this.alert = {
                                message: 'Houve um erro, tente novamente',
                                isError: true,
                            })
                    )
                    .then(() => this.requests--);
            },
        },
    };
</script>