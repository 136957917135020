<template>
    <div
        v-if="(product.partner || {}).url || visibleCategories"
        class="d-flex flex-wrap flex-spaced-fixed mb"
    >
        <a
            v-if="(product.partner || {}).url"
            v-tooltip="`Confira mais produtos da coleção ${product.partner.name}`"
            :href="`${$scope.URL}${product.partner.partner_type_id === 2 ? 'artistas/' : ''}${
                product.partner.url
            }`"
            class="flex-child-fixed chip chip-mobile compact"
        >
            <strong>Coleção:&nbsp;</strong>{{ product.partner.name }}
        </a>
        <a
            v-for="category in visibleCategories"
            v-tooltip="`Confira mais ${product.type.plural} com o tema ${category.name}`"
            :href="getCategoryUrl(category)"
            class="flex-child-fixed chip chip-mobile compact"
        >
            {{ category.name }}
            <input id="categoriesProduct" :value="category.name" type="hidden" /><!-- todo -->
        </a>
    </div>
</template>

<script>
    export default {
        name: 'ProductCategories',

        props: {
            product: {
                type: Object,
                required: true,
            },
        },

        computed: {
            visibleCategories() {
                return this.product.categories.filter((c) => c.parent_id === 5 && !c.hidden);
            },
        },

        methods: {
            getCategoryUrl(category) {
                return `${this.$scope.URL}${
                    this.product.type.url == 'camiseta-infantil'
                        ? 'camiseta/infantil'
                        : this.product.type.url
                }/${category.url}/`;
            },
        },
    };
</script>
