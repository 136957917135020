<template>
    <div v-if="askLogin" class="mt cart-min-height">
        <div class="alert-danger form-inline">
            <label>{{ $t('_cart.unableToLoadDataLogin') }}</label>
            <a class="btn btn-light ml-auto" href="/entrar/?back=sacola">{{ $t('toDoLogin') }}</a>
        </div>
    </div>
    <div v-else-if="errorLoadingCart || errorLoadingCustomer" class="mt cart-min-height">
        <div class="alert-danger form-inline">
            <label>{{ $t('_cart.unableToLoadPurchase') }}</label>
            <button class="btn btn-light ml-auto" @click="tryToFetch()">
                {{ $t('toTryAgain') }}
            </button>
        </div>
    </div>
    <div v-else class="mt">
        <template v-if="loadingDisplay">
            <Spinner />
        </template>

        <CartEmpty
            v-else-if="!cart || !cart.products || cart.products.data.length === 0"
            :recommendationsTitle="$t('_cart.ideasToChangeStatus')"
            :is-lateral-menu="isLateralMenu"
        />

        <template v-else>
            <div
                class="overlay-cart"
                v-if="loadingCart || loadingDisplay"
                :class="{ 'over-lateral': isLateralMenu }"
            ></div>
            <div
                id="cart"
                :class="[
                    isLateralMenu ? 'cart-display' : 'flex-grid',
                    isMobile || isLateralMenu ? 'cart-display-AB' : '',
                    loading ? 'loading' : '',
                    $scope.IS_SALES_REP ? 'mt' : '',
                ]"
                class="mb-0"
            >
                <div class="flex-xs-12 flex-md-6 flex-lg-7">
                    <!-- <h1 class="mt">
                        {{ $t('_shop.yourBag') }}
                    </h1> -->
                    <Advantages :hidePromoBanner="true" />
                    <p v-if="$scope.showSizeNotice && isLateralMenu">
                        Nossos tamanhos mudaram desde sua última compra. Confira as medidas no
                        produto
                    </p>

                    <div>
                        <button
                            style="all: unset; cursor: pointer"
                            class="hidden-sm-down"
                            v-if="!isLateralMenu && !showSpecialProducts"
                            @click="toggleGifts()"
                        >
                            <a class="center-block text-left text-yanone mt">
                                <span>
                                    <p class="cart-security">
                                        <i class="icon-cr icon-gift mr-1"></i>
                                        <strong class="hidden-sm-down">{{
                                            $t('_shop.giftOrder')
                                        }}</strong>
                                        <strong class="hidden-sm-up">{{
                                            $t('_shop.giftOrderShort')
                                        }}</strong>
                                    </p>
                                </span>
                            </a>
                        </button>
                        <CartGiftOptions
                            v-if="showGiftCards && !isMobile && !isLateralMenu"
                            :show-packing="
                                cart.maximum_allowed_packings === null ||
                                cart.maximum_allowed_packings > 0
                            "
                            :maximum-packings="cart.maximum_allowed_packings - packingsQuantity"
                            :is-lateral-menu="isLateralMenu"
                            @hide-gifts="toggleGifts()"
                            @update-cart="fetchCart()"
                            @gift-quantity-changed="reportCustomProductToTagManager"
                        />
                    </div>
                    <CartSpecialMug
                        v-if="$scope.IS_REGULAR_CUSTOMER && enableSpecialMug && allowAddSpecialMug"
                        :giftMessage="cart ? cart.gift_message : null"
                        :is-mug-in-cart="isSpecialMugInCart"
                        :special-mug-product-id="specialMugProductId"
                        @update-cart="fetchCart()"
                        @changing-products="loadingCart = true"
                        @done-changing-products="loadingCart = false"
                    />
                    <p v-if="$scope.showSizeNotice && !isLateralMenu && !showGiftCards">
                        Nossos tamanhos mudaram desde sua última compra. Confira as medidas no
                        produto
                    </p>

                    <FreeShippingProgress
                        v-if="cart && !cart.is_only_virtual_products && (isMobile || isLateralMenu)"
                        :cart="cart"
                        :national-shipping="nationalShipping"
                        :is-lateral-menu="isLateralMenu"
                        :is-mobile="isMobile"
                        :loading="loading || loadingShipping"
                        class="mb-3"
                        :calculated-shipping-obj="freeShippingCalcObj"
                        @scroll-to-shipping="scrollToShipping()"
                    />

                    <CartProducts
                        v-show="
                            (!showGiftCards || isMobile || isLateralMenu) && !showSpecialProducts
                        "
                        :cart="cart"
                        :cartToken="cartToken"
                        :donation-product-id="donationProductId"
                        :enable-donations="enableDonations"
                        :isDonationInCart="isDonationInCart"
                        :loading="loading"
                        :loyaltyMultiplier="loyaltyMultiplier"
                        :products="cart ? cart.products.data : null"
                        :special-mug-product-id="specialMugProductId"
                        :url="url"
                        :is-lateral-menu="isLateralMenu"
                        @update-cart="fetchCart()"
                        @select-gifts="toggleGifts()"
                        @product-quantity-changed="reportCartProductToTagManager"
                        @custom-product-added="reportCustomProductToTagManager"
                        @changing-products="loadingCart = true"
                        @done-changing-products="loadingCart = false"
                        @request-shipping-days-modal="showShippingDaysModal = true"
                    />

                    <div
                        v-if="cart && cart.promotion_alert && cart.promotion_alert.show_alert"
                        id="cart-promotion-alert"
                        class="mb-4"
                    >
                        <div class="promo-title">
                            {{
                                cart.promotion_alert.offer_banner || cart.promotion_alert.offer_name
                            }}
                        </div>
                        <a href="/promo/" v-if="cart.promotion_alert.products_needed > 1"
                            >Clique aqui e escolha mais
                            {{ cart.promotion_alert.products_needed }} produtos para aproveitar</a
                        >
                        <a href="/promo/" v-else
                            >Clique aqui e escolha mais 1 produto para aproveitar</a
                        >
                    </div>

                    <!-- <div class="hidden-sm-down">
                        <Lazy v-if="!$scope.IS_SALES_REP">
                            <CartRecommendations />
                        </Lazy>
                    </div> -->

                    <!-- special black friday -->
                    <div>
                        <button
                            v-if="!showSpecialProducts && enableSpecialProducts && !showGiftCards"
                            style="all: unset; cursor: pointer"
                            @click="toggleSpecialProducts()"
                        >
                            <a class="center-block text-left text-yanone mt">
                                <img
                                    class="lazy img-responsive w-full"
                                    alt="Banner black friday"
                                    :src="
                                        $scope.CDN_IMAGES +
                                        '/images/hotsites/bf-23/produtos-especiais-24.png?auto=compress,format&q=65'
                                    "
                                    v-if="isLateralMenu"
                                />
                                <img
                                    class="lazy img-responsive w-full"
                                    alt="Banner black friday"
                                    :src="
                                        $scope.CDN_IMAGES +
                                        '/images/hotsites/bf-23/produtos-especiais-24.png?auto=compress,format&q=65'
                                    "
                                    v-else-if="!isLateralMenu"
                                />
                            </a>
                        </button>

                        <CartSpecialOptions
                            v-if="showSpecialProducts"
                            :is-lateral-menu="isLateralMenu"
                            @hide-special-products="toggleSpecialProducts()"
                            @update-cart="fetchCart()"
                            @special-product-quantity-changed="reportCustomProductToTagManager"
                        />
                    </div>

                    <!-- special black friday -->
                    <h3 v-show="isMobile">Embalagens:</h3>
                    <CartGiftOptions
                        v-if="isMobile || isLateralMenu"
                        :show-packing="
                            cart.maximum_allowed_packings === null ||
                            cart.maximum_allowed_packings > 0
                        "
                        :maximum-packings="cart.maximum_allowed_packings - packingsQuantity"
                        :is-lateral-menu="isLateralMenu"
                        :is-mobile="isMobile"
                        @hide-gifts="toggleGifts()"
                        @update-cart="fetchCart()"
                        @gift-quantity-changed="reportCustomProductToTagManager"
                    />
                </div>
                <div class="flex-xs-12 flex-md-1"></div>
                <div
                    id="cart-shipping-anchor"
                    class="flex-xs-12 flex-md-5 flex-lg-4"
                    :class="{ isLateralMenu: 'cart-review' }"
                >
                    <CartReview
                        :cart="cart"
                        :enableExpressShipping="enableExpressShipping"
                        :loading="loadingCart"
                        :is-lateral-menu="isLateralMenu"
                        :is-mobile="isMobile"
                        @update-cart="fetchCart()"
                        @finish-order="toFinishOrder"
                        @change-shipping-international="changeShippingInternational"
                        @loading-cart="loadingCart = true"
                        @done-update-cart="loadingCart = false"
                        @update-cashback="updateCashback()"
                        @update-free-shipping-calc-obj="updateShippingCalcObj($event)"
                    />
                </div>
                <a
                    class="text-left text-yanone pos-relative carrier-international"
                    @click="changeShippingInternational()"
                    v-if="isMobile || isLateralMenu"
                >
                    <span>
                        <p class="cart-security text-underline hidden-sm-down">
                            {{ $t('_shop.yourDeliveryOutside') }}
                        </p>
                        <p class="cart-security text-underline hidden-sm-up">
                            {{ $t('_shop.deliveryOutside') }}
                        </p>
                    </span>
                </a>
            </div>

            <Modal
                v-if="$scope.IS_REGULAR_CUSTOMER && enableSpecialMug && allowAddSpecialMug"
                v-model="showSpecialMugWarning"
            >
                <template v-slot:title>Caneca personalizada</template>
                <div class="card-block">
                    <p class="mb mt-0">
                        Você ganhou uma
                        <a
                            class="text-accent cursor-pointer"
                            href="https://chicorei.com/caneca/caneca-criatividade-muda-o-mundo-12505.html"
                            target="_blank"
                            >Caneca Criatividade muda o Mundo</a
                        >
                        personalizada!
                    </p>

                    <CartSpecialMug
                        :giftMessage="cart ? cart.gift_message : null"
                        :is-mug-in-cart="isSpecialMugInCart"
                        :special-mug-product-id="specialMugProductId"
                        :startFormOpened="true"
                        @special-mug-added="ignoreSpecialMug"
                    />
                </div>

                <div class="card-block text-right">
                    <button class="btn btn-sm" @click="ignoreSpecialMug">
                        Não quero uma caneca
                    </button>
                </div>
            </Modal>
        </template>

        <Modal v-if="$scope.IS_SALES_REP" v-model="showShippingDaysModal">
            <template #title>Alterar previsão de envio</template>
            <SalesRepCartShippingDays
                v-if="cart"
                :initial-days="cart.fixed_shipping_days"
                @done="showShippingDaysModal = false"
                @success="fetchCart()"
            />
        </Modal>
    </div>
</template>

<script>
    import {
        EventBus,
        getScope,
        resizeOrientationAware,
        scrollToPosition,
        timeHandler,
    } from '../../../common/common';
    import Spinner from '@bit/chicorei.chicomponents.feedback.spinner';
    import { initModals } from '../../../common/cr-components';
    import { customerService } from '../../../common/service/resource';
    import { cartStore } from '../../common/store/cart-store';
    import Advantages from '../common/Advantages';
    import Modal from '../common/container/Modal';
    import Lazy from '../common/Lazy';
    import SalesRepCartShippingDays from '../sales-rep/SalesRepCartShippingDays';
    import CartEmpty from './CartEmpty';
    import CartGiftOptions from './CartGiftOptions';
    import CartProducts from './CartProducts';
    import CartReview from './CartReview';
    import CartRecommendations from './recommendation/CartRecommendations';
    import CartSpecialMug from './special-promo/CartSpecialMug';
    import CartSpecialOptions from './CartSpecialOptions';
    import FreeShippingProgress from './FreeShippingProgress.vue';
    import cr$ from '../../../common/crquery';

    export default {
        name: 'Cart',
        components: {
            FreeShippingProgress,
            SalesRepCartShippingDays,
            Lazy,
            Modal,
            Advantages,
            CartEmpty,
            CartGiftOptions,
            CartRecommendations,
            CartProducts,
            CartReview,
            CartSpecialMug,
            CartSpecialOptions,
            Spinner,
        },

        props: {
            isLateralMenu: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                storeState: cartStore.state,
                loadingCart: true,
                loadingCustomer: true,
                loadingShipping: false,
                errorLoadingCart: false,
                errorLoadingCustomer: false,
                askLogin: false,
                purchaseAmount: null,
                customerHasPrime: null,
                showGiftCards: false,
                isDonationInCart: false,

                loadingCartFech: true,
                loadingDiscount: false,

                cartToken: window.token_cart,
                url: window.url,
                enableExpressShipping: window.enableExpressShipping,

                tagManagerCartReportSent: false,

                // Caneca personalizada
                enableSpecialMug: false,
                specialMugProductId: 12505,
                showSpecialMugWarning: false,
                specialMugIgnored: false,

                // Doações
                enableDonations: false,
                donationProductId: 11691,

                showShippingDaysModal: false,
                deliveryOut: true,
                showSpecialProducts: false,
                enableSpecialProducts: true,

                //teste AB nova versao
                freeShippingCalcObj: null,
                isMobile: false,

                nationalShipping: true,
            };
        },

        mounted() {
            this.tryToFetch();
            initModals();
            this.resizeAwareRemoveCb = resizeOrientationAware(() => {
                this.isMobile = window.innerWidth < 768;
            }, true);

            EventBus.$on('national-shipping-changed', this.nationalShippingChanged);
        },

        beforeDestroy() {
            this.resizeAwareRemoveCb();
            EventBus.$off('national-shipping-changed', this.nationalShippingChanged);
        },

        watch: {
            cart: function () {
                // Caso o carrinho mude, perguntamos sobre a caneca de novo
                this.specialMugIgnored = false;
            },

            loading: function (newVal) {
                if (!newVal && this.cart && !this.tagManagerCartReportSent)
                    this.reportCartToTagManager();
            },
        },
        created() {
            EventBus.$on('update-loading-cart', (c) => {
                this.loadingCart = c;
            });
        },

        computed: {
            cart() {
                return this.storeState.cart;
            },

            loading() {
                return this.storeState.fetchingCart || this.loadingCustomer;
            },

            loadingDisplay() {
                return (
                    this.storeState.updatingCartDisplay ||
                    this.loadingCustomer ||
                    this.loadingShipping
                );
            },

            loyaltyMultiplier() {
                let multiplier = 1;

                if (
                    this.cart &&
                    this.cart.promo_loyalty &&
                    this.cart.promo_loyalty.ativa > 0 &&
                    this.cart.promo_loyalty.id_tipo_oferta === 4 &&
                    this.cart.promo_loyalty.quantidades > 0
                ) {
                    multiplier = this.cart.promo_loyalty.quantidades;
                }

                return multiplier;
            },

            isSpecialMugInCart: function () {
                if (!this.cart || !this.cart.products) return false;

                for (let product of this.cart.products.data) {
                    if (product.produto_id == this.specialMugProductId) {
                        return true;
                    }
                }
                return false;
            },

            allowAddSpecialMug() {
                return !(
                    !this.cart ||
                    !this.cart.products ||
                    this.cart.products.data
                        .filter((p) => p.product.data.type_id === 1)
                        .reduce((sum, product) => sum + product.quantity, 0) < 3
                );
            },

            packingsQuantity() {
                if (!this.cart) {
                    return 0;
                }

                const addedPackingProducts = this.cart.products.data.filter(
                    (p) => p.product.type.id === 13
                );
                let quantity = 0;
                addedPackingProducts.map((p) => {
                    quantity = quantity + p.quantity;
                });

                return quantity;
            },
        },

        methods: {
            updateShippingCalcObj(obj) {
                this.freeShippingCalcObj = obj;
            },

            tryToFetch: function () {
                this.fetchCart();
                this.fetchCustomer();
            },

            isUserLogged: function () {
                const userCookie = this.$scope.id;
                return userCookie !== undefined && userCookie !== null && userCookie > 0;
            },

            fetchCart: function () {
                if (this.isMobile || this.isLateralMenu) {
                    this.loadingCart = true;
                }

                cartStore
                    .fetch()
                    .then(() => {
                        this.loadingCart = false;
                    })
                    .catch(() => {});
            },

            fetchCustomer: function () {
                this.loadingCustomer = true;

                if (this.isUserLogged()) {
                    customerService
                        .get(this.$scope.id, {
                            withDefaultAddress: 1,
                            withAddress: 1,
                        })
                        .then((response) => {
                            this.customerHasPrime =
                                response.data.data.date_start_prime !== undefined &&
                                response.data.data.date_start_prime !== null;
                            this.purchaseAmount = response.data.data.quantidade_compras;
                            this.loadingCustomer = false;
                            this.errorLoadingCustomer = false;
                        })
                        .catch(() => {
                            this.errorLoadingCustomer = true;
                        });
                } else {
                    this.loadingCustomer = false;
                }
            },

            toggleGifts: function () {
                this.showGiftCards = !this.showGiftCards;
            },

            getProductsSkus: function () {
                // string com IDs dos produtos separados por vírgulas
                const reducer = (reduced, product) => reduced + product.produto_id + ',';
                return this.cart.products.data.reduce(reducer, '').slice(0, -1);
            },

            getProductsCriteo: function () {
                return this.cart.products.data.map((product) => ({
                    id: product.produto_id,
                    quantity: product.quantity,
                    price: product.total_price,
                }));
            },

            reportCartToTagManager: function () {
                if (!this.cart) return;

                const totalPaid = Math.max(this.cart.total_paid, 0);
                const productsSku = this.getProductsSkus();

                window.dataLayer.push({
                    'page-type': 'cart',
                    'total-cart': totalPaid,
                    'count-products-cart': this.cart.products.data
                        ? this.cart.products.data.length
                        : 0,
                    'coupon-cart':
                        this.cart.discounts.data && this.cart.discounts.data.length > 0
                            ? this.cart.discounts.data[0].name
                            : '',
                    skus: productsSku,
                    'user-type': this.$scope.id && this.purchaseAmount > 0 ? 'Old' : 'New',
                    'products-cart-criteo': this.getProductsCriteo(),
                    google_tag_params: {
                        ecomm_prodid: productsSku,
                        ecomm_pagetype: 'cart',
                        ecomm_totalvalue: totalPaid,
                    },
                });

                this.tagManagerCartReportSent = true;
            },

            reportCustomProductToTagManager: function (product) {
                const reportProduct = {
                    name: product.name,
                    id: product.id + '',
                    price: product.price + '',
                    brand: 'Chico Rei',
                    category: (product.type || {}).name,
                    quantity: 1,
                };

                this.reportCustomProductToTagManagerV4(product);
                this.reportItemToTagManager(reportProduct, 1);
            },

            reportCustomProductToTagManagerV4: function (product) {
                const reportProduct = {
                    item_name: product.name,
                    item_id: product.id + '',
                    price: product.price + '',
                    item_brand: 'Chico Rei',
                    item_category: (product.type || {}).name,
                    item_category2: product.gender,
                    item_category3: product.size,
                    item_category4: (product.color || {}).name,
                    quantity: 1,
                    currency: 'BRL',
                };

                this.reportItemToTagManagerV4(reportProduct, 1);
            },

            reportCartProductToTagManager: function (cartProduct, diffQuantity) {
                const reportProduct = {
                    name: cartProduct.product.name,
                    id: cartProduct.product.id + '',
                    price: cartProduct.product.price + '',
                    brand: 'Chico Rei',
                    category: (cartProduct.product.type || {}).name,
                    quantity: diffQuantity,
                };

                reportProduct.variant = this.buildVariantReportItem(
                    cartProduct.gender,
                    cartProduct.size,
                    cartProduct.product_color_name || cartProduct.color_name
                );
                this.reportCartProductToTagManagerV4(cartProduct, diffQuantity);
                this.reportItemToTagManager(reportProduct);
            },

            reportCartProductToTagManagerV4: function (cartProduct, diffQuantity) {
                const reportProduct = {
                    item_name: cartProduct.product.name,
                    item_id: cartProduct.product.id + '',
                    price: cartProduct.product.price,
                    item_brand: 'Chico Rei',
                    item_category: (cartProduct.product.type || {}).name,
                    item_category2: cartProduct.gender,
                    item_category3: cartProduct.size,
                    item_category4: cartProduct.product.color.name,
                    quantity: diffQuantity,
                    currency: 'BRL',
                };

                reportProduct.variant = this.buildVariantReportItem(
                    cartProduct.gender,
                    cartProduct.size,
                    cartProduct.product_color_name || cartProduct.color_name
                );

                this.reportItemToTagManagerV4(reportProduct);
            },

            buildVariantReportItem: function (gender, size, color_name) {
                return `${gender}-${size}-${color_name}`
                    .replace('--', '-')
                    .replace(/-$/, '')
                    .replace(/^-/, '');
            },

            reportItemToTagManager: function (product) {
                const report = {
                    ecommerce: {
                        currencyCode: 'BRL',
                    },
                };

                if (product.quantity > 0) {
                    report.event = 'addToCart';
                    report.ecommerce.add = { products: [product] };
                } else {
                    product.quantity = Math.abs(product.quantity);
                    report.event = 'removeFromCart';
                    report.ecommerce.remove = { products: [product] };
                }

                window.dataLayer.push(report);
            },

            reportItemToTagManagerV4: function (product) {
                const report = {
                    ecommerce_v4: {},
                };

                window.dataLayer.push({ ecommerce_v4: null });
                if (product.quantity > 0) {
                    report.event = 'add_to_cart';
                    report.ecommerce_v4.items = [product];
                } else {
                    product.quantity = Math.abs(product.quantity);
                    report.event = 'remove_from_cart';
                    report.ecommerce_v4.items = [product];
                }

                window.dataLayer.push(report);
            },

            ignoreSpecialMug() {
                this.specialMugIgnored = true;
                this.toFinishOrder();
            },

            toFinishOrder() {
                if (
                    this.$scope.IS_REGULAR_CUSTOMER &&
                    !this.specialMugIgnored &&
                    this.enableSpecialMug &&
                    this.allowAddSpecialMug &&
                    !this.isSpecialMugInCart
                ) {
                    this.showSpecialMugWarning = true;
                    return;
                }

                window.location.href = '/sacola/checkout';
            },

            changeShippingInternational() {
                EventBus.$emit('change-carrier-international');
            },

            nationalShippingChanged(event) {
                this.nationalShipping = event;
            },

            toggleSpecialProducts() {
                this.showSpecialProducts = !this.showSpecialProducts;
            },

            updateCashback() {
                this.loadingCart = true;
                cartStore
                    .updateProduct({
                        use_cashback: this.cart.use_cashback,
                    })
                    .then()
                    .catch(
                        () =>
                            (this.alert = {
                                message: 'Houve um erro, tente novamente',
                                isError: true,
                            })
                    )
                    .then(() => this.fetchCart());
            },

            scrollToShipping() {
                if (this.isLateralMenu) {
                    this.$nextTick(() => {
                        this.sidebarContainer = cr$.byId('sidebar-content');
                        this.cartShippingAnchor = cr$.byId('cart-shipping-anchor');
                        this.containerHeight = this.sidebarContainer.children().raw[1].offsetHeight;
                        let cartShippingAnchorHeight = this.cartShippingAnchor.raw.offsetHeight;
                        this.sidebarContainer.scrollTo(0, cartShippingAnchorHeight + 310, 500);
                    });
                } else {
                    cr$.byId('cart-shipping-anchor').scrollIntoView(500, -60);
                }
            },
        },
    };
</script>
