<script>
    export default {
        name: 'BaseInputField',

        props: {
            value: {
                default: null,
            },

            id: String,
            name: String,
            placeholder: String,
            maxlength: String | Number,
            readonly: Boolean,
            disabled: Boolean,
            autocomplete: String,
            inputClasses: String,

            autoCleanErrors: {
                type: Boolean,
                default: true,
            },
        },

        computed: {
            getInputClasses() {
                let classes = this.inputClasses ? this.inputClasses.split(' ') : [];
                if (this.error) classes.push('input-danger');
                return classes;
            },

            eventListeners() {
                return { ...this.$listeners, input: this.onInput };
            },
        },

        methods: {
            focus() {
                this.$refs.inputFieldRef.focus();
            },

            blur() {
                this.$refs.inputFieldRef.blur();
            },

            getInput() {
                return this.$refs.inputFieldRef;
            },
        },
    };
</script>
