<template>
    <div
        :id="item.id"
        :class="{
            open,
            closed: !open,
        }"
        class="toggleable"
    >
        <div class="header-detail content d-flex flex-justify-center">
            <!-- detail-group -->
            <template v-if="hasGroups">
                <div
                    v-for="(group, index) in item.children"
                    class="header-collections d-flex flex-column"
                    :class="{ 'text-uppercase': group.is_fancy !== '1' }"
                    :style="{
                        flex:
                            !item.thumbs && ['324', '332', '337', '472'].includes(group.id)
                                ? '0.4 0 0'
                                : group.is_fancy !== '1'
                                ? '0.7 0 0'
                                : `${Math.ceil(group.children.length / 12)} 0 0`,
                    }"
                >
                    <template v-if="group.title && group.is_fancy !== '1'">
                        <a
                            v-if="group.url"
                            :class="{
                                'text-bold': group.is_bold === '1',

                                'mb-2': group.is_bold !== '1',
                            }"
                            :href="group.url"
                            @click="
                                group.title !== 'Estampadas' && group.title !== 'Com estampa'
                                    ? true
                                    : clicOptimize(group.title)
                            "
                            class="mt-0 mr"
                        >
                            {{ group.title }}
                        </a>
                        <p v-else :class="{ 'text-bold': group.children }" class="mt-0 mr">
                            {{ group.title }}
                        </p>
                    </template>

                    <!--header-collections-items-->
                    <HeaderDesktopContentItems
                        v-if="group.children"
                        :items="group.children"
                        :fancy="group.is_fancy === '1'"
                    />
                </div>
            </template>
            <template v-else>
                <HeaderDesktopContentItems
                    v-if="item.children"
                    :items="item.children"
                    class="flex-item-grow"
                />
            </template>

            <!-- detail-thumbs -->
            <HeaderDesktopContentThumbs v-if="item.thumbs" :thumbs="item.thumbs" />
        </div>
    </div>
</template>

<script>
    import HeaderDesktopContentItems from './HeaderDesktopContentItems';
    import HeaderDesktopContentThumbs from './HeaderDesktopContentThumbs';

    export default {
        name: 'HeaderDesktopContentSection',

        components: { HeaderDesktopContentItems, HeaderDesktopContentThumbs },

        props: {
            item: {
                type: Object,
                required: true,
            },
            open: Boolean,
        },

        computed: {
            hasGroups() {
                return (
                    this.item.children &&
                    this.item.children[0] &&
                    this.item.children[0].children?.length > 0
                );
            },
        },

        methods: {
            clicOptimize() {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({ event: 'click_estampadas' });
            },
        },
    };
</script>
