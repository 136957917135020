<template>
    <Modal :value="value" @input="$emit('input', $event)">
        <template v-if="product" v-slot:title>
            {{ product.name }} -
            <a :href="$scope.URL + product.link.substr(1)" class="text-accent">
                {{ $t('_product.accessPage') }}
            </a>
        </template>

        <div v-if="!product" class="py">
            <span class="cr-spinner" />
        </div>

        <div v-else :class="{ busy }" class="card-block">
            <ProductWholesalerSingleSize
                v-if="(product.type || {}).id === 15"
                v-model="wholesalerSizesQuantitiesToBuy"
                :product="product"
            />
            <ProductWholesalerMultipleSizes
                v-else
                v-model="wholesalerSizesQuantitiesToBuy"
                :product="product"
                class="mb"
            />

            <div class="d-flex flex-align-center mt-4">
                <ProductWholesalerSizesSummary
                    v-if="wholesalerSizesQuantitiesToBuy"
                    :variations="product.variations"
                    :wholesaler-sizes-quantities-to-buy="wholesalerSizesQuantitiesToBuy"
                    class="mr-auto"
                />
                <span
                    v-show="showSuccessMessage"
                    class="text-accent text-uppercase ml-auto mr text-yanone flex-child-fixed fade-out"
                >
                    <i class="icon-cr icon-ok primary" />
                    {{ $t('added') }}
                </span>
                <ProductBuyButton
                    :allow-redirect-on-buy="false"
                    :product="product"
                    :size-to-buy="null"
                    :text="$t('_shop.addToCart')"
                    :wholesaler-sizes-quantities-to-buy="wholesalerSizesQuantitiesToBuy"
                    class="flex-child-fixed"
                    @busy="busy = true"
                    @done="onBought"
                    @fail="busy = false"
                />
            </div>
        </div>
    </Modal>
</template>

<script>
    import ProductBuy from './ProductBuy';
    import ProductWholesalerSingleSize from './ProductWholesalerSingleSize';
    import ProductWholesalerMultipleSizes from './ProductWholesalerMultipleSizes';
    import ProductBuyButton from './ProductBuyButton';
    import Modal from '../common/container/Modal';
    import { productService } from '../../../common/service/resource';
    import ProductWholesalerSizesSummary from './ProductWholesalerSizesSummary';

    export default {
        name: 'ProductQuickBuy',

        components: {
            ProductWholesalerSizesSummary,
            Modal,
            ProductBuyButton,
            ProductWholesalerMultipleSizes,
            ProductWholesalerSingleSize,
            ProductBuy,
        },

        props: {
            value: Object | Boolean,
            productId: {
                type: Number,
                required: true,
            },
        },

        data() {
            return {
                busy: false,
                product: null,
                alert: null,
                showSuccessMessage: false,
                wholesalerSizesQuantitiesToBuy: null,
            };
        },

        created() {
            this.fetch();
        },

        methods: {
            fetch() {
                productService
                    .get(this.productId)
                    .then((response) => {
                        this.product = response.data;
                    })
                    .catch(
                        () =>
                            (this.alert = {
                                message: 'Não foi possível carregar o produto',
                                isError: true,
                            })
                    );
            },

            reset() {
                for (const prop in this.wholesalerSizesQuantitiesToBuy) {
                    if (this.wholesalerSizesQuantitiesToBuy.hasOwnProperty(prop)) {
                        this.wholesalerSizesQuantitiesToBuy[prop] = 0;
                    }
                }
                this.busy = false;
            },

            onBought() {
                this.showSuccessMessage = true;
                setTimeout(() => (this.showSuccessMessage = false), 3100);
                this.reset();
            },
        },
    };
</script>
