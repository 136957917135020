<template>
    <div v-if="!showAddToCart">
        <div class="list-add-to-cart-mobile z-max" v-if="loadingCart">
            <button class="btn btn-lg text-uppercase mt-2">
                <div class="my cr-spinner-small"></div>
            </button>
        </div>
        <div class="hide-drawer-bottom list-add-to-cart-mobile" :class="{ close: loadingCart }">
            <div class="d-flex flex-justify-between flex-align-center">
                <h3 class="mr-1">{{ product.name }}</h3>
                <i class="icon-cr icon-close" @click.prevent="$emit('close')"></i>
            </div>
            <div class="d-flex prices mt-2">
                <div v-show="product.price_old" class="mr-3">
                    <span>de:</span> {{ $f.formatMoney(product.price_old) }}
                </div>
                <div>
                    <span v-show="product.price_old">por:</span> {{ $f.formatMoney(product.price) }}
                    <span>até 4x</span>
                </div>
            </div>
            <div class="mt-3 mb-3 text-bold" style="float: right">
                <a class="text-no-decoration" :href="product.link"><u>Ver página do produto</u></a>
            </div>
            <ProductVariations
                class="ad"
                v-model="sizeToBuy"
                :product="product"
                :shop-mode="true"
                :initial-fabric-id="initialFabricId"
                :initial-size-name="initialSizeName"
                :initial-model-url="initialModelUrl"
            />
            <p class="select-size-error" v-if="error">{{ error }}</p>
            <button
                class="btn btn-lg text-uppercase mt-2"
                :class="{ 'fade-4': isSizeUnavailable }"
                @click.stop="handleAddToCart"
                v-tooltip="tooltip"
            >
                <span>Adicionar à sacola</span>
            </button>
        </div>
    </div>
    <div class="list-add-to-cart-mobile" v-else>
        <div>
            <p class="text-uppercase text-bold text-center px py-1">
                produto adicionado à sacola com sucesso
            </p>
            <div class="text-center line" />
        </div>
    </div>
</template>

<script>
    import ProductVariations from './ProductVariations.vue';
    import { cartStore } from '../../common/store/cart-store';
    import { EventBus } from '../../../common/common';

    export default {
        name: 'ProductListAddToCartMobile',
        components: { ProductVariations },

        props: {
            product: {
                type: Object,
                required: true,
            },
            initialFabricId: {
                type: Number,
                required: false,
            },
            initialModelUrl: {
                type: String,
                required: false,
            },
            initialSizeName: {
                type: String,
                required: false,
            },
        },

        data() {
            return {
                sizeToBuy: null,
                loadingCart: false,
                showAddToCart: false,
                error: null,
            };
        },

        watch: {
            product(to) {
                this.sizeToBuy = null;
                this.error = null;
            },

            showAddToCart(to) {
                setTimeout(() => {
                    cr$.byClass('line').addClass('animated');
                }, 500);
            },
        },

        computed: {
            tooltip() {
                if (!this.sizeToBuy) {
                    return this.$t(
                        this.product.is_clothing
                            ? '_product.selectOptionAbove'
                            : '_product.selectDesiredSize'
                    );
                } else if (this.isSizeUnavailable) {
                    return this.$t('_product.colorIsUnavailable', [
                        this.sizeToBuy.product_color_name,
                    ]);
                }

                return null;
            },

            isSizeUnavailable() {
                return (
                    this.sizeToBuy &&
                    (!this.product.in_stock ||
                        (!this.sizeToBuy.virtual_stock && this.sizeToBuy.quantity === 0))
                );
            },
        },

        methods: {
            handleAddToCart() {
                this.error = null;
                if (!this.sizeToBuy || this.isSizeUnavailable) {
                    return;
                }

                this.loadingCart = true;
                this.reportToTagManager();
                this.addToCart(false);
            },

            reportToTagManager() {
                let reportItems = [];
                let reportItemsV4 = [];
                const variation = this.sizeToBuy;

                if (!this.$scope.IS_REGULAR_CUSTOMER) {
                    const variationInfo = this.buildEventVariationInfo(variation);
                    window.dataLayer.push(this.buildCartProductEvent(variationInfo));
                    reportItems.push(this.buildCartEventItem(variationInfo, 1));
                    reportItemsV4.push(this.buildCartEventItemV4(variationInfo, 1));
                } else {
                    const variationInfo = this.buildEventVariationInfo(variation);
                    window.dataLayer.push(this.buildCartProductEvent(variationInfo));
                    reportItems.push(this.buildCartEventItem(variationInfo));
                    reportItemsV4.push(this.buildCartEventItemV4(variationInfo));
                }

                window.dataLayer.push({
                    event: 'addToCart',
                    ecommerce: {
                        currencyCode: 'BRL',
                        add: { products: reportItems },
                    },
                });

                window.dataLayer.push({ ecommerce_v4: null });
                window.dataLayer.push({
                    event: 'add_to_cart',
                    ecommerce_v4: {
                        items: reportItemsV4,
                    },
                });
            },

            buildEventVariationInfo(variation) {
                const info = {};
                info.sizeName = variation.size_name === 'Único' ? 'Padrão' : variation.size_name;

                switch (variation.gender) {
                    case 'M':
                        info.genderName = 'Masculino';
                        break;
                    case 'F':
                        info.genderName = 'Feminino';
                        break;
                    case 'U':
                        info.genderName = 'Unisex';
                        break;
                    case 'I':
                        info.genderName = 'Infantil';
                        break;
                    default:
                        info.genderName = '';
                }

                info.colorName = variation.product_color_name;

                return info;
            },

            buildCartProductEvent(sizeInfo) {
                const event_id = 'ADTC_' + this.product.id;
                this.facebookEventId = event_id;
                return {
                    event: 'addToCartProduct',
                    'event-id-fb': event_id,
                    'product-size-name': sizeInfo.sizeName,
                    'product-gender-name': sizeInfo.genderName,
                };
            },

            buildCartEventItem(variationInfo, quantity = 1) {
                const reportProduct = {
                    name: this.product.name,
                    id: `${this.product.id}`,
                    price: `${this.price}`,
                    brand: 'Chico Rei',
                    category: this.category,
                    quantity,
                };

                reportProduct.variant = this.buildVariantReportItem(
                    variationInfo.genderName,
                    variationInfo.sizeName,
                    variationInfo.colorName
                );

                return reportProduct;
            },

            buildCartEventItemV4(variationInfo, quantity = 1) {
                const reportProductV4 = {
                    item_name: this.product.name,
                    item_id: `${this.product.id}`,
                    price: `${this.price}`,
                    item_brand: 'Chico Rei',
                    item_category: this.product.type.name,
                    item_category2: variationInfo.genderName,
                    item_category3: variationInfo.sizeName,
                    item_category4: variationInfo.colorName,
                    quantity: quantity,
                };

                reportProductV4.variant = this.buildVariantReportItem(
                    variationInfo.genderName,
                    variationInfo.sizeName,
                    variationInfo.colorName
                );

                return reportProductV4;
            },

            buildVariantReportItem(genderName, sizeName, colorName) {
                return `${genderName}-${sizeName}-${colorName}`
                    .replace('--', '-')
                    .replace(/-$/, '')
                    .replace(/^-/, '');
            },

            getCartBodyRequest() {
                if (!this.sizeToBuy) {
                    return;
                }

                const products = [];

                products.push({
                    product_id: this.product.id,
                    variation_id: this.sizeToBuy.variation_id,
                    quantity: 1,
                });

                const data = new FormData();
                data.append('json', '1');
                data.append('products', JSON.stringify(products));
                // data.append('fb_event_id', this.facebookEventId);
                return data;
            },

            addToCart(openSidebarCart = true) {
                const cartBodyRequest = this.getCartBodyRequest();
                this.loadingCart = true;
                cartStore
                    .addProduct(cartBodyRequest, openSidebarCart)
                    .then((response) => {
                        this.$scope.cart_id = response.data.cart_id;
                        const products = JSON.parse(cartBodyRequest.get('products'));
                        const productId = products[0].product_id;
                        EventBus.$emit('list-product-added-to-cart-mobile', productId);
                        this.loadingCart = false;
                        this.showAddToCart = true;

                        setTimeout(() => {
                            this.$emit('close');
                        }, 1500);
                    })
                    .catch(() => {
                        this.error = 'Erro ao adiconar ao carrinho.';
                        this.loadingCart = false;
                    });
            },
        },
    };
</script>
