export const productStore = {
    state: {
        product: null,

        sizeToBuy: null,
        wholesalerSizesQuantitiesToBuy: null,

        isProductSingleSize: false,

        showWishlist: false,
        showReprint: false,

        sizeToReprint: null,
        sizeToWish: null,
    },

    setProduct(product) {
        this.state.product = product;

        if (this.state.product.variations) {
            // Do elastic
            this.state.isProductSingleSize =
                this.state.product.variations.length === 0
                    ? false
                    : this.state.product.variations.length === 1 ||
                      this.state.product.variations[0].size_name === 'Único' ||
                      this.state.product.variations[0].size_name === 'Padrão';
        } else {
            this.state.isProductSingleSize =
                this.state.product.stock.data.length === 1 ||
                this.state.product.stock.data[0].size === 'Único' ||
                this.state.product.stock.data[0].size === 'Padrão';
        }

        this.state.sizeToBuy = this.state.isProductSingleSize
            ? this.state.product.variations[0]
            : null;
    },
};
