export default () => ({
    pt: {
        _marketing: {
            learnMoreProjects: 'Confira nossos projetos',
        },
    },
    en: {
        _marketing: {
            learnMoreProjects: 'Learn more about our projects',
        },
    },
});
