<template>
    <div :class="[isMobile ? 'hidden-md-up mb-3' : 'hidden-md-down']">
        <hr :class="[isMobile ? 'hidden-md-down' : 'hidden-md-up']" />
        <div id="product-credit" class="d-flex">
            <div v-if="partner.id && partner.partner_type_id === 2" class="d-flex">
                <img
                    v-if="partner.avatar"
                    :src="`${$scope.CDN_IMAGES}/images/site/parceiros/avatars/${partner.avatar}?auto=compress,format&q=65`"
                    alt="Avatar do parceiro"
                    class="lazy product-credit-img"
                />
                <div class="product-credit-text">
                    <p>
                        Arte criada por <strong>{{ partner.name }}</strong>
                        <i
                            v-tooltip:right="
                                `Esta criação é parte do projeto &quot;Galeria de Artistas&quot;. Comprando essa camiseta, você apoia a arte independente.`
                            "
                            class="icon-cr icon-question-sign"
                        ></i>
                        <br />
                        <a :href="`/artistas/${partner.url}`" class="text-underline">Confira</a> a
                        coleção completa
                    </p>
                </div>
            </div>
            <div v-else-if="partner.id && partner.partner_type_id === 1" class="d-flex">
                <img
                    v-if="partner.id"
                    :src="`${$scope.CDN_IMAGES}/images/site/parceiros/logos/colecao-oficial-selo-padrao.png?auto=compress,format&q=65`"
                    alt="Selo coleção oficial"
                    class="lazy product-credit-img"
                />
                <div class="product-credit-text">
                    <p>
                        Coleção oficial: <strong>{{ partner.name }}</strong
                        ><br />
                        <a :href="`/${partner.url}`" class="text-underline">Confira</a> a coleção
                        completa
                    </p>
                </div>
            </div>
            <div v-else class="d-flex">
                <img
                    :src="`${$scope.CDN_IMAGES}/images/site/parceiros/avatar/selo_CREstudio_laranja.png?auto=compress,format&q=65`"
                    alt="Selo estúdio Chico Rei"
                    class="lazy product-credit-img"
                />
                <div class="product-credit-text">
                    <p>
                        Arte criada por <strong>Chico Rei Estúdio</strong><br />
                        <a href="/camiseta/estudio/" class="text-underline">Confira</a> a coleção
                        completa
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProductCredit',

        props: {
            partner: {
                type: Object,
                required: true,
            },
            isMobile: {
                type: Boolean,
                required: true,
            },
        },
    };
</script>
