<template>
    <div>
        <div class="pos-relative">
            <NumberField
                :id="'size-' + size.variation_id"
                :disabled="disabled"
                :input-classes="'text-center'"
                :input-style="{ color: disabled ? 'transparent' : null }"
                :max="disabled ? 0 : maxQuantity"
                :min="0"
                :value="value"
                @input="$emit('input', $event)"
            />
            <template v-if="!disabled">
                <i
                    :class="{ busy: disabled }"
                    class="icon-cr icon-cr-hover icon-less pos-absolute pos-left pos-center-y ml-1"
                    @click="$emit('input', value - 1)"
                />
                <i
                    :class="{ busy: disabled }"
                    class="icon-cr icon-cr-hover icon-plus pos-absolute pos-right pos-center-y mr-1"
                    @click="$emit('input', value + 1)"
                />
            </template>
        </div>
        <p
            v-if="product.in_stock && (size.virtual_stock || size.quantity > 0)"
            class="text-secondary text-uppercase text-center text-small"
        >
            {{ productionType }}
        </p>
        <p
            v-else-if="!size.virtual_stock"
            class="text-secondary text-uppercase text-center text-small text-underline cursor-pointer"
            @click="$emit('open-reprint', size)"
        >
            Esgotado
        </p>
    </div>
</template>

<script>
    import NumberField from '@bit/chicorei.chicomponents.input.number-field';

    export default {
        name: 'ProductWholesalerSizeField',

        components: { NumberField },

        props: {
            value: Number,
            product: {
                type: Object,
                required: true,
            },
            size: {
                type: Object,
                required: true,
            },
        },

        computed: {
            disabled() {
                return !this.product.in_stock || (!this.size.quantity && !this.size.virtual_stock);
            },

            maxQuantity() {
                return !this.size.virtual_stock ? this.size.quantity : null;
            },

            productionType() {
                if (this.size.quantity > 0) {
                    return this.size.quantity + ' em estoque';
                } else return '';
            },
        },
    };
</script>
