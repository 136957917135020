<template>
    <div id="product-wholesaler-summary d-inline">
        <span :class="{ 'text-secondary': count === 0 }" class="h3 my-0">
            {{ $tc('selectedItemsX', count) }}
        </span>
        <span v-if="onDemandInfo" class="text-secondary text-yanone">
            {{ onDemandInfo }}
        </span>
    </div>
</template>

<script>
    export default {
        name: 'ProductWholesalerSizesSummary',

        props: {
            variations: {
                type: Array,
                required: true,
            },
            wholesalerSizesQuantitiesToBuy: {
                type: Object,
                required: true,
            },
        },

        computed: {
            count() {
                return this.wholesalerSizesQuantitiesToBuy
                    ? Object.keys(this.wholesalerSizesQuantitiesToBuy).reduce(
                          (prev, current) => prev + this.wholesalerSizesQuantitiesToBuy[current],
                          0
                      )
                    : 0;
            },

            onDemandInfo() {
                const onDemandQtyToBuy = Object.keys(this.wholesalerSizesQuantitiesToBuy).reduce(
                    (accumulator, current) => {
                        const qtyToBuy = this.wholesalerSizesQuantitiesToBuy[current];

                        if (qtyToBuy > 0) {
                            const variation = this.variations.find(
                                (v) => v.variation_id === parseInt(current)
                            );
                            if (
                                variation.quantity !== undefined &&
                                variation.quantity !== null &&
                                variation.quantity < qtyToBuy
                            ) {
                                return accumulator + qtyToBuy - variation.quantity;
                            }
                        }

                        return accumulator;
                    },
                    0
                );

                if (onDemandQtyToBuy > 0) {
                    return this.$tc('_shop.itemsOnDemandX', onDemandQtyToBuy);
                }
            },
        },
    };
</script>