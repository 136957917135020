<template>
    <div class="d-inline-flex w-full">
        <ul v-if="products" ref="list" :class="innerListClass" @scroll="$emit('scroll', $event)">
            <template v-for="(product, index) in limitedProducts">
                <template v-if="showBanner && getBannerByIndex(index)">
                    <div class="product-list-item banner">
                        <div class="banner-wrapper overflow-hidden aspect bg-default-1">
                            <a :href="getBannerByIndex(index).redirect_link" target="_blank">
                                <div class="pos-relative">
                                    <picture>
                                        <source
                                            :srcset="`${$scope.CDN_IMAGES}/images/site/banners/${
                                                getBannerByIndex(index).desktop_banner
                                            }?auto=compress,format&q=65`"
                                            media="(min-width: 576px)"
                                        />
                                        <img
                                            :data-src="`${$scope.CDN_IMAGES}/images/site/banners/${
                                                getBannerByIndex(index).mobile_banner
                                            }?auto=compress,format&q=65`"
                                            border="0"
                                            class="lazy lazy-fade w-full"
                                            itemprop="image"
                                            src=""
                                        />
                                    </picture>
                                </div>
                            </a>
                        </div>
                    </div>
                </template>
                <template>
                    <ProductListItem
                        :small="small"
                        :key="getListKeyFor(product)"
                        :eager-load-image="ignoreLazyLoadFirstImages && index <= 1"
                        :options="innerItemOptions"
                        :product="product"
                        :selected-filters="selectedFilters"
                        :shorter-image="shorterImage"
                        :id="product.id + `-productRef`"
                        :is-fast-buying="addingProduct && addingProduct.id == product.id"
                        @click="pushItemEvent(product)"
                        @show-add-to-cart-mobile="$emit('show-add-to-cart-mobile', $event)"
                        :style="{
                            opacity:
                                addingProduct && product.id !== addingProduct.id ? '0.3' : null,
                        }"
                    >
                        <template v-slot:below-image>
                            <slot name="below-image" :product="product" />
                        </template>
                        <template v-slot:list-add-to-cart-desktop="{ selectedFabricId }">
                            <slot
                                name="list-add-to-cart-desktop"
                                :selectedFabricId="selectedFabricId"
                                :product="product"
                            />
                        </template>
                    </ProductListItem>
                </template>
            </template>
        </ul>
    </div>
</template>

<script>
    import ProductListItem from '../product/ProductListItem';
    import { updateLazyLoad } from '../../../common/common';

    const DEFAULT_ITEM_OPTIONS = {
        baseUrl: window.url,
        displayName: true,
        displayArt: true,
        displayPrice: true,
        displayColors: false,
        imageWidth: 290,
        imageHeight: 435,
        lang: 'pt',
    };

    export default {
        name: 'ProductList',

        components: { ProductListItem },

        props: {
            products: {
                type: Array,
                required: true,
            },
            itemOptions: {
                type: Object,
                default: () => DEFAULT_ITEM_OPTIONS,
            },
            sliderOptions: {
                type: Object,
            },
            selectedFilters: {
                type: Object,
            },
            eventName: String,
            currentPage: {
                type: Number,
                default: 0,
            },
            perPage: {
                type: Number,
                default: 0,
            },
            ignoreLazyLoadFirstImages: {
                type: Boolean,
                default: false,
            },
            listClass: {
                type: String | Object,
                default: 'product-list product-list-4 product-list-gap-y-4',
            },
            shorterImage: Boolean,
            small: Boolean,
            addingProduct: {
                type: Object,
                default: null,
            },
            bannerString: { default: null },
        },

        data() {
            return {
                productsLayer: null,
                productsLayerV4: null,
                productToBuy: null,
                banners: null,
            };
        },

        watch: {
            products: {
                immediate: true,
                handler(to) {
                    if (!to) return;
                    this.pushListEvent();
                    this.$nextTick(() => {
                        updateLazyLoad();
                    });
                },
            },
            bannerString: {
                immediate: true,
                handler(to) {
                    this.banners = JSON.parse(this.bannerString);
                },
            },
        },

        computed: {
            limitedProducts() {
                return (this.options || {}).limit > 0
                    ? this.products.slice(
                          this.options.offset,
                          this.options.offset + this.options.limit
                      )
                    : this.products;
            },

            innerListClass() {
                let classes = { slider: this.sliderOptions };

                if (typeof this.listClass === 'string') {
                    classes[this.listClass] = true;
                } else {
                    classes = { ...classes, ...this.listClass };
                }

                return classes;
            },

            innerItemOptions() {
                const inner = { ...this.itemOptions };

                for (let prop in DEFAULT_ITEM_OPTIONS) {
                    if (DEFAULT_ITEM_OPTIONS.hasOwnProperty(prop) && inner[prop] === undefined) {
                        inner[prop] = DEFAULT_ITEM_OPTIONS[prop];
                    }
                }

                return inner;
            },

            showBanner() {
                if (this.banners) {
                    return this.banners.length > 0 ?? false;
                }
            },

            randomizedBanners() {
                const list = [
                    {
                        image: {
                            desktop:
                                this.$scope.CDN_IMAGES +
                                `/images/hotsites/blackfriday-2023/banners-lista-produtos/banner_canecas_618x458_311023.png`,
                            mobile:
                                this.$scope.CDN_IMAGES +
                                `/images/hotsites/blackfriday-2023/banners-lista-produtos/banner_canecas_576x576_311023.png`,
                        },
                        url: '/promo/?tipo=17&click_track=1',
                        alt: 'Ver todas as canecas',
                    },
                    {
                        image: {
                            desktop:
                                this.$scope.CDN_IMAGES +
                                `/images/hotsites/blackfriday-2023/banners-lista-produtos/banner_meias_618x458_311023.png`,
                            mobile:
                                this.$scope.CDN_IMAGES +
                                `/images/hotsites/blackfriday-2023/banners-lista-produtos/banner_meias_576x576_311023.png`,
                        },
                        url: '/promo/?tipo=33&click_track=1',
                        alt: 'Ver todas as meias',
                    },
                    {
                        image: {
                            desktop:
                                this.$scope.CDN_IMAGES +
                                `/images/hotsites/blackfriday-2023/banners-lista-produtos/banner_posters_618x458_311023.png`,
                            mobile:
                                this.$scope.CDN_IMAGES +
                                `/images/hotsites/blackfriday-2023/banners-lista-produtos/banner_posters_576x576_311023.png`,
                        },
                        url: '/promo/?tipo=6&click_track=1',
                        alt: 'Ver todos os posters',
                    },
                ];
                const shuffledBanners = list.sort(() => Math.random() - 0.5);
                return shuffledBanners.slice(0, 2);
            },
        },

        methods: {
            getListKeyFor(product) {
                const filters = this.selectedFilters || {};

                return `${product.id}-${filters.model_url}-${filters.size}-${filters.color}-${filters.onlyInStock}-${filters.inPhysicalStock}`;
            },

            pushListEvent() {
                if (!this.eventName) return;

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({ ecommerce_v4: null });

                this.productsLayer = this.products.map((product, index) => {
                    return {
                        name: product.name,
                        id: product.id + '',
                        price: product.price + '',
                        brand: 'Chico Rei',
                        category: product.type.name,
                        variant: '',
                        list: this.eventName,
                        position: this.perPage * (this.currentPage - 1) + index,
                    };
                });

                window.dataLayer.push({
                    event: 'list-products',
                    non_interaction: true,
                    ecommerce: {
                        currencyCode: 'BRL',
                        impressions: this.productsLayer,
                    },
                });

                this.productsLayerV4 = this.products.map((product, index) => {
                    return {
                        item_name: product.name, // Name or ID is required.
                        item_id: product.id + '',
                        price: product.price,
                        item_brand: 'Chico Rei',
                        item_category: product.type.name,
                        item_list_name: this.eventName,
                        index: this.perPage * (this.currentPage - 1) + index,
                        quantity: 1,
                    };
                });

                window.dataLayer.push({
                    event: 'view_item_list',
                    non_interaction: true,
                    ecommerce_v4: {
                        items: this.productsLayerV4,
                    },
                });
            },

            pushItemEvent(product) {
                if (!this.eventName) return;
                window.dataLayer.push({
                    event: 'productClick',
                    ecommerce: {
                        click: {
                            actionField: { list: this.eventName },
                            products: [
                                this.productsLayer.find((item) => item.id === product.id + ''),
                            ],
                        },
                    },
                });

                window.dataLayer.push({ ecommerce_v4: null });
                window.dataLayer.push({
                    event: 'select_item',
                    ecommerce_v4: {
                        items: [
                            this.productsLayerV4.find((item) => item.item_id === product.id + ''),
                        ],
                    },
                });
            },

            getBanner(index) {
                return index >= 0 && index < this.banners.length ? this.banners[index] : false;
            },

            // Função para gerar o mapeamento de índices baseado nas condições
            getBannerMapping() {
                return {
                    36: {
                        1: { 4: 0, 20: 1 },
                        2: { 4: 0, 20: 1 },
                    },
                    60: {
                        1: { 4: 0, 20: 1, 32: 2, 48: 3 },
                    },
                };
            },

            getBannerByIndex(index) {
                const mapping = this.getBannerMapping();
                const perPage = this.perPage;
                const currentPage = this.currentPage;

                if (mapping[perPage] && mapping[perPage][currentPage]) {
                    const bannerIndex = mapping[perPage][currentPage][index];
                    // Verifica se o banner existe antes de retornar
                    if (bannerIndex !== undefined) {
                        return this.getBanner(bannerIndex);
                    }
                }
                return false;
            },
        },
    };
</script>
