export default () => ({
    pt: {
        _search: {
            toSeeAllResultsX: 'Ver todos os resultados ({0})',
            toSeeAllX: 'Ver todos ({0})',
            toSeeAllIn: 'Ver tudo em',
            noResultsFound: 'Ops! Não encontramos resultados para o filtro utilizado',
        },
    },
    en: {
        _search: {
            toSeeAllResultsX: 'See all results ({0})',
            toSeeAllX: 'See all ({0})',
            toSeeAllIn: 'See all in',
            noResultsFound: "Oops! We didn't find results for this search",
        },
    },
});
