export const AUTHENTICATION_STATUS = {
    AUTH_OK_STATE: 0,
    AUTH_NEW_ACCOUNT: 1,
    AUTH_NEED_LOGIN: 2,
    AUTH_NEED_LOGIN_TO_EDIT: 3,
};

export const CHECKOUT_STEPS = {
    STEP_INITIALIZATION: 0,
    STEP_AUTHENTICATION: 1,
    STEP_ADDRESS: 2,
    STEP_CARRIER: 3,
    STEP_PAYMENT: 4,
};
