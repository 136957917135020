<script>
    import LazyLoad from 'vanilla-lazyload';

    export default {
        name: 'Lazy',

        data() {
            let triggerId;

            do {
                triggerId = `vue-lazy-${Math.floor(Math.random() * 1000000)}`;
            } while (document.getElementById(triggerId));

            return {
                lazyLoaded: false,
                triggerId,
            };
        },

        render(createElement) {
            if (this.$scope.IS_CRAWLER || this.lazyLoaded) {
                return this.$slots.default.length > 1
                    ? createElement('div', this.$slots.default)
                    : this.$slots.default;
            } else {
                return createElement('div', { attrs: { id: this.triggerId } }, []);
            }
        },

        mounted() {
            this.$nextTick(() => {
                let lazy = new LazyLoad({
                    elements_selector: `#${this.triggerId}`,
                    cancel_on_exit: false,
                    callback_enter: () => {
                        // obs@Jorge: não entendo o porquê, mas o timeout impede que dê erro no destroy
                        // fonte: https://github.com/verlok/lazyload/blob/master/demos/destroy.html
                        setTimeout(() => lazy.destroy(), 500);
                        this.lazyLoaded = true;
                    },
                });
            });
        },
    };
</script>
