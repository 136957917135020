export const initOptionGroups = () => {
    const groups = document.querySelectorAll('.cr-option-group');

    for (let group of groups) {
        const groupOptions = group.querySelectorAll('.cr-option');

        for (let option of groupOptions) {
            option.onclick = (ev) => {
                let targetOption = ev.target;

                while (targetOption && !targetOption.classList.contains('cr-option')) {
                    targetOption = targetOption.parentElement;
                }

                if (!targetOption) return;

                if (targetOption.classList.contains('cr-option-disabled')) return;
                if (targetOption.classList.contains('cr-option-selected')) {
                    targetOption.classList.remove('cr-option-selected');
                    return;
                }

                for (let option of groupOptions) {
                    if (option === targetOption) option.classList.add('cr-option-selected');
                    else option.classList.remove('cr-option-selected');
                }
            };
        }
    }
};

export const initSelectTogglers = () => {
    const togglers = document.querySelectorAll('select.cr-toggler');

    for (let toggler of togglers) {
        for (let i = 1; i < toggler.children.length; i++) {
            const elemIdToHide = toggler.options[i].getAttribute('aria-controls');
            document.getElementById(elemIdToHide).classList.add('hidden');
        }

        toggler.onchange = (ev) => {
            if (ev.target.selectedOption !== undefined) {
                const elemIdToShow = ev.target.selectedOption.getAttribute('aria-controls');
                document.getElementById(elemIdToShow).classList.remove('hidden');

                for (let i = 0; i < ev.target.children.length; i++) {
                    if (i !== ev.target.selectedIndex) {
                        const elemIdToHide = ev.target.options[i].getAttribute('aria-controls');
                        document.getElementById(elemIdToHide).classList.add('hidden');
                    }
                }
            } else {
                // pra browsers que não suportam 'selectedOption'
                for (const option of ev.target.querySelectorAll('option')) {
                    const elemId = option.getAttribute('aria-controls');
                    const elem = document.getElementById(elemId);
                    if (option.getAttribute('value') === ev.target.value) {
                        elem.classList.remove('hidden');
                    } else {
                        elem.classList.add('hidden');
                    }
                }
            }
        };
    }
};

export const initHoverAccordions = () => {
    const keepState = (item) => {
        item.classList.add('keep-state');
    };

    const looseState = (item) => {
        item.classList.remove('keep-state');
    };

    const onMouseEnter = (ev) => {
        const hoverAccordionItem = ev.target;
        const siblings =
            hoverAccordionItem.parentElement.querySelectorAll('.accordion-item-wrapper');

        for (let sibling of siblings) {
            looseState(sibling);
        }

        keepState(hoverAccordionItem);
    };

    const hoverAccordionItems = document.querySelectorAll(
        '.hover-accordion.hover-accordion-keep-state .accordion-item-wrapper'
    );
    for (let hoverAccordionItem of hoverAccordionItems) {
        hoverAccordionItem.onmouseenter = onMouseEnter;
    }

    const firstHoverAccordionItems = document.querySelectorAll(
        '.hover-accordion.hover-accordion-keep-state .accordion-item-wrapper:first-child'
    );
    for (let hoverAccordionItem of firstHoverAccordionItems) {
        keepState(hoverAccordionItem);
    }
};

const initModalTriggers = () => {
    const modalTriggers = document.querySelectorAll('[data-toggle="cr-modal"]');

    if (!modalTriggers) return;

    for (const triggerEl of modalTriggers) {
        if (triggerEl.getAttribute('data-was-processed') === 'true') continue;

        triggerEl.addEventListener('click', (event) => {
            event.preventDefault();

            const modalContainerSelector = triggerEl.getAttribute('data-target');
            if (!modalContainerSelector) {
                console.warn(
                    '[cr-components.initModals] data-target attribute missing for modal trigger.'
                );
                return;
            }

            const modalContainer = document.querySelector(modalContainerSelector);
            if (!modalContainer) {
                console.warn(
                    `[cr-components.initModals] modal element not found with selector "${modalContainerSelector}".`
                );
                return;
            }

            modalContainer.classList.add('opened');
        });

        triggerEl.setAttribute('data-was-processed', 'true');
    }
};

const initModalElements = () => {
    const modalElements = document.querySelectorAll('.cr-modal, .cr-modal-alert');

    for (const modalEl of modalElements) {
        modalEl.addEventListener('click', (event) => {
            if (
                event.target.classList.contains('cr-modal') ||
                event.target.classList.contains('cr-modal-alert')
            ) {
                event.preventDefault();
                event.stopPropagation();
                event.target.classList.remove('opened');
            }
        });

        const dismissElements = modalEl.querySelectorAll('[data-dismiss="cr-modal"]');
        for (const dismissEl of dismissElements) {
            dismissEl.addEventListener('click', (event) => {
                event.preventDefault();
                event.stopPropagation();
                modalEl.classList.remove('opened');
            });
        }
    }
};

export const initModals = () => {
    initModalTriggers();
    initModalElements();
};

export const openModal = (modal) => {
    modal.classList.add('opened');
};

export const dismissModal = (modal) => {
    modal.classList.remove('opened');
};

const buildTooltip = (mouseEnterEvent) => {
    const triggerEl = mouseEnterEvent.target;

    if (triggerEl.getAttribute('data-toggle') !== 'cr-tooltip') return;

    const tooltipText = triggerEl.getAttribute('data-text') || triggerEl.getAttribute('title');
    if (!tooltipText) {
        console.warn(
            '[cr-components.initTooltips] data-text attribute missing for tooltip trigger.'
        );
        return;
    }

    let tooltipPosition = triggerEl.getAttribute('data-placement');
    if (!tooltipPosition) {
        console.warn(
            '[cr-components.initTooltips] data-placement attribute missing for tooltip trigger. Assuming "top" as default.'
        );
        tooltipPosition = 'top';
    }

    const tooltipEl = document.createElement('div');
    tooltipEl.classList.add('cr-tooltip');
    tooltipEl.classList.add('cr-tooltip-' + tooltipPosition);
    tooltipEl.innerText = tooltipText;

    tooltipEl.style.position = 'fixed';
    triggerEl.parentNode.appendChild(tooltipEl);

    tooltipEl.style.width = tooltipEl.offsetWidth + 'px';

    const rect = triggerEl.getBoundingClientRect();

    switch (tooltipPosition) {
        case 'top':
        case 'bottom':
            const left = rect.x + triggerEl.offsetWidth / 2;
            tooltipEl.style.left = left + 'px';

            if (left > window.innerWidth / 2) {
                // tooltip está a direita, usamos a borda direita como limite
                tooltipEl.style.maxWidth = (window.innerWidth - left) * 2 + 'px';
            } else {
                // tooltip está a esquerda, usamos a borda esquerda como limite
                tooltipEl.style.maxWidth = left * 2 + 'px';
            }
            break;
        case 'left':
        case 'right':
            // copiar restrições acima aqui, pra tooltips verticais
            tooltipEl.style.top = rect.y + triggerEl.offsetHeight / 2 + 'px';
            break;
    }

    switch (tooltipPosition) {
        case 'top':
            tooltipEl.style.top = 'calc(' + (rect.y - tooltipEl.offsetHeight) + 'px - .5rem)';
            break;
        case 'bottom':
            tooltipEl.style.top = 'calc(' + (rect.y + triggerEl.offsetHeight) + 'px + .5rem)';
            break;
        case 'left':
            tooltipEl.style.left = 'calc(' + (rect.x - tooltipEl.offsetWidth) + 'px - .5rem)';
            break;
        case 'right':
            tooltipEl.style.left = 'calc(' + (rect.x + triggerEl.offsetWidth) + 'px + .5rem)';
            break;
    }

    const onTriggerMouseLeave = () => {
        triggerEl.parentNode.removeChild(tooltipEl);
        triggerEl.removeEventListener('mouseleave', onTriggerMouseLeave);
    };

    triggerEl.addEventListener('mouseleave', onTriggerMouseLeave);
};

export const initTooltip = (triggerEl) => {
    if (triggerEl.getAttribute('data-was-processed') === 'true') return;

    triggerEl.addEventListener('mouseenter', buildTooltip);
    triggerEl.setAttribute('data-was-processed', 'true');
};

export const initTooltips = () => {
    const tooltipTriggers = document.querySelectorAll('[data-toggle="cr-tooltip"]');

    for (const triggerEl of tooltipTriggers) {
        initTooltip(triggerEl);
    }
};

export const enableTooltip = (triggerEl) => {
    triggerEl.setAttribute('data-toggle', 'cr-tooltip');
    initTooltip(triggerEl);
};

export const disableTooltip = (triggerEl) => {
    triggerEl.removeAttribute('data-toggle');
    triggerEl.removeAttribute('data-was-processed');
    triggerEl.removeEventListener('mouseenter', buildTooltip);
};

export const initAlerts = () => {
    const alerts = document.querySelectorAll('.alert');

    for (const alert of alerts) {
        const dismissEls = document.querySelectorAll('[data-dismiss="alert"]');

        for (const dismissEl of dismissEls) {
            dismissEl.onclick = () => {
                alert.remove();
            };
        }
    }
};
