<template>
    <FeaturedProductsFetcher
        v-slot="{ products }"
        :category-id="categoryId"
        :limit="limit"
        :partner-id="partnerId"
        :sort-by-released="sortByReleased"
        :type-id="typeId"
    >
        <div v-if="title" class="section-header">
            <h2 class="section-title">
                <a :href="`${link}`">
                    {{ title }}
                </a>
            </h2>
            <a v-if="link" :href="link" class="hidden-xs-down"> Ver mais</a>
        </div>
        <ProductGroupList
            v-if="products"
            :class="innerListClass"
            :limit="limit"
            :products="products"
            :show-installments="showInstallments"
            :show-price="showPrice"
            :image-width="imageWidth"
            :image-height="imageHeight"
            :hide-seals="hideSeals"
        />
        <div class="hidden-not-touch" v-if="products">
            <ProductGroupList
                :limit="limit"
                :products="products"
                :show-installments="showInstallments"
                :show-price="showPrice"
                :image-width="imageWidth"
                :image-height="imageHeight"
                :hide-seals="hideSeals"
                class="product-horizontal-list product-horizontal-list-6 no-scrollbar"
                @scroll="scrollEvent = $event"
                id="featured-products-hs"
            />
            <HorizontalScrollbar id-list="featured-products-hs" :scroll-event="scrollEvent" />
        </div>
    </FeaturedProductsFetcher>
</template>

<script>
    import ProductGroupList from '../product/ProductGroupList';
    import FeaturedProductsFetcher from './FeaturedProductsFetcher';
    import HorizontalScrollbar from '../common/container/HorizontalScrollbar';

    export default {
        name: 'FeaturedProducts',

        components: {
            HorizontalScrollbar,
            FeaturedProductsFetcher,
            ProductGroupList,
        },

        props: {
            partnerId: Number,
            categoryId: Number,
            typeId: Number,
            imageWidth: Number,
            imageHeight: Number,
            title: String,
            link: String,
            showPrice: Boolean,
            hideSeals: Boolean,
            showInstallments: Boolean,
            listClass: String | Object,
            sortByReleased: Boolean,
            limit: {
                type: Number,
                default: 6,
            },
        },

        data() {
            return {
                scrollEvent: null,
            };
        },

        computed: {
            innerListClass() {
                return this.listClass || `product-list product-list-${this.limit} hidden-touch`;
            },
        },
    };
</script>
