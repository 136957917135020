import { getScope, readCookie, setCookie, readUrlQuery, updateLazyLoad } from './common';

const debug = false;

const POPUP_COOKIE_PREFIX = '_cr-pp';

let popups = [];

const getUTCExpiration = (expiration) => {
    const date = new Date();
    date.setTime(date.getTime() + getMillisecondsFromObject(expiration));
    return date.toUTCString();
};

const showPopup = (popup) => {
    const wrapper = document.createElement('div');
    wrapper.innerHTML = popup.html;
    document.body.appendChild(wrapper);

    updateLazyLoad();

    if (popup.expireAfter) {
        setCookie(
            POPUP_COOKIE_PREFIX + popup.id,
            new Date().getTime(),
            getUTCExpiration(popup.expireAfter)
        );
    } else {
        setCookie(POPUP_COOKIE_PREFIX + popup.id, new Date().getTime());
    }
    setCookie(POPUP_COOKIE_PREFIX, new Date().getTime());

    popups = popups.filter((p) => p.id !== popup.id);

    if (popup.onShow) popup.onShow();
};

const isMobile = function () {
    const UA = navigator.userAgent || navigator.vendor || window.opera;

    return (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            UA
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            UA.substr(0, 4)
        )
    );
};

const getMillisecondsFromObject = (object) => {
    let ms = 0;
    if (object.days) ms += object.days * 24 * 3600 * 1000;
    if (object.hours) ms += object.hours * 3600 * 1000;
    if (object.minutes) ms += object.minutes * 60 * 1000;
    if (object.seconds) ms += object.seconds * 1000;
    return ms;
};

const checkDevice = (showOptions) => (isMobile() ? showOptions.mobile : showOptions.desktop);

const getLastShowedTime = (popupId) => {
    let cookieValue = readCookie(POPUP_COOKIE_PREFIX + (popupId || ''));
    return cookieValue ? parseInt(cookieValue) : undefined;
};

const getCurrentPath = () => {
    const url = window.location.href.replace('http://', '').replace('https://', '');
    return url.substring(url.indexOf('/'));
};

const checkPathCriteria = (paths) =>
    !paths || paths.some((p) => new RegExp(p, 'i').test(getCurrentPath()));

const checkPathException = (paths) =>
    !paths || paths.every((p) => !new RegExp(p, 'i').test(getCurrentPath()));

const checkIdleTimeCriteria = (idleTime) => {
    if (idleTime === undefined) return true;

    const lastPopupShownTime = getLastShowedTime();
    if (!lastPopupShownTime) return true;

    return new Date().getTime() - lastPopupShownTime >= getMillisecondsFromObject(idleTime);
};

const checkCartCriteria = (cartOptions) => {
    if (cartOptions === undefined) return true;

    const cartCountCookie = getScope('cart_products_count');

    const cartProductsCount = cartCountCookie ? parseInt(cartCountCookie) : 0;
    if (cartOptions.status === CART_EMPTY && cartProductsCount > 0) return false;
    if (cartOptions.status === CART_NOT_EMPTY && cartProductsCount === 0) return false;

    return (
        cartOptions.productsCount === undefined || cartOptions.productsCount === cartProductsCount
    );
};

const isPopupExpired = (popupId) => {
    const popupCookie = readCookie(POPUP_COOKIE_PREFIX + popupId);
    return popupCookie === undefined || popupCookie === null;
};

const getCurrentUtm = () => {
    const utm = readUrlQuery('utm_source');
    if (utm) return utm.toLowerCase();
};

const checkUtmCriteria = (utms) => !utms || utms.some((utm) => utm === getCurrentUtm());

const checkUtmException = (utms) => !utms || utms.every((utm) => utm !== getCurrentUtm());

const checkPopupShownBeforeException = (popupsBefore) =>
    !popupsBefore || popupsBefore.every((popupId) => isPopupExpired(popupId));

const isUserLogged = () =>
    getScope('id') !== undefined && getScope('id') !== null && getScope('id') > 0; // todo confirmar com Bendia

const checkLoginCriteria = (logged) => logged === undefined || isUserLogged() === logged;

const checkLoginException = (logged) => logged === undefined || isUserLogged() !== logged;

const checkActivePromoCriteria = (activePromo) =>
    activePromo === undefined || window.initialScope.PROMO !== undefined;

const checkWholesalerCriteria = (wholesaler) =>
    wholesaler === undefined || wholesaler === window.initialScope.IS_WHOLESALER;

const checkCriteria = (popupId, criteria) => {
    if (criteria === undefined) return true;
    if (!checkIdleTimeCriteria(criteria.idleTime)) {
        if (debug)
            console.log(`[popups-manager] popup ${popupId} blocked (reason: criteria.idleTime)`);
        return false;
    }
    if (!checkPathCriteria(criteria.paths)) {
        if (debug)
            console.log(`[popups-manager] popup ${popupId} blocked (reason: criteria.paths)`);
        return false;
    }
    if (!checkCartCriteria(criteria.cart)) {
        if (debug) console.log(`[popups-manager] popup ${popupId} blocked (reason: criteria.cart)`);
        return false;
    }
    if (!checkLoginCriteria(criteria.logged)) {
        if (debug)
            console.log(`[popups-manager] popup ${popupId} blocked (reason: criteria.logged)`);
        return false;
    }
    if (!checkActivePromoCriteria(criteria.activePromo)) {
        if (debug)
            console.log(`[popups-manager] popup ${popupId} blocked (reason: criteria.activePromo)`);
        return false;
    }
    if (!checkWholesalerCriteria(criteria.wholesaler)) {
        if (debug)
            console.log(`[popups-manager] popup ${popupId} blocked (reason: criteria.wholesaler)`);
        return false;
    }
    return true;
};

const checkExceptions = (popupId, except) => {
    if (except === undefined) return true;
    if (!checkPathException(except.paths)) {
        if (debug) console.log(`[popups-manager] popup ${popupId} blocked (reason: except.paths)`);
        return false;
    }
    if (!checkPopupShownBeforeException(except.popupsBefore)) {
        if (debug)
            console.log(`[popups-manager] popup ${popupId} blocked (reason: except.popupsBefore)`);
        return false;
    }
    if (!checkLoginException(except.logged)) {
        if (debug) console.log(`[popups-manager] popup ${popupId} blocked (reason: except.logged)`);
        return false;
    }
    return true;
};

const tryShowPopup = (popup) => {
    if (!isPopupExpired(popup.id)) {
        if (debug) console.log(`[popups-manager] popup ${popup.id} blocked (reason: not expired)`);
        return false;
    }
    if (!checkDevice(popup.devices)) {
        if (debug) console.log(`[popups-manager] popup ${popup.id} blocked (reason: devices)`);
        return false;
    }
    if (!checkCriteria(popup.id, popup.criteria)) return false;
    if (!checkExceptions(popup.id, popup.except)) return false;
    showPopup(popup);
    return true;
};

const onExitIntent = () => {
    if (debug) console.log('[popups-manager] trigger: exit-intent');
    popups.some((popup) => popup.triggers.exitIntent && tryShowPopup(popup));
};

const onEnterPage = () => {
    const currentUtm = getCurrentUtm();
    if (currentUtm && currentUtm.trim().length > 0) {
        if (debug) console.log('[popups-manager] trigger: utm');
        popups.some(
            (popup) =>
                popup.triggers.utm &&
                (!popup.criteria || checkUtmCriteria(popup.criteria.utms)) &&
                (!popup.except || checkUtmException(popup.except.utms)) &&
                tryShowPopup(popup)
        );
    }
};

const onMouseOut = (e) => {
    // https://github.com/beeker1121/exit-intent-popup/blob/master/js/bioep.js#L224
    e = e ? e : window.event;
    if (e.target.tagName && e.target.tagName.toLowerCase() === 'input') return;
    const vpWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (e.clientX >= vpWidth - 50 || e.clientY >= 50) return;
    if (!(e.relatedTarget || e.toElement)) onExitIntent();
};

if (!isMobile()) {
    setTimeout(function () {
        document.addEventListener('mouseout', onMouseOut);
    }, 5000);
}

export let CART_EMPTY = 1;
export let CART_NOT_EMPTY = 2;

const PopupsManager = {
    add: (popup) => {
        popups.push(popup);
        popups = popups.sort((a, b) => (a.priority || 0) - (b.priority || 0));
    },
    triggerPageEnter: onEnterPage,
};

export default PopupsManager;
