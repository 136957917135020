<template>
    <div class="content mb" v-if="reviews && reviews.length > 0">
        <hr />
        <div
            :class="
                productReviewStatistics && productReviewStatistics.length > 0
                    ? 'flex-grid'
                    : 'block'
            "
        >
            <div class="flex-sm-12 flex-md-5">
                <h2 class="h6 text-bold text-default-5 mb text-uppercase">
                    O que estão falando sobre este produto
                </h2>
                <div
                    class="d-flex flex-column flex-justify-center flex-align-center mb mt cr-review-note"
                    itemprop="aggregateRating"
                    itemtype="https://schema.org/AggregateRating"
                    itemscope
                >
                    <div class='note-crviews'>
                        <p
                            class="text-bold text-uppercase h1"
                            itemprop="ratingValue"
                            :content="product.review_rating_mean"
                        >
                            {{ $f.number(product.review_rating_mean, 1) }}

                        </p>
                        <p class="text-bold text-uppercase note-total">/5</p>
                    </div>
                    <p class="text-uppercase h4">NOTA DO PRODUTO</p>
                    <avaliacoes-show-stars :stars-rating="product.review_rating_mean" />
                    <br />
                    <span>Média de {{ product.review_count }} avaliações</span>
                    <meta itemprop="reviewCount" content="89" />
                </div>
                <div class="review-statistics content">
                    <div v-for="question in productReviewStatistics">
                        <div v-if="question.type === 4" class="scale">
                            <hr />
                            <p>Avaliações por características do produto:</p>
                            <h3 class='h5 text-bold'>
                                {{ question.title }}
                            </h3>
                            <div class="d-flex scale-bar pos-relative">
                                <div
                                    class="scale-answer-bar pos-absolute"
                                    :style="{ left: question.scale_mean - 13 + '%' }"
                                ></div>
                            </div>
                            <div class="d-flex flex-justify-between scale-subtext">
                                <p>{{ question.options[0].sub_text }}</p>
                                <p>{{ question.options[2].sub_text }}</p>
                                <p>{{ question.options[4].sub_text }}</p>
                            </div>
                        </div>
                        <div v-if="question.type === 2" class="multiple">
                            <hr />
                            <p>Quando perguntamos:</p>
                            <h3 class='h5  text-bold'>
                                {{ question.title }}
                            </h3>
                            <div
                                v-for="(n, index) in 3"
                                v-if="question.options_answer_sort_percentage[index] > 0"
                            >
                                <p class="text-small">
                                    {{ question.options_answer_sort_percentage[index] }}%
                                    mencionaram
                                </p>

                                <h4 class='text-bold h5'>
                                    {{ question.options[question.options_answer_sort[index]].text }}
                                </h4>
                            </div>
                        </div>
                        <div v-if="question.type === 1" class="unique">
                            <hr />
                            <p>Quando perguntamos:</p>
                            <h3 class='h5 text-bold'>
                                {{ question.title }}
                            </h3>
                            <p class="text-small">A resposta mais mencionada foi:</p>
                            <h5>
                                {{ question.options[question.options_answer_sort[0]].sub_text }}
                            </h5>
                        </div>
                        <div v-if="question.type === 3" class="binary">
                            <hr />
                            <p>Quando perguntamos:</p>
                            <h3 class='h5 text-bold'>
                                {{ question.title }}
                            </h3>
                            <div class="d-flex binary-bar">
                                <div class="binary-question-bar">
                                    <div
                                        class="binary-answer-bar"
                                        :style="{
                                            width: question.options_answer_sort_percentage[0] + '%',
                                        }"
                                    >
                                        <p>
                                            {{
                                                question.options[question.options_answer_sort[0]]
                                                    .text
                                            }}
                                        </p>
                                    </div>
                                </div>
                                <p>{{ question.options_answer_sort_percentage[0] }}%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr v-if="!productReviewStatistics" />
            <div class="flex-sm-12 flex-md-7">
                <div class="d-flex flex-justify-between mb-3" :class="{ busy: requests > 0 }">
                    <h2 class="h6 text-bold text-default-5 mb-2 text-uppercase">
                        Avaliações com comentários
                    </h2>
                    <div class="cr-select review-select my-0">
                        <select v-model="ordering" class="select-mobile mt-0">
                            <option value="latest">Mais novos</option>
                            <option value="oldest">Mais antigos</option>
                            <option value="relevant">Mais relevantes</option>
                            <option value="useful">Mais úteis</option>
                        </select>
                    </div>
                </div>
                <div :class="{ busy: requests > 0 }">
                    <div
                        v-for="review in reviews"
                        :key="review.id"
                        class="cr-review mb-3"
                        itemprop="review"
                        itemscope
                        itemtype="https://schema.org/Review"
                    >
                        <div class="review-info">
                            <div class="d-flex flex-justify-between flex-align-center">
                                <div
                                    class="mt-3 mb-3"
                                    :class="[isMobile ? '' : 'd-flex flex-align-center']"
                                >
                                    <h3 class='h4'>
                                        <span
                                            itemprop="author"
                                            itemscope
                                            itemtype="https://schema.org/Person"
                                        >
                                            <span itemprop="name" :content="review.customer.name">{{
                                                $f.abbreviateName(review.customer.name)
                                            }}</span>
                                            <span v-if="review.indicate && review.indicate === 1"
                                                >recomenda este produto</span
                                            >
                                        </span>
                                    </h3>

                                    <span
                                        itemprop="reviewRating"
                                        itemscope
                                        itemtype="https://schema.org/Rating"
                                        class="text-nowrap"
                                    >
                                        <span itemprop="ratingValue" :content="review.note"
                                            ><avaliacoes-show-stars
                                                class="ml"
                                                :stars-rating="review.note"
                                        /></span>
                                    </span>
                                </div>

                                <span
                                    class="review-useful mt-3 mb-3"
                                    v-if="!isMobile && (review.title || review.text)"
                                    >Esse comentário foi útil ?
                                    <i
                                        v-if="!review.marked"
                                        @click="markUseful(review)"
                                        class="cursor-pointer icon-cr icon-useful ml mr"
                                    ></i>
                                    <i v-else class="icon-cr icon-useful ml mr"></i>
                                    {{ review.useful_count }}</span
                                >
                            </div>

                            <p>
                                <template v-if="review.title">
                                    <span class="review-title">{{ review.title }}</span>
                                    <br />
                                </template>
                                <span v-if="review.text" class="review-text" itemprop="name">{{
                                    review.text
                                }}</span>
                                <span v-else-if="!review.text || !review.title" class="text-small"
                                    >Cliente não escreveu uma avaliação, apenas deu nota do
                                    produto.</span
                                >
                            </p>
                            <span
                                class="review-useful ml mt"
                                v-if="isMobile && (review.title || review.text)"
                                >Esse comentário foi útil ?
                                <i
                                    v-if="!review.marked"
                                    @click="markUseful(review)"
                                    class="cursor-pointer icon-cr icon-useful"
                                ></i>
                                <i v-else class="icon-cr icon-useful ml mr"></i>
                                {{ review.useful_count }}</span
                            >
                            <div v-if="review.image_customer_comment" class="d-flex ml-3 mt">
                                <div
                                    v-for="image in review.image_customer_comment"
                                    :key="review.image_customer_comment.id"
                                    class="mr-2"
                                    @click="
                                        $emit('zoom', [
                                            getSource(image.image, review.customer_id, true),
                                        ])
                                    "
                                >
                                    <img
                                        :src="getSource(image.image, review.customer_id)"
                                        class="cursor-pointer"
                                    />
                                </div>
                            </div>
                            <div v-if="review.customer_comment_reply" class="review-reply ml-3 mt">
                                <p>{{ review.customer_comment_reply.text }}</p>
                            </div>
                        </div>

                        <meta itemprop="datePublished" :content="review.meta_data_published" />
                        <span class="review-date"
                            >Avaliado em {{ $f.formatDate(review.created_at) }}</span
                        >
                        <hr />
                    </div>
                    <div v-if="hasMorePages" class="d-flex flex-justify-center flex-align-center">
                        <button
                            class="btn btn-light text-uppercase mt-5 mb-5 review-btn"
                            @click="showMore"
                        >
                            carregar mais
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { commentService, productService } from '../../../common/service/resource';
    import { resizeOrientationAware } from '../../../common/common';
    import AvaliacoesShowStars from '../avaliacoes/AvaliacoesShowStars.vue';
    import order from '../../../common/service/msw/handlers/order';

    export default {
        name: 'CrProductReviews',

        props: {
            product: {
                type: Object,
                required: true,
            },
        },

        components: {
            AvaliacoesShowStars,
        },

        data() {
            return {
                requests: 0,
                reviews: null,
                ordering: 'relevant',
                currentPage: 1,
                hasMorePages: false,
                isMobile: false,
                productReviewStatistics: null,
            };
        },

        mounted() {
            this.resizeAwareRemoveCb = resizeOrientationAware(() => this.onResize(), true);
        },

        beforeDestroy() {
            this.resizeAwareRemoveCb();
        },

        watch: {
            ordering: {
                immediate: true,
                handler(to) {
                    if (to) {
                        this.fetchReviews(1);
                        this.fetchReviewStatistics();
                    }
                },
            },
        },

        methods: {
            onResize() {
                this.isMobile = window.innerWidth < 768;
            },

            fetchReviews(page) {
                this.requests++;
                this.currentPage = page;
                productService
                    .queryComments(this.product.id, {
                        ordering: this.ordering,
                        perPage: 4,
                        page: this.currentPage,
                    })
                    .then((response) => {
                        if (this.currentPage === 1) {
                            this.reviews = response.data.data;
                        } else {
                            this.reviews.push(...response.data.data);
                        }
                        this.hasMorePages =
                            this.currentPage < response.data.meta.pagination.total_pages;
                    })
                    .catch(() => {})
                    .then(() => {
                        this.requests--;
                    });
            },

            getSource(image, customer_id, isZoom = false) {
                let url = this.$scope.CDN_IMAGES + image;

                if (isZoom) {
                    return url + '?auto=compress,format&q=65&max-w=1250&h=600';
                } else {
                    return url + '?auto=compress,format&q=65&w=100&h=100';
                }
            },

            markUseful(review) {
                this.requests++;
                commentService
                    .markUseful(review.id)
                    .then((response) => {
                        review.useful_count++;
                        this.$set(review, 'marked', true);
                    })
                    .catch(() => {})
                    .then(() => {
                        this.requests--;
                    });
            },

            showMore() {
                this.fetchReviews(this.currentPage + 1);
            },

            fetchReviewStatistics() {
                this.requests++;
                productService
                    .getStatistics(this.product.id)
                    .then((response) => {
                        this.productReviewStatistics = response.data;
                    })
                    .catch(() => {})
                    .then(() => {
                        this.requests--;
                    });
            },
        },
    };
</script>
