<template>
    <div>
        <div id="cart-review" class="frame px-0 pt-0">
            <template>
                <div :class="[isLateralMenu ? '' : '']" class="carrier-discount-teste-ab">
                    <CartShipping
                        v-if="cart && !cart.is_only_virtual_products"
                        :enableExpressShipping="enableExpressShipping"
                        :loading="loading"
                        :cart="cart"
                        :cart-has-prime="cart.has_prime"
                        :is-lateral-menu="isLateralMenu"
                        :is-mobile="isMobile"
                        class="frame px-0"
                        @free-shipping-calc-obj="updateFreeShippingCalcObj($event)"
                    />
                    <CartDiscount
                        :cart="cart"
                        :is-lateral-menu="isLateralMenu"
                        :is-mobile="isMobile"
                        @update-cart="updateCart()"
                        @loading-cart="$emit('loading-cart')"
                        @done-update-cart="$emit('done-update-cart')"
                    />
                    <div
                        class="cart-cashback-toggle text-uppercase d-flex flex-justify-between mt-3"
                        v-if="cart.total_cashback_allowed_for_use > 0"
                    >
                        <p class="mx-0 my-0">
                            Usar saldo de cashback:
                            {{ cart.total_cashback_allowed_for_use | formatMoney }}
                            <i
                                v-if="
                                    $scope.cashback_available > cart.total_cashback_allowed_for_use
                                "
                                class="icon-cr icon-info cursor-pointer"
                                style="font-size: 1.2em"
                                @click="cashbackRulesModal = true"
                            ></i>
                        </p>

                        <input
                            id="use-cashback-switch"
                            class="cr-switch"
                            type="checkbox"
                            :checked="cart.use_cashback"
                            @change="toggleCashback"
                        />
                        <label for="use-cashback-switch" class="d-inline-block"> </label>
                    </div>
                </div>
            </template>

            <template v-if="cart">
                <div class="values">
                    <div class="d-flex flex-align-center">
                        <p class="ml-0 my-0 mr-auto" style="font-size: 15px">PRODUTOS</p>
                        <div class="text-right">
                            <p class="text-right mx-0 my-0" style="font-size: 15px">
                                <span>{{ $f.formatMoney(cart.total_products) }}</span>
                            </p>
                        </div>
                    </div>

                    <!--                    <div class="d-flex hidden">-->
                    <!--                        <p class="ml-0 my-0 mr-auto" style="font-size: 15px">-->
                    <!--                            FRETE-->
                    <!--                        </p>-->
                    <!--                        <div class="text-right">-->
                    <!--                            <p class="mx-0 my-0" style="font-size: 15px">-->
                    <!--                                <span class="cursor-pointer" @click='calculateShipping'><u id="display-shipping">calcular</u></span>-->
                    <!--                            </p>-->
                    <!--                        </div>-->
                    <!--                    </div>-->

                    <div class="d-flex" v-if="cart.total_discounts > 0">
                        <p class="ml-0 my-0 mr-auto" style="font-size: 15px">DESCONTO</p>
                        <div class="text-right">
                            <p class="mx-0 my-0" style="font-size: 15px">
                                <span>- {{ $f.formatMoney(cart.total_discounts) }}</span>
                            </p>
                        </div>
                    </div>

                    <div class="d-flex">
                        <p class="ml-0 my-0 mr-auto mt-2" style="font-size: 15px">
                            <strong>TOTAL</strong>
                        </p>
                        <div id="cart-finish-price" class="text-right">
                            <p class="ml-0 my-0 mr-auto" style="font-size: 15px">
                                <strong>
                                    {{
                                        $f.formatMoney(
                                            Math.max(0, cart.total_products - cart.total_discounts)
                                        )
                                    }}
                                </strong>
                            </p>
                            <p
                                v-if="
                                    Math.max(0, cart.total_products - cart.total_discounts) >= 20 &&
                                    $scope.IS_REGULAR_CUSTOMER &&
                                    $f.formatCreditCardInstallments(
                                        cart.total_products - cart.total_discounts
                                    )
                                "
                                class="text-secondary mx-0 my-0 text-yanone hidden-xs-down"
                            >
                                Ou
                                {{
                                    $f.formatCreditCardInstallments(
                                        cart.total_products - cart.total_discounts
                                    )
                                }}
                                sem juros
                                <br />
                                <small
                                    v-if="
                                        $scope.CONFIG['CREDIT_CARD_MINIMUN_INSTALLMENT_VALUE'] > 5
                                    "
                                    >(Parcela mínima: R$ 30)</small
                                >
                            </p>
                        </div>
                    </div>
                </div>

                <div
                    class="text-uppercase d-flex flex-align-center mt-4 mb-3"
                    v-if="$scope.IS_REGULAR_CUSTOMER && cart.total_cashback_generated > 0"
                >
                    <i class="icon-cr icon-cashback mr-1 text-cashback" style="font-size: 20px" />
                    <p class="text-cashback">
                        Essa compra gera {{ cart.total_cashback_generated | formatMoney }} de
                        cashback
                    </p>
                </div>

                <div
                    class="finish-cart-AB fixed-button"
                    :class="[showButton ? 'positioned-button' : '']"
                    v-if="isLateralMenu"
                >
                    <div class="d-flex flex-column" style="margin: 1rem">
                        <p class="ml-0 my-0 mr-auto mt-2" style="font-size: 14px; color: gray">
                            TOTAL
                        </p>
                        <div>
                            <p class="ml-0 my-0 mr-auto" style="font-size: 16px">
                                <strong>
                                    {{
                                        $f.formatMoney(
                                            Math.max(0, cart.total_products - cart.total_discounts)
                                        )
                                    }}
                                </strong>
                            </p>
                        </div>
                    </div>
                    <button class="btn btn-xl btn-wide mt" @click="$emit('finish-order')">
                        {{ $t('_shop.toFinishOrder') }}
                        <span><i class="animation-go-back icon-cr icon-arrow-right ml-2"></i></span>
                    </button>
                </div>

                <div id="cart-finish">
                    <template v-if="cart.enable_checkout">
                        <div
                            v-if="isLateralMenu"
                            class="d-flex flex-justify-around flex-align-center mt-4"
                        >
                            <div class="flex-item-grow">
                                <button
                                    style="
                                        background-color: black;
                                        color: white;
                                        font-size: 13px;
                                        border: 1px solid;
                                        border-radius: unset;
                                    "
                                    class="btn btn-wide d-flex flex-justify-center flex-align-center"
                                    @click="$emit('finish-order')"
                                >
                                    <div class="mr-2">
                                        {{ $t('_shop.toFinishOrder') }}
                                    </div>
                                    <span
                                        ><i
                                            class="animation-go-back icon-cr icon-arrow-right ml-2"
                                        ></i
                                    ></span>
                                </button>
                            </div>
                        </div>
                        <button
                            v-if="!isLateralMenu"
                            class="btn btn-xl btn-wide mt"
                            @click="$emit('finish-order')"
                        >
                            {{ $t('_shop.toFinishOrder') }}
                            <span
                                ><i class="animation-go-back icon-cr icon-arrow-right ml-2"></i
                            ></span>
                        </button>
                    </template>

                    <template v-else>
                        <Alert v-if="$scope.IS_REGULAR_CUSTOMER" :alert="blockCheckoutAlert" />

                        <div class="d-flex flex-justify-around flex-align-center mt-3">
                            <div class="flex-item-grow">
                                <button class="btn btn-wide btn-xl" disabled>
                                    {{
                                        $t(
                                            $scope.IS_REGULAR_CUSTOMER
                                                ? '_shop.toFinishOrder'
                                                : '_shop.minimumValueNotReached'
                                        )
                                    }}
                                    <span class="ml-3" style="font-size: 18px">→</span>
                                </button>
                            </div>
                        </div>
                    </template>

                    <template v-if="!isLateralMenu">
                        <a href="/">
                            <button
                                style="
                                    background-color: white;
                                    color: black;
                                    font-size: 13px;
                                    border: 1px solid;
                                    border-radius: unset;
                                "
                                class="btn btn-wide mt"
                                @click=""
                            >
                                {{ $t('_shop.toKeepBuying') }}
                            </button>
                        </a>

                        <a
                            v-if="!isMobile"
                            class="text-right text-yanone mt pos-relative mt-2 carrier-international"
                            @click="$emit('change-shipping-international')"
                        >
                            <span>
                                <p class="cart-security text-underline">
                                    Sua entrega é fora do Brasil?
                                </p>
                            </span>
                        </a>
                    </template>
                </div>
            </template>
        </div>

        <Modal v-model="cashbackRulesModal">
            <template v-slot:title> Regulamento Cashback </template>
            <div class="card-block">
                <CashbackFaq :is-hotsite="false" />
            </div>
        </Modal>

        <div
            class="finish-cart-AB fixed-button"
            :class="[showButton ? 'positioned-button' : '']"
            v-if="!isLateralMenu"
            v-show="isMobile"
        >
            <div class="d-flex flex-column" style="margin: 1rem">
                <p class="ml-0 my-0 mr-auto mt-2" style="font-size: 14px; color: gray">TOTAL</p>
                <div>
                    <p class="ml-0 my-0 mr-auto" style="font-size: 16px">
                        <strong>
                            {{
                                $f.formatMoney(
                                    Math.max(0, cart.total_products - cart.total_discounts)
                                )
                            }}
                        </strong>
                    </p>
                </div>
            </div>
            <button class="btn btn-xl btn-wide mt" @click="$emit('finish-order')">
                {{ $t('_shop.toFinishOrder') }}
                <span><i class="animation-go-back icon-cr icon-arrow-right ml-2"></i></span>
            </button>
        </div>
    </div>
</template>
<script>
    import Spinner from '@bit/chicorei.chicomponents.feedback.spinner';
    import { getScope, readCookie, setCookie, EventBus } from '../../../common/common';
    import Alert from '../common/Alert';
    import CartShipping from './CartShipping';
    import CartDiscount from './CartDiscount';
    import CashbackFaq from '../cashback/CashbackFaq.vue';
    import Modal from '../common/container/Modal.vue';
    import throttle from 'lodash/throttle';

    export default {
        name: 'CartReview',

        components: {
            Modal,
            CashbackFaq,
            Spinner,
            Alert,
            CartShipping,
            CartDiscount,
        },

        props: ['loading', 'cart', 'enableExpressShipping', 'isLateralMenu', 'isMobile'],

        data() {
            return {
                cashbackRulesModal: false,

                //teste AB nova versao
                showButton: false,
                throttledScrollEvent: null,
            };
        },

        mounted() {
            this.throttledScrollEvent = throttle(this.handleScroll, 500);
            if (this.isLateralMenu) {
                const sidebarContent = document.getElementById('sidebar-content');
                sidebarContent.addEventListener('scroll', this.throttledScrollEvent, {
                    passive: true,
                });
            } else {
                window.addEventListener('scroll', this.throttledScrollEvent, {
                    passive: true,
                });
            }
        },

        beforeDestroy() {
            if (this.throttledScrollEvent) {
                if (this.isLateralMenu) {
                    const sidebarContent = document.getElementById('sidebar-content');
                    sidebarContent.removeEventListener('scroll', this.throttledScrollEvent);
                } else {
                    window.removeEventListener('scroll', this.throttledScrollEvent);
                }
            }
        },

        computed: {
            blockCheckoutAlert() {
                return this.cart && !this.cart.enable_checkout
                    ? {
                          message: this.cart.block_checkout_message,
                          isError: true,
                          timeout: 0,
                      }
                    : null;
            },
        },

        methods: {
            handleScroll() {
                const finishCartDiv = document
                    .getElementById('cart-finish')
                    .getBoundingClientRect().top;

                if (finishCartDiv <= window.innerHeight && !this.showButton) {
                    this.showButton = true; // Mostrar o botão posicionado
                } else if (finishCartDiv > window.innerHeight && this.showButton) {
                    this.showButton = false; // Mostrar o botão fixo
                }
            },

            updateCart() {
                this.$emit('update-cart');
            },

            calculateShipping() {
                EventBus.$emit('calculate-shipping');
            },

            toggleCashback() {
                this.cart.use_cashback = !this.cart.use_cashback;
                this.$emit('update-cashback');
            },

            updateFreeShippingCalcObj(obj) {
                this.$emit('update-free-shipping-calc-obj', obj);
            },
        },
    };
</script>
