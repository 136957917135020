<template>
    <LoadingButton
        v-tooltip="tooltip"
        :class="{ 'fade-4': isSizeUnavailable }"
        :loading="addingToCart"
        class="btn btn-xl btn-image font-roboto-c"
        @click.native="buy"
    >
        <span v-show="!addingToCart"> &nbsp;{{ text }} </span
        ><i class="animation-go-back icon-cr icon-arrow-right ml-2"></i>
    </LoadingButton>
</template>

<script>
    import { cartStore } from '../../common/store/cart-store';
    import { productStore } from '../../common/store/product-store';
    import LoadingButton from '../common/LoadingButton';

    export default {
        name: 'ProductBuyButton',

        components: { LoadingButton },

        props: {
            product: {
                type: Object,
                required: true,
            },
            sizeToBuy: Object,
            wholesalerSizesQuantitiesToBuy: Object,
            disableTooltip: Boolean,
            isSizeUnavailable: {
                type: Boolean,
                default: false,
            },
            text: {
                type: String,
                required: true,
                default: 'Comprar',
            },
        },

        data() {
            return {
                productStoreState: productStore.state,
                cartStoreState: cartStore.state,
                facebookEventId: null,
            };
        },

        computed: {
            addingToCart() {
                return this.cartStoreState.updatingCart;
            },

            price() {
                return this.product.price_min > 0 ? this.product.price_min : this.product.price;
            },

            hasSelectedSize() {
                if (this.$scope.IS_REGULAR_CUSTOMER) {
                    return this.sizeToBuy !== null && this.sizeToBuy !== undefined;
                } else {
                    for (const variationId in this.wholesalerSizesQuantitiesToBuy) {
                        if (
                            this.wholesalerSizesQuantitiesToBuy.hasOwnProperty(variationId) &&
                            this.wholesalerSizesQuantitiesToBuy[variationId] > 0
                        )
                            return true;
                    }

                    return false;
                }
            },

            tooltip() {
                if (!this.disableTooltip && !this.hasSelectedSize) {
                    return this.$t(
                        this.product.is_clothing
                            ? '_product.selectOptionAbove'
                            : '_product.selectDesiredSize'
                    );
                } else if (this.isSizeUnavailable) {
                    return this.$t('_product.colorIsUnavailable', [
                        this.sizeToBuy.product_color_name,
                    ]);
                }

                return null;
            },
        },

        methods: {
            buy() {
                if (this.hasSelectedSize && !this.isSizeUnavailable) {
                    this.reportToTagManager();
                    this.addToCart();
                } else {
                    this.$emit('request-size');
                }
            },

            reportToTagManager() {
                let reportItems = [];
                let reportItemsV4 = [];

                if (!this.$scope.IS_REGULAR_CUSTOMER) {
                    for (let variationId in this.wholesalerSizesQuantitiesToBuy) {
                        if (
                            !this.wholesalerSizesQuantitiesToBuy.hasOwnProperty(variationId) ||
                            this.wholesalerSizesQuantitiesToBuy[variationId] <= 0
                        )
                            continue;

                        variationId = parseInt(variationId);
                        const variation = this.product.variations.find(
                            (i) => i.variation_id === variationId
                        );
                        const variationInfo = this.buildEventVariationInfo(variation);
                        window.dataLayer.push(this.buildCartProductEvent(variationInfo));
                        reportItems.push(
                            this.buildCartEventItem(
                                variationInfo,
                                this.wholesalerSizesQuantitiesToBuy[variationId]
                            )
                        );
                        reportItemsV4.push(
                            this.buildCartEventItemV4(
                                variationInfo,
                                this.wholesalerSizesQuantitiesToBuy[variationId]
                            )
                        );
                    }
                } else {
                    const variationInfo = this.buildEventVariationInfo(this.sizeToBuy);
                    window.dataLayer.push(this.buildCartProductEvent(variationInfo));
                    reportItems.push(this.buildCartEventItem(variationInfo));
                    reportItemsV4.push(this.buildCartEventItemV4(variationInfo));
                }

                window.dataLayer.push({
                    event: 'addToCart',
                    ecommerce: {
                        currencyCode: 'BRL',
                        add: { products: reportItems },
                    },
                });

                window.dataLayer.push({ ecommerce_v4: null });
                window.dataLayer.push({
                    event: 'add_to_cart',
                    ecommerce_v4: {
                        items: reportItemsV4,
                    },
                });
            },

            buildEventVariationInfo(variation) {
                const info = {};

                info.sizeName = variation.size_name === 'Único' ? 'Padrão' : variation.size_name;

                switch (variation.gender) {
                    case 'M':
                        info.genderName = 'Masculino';
                        break;
                    case 'F':
                        info.genderName = 'Feminino';
                        break;
                    case 'U':
                        info.genderName = 'Unisex';
                        break;
                    case 'I':
                        info.genderName = 'Infantil';
                        break;
                    default:
                        info.genderName = '';
                }

                info.colorName = variation.product_color_name;

                return info;
            },

            buildCartProductEvent(sizeInfo) {
                const event_id = 'ADTC_' + this.product.id;
                this.facebookEventId = event_id;
                return {
                    event: 'addToCartProduct',
                    'event-id-fb': event_id,
                    'product-size-name': sizeInfo.sizeName,
                    'product-gender-name': sizeInfo.genderName,
                };
            },

            buildCartEventItem(variationInfo, quantity = 1) {
                const reportProduct = {
                    name: this.product.name,
                    id: `${this.product.id}`,
                    price: `${this.price}`,
                    brand: 'Chico Rei',
                    category: this.category,
                    quantity,
                };

                reportProduct.variant = this.buildVariantReportItem(
                    variationInfo.genderName,
                    variationInfo.sizeName,
                    variationInfo.colorName
                );

                return reportProduct;
            },

            buildCartEventItemV4(variationInfo, quantity = 1) {
                const reportProductV4 = {
                    item_name: this.product.name,
                    item_id: `${this.product.id}`,
                    price: `${this.price}`,
                    item_brand: 'Chico Rei',
                    item_category: this.product.type.name,
                    item_category2: variationInfo.genderName,
                    item_category3: variationInfo.sizeName,
                    item_category4: variationInfo.colorName,
                    quantity: quantity,
                };

                reportProductV4.variant = this.buildVariantReportItem(
                    variationInfo.genderName,
                    variationInfo.sizeName,
                    variationInfo.colorName
                );

                return reportProductV4;
            },

            buildVariantReportItem(genderName, sizeName, colorName) {
                return `${genderName}-${sizeName}-${colorName}`
                    .replace('--', '-')
                    .replace(/-$/, '')
                    .replace(/^-/, '');
            },

            getCartBodyRequest() {
                const products = [];
                if (!this.$scope.IS_REGULAR_CUSTOMER) {
                    for (const variationId in this.wholesalerSizesQuantitiesToBuy) {
                        if (
                            !this.wholesalerSizesQuantitiesToBuy.hasOwnProperty(variationId) ||
                            this.wholesalerSizesQuantitiesToBuy[variationId] <= 0
                        )
                            continue;

                        products.push({
                            product_id: this.product.id,
                            variation_id: variationId,
                            quantity: this.wholesalerSizesQuantitiesToBuy[variationId],
                        });
                    }
                } else {
                    products.push({
                        product_id: this.product.id,
                        variation_id: this.sizeToBuy.variation_id,
                        quantity: 1,
                    });
                }

                const data = new FormData();
                data.append('json', '1');
                data.append('products', JSON.stringify(products));
                data.append('fb_event_id', this.facebookEventId);
                return data;
            },

            addToCart() {
                this.$emit('busy');
                cartStore
                    .addProduct(this.getCartBodyRequest())
                    .then((response) => {
                        this.$scope.cart_id = response.data.cart_id;
                        this.$emit('done');
                    })
                    .catch((error) => {
                        this.$emit('fail');
                        const alert = {
                            message: `${this.$t('_product.colorIsUnavailable')} ${this.$t(
                                'tryAgainLaterContact'
                            )}`,
                            isError: true,
                        };
                        if (error.response) {
                            alert.message = error.response.data.message;
                        }
                        this.$emit('alert', alert);
                    });
            },
        },
    };
</script>