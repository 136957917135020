<template>
    <div id="cart-organization-donation" :class="{ loading }">
        <div class="frame-outline px-0 py-0 mb">
            <div class="px py text-yanone text-uppercase d-flex">
                <p class="mr-2 my-0">
                    <i class="icon-cr icon-like"></i>
                </p>
                <div class="flex-item-grow">
                    <p
                        :class="{
                            opened: !showOrganizationSelection && donateTo,
                        }"
                        class="mx-0 my-0 collapsible"
                    >
                        <span v-if="donateTo">
                            Parte da renda do seu pedido vai para a instituição
                            <strong>{{ donateTo.name }}</strong
                            >!
                        </span>
                        <br />
                        <a
                            class="text-accent cursor-pointer"
                            v-if="organizations && (organizations || []).length > 1"
                            @click="showOrganizationSelection = true"
                        >
                            Clique aqui para alterar a instituição
                        </a>
                    </p>

                    <div
                        :class="{
                            opened: showOrganizationSelection || !donateTo,
                        }"
                        class="collapsible"
                    >
                        <p class="mt-0">
                            Selecione uma instituição para beneficiar com seu pedido:
                        </p>

                        <ul class="organization-options">
                            <li v-for="org in organizations" :key="org.id">
                                <input
                                    :id="`organization-${org.id}`"
                                    v-model="actualOrganization"
                                    :value="org.id"
                                    class="cr-radio"
                                    type="radio"
                                />
                                <label :for="`organization-${org.id}`">
                                    <div class="organization-option-content">
                                        <p class="text-bold">{{ org.name }}</p>
                                        <p class="text-secondary text-no-transform">
                                            {{ org.cart_message }}
                                        </p>
                                    </div>
                                </label>
                            </li>
                        </ul>

                        <div class="mt text-right">
                            <button
                                v-if="donateTo"
                                class="btn btn-light"
                                @click="showOrganizationSelection = false"
                            >
                                Cancelar
                            </button>
                            <button :disabled="!actualOrganization" class="btn" @click="submit()">
                                Selecionar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Radio from '@bit/chicorei.chicomponents.input.radio';

    export default {
        name: 'CartOrganizationDonation',

        props: ['organizations', 'selectedOrganization', 'loading'],
        components: { Radio },

        data() {
            return {
                showOrganizationSelection: false,
                actualOrganization: null,
            };
        },

        watch: {
            loading(newVal) {
                if (!newVal) this.showOrganizationSelection = false;
            },

            donateTo: {
                immediate: true,
                handler(to) {
                    this.actualOrganization = (to || {}).id;
                },
            },
        },

        computed: {
            donateTo() {
                if (!this.selectedOrganization) return null;

                const organizationIndex = (this.organizations || []).findIndex(
                    (o) => o.id == this.selectedOrganization
                );

                return organizationIndex >= 0 ? this.organizations[organizationIndex] : null;
            },
        },

        methods: {
            submit: function () {
                if (!this.actualOrganization) return;
                this.$emit('organization-selected', this.actualOrganization);
            },
        },
    };
</script>