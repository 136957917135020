<template>
    <div class="header">
        <div class="h-full flex-column">
            <nav class="header-drawer-items">
                <a href="/minas-para-o-mundo" class="h5 my-0 text-uppercase">Quem Somos</a>
            </nav>
            <nav class="header-drawer-items">
                <a href="/sustentabilidade" class="h5 my-0 text-uppercase">Sustentabilidade</a>
            </nav>
            <nav class="header-drawer-items">
                <a href="/nossas-lojas" class="h5 my-0 text-uppercase">Nossas Lojas</a>
            </nav>
            <nav class="header-drawer-items">
                <a href="/trabalhe-conosco" class="h5 my-0 text-uppercase">Trabalhe Aqui</a>
            </nav>
            <nav class="header-drawer-items">
                <a href="https://blog.chicorei.com" target="_blank" class="h5 my-0 text-uppercase"
                    >Blog</a
                >
            </nav>

            <nav class="header-drawer-items">
                <a href="/chicundum" target="_blank" class="h5 my-0 text-uppercase">Chicundum</a>
            </nav>

            <nav class="header-drawer-items">
                <a href="/central-de-ajuda/" class="h5 my-0 text-uppercase">Central de ajuda</a>
            </nav>
            <nav class="header-drawer-items">
                <a href="/fale-conosco" class="h5 my-0 text-uppercase">Fale conosco</a>
            </nav>
            <nav class="header-drawer-items">
                <a href="/guia-de-tamanhos" target="_blank" class="h5 my-0 text-uppercase">Guia de tamanhos</a>
            </nav>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'HeaderSideAbout',

        components: {},

        data() {
            return {};
        },

        mounted() {},

        watch: {},

        computed: {},

        methods: {},
    };
</script>
