<template>
    <div v-if="indicatorLeftPosition !== null && variablesSet" class="text-center">
        <div class="pos-relative" id="scroll-button">
            <div
                class="scrollbar-box-shadow-left"
                :style="{
                    display: indicatorLeftPosition > 0 && showButtons ? 'initial' : 'none',
                    width: indicatorLeftPosition > 0 && showButtons ? 'initial' : 0,
                    height: `${containerHeight}px`,
                }"
                @click="scrollButton(-indexDelta)"
            >
                <button class="scrollbar-button-left hidden-touch">
                    <i class="icon-cr icon-arrow-right" style="transform: rotate(180deg)" />
                </button>
            </div>
            <div
                class="scrollbar-box-shadow-right"
                :style="{
                    display: indicatorLeftPosition !== 100 && showButtons ? 'initial' : 'none',
                    width: indicatorLeftPosition !== 100 && showButtons ? 'initial' : 0,
                    height: `${containerHeight}px`,
                }"
                @click="scrollButton(+indexDelta)"
            >
                <button class="scrollbar-button-right hidden-touch">
                    <i class="icon-cr icon-arrow-right" />
                </button>
            </div>
        </div>
        <div
            :style="`width: ${barWidth}px; display: ${showButtons ? '' : 'none !important'}`"
            class="horizontal-scrollbar d-inline-block"
        >
            <div class="pos-relative h-full" style="margin: 0 14px">
                <div
                    :style="indicatorStyle"
                    class="horizontal-scrollbar-thumb pos-absolute bg-default-5 h-full"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import cr$ from '../../../../common/crquery';
    import debounce from 'lodash/debounce';

    const BAR_WIDTH = 280;
    const THUMB_WIDTH = 28;

    export default {
        name: 'HorizontalScrollbar',

        props: {
            scrollEvent: Event,
            buttonHeight: {
                type: Number,
                default: 0,
            },
            idList: String,
        },

        data() {
            return {
                barWidth: BAR_WIDTH,
                indicatorLeftPosition: 0,
                index: 0,
                showButtons: false,
                containerHeight: null,
                listCrById: null,
                listItems: null,
                itemCrEl: null,
                listContainerCrEl: null,
                listItemContainerCrEl: null,
                containerDimension: 1,
                itemDimension: 1,
                variablesSet: false,
                resizeObserver: null,
                indexDelta: this.idList === 'hotsite-list' ? 1 : 2,
            };
        },

        watch: {
            scrollEvent(event) {
                const scrollRange = event.target.scrollWidth - event.target.clientWidth;
                if (event.target.scrollWidth > event.target.clientWidth) {
                    this.indicatorLeftPosition = (event.target.scrollLeft / scrollRange) * 100;
                } else {
                    this.indicatorLeftPosition = null;
                }
            },
        },

        computed: {
            indicatorStyle() {
                return {
                    width: THUMB_WIDTH + 'px',
                    left: this.indicatorLeftPosition + '%',
                    transform: 'translateX(-14px)',
                };
            },
        },

        mounted() {
            const updateVariables = debounce(() => {
                this.setVariables();
            }, 200);

            this.resizeObserver = new ResizeObserver(updateVariables);

            this.$nextTick(() => {
                this.listCrById = cr$.byId(this.idList);

                updateVariables();

                this.resizeObserver.observe(this.listCrById.raw);
            });
        },

        beforeDestroy() {
            this.resizeObserver.unobserve(this.listCrById.raw);
        },

        methods: {
            setVariables() {
                const listCaseId = (this.idList || '').replace(/-(hs-.*)/, '-hs');

                switch (listCaseId) {
                    //Caso use product-horizontal-list
                    case 'home-week-highlights':
                    case 'last-released-wholesaler':
                    case 'recommendation-list':
                        this.listItems = this.listCrById.children().children().raw;
                        this.itemCrEl = this.listItems[0];
                        this.listContainerCrEl = this.listCrById.children();
                        this.listItemContainerCrEl = this.listCrById.children().children();
                        this.containerDimension = this.listContainerCrEl.raw[0].offsetWidth;
                        if (this.listItemContainerCrEl.exists()) {
                            this.itemDimension =
                                this.listItemContainerCrEl.raw[this.index].offsetWidth;
                        }
                        break;
                    case 'featured-products-hs':
                        this.listItems = this.listCrById.children().raw;
                        this.itemCrEl = this.listItems[0];
                        this.listContainerCrEl = this.listCrById.children();
                        this.listItemContainerCrEl = this.listCrById.children();
                        this.containerDimension = this.listContainerCrEl.raw[0].offsetWidth;
                        if (this.listItemContainerCrEl.exists()) {
                            this.itemDimension =
                                this.listItemContainerCrEl.raw[this.index].offsetWidth;
                        }
                        break;
                    //Caso use horizontal-list
                    case 'categories-list':
                    case 'other-products-list':
                        this.listItems = this.listCrById.children().raw;
                        this.itemCrEl = this.listItems[0];
                        this.listContainerCrEl = this.listCrById;
                        this.listItemContainerCrEl = this.listCrById.children();
                        this.containerDimension = this.listContainerCrEl.raw.offsetWidth;
                        if (this.listItemContainerCrEl.exists()) {
                            this.itemDimension = this.listItemContainerCrEl.raw[0].offsetWidth + 10;
                        }
                        break;
                    case 'img-list':
                    case 'hotsite-list':
                        this.listItems = this.listCrById.children().raw;
                        this.itemCrEl = this.listItems[0];
                        this.listContainerCrEl = this.listCrById;
                        this.listItemContainerCrEl = this.listCrById.children();
                        this.containerDimension = this.listContainerCrEl.raw.offsetWidth;
                        if (this.listItemContainerCrEl.exists()) {
                            this.itemDimension =
                                this.listItemContainerCrEl.raw[this.index].offsetWidth;
                        }
                        break;
                    default:
                        this.listItems = this.listCrById.children().raw;
                        this.itemCrEl = this.listItems[0].children;
                        this.listContainerCrEl = this.listCrById;
                        this.listItemContainerCrEl = this.listCrById.children().children();
                        this.containerDimension = this.listContainerCrEl.raw.offsetWidth;
                        if (this.listItemContainerCrEl.exists()) {
                            this.itemDimension = this.listItemContainerCrEl.raw[0].offsetWidth + 15;
                        }
                        break;
                }
                this.containerHeight = this.listCrById.raw.offsetHeight;
                this.showButtons =
                    this.listItems.length * this.itemDimension - this.itemDimension * 0.3 >
                    this.containerDimension;
                this.variablesSet = true;
            },

            scrollButton(indexDelta) {
                this.index = this.index + indexDelta;
                let itemPercentage = this.itemDimension / this.containerDimension;
                const imagesThatFit = Math.floor(1 / itemPercentage);
                const imagesThatDontFit = Math.max(0, this.listItems.length - imagesThatFit);
                const maxPercentage = itemPercentage * imagesThatDontFit;
                let requestedPercentage = Math.max(
                    0,
                    Math.min(maxPercentage, this.index * itemPercentage)
                );
                const scrollValue = requestedPercentage * this.containerDimension;
                this.listContainerCrEl.scrollTo(scrollValue, 0, 400);
            },
        },
    };
</script>
