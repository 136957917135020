<template>
    <div :class="{ 'flex-md-12': observations || productTypeId === 1 }" class="flex-xs-12">
        <h3  v-for="item in modelings" class='btn-title-sizes'>
            <button
                v-on:click="$emit('input', item)"
                type="button"
                :class="{ 'btn-light': selectedModeling !== item }"
                class="btn mr-2 hidden-md-down"
            >
                {{ item.name }}
            </button>
        </h3>

        <div class="cr-select text-center pos-center-x select-model-group">
            <select
                v-model="innerValue"
                class="cr-toggler mx-0 my-0 hidden-md-up grayColor select-model text-uppercase"
            >
                <option v-for="modeling in modelings" :value="modeling">
                    {{ modeling.name }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProductSizesSelectTable',

        props: {
            selectedModeling: Object,
            modelings: Array,
            observations: Array,
            product: {
                type: Object,
                required: true,
            },
            productTypeId: Number,
            value: {
                default: null,
            },
        },

        data() {
            return {
                innerValue: null,
            };
        },

        watch: {
            value: {
                handler: function (newValue) {
                    this.innerValue = newValue;
                },
                immediate: true,
            },

            innerValue(newValue) {
                this.$emit('input', newValue);
            },
        },
    };
</script>
