<template>
    <a
        :href="item.url"
        class="header-collection-item h5 mr mt-0"
        :class="{ 'text-bold': item.is_bold !== '0' }"
    >
        {{ item.title }}
        <span v-if="item.is_new !== '0'" class="text-uppercase text-bold text-primary-1 seal-new"
            >novo!</span
        >
        <span v-if="item.is_arrow !== '0'"><i class="icon-cr icon-arrow-right"></i></span>
    </a>
</template>

<script>
    export default {
        name: 'HeaderDesktopContentItem',

        props: {
            item: {
                type: Object,
                required: true,
            },
        },
    };
</script>
