<template>
    <div>
        <p v-if="$scope.IS_WHOLESALER || $scope.IS_SALES_REP" class="product-list-item-price">
            R${{ formatPrice(product.price) }}
        </p>
        <template v-else-if="$scope.IS_REGULAR_CUSTOMER">
            <template v-if="isCombo">
                <p class="text-bold">
                    {{ $f.formatMoney(product.price) }}
                    <span v-if="isCombo">{{ $t('or').toLowerCase() }}</span>
                </p>
                <p class="product-list-item-price text-primary" :class="{ 'edit-iOS': isIOS }">
                    <span content="BRL" itemprop="priceCurrency"> R$ </span>
                    <span :content="product.promo_combo_unit_price" itemprop="price">
                        {{ formatPrice(product.promo_combo_unit_price) }}
                    </span>
                </p>
                <p class="text-info-4">{{ product.promo_instruction }}</p>
            </template>
            <template v-else>
                <div class="d-flex flex-align-center flex-justify-start">
                    <p v-if="hasDiscount && (!options || !options.hidePriceOld)">
                        <del>R$ {{ formatPrice(product.price_old) }}</del>
                    </p>
                    <div v-if="product.price_min > 0 && product.price_min < product.price_max">
                        <p class="text-bold text-secondary">A partir de</p>
                        <p
                            class="product-list-item-price text-default-5"
                            :class="{ 'ml-3': hasDiscount, 'edit-iOS': isIOS }"
                        >
                            <span content="BRL" itemprop="priceCurrency"> R$ </span>
                            <span :content="product.price_min" itemprop="price">
                                {{ formatPrice(product.price_min) }}
                            </span>
                        </p>
                    </div>
                    <p
                        v-else
                        class="product-list-item-price text-default-5"
                        :class="{
                            'ml-3': hasDiscount && (!options || !options.hidePriceOld),
                            'edit-iOS': isIOS,
                        }"
                    >
                        <span content="BRL" itemprop="priceCurrency"> R$ </span>
                        <span :content="product.price" itemprop="price">
                            {{ formatPrice(product.price) }}
                        </span>
                    </p>
                    <p
                        v-if="
                            (!options || !options.hideInstallments) &&
                            product.price_min == product.price_max &&
                            product.price >= 20 &&
                            !hasDiscount &&
                            isMobile
                        "
                        class="ml-4 text-default-3 hidden-xs"
                    >
                        4x {{ $f.formatMoney(product.price / 4) }}
                    </p>
                    <p
                        v-else-if="
                            (!options || !options.hideInstallments) &&
                            product.price_min == product.price_max &&
                            product.price >= 20 &&
                            !isMobile
                        "
                        class="ml-4 text-default-3 hidden-xs"
                    >
                        4x {{ $f.formatMoney(product.price / 4) }}
                    </p>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
    export default {
        name: 'ProductCatalogPrice',

        props: {
            product: {
                type: Object,
                required: true,
            },
            options: Object,
            showPromo: {
                type: Boolean,
                defaut: false,
            },
        },

        data() {
            return {
                isMobile: null,
            };
        },

        mounted() {
            this.isMobile = window.screen.width <= 425;
        },

        computed: {
            isCombo() {
                return (
                    this.showPromo &&
                    this.product.promo_instruction &&
                    this.product.promo_combo_unit_price &&
                    [25, 28].includes(this.product.promo_combo)
                );
            },

            hasDiscount() {
                return (
                    !this.product.price_min &&
                    !this.product.price_max &&
                    this.product.price_old > this.product.price &&
                    this.$scope.IS_REGULAR_CUSTOMER &&
                    (this.options || {}).displayPercent &&
                    (this.options || {}).lang_iso != 'en'
                );
            },

            isIOS() {
                const userAgent = navigator.userAgent;
                return (
                    userAgent.includes('iPhone') ||
                    userAgent.includes('iPad') ||
                    userAgent.includes('iPod')
                );
            },
        },

        methods: {
            /** OBS @jorge: Não substituir por $f.formatMoney (o "R$" está no template devido ao schema.org/Offer) **/
            formatPrice(value) {
                let val = (value / 1).toFixed(2).replace('.', ',');
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            },
        },
    };
</script>
