var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.indicatorLeftPosition !== null && _vm.variablesSet)?_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"pos-relative",attrs:{"id":"scroll-button"}},[_c('div',{staticClass:"scrollbar-box-shadow-left",style:({
                display: _vm.indicatorLeftPosition > 0 && _vm.showButtons ? 'initial' : 'none',
                width: _vm.indicatorLeftPosition > 0 && _vm.showButtons ? 'initial' : 0,
                height: `${_vm.containerHeight}px`,
            }),on:{"click":function($event){return _vm.scrollButton(-_vm.indexDelta)}}},[_vm._m(0)]),_vm._v(" "),_c('div',{staticClass:"scrollbar-box-shadow-right",style:({
                display: _vm.indicatorLeftPosition !== 100 && _vm.showButtons ? 'initial' : 'none',
                width: _vm.indicatorLeftPosition !== 100 && _vm.showButtons ? 'initial' : 0,
                height: `${_vm.containerHeight}px`,
            }),on:{"click":function($event){return _vm.scrollButton(+_vm.indexDelta)}}},[_vm._m(1)])]),_vm._v(" "),_c('div',{staticClass:"horizontal-scrollbar d-inline-block",style:(`width: ${_vm.barWidth}px; display: ${_vm.showButtons ? '' : 'none !important'}`)},[_c('div',{staticClass:"pos-relative h-full",staticStyle:{"margin":"0 14px"}},[_c('div',{staticClass:"horizontal-scrollbar-thumb pos-absolute bg-default-5 h-full",style:(_vm.indicatorStyle)})])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"scrollbar-button-left hidden-touch"},[_c('i',{staticClass:"icon-cr icon-arrow-right",staticStyle:{"transform":"rotate(180deg)"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"scrollbar-button-right hidden-touch"},[_c('i',{staticClass:"icon-cr icon-arrow-right"})])
}]

export { render, staticRenderFns }