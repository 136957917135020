<template>
    <div v-if="images" id="product-images">
        <div id="product-images-list-container" class="hidden-md-down">
            <div id="product-images-list-scroll" ref="listContainer">
                <ul id="product-images-list" ref="list" :style="{ top: translation }">
                    <li
                        v-for="(image, index) in images"
                        :key="image.id"
                        :class="{ active: image === currentImage }"
                        class="cursor-pointer border-radius"
                        @click="selectImage(index)"
                    >
                        <img
                            :alt="product.name"
                            :src="[
                                image.isVideo && image === currentImage ? image.thumb : image.small,
                            ]"
                            class="d-block"
                        />
                        <div :class="{ 'active-open': image === currentImage }"></div>
                    </li>
                </ul>
            </div>
            <div
                id="product-images-list-previous"
                @click="selectImage(currentIndex - 1)"
                :class="currentIndex === 0 ? 'hidden-md-up' : ''"
            >
                <i class="icon-cr icon-arrow-left hidden-md-up"></i>
                <i class="icon-cr icon-arrow-up hidden-md-down"></i>
            </div>
            <div
                id="product-images-list-next"
                @click="selectImage(currentIndex + 1)"
                :class="currentIndex === images.length - 1 ? 'hidden-md-up' : ''"
            >
                <i class="icon-cr icon-arrow-right hidden-md-up"></i>
                <i class="icon-cr icon-arrow-down hidden-md-down"></i>
            </div>
        </div>
        <template v-if="currentImage">
            <div id="product-image">
                <div ref="imageContainer">
                    <ImageZoom
                        :default-src="currentImage.default"
                        :enabled="currentImage.allowZoom"
                        :large-src="currentImage.large"
                        :images="images"
                        :current-index="currentIndex"
                        :isVideo="currentImage.isVideo"
                        @zoom-in="isZoomed = true"
                        @zoom-out="isZoomed = false"
                    />
                    <i
                        v-if="!currentImage.isReal"
                        style="background: transparent"
                        class="icon-cr icon-question-sign cursor-pointer"
                        @click="showMaskDisclaimer = true"
                    ></i>
                    <i
                        v-if="currentImage.allowZoom"
                        :class="{
                            'icon-zoom-in': !isZoomed,
                            'icon-zoom-out': isZoomed,
                        }"
                        style="background: transparent"
                        class="icon-cr hidden-sm-up"
                        @click="showPinchZoom = true"
                    ></i>
                    <i
                        class="icon-cr icon-wishlist hidden-sm-up"
                        style="background: transparent"
                        @click="showWishlist = true"
                    >
                    </i>
                </div>
            </div>
            <div v-if="showPinchZoom" id="product-image-touch-zoom">
                <ImagePinch :image="currentImage.large" @pinch="showPinchHint = false" />
                <p
                    class="text-yanone pos-fixed pos-top pos-center-x"
                    @click="showPinchZoom = false"
                >
                    <i class="icon-cr icon-close white"></i>
                    Clique aqui para sair do zoom
                </p>
                <p
                    v-show="showPinchHint"
                    class="d-flex flex-align-center text-yanone pos-fixed pos-bottom pos-center-x"
                >
                    <i class="icon-cr icon-pinch white"></i>
                    Amplie para ver cada detalhe
                </p>
            </div>
        </template>
        <Modal v-model="showMaskDisclaimer">
            <template v-slot:title>Observação</template>
            <div class="card-block">
                <p>
                    Por não ser uma foto real do produto, a tonalidade da malha e da estampa podem
                    ter uma pequena variação de acordo com a cor escolhida.
                </p>
            </div>
        </Modal>

        <Modal v-model="showWishlist">
            <template v-slot:title>{{ $t('_shop._wishlist.add') }}</template>
            <div class="card-block">
                <ProductWishlist v-if="showWishlist" @done="showWishlist = false" />
            </div>
        </Modal>
    </div>
</template>

<script>
    import throttle from 'lodash/throttle';
    import { EventBus } from '../../../common/common';
    import cr$ from '../../../common/crquery';
    import { hideMobileHeader } from '../../../common/header';
    import ImagePinch from '../common/ImagePinch';
    import ImageZoom from '../common/ImageZoom';
    import Modal from '../common/container/Modal';
    import ProductWishlist from './ProductWishlist.vue';
    import { useSwipe } from '@vueuse/core';

    const pixelRatio = Math.max(1, Math.min(2, window.devicePixelRatio));

    const SMALL_WIDTH = Math.round(232 * pixelRatio);
    const SMALL_HEIGHT = Math.round(325 * pixelRatio);
    const DEFAULT_WIDTH = Math.round(550 * pixelRatio);
    const DEFAULT_HEIGHT = Math.round(824 * pixelRatio);

    const LARGE_WIDTH = 1800;

    const WEAR_MARK =
        '&markpad=0&mark=https%3A%2F%2Fchico-rei.imgix.net%2Fimages%2Fsite%2Fproduct%2Fselo-desgaste.png%3Fauto%3Dformat%26w%3D0.60';

    export default {
        name: 'ProductImages',

        components: { ImagePinch, ImageZoom, Modal, ProductWishlist },

        props: {
            product: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                color: {
                    fabric_id: this.product.fabric_id,
                    product_color_id: this.product.product_color.id,
                    product_color_hexa: this.product.product_color.hexa,
                },
                currentIndex: null,

                translatedIndex: 0,
                translation: 0,

                isZoomed: false,
                showPinchZoom: false,
                showPinchHint: true,

                showMaskDisclaimer: false,
                selectedModelId: null,
                showWishlist: false,
            };
        },

        computed: {
            imagesByFabric() {
                const grouped = {};

                (this.product.images_by_fabric || []).forEach((i) => {
                    if (!grouped[i.fabric_id]) {
                        grouped[i.fabric_id] = [];
                    }

                    grouped[i.fabric_id].push(i);
                });

                return grouped;
            },

            productImages() {
                //const modelImages= this.product.images.filter(i => !this.selectedModelId || !i.product_model_id || i.product_model_id === this.selectedModelId);
                const modelImages = this.product.images.sort((a, b) => {
                    return a.product_model_id > b.product_model_id ? -1 : 1;
                });

                let images = modelImages.filter(
                    (i) => i.fabric_id === this.color.fabric_id || i.type_id === 7
                );

                if (images.length > 0) {
                    return images;
                }

                if (!this.imagesByFabric[this.color.fabric_id]) {
                    return [
                        ...modelImages.filter(
                            (i) => i.fabric_id === this.product.fabric_id || i.type_id === 7
                        ),
                    ];
                }

                const thumbImage = this.imagesByFabric[this.color.fabric_id].find(
                    (i) => i.product_model_id === null
                );

                return [
                    {
                        id: Date.now(),
                        height: 2700,
                        width: 1800,
                        seal: false,
                        main: false,
                        order: 1,
                        type_id: null,
                        url: (thumbImage || {}).url,
                        is_montage: true,
                    },
                    ...modelImages.filter(
                        (i) => i.fabric_id === this.product.fabric_id || i.type_id === 7
                    ),
                ];
            },

            images() {
                let images = null;

                if ((this.productImages || {}).length > 0) {
                    images = this.productImages
                        .filter(
                            (i) =>
                                i.type_id !== 1 &&
                                (![107269, 106429, 106428, 106427, 106426, 106425].includes(
                                    this.product.id
                                ) ||
                                    i.order <= 1 ||
                                    i.order > 3)
                        )
                        .map((image, index) => {
                            const concatStr = image.url.indexOf('g?') !== -1 ? '&' : '?';

                            const result = {
                                id: image.id,
                                modelId: image.product_model_id,
                                small: `${
                                    image.url
                                }${concatStr}auto=compress,format&q=65&w=${Math.min(
                                    SMALL_WIDTH,
                                    image.width
                                )}&h=${Math.min(SMALL_HEIGHT, image.height)}&fit=crop`,
                                default: `${
                                    image.url
                                }${concatStr}auto=compress,format&q=65&w=${Math.min(
                                    DEFAULT_WIDTH,
                                    image.width
                                )}&h=${Math.min(DEFAULT_HEIGHT, image.height)}&fit=crop&crop=top`,
                                large: `${
                                    image.url
                                }${concatStr}auto=compress,format&q=65&w=${Math.min(
                                    LARGE_WIDTH,
                                    image.width
                                )}`,
                                gender: image.gender,
                                allowZoom: image.width === LARGE_WIDTH,
                                isReal: !image.is_montage,
                                fabric_id: image.fabric_id,
                            };

                            if (image.seal) {
                                // result.default += WEAR_MARK;
                            }

                            return result;
                        });
                }

                this.currentIndex = images ? 0 : null;

                return images;
            },

            currentImage() {
                return this.currentIndex !== null ? this.images[this.currentIndex] : null;
            },
        },

        watch: {
            images(to) {
                if (to) {
                    this.goToIndexForModel();
                }
            },
        },

        created() {
            EventBus.$on('selected-modeling-change', this.setModeling);
            EventBus.$on('selected-color-change', (c) => (this.color = c));
        },

        mounted() {
            window.addEventListener(
                'resize',
                throttle(() => this.translateTo(this.translatedIndex), 500)
            );

            if (this.$refs.imageContainer) {
                useSwipe(this.$el, {
                    onSwipeEnd: this.handleSwipe,
                });
            }
        },

        methods: {
            handleSwipe(e, direction) {
                if (!this.showPinchZoom) {
                    if (direction === 'LEFT') {
                        this.selectImage(this.currentIndex + 1);
                    } else if (direction === 'RIGHT') {
                        this.selectImage(this.currentIndex - 1);
                    }
                }
            },

            setModeling(modelId) {
                this.selectedModelId = modelId;
                this.goToIndexForModel();
            },

            goToIndexForModel() {
                let newIndex = (this.images || []).findIndex(
                    (image) => image.modelId === this.selectedModelId
                );
                newIndex =
                    newIndex >= 0 &&
                    (this.color || {}).fabric_id === this.images[newIndex].fabric_id
                        ? newIndex
                        : 0;

                this.selectImage(newIndex);
            },

            translateTo(toIndex) {
                const listCrEl = cr$.byEl(this.$refs.list);
                if (!this.images || !listCrEl.exists()) {
                    return;
                }

                const listContainerCrEl = cr$.byEl(this.$refs.listContainer);
                const itemCrEl = cr$.byEl(listCrEl.children().raw[0]);

                let containerDimension, itemDimension;
                if (listCrEl.css('flex-direction') === 'column') {
                    containerDimension = listContainerCrEl.raw.offsetHeight;
                    itemDimension = itemCrEl.raw.offsetHeight;
                } else {
                    containerDimension = listContainerCrEl.raw.offsetWidth;
                    itemDimension = itemCrEl.raw.offsetWidth;
                }

                let itemPercentage =
                    (itemDimension + parseInt(itemCrEl.css('margin-bottom'))) / containerDimension;

                const imagesThatFit = Math.floor(1 / itemPercentage);
                const imagesThatDontFit = Math.max(0, this.images.length - imagesThatFit);

                const maxPercentage = itemPercentage * imagesThatDontFit;

                const itemRequested = listCrEl.children().raw[toIndex];

                if (
                    itemRequested &&
                    ((listCrEl.css('flex-direction') === 'column' &&
                        this.$refs.listContainer.offsetHeight + this.$refs.listContainer.scrollTop <
                            itemRequested.offsetTop + itemRequested.offsetHeight) ||
                        (listCrEl.css('flex-direction') !== 'column' &&
                            this.$refs.listContainer.offsetWidth +
                                this.$refs.listContainer.scrollLeft <
                                itemRequested.offsetLeft + itemRequested.offsetWidth))
                ) {
                    toIndex = toIndex - imagesThatFit + 1;
                }

                let requestedPercentage = Math.max(
                    0,
                    Math.min(maxPercentage, toIndex * itemPercentage)
                );

                this.translatedIndex = Math.max(0, Math.min(toIndex, imagesThatDontFit));

                const scrollValue = requestedPercentage * containerDimension;
                if (listCrEl.css('flex-direction') === 'column') {
                    listContainerCrEl.scrollTo(0, scrollValue, 150);
                } else {
                    listContainerCrEl.scrollTo(scrollValue, 0, 150);
                }
            },

            selectImage(index) {
                const listCrEl = cr$.byEl(this.$refs.list);
                if (!this.images || !listCrEl.exists()) {
                    return;
                }

                index = isNaN(index) || index < 0 ? 0 : index;
                this.currentIndex = index > this.images.length - 1 ? this.images.length - 1 : index;

                const itemRequested = listCrEl.children().raw[this.currentIndex];

                if (
                    itemRequested &&
                    ((listCrEl.css('flex-direction') === 'column' &&
                        (itemRequested.offsetTop < this.$refs.listContainer.scrollTop ||
                            this.$refs.listContainer.offsetHeight +
                                this.$refs.listContainer.scrollTop <
                                itemRequested.offsetTop + itemRequested.offsetHeight)) ||
                        (listCrEl.css('flex-direction') !== 'column' &&
                            (itemRequested.offsetLeft < this.$refs.listContainer.scrollLeft ||
                                this.$refs.listContainer.offsetWidth +
                                    this.$refs.listContainer.scrollLeft <
                                    itemRequested.offsetLeft + itemRequested.offsetWidth)))
                ) {
                    this.translateTo(this.currentIndex);
                }
            },
        },
    };
</script>
