<template>
    <button
        :class="{ loading }"
        :disabled="loading || disabled"
        class="btn-loading"
        @click.prevent="$emit('click')"
    >
        <span v-show="loading" class="btn-spinner-wrapper">
            <span class="cr-spinner-small"></span>
        </span>
        <slot></slot>
        <span v-if="(!$slots.default && !$scopedSlots.default) || loading">&nbsp;</span>
    </button>
</template>

<script>
    export default {
        name: 'LoadingButton',

        props: {
            loading: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>
