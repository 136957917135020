import { cartService } from '../../../../../common/service/resource';
import { readCookie } from '../../../../../common/common';
import { CHECKOUT_STEPS, AUTHENTICATION_STATUS } from '../consts';

const cartInitialState = {
    requests: 0,
    cart: null,
    checkingOut: false,
    checkedOut: false,
    error: null,
};

export default (store) => ({
    state: cartInitialState,

    fetch() {
        return new Promise((resolve, reject) => {
            if (!initialScope.cart_id || initialScope.cart_id < 1) {
                store.redirect();
                reject();
                return;
            }

            this.state.error = null;

            this.state.requests++;

            cartService
                .get(
                    initialScope.cart_id,
                    {
                        withProducts: true,
                        withEstampa: true,
                        withDiscounts: true,
                        withCustomer: true,
                        withOrganization: true,
                        withAddress: true,
                        setAddressId: true,
                        zipCode: readCookie('cep'),
                    },
                    {
                        headers: {
                            'cr-api-fast-auth': store.state.authentication.fastLoginEmail,
                        },
                    }
                )
                .then((response) => {
                    const cart = {
                        address: { data: null },
                        ...response.data.data,
                    };

                    if (!this.validate(cart)) {
                        this.state.requests--;
                        store.redirect();
                        reject();
                        return;
                    }

                    this.state.cart = cart;
                    this.items = this.reportProductsDataLayer(cart);

                    window.dataLayer.push({
                        event: 'loadCheckout',
                        'count-products-cart': this.state.cart.products.data.length,
                        'coupon-cart': this.state.cart.discounts.data[0]
                            ? this.state.cart.discounts.data[0].name
                            : '',
                    });

                    window.dataLayer.push({
                        event: 'begin_checkout',
                        ecommerce_v4: {
                            value: this.state.cart.total_paid,
                            currency: 'BRL',
                            items: this.items,
                        },
                    });

                    if (
                        !!store.state.authentication.fastLoginEmail &&
                        !(cart.customer || {}).data
                    ) {
                        store.authentication.setStatus(AUTHENTICATION_STATUS.AUTH_NEW_ACCOUNT);
                    }

                    this.state.requests--;
                    resolve();
                })
                .catch((error) => {
                    this.state.requests--;

                    if (error.response) {
                        if (error.response.status === 404) {
                            return store.redirect();
                        }

                        if (error.response.status === 403 || error.response.status === 401) {
                            if (initialScope.id) {
                                return store.redirect();
                            }

                            if (store.state.authentication.fastLoginEmail) {
                                if (this.state.cart) {
                                    store.authentication.setStatus(
                                        AUTHENTICATION_STATUS.AUTH_NEED_LOGIN
                                    );
                                } else {
                                    store.authentication.setFastLoginEmail('');
                                    reject();
                                }

                                return;
                            }
                        }

                        this.state.error =
                            error.response.data.message || '_checkout.errorLoadItems';
                    }
                    reject(error);
                });
        });
    },

    reportProductsDataLayer(cart) {
        const products = [];
        this.items = cart.products.data.forEach(function (value) {
            products.push({
                item_name: value['product'].name, // Name or ID is required.
                item_id: value['produto_id'],
                price: value['product'].price,
                item_brand: 'Chico Rei',
                item_category: value['product'].type.name,
                item_category2: value['gender'],
                item_category3: value['size'],
                item_category4: value['color_name'],
                item_variant: value['gender'] + '-' + value['size'] + '-' + value['color_name'],
                quantity: value['quantity'],
            });
        });
        return products;
    },

    updateStateWithResponse(data) {
        this.state.cart.carrier_id = data.carrier_id;
        this.state.cart.frete_id = data.frete_id;
        this.state.cart.total_expresso = data.total_expresso;
        this.state.cart.carrier_id = data.carrier_id;
        this.state.cart.expresso = data.expresso;

        this.state.cart.total_paid = data.total_paid;
        this.state.cart.total_paid_dollar = data.total_paid_dollar;
        this.state.cart.total_shipping = data.total_shipping;
        this.state.cart.total_shipping_dollar = data.total_shipping_dollar;
        this.state.cart.total_discounts = data.total_discounts;
        this.state.cart.total_discounts_dollar = data.total_discounts_dollar;
        this.state.cart.total_products = data.total_products;
        this.state.cart.total_products_dollar = data.total_products_dollar;

        if (data.customer) {
            this.state.cart.customer.data = data.customer.data;
        }
    },

    validate(cart) {
        if (
            !cart ||
            !cart.enable_checkout ||
            (initialScope.id &&
                (!cart.customer_id ||
                    cart.customer_id === '' ||
                    cart.customer_id != initialScope.id ||
                    (initialScope.IS_SALES_REP && !cart.for_customer_id))) ||
            !cart.products ||
            cart.products.data.length === 0
        ) {
            return false;
        }

        let cartIsValid = true;
        let hasKit = false;
        let hasEspecialMug = false;

        // Verifica se todos os produtos do carrinho estão válidos (quantidade menor ou igual ao estoque)
        cart.products.data.forEach(function (value) {
            hasKit = hasKit || value['produto_id'] == 12065;
            hasEspecialMug = hasEspecialMug || value['produto_id'] == 12505;
            cartIsValid = cartIsValid && value['has_quantity'] && value['can_be_bought'];
        });

        return (
            cartIsValid &&
            (!hasKit || (hasKit && cart.total_products >= 30)) &&
            (!hasEspecialMug || (hasEspecialMug && cart.gift_message))
        );
    },

    checkout() {
        if (store.state.step.active !== CHECKOUT_STEPS.STEP_PAYMENT) {
            return;
        }

        if (!store.state.payment.payloadBuilder) {
            this.state.error = {
                msg: '_checkout._payment.selectMethod',
                i18n: true,
            };
            return;
        }

        this.state.error = null;

        store.state.payment.payloadBuilder((paymentPayload) => {
            this.state.requests++;
            this.state.checkingOut = true;

            const postData = {
                id: this.state.cart.id,
                cart: this.state.cart.id,
                total_paid: this.state.cart.total_paid,
                utm_source: readCookie('utm_source'),
                utm_medium: readCookie('utm_medium'),
                utm_term: readCookie('utm_term'),
                utm_content: readCookie('utm_content'),
                utm_campaign: readCookie('utm_campaign'),
                xtra: readCookie('meliuz'),
                ...paymentPayload,
                // experiment_var: 'pd-' + initialScope.EXPERIMENT_VAR,
            };

            cartService
                .checkout(
                    this.state.cart.id,
                    postData,
                    {},
                    {
                        headers: {
                            'cr-api-fast-auth': store.state.authentication.fastLoginEmail,
                        },
                    }
                )
                .then((response) => {
                    // Erros não tratados
                    if (response.data.message && !response.data.data) {
                        this.state.error = {
                            msg: '_checkout.errorProcessing',
                            i18n: true,
                        };
                    } else {
                        if (response.data.data.module_id !== 230) {
                            window.location.href =
                                '/sacola/confirmacao' +
                                '?id_order=' +
                                response.data.data.order_id +
                                '&id_module=' +
                                response.data.data.module_id +
                                '&id_cart=' +
                                this.state.cart.id +
                                (initialScope.IS_SALES_REP
                                    ? '&for_customer_id=' + this.state.cart.for_customer_id
                                    : '') +
                                (store.state.authentication.fastLoginEmail
                                    ? '&fast_mail=' +
                                      store.state.authentication.fastLoginEmail +
                                      '&fast_customer=' +
                                      this.state.cart.customer_id
                                    : '');
                        } else if (response.data.data.payment_return) {
                            window.location.href = response.data.data.payment_return;
                        } else {
                            this.state.error = {
                                msg: '_checkout.errorProcessing',
                                i18n: true,
                            };
                        }
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        let errorMsg;

                        if (postData.module === 'cartoesdecredito') {
                            errorMsg = error.response.data.message;
                        } else {
                            errorMsg = error.response.data.message;
                        }

                        // Recarrega o carrinho
                        if (
                            error.response.data.redirect === '/sacola/checkout' ||
                            error.response.data.redirect === '/carrinho/checkout'
                        ) {
                            this.fetch();
                        } else if ((error.response.data.errors || {}).discount_id) {
                            this.removeInvalidDiscount(error.response.data.errors.discount_id);
                        }

                        if (errorMsg) this.state.error = { msg: errorMsg, i18n: false };
                    } else {
                        this.state.error = {
                            msg: '_checkout.errorProcessing',
                            i18n: true,
                        };
                    }
                })
                .then(() => {
                    this.state.checkedOut = this.state.error === null;
                    this.state.checkingOut = false;
                    this.state.requests--;
                });
        });
    },

    removeInvalidDiscount(discountId) {
        cartService
            .removeDiscount(this.state.cart.id, discountId)
            .then(() => {
                this.fetch();

                this.state.error = {
                    msg: '_checkout.invalidDiscountRecalculate',
                    i18n: true,
                };
            })
            .catch(
                () =>
                    (this.state.error = {
                        msg: '_checkout.errorProcessing',
                        i18n: true,
                    })
            );
    },
});
