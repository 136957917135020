export default () => ({
    pt: {
        about: 'Sobre',
        checkHere: 'Confira aqui',
        observations: 'Observações',
        loading: 'Carregando',
        tellUs: 'Fala pra gente',
        toSeeMore: 'Ver mais',
        toCancel: 'Cancelar',
        toOrder: 'Produção sob Demanda',
        toSend: 'Enviar',
        gallery: 'Galeria',
        requirements: 'Requisitos',
        yes: 'Sim',
        no: 'Não',
        back: 'Voltar',
        history: {
            lastSeen: 'Últimos vistos',
            lastSeenLong: 'Confira as últimas maravilhas vistas por você!',
        },
        toAdd: 'Adicionar',
        wait: 'Aguarde',
        toTryAgain: 'Tentar novamente',
        tryAgainLater: 'Tente mais tarde',
        tryAgainLaterContact: '@:tryAgainLater ou entre em contato conosco.',
        quantity: 'Quantidade',
        or: 'Ou',
        toDoLogin: 'Fazer login',
        learnMore: 'Saiba mais',
        toApply: 'Aplicar',
        cryptoSafeSite: 'Site criptografado e seguro',
        shipping: 'Frete',
        discounts: 'Desconto',
        calculate: 'Calcular',
        type: 'Tipo',
        forecast: 'Previsão',
        price: 'Preço',
        datesFromToXHtml: 'De <strong>{0}</strong> a <strong>{1}</strong>',
        free: 'Grátis',
        period: 'Período',
        checkTerms: 'Confira o regulamento',
        color: 'Cor',
        unavailable: 'Produto Esgotado',
        wrappings: 'Embalagens',
        toRemove: 'Remover',
        leaveZoom: 'Sair do zoom',
        toAddSelectedX: 'Adicionar {n} selecionado | Adicionar {n} selecionados',
        sizeXY: 'Tamanho {0}x{1}cm',
        expiration: 'Validade',
        toSeeDetails: 'Ver detalhes',
        toHide: 'Ocultar',
        product: 'Produto',
        products: 'Produtos',
        exchange: 'Troca',
        delivery: 'Frete',
        total: 'Total',
        waitMoment: 'Aguarde uns instantes',
        dateToXHtml: 'Entrega até <strong>{0}</strong>',
        untilDateXHtml: 'A partir de <strong>{0}</strong>',
        untilDateToXHtml: 'Retirada a partir de <strong>{0}</strong>',
        automatic: 'Automática',
        toChange: 'Alterar',
        toDefine: 'Definir',
        toSave: 'Salvar',
        toEdit: 'Editar',
        toDelete: 'Excluir',
        contactUs: 'Fale consoco',
        address: 'Endereço',
        storeAddress: 'Endereço para retirada',
        labeladdressshipping: 'Entrega',
        identification: 'Identificação',
        toFinish: 'Finalizar',
        payment: 'Pagamento',
        customer: 'Cliente',
        errorContactUsHtml:
            'Se o erro persistir, fale com a gente <a href="/fale-conosco">clicando aqui</a>.',
        noResults: 'Nenhum resultado',
        toFilter: 'Filtrar',
        search: 'Busca',
        tag: 'Tag',
        of: 'De',
        from: 'Acima de',
        until: 'Até',
        size: 'Tamanho',
        category: 'Categoria',
        categories: 'Categorias',
        theme: 'Tema',
        themes: 'Temas',
        all_f: 'Todas',
        all_m: 'Todos',
        exclusiveCollections: 'Coleções Exclusivas',
        adult: 'Adulto',
        toClear: 'Limpar',
        filters: 'Filtros',
        gender: 'Gênero',
        model: 'Modelagem',
        cityOrState: 'Cidade ou estado',
        hashtags: 'Hashtags',
        clothes: 'Roupas',
        housing: 'Casa',
        accessories: 'Acessórios',
        in: 'Em',
        added: 'Adicionado',
        selectedItemsX: 'Nenhum item selecionado | {n} item selecionado | {n} itens selecionados',
        productionType: 'Tipo de produção',
        toSeeLess: 'Ver menos',
        store: 'Loja',
        offer: 'Promoção',
        email: 'E-mail',
    },
    en: {
        about: 'About',
        checkHere: 'Check it out',
        observations: 'Observations',
        loading: 'Loading',
        tellUs: 'Tell us',
        toSeeMore: 'See more',
        toCancel: 'Cancel',
        toOrder: 'Custom Product',
        toSend: 'Send',
        gallery: 'Gallery',
        requirements: 'Requirements',
        yes: 'Yes',
        no: 'No',
        back: 'Back',
        history: {
            lastSeen: 'Last seen',
            lastSeenLong: 'Check out the latest wonders seen by you!',
        },
        toAdd: 'Add',
        wait: 'Wait',
        email: 'E-mail',
        toTryAgain: 'Tentar novamente',
        tryAgainLater: 'Tente mais tarde',
        tryAgainLaterContact: '@:tryAgainLater or contact us.',
        quantity: 'Quantity',
        or: 'Or',
        toDoLogin: 'Login',
        learnMore: 'Learn more',
        cryptoSafeSite: 'Encrypted and secure website',
        shipping: 'Shipping',
        calculate: 'Calculate',
        type: 'Type',
        forecast: 'Forecast',
        price: 'Price',
        datesFromToXHtml: 'From <strong>{0}</strong> to <strong>{1}</strong>',
        free: 'Free',
        period: 'Period',
        checkTerms: 'Check the terms',
        color: 'Color',
        unavailable: 'Product Unavailable',
        toRemove: 'Remove',
        leaveZoom: 'Leave zoom',
        toAddSelectedX: 'Add {n} selected',
        sizeXY: 'Size {0}x{1}cm',
        expiration: 'Expiration date',
        toSeeDetails: 'View details',
        toHide: 'Hide',
        product: 'Product',
        products: 'Products',
        exchange: 'Exchange',
        delivery: 'Delivery',
        total: 'Total',
        waitMoment: 'Wait a moment',
        dateToXHtml: 'Until <strong> {0} </strong>',
        automatic: 'Automatic',
        toChange: 'Change',
        toDefine: 'Define',
        toEdit: 'Edit',
        toDelete: 'Delete',
        contactUs: 'Contact us',
        address: 'Address',
        identification: 'Identification',
        toFinish: 'Finish',
        payment: 'Payment',
        customer: 'Customer',
        toApply: 'Apply',
        errorContactUsHtml: 'If the error persists, <a href="/fale-conosco"> contact us </a>.',
        noResults: 'No results',
        toFilter: 'Filter',
        search: 'Search',
        tag: 'Tag',
        of: 'From',
        from: 'Above',
        until: 'Until',
        size: 'Size',
        category: 'Category',
        categories: 'Categories',
        theme: 'Theme',
        themes: 'Themes',
        all_f: 'All',
        all_m: 'All',
        exclusiveCollections: 'Exclusive Collections',
        adult: 'Adult',
        toClear: 'Clear',
        filters: 'Filters',
        gender: 'Gender',
        model: 'Model',
        cityOrState: 'City or State',
        hashtags: 'Hashtags',
        clothes: 'Clothes',
        housing: 'Housing',
        accessories: 'Accessories',
        in: 'In',
        added: 'Added',
        selectedItemsX: 'No items selected | {n} item selected | {n} items selected',
        productionType: 'Production type',
        toSeeLess: 'See less',
        store: 'Store',
        offer: 'Promoção',
    },
});
