<template>
    <div :class="[isHotsite ? 'mt-4' : '']" class="faq">
        <div v-if="isHotsite">
            <h2>
                Ficou com alguma dúvida sobre como funciona o programa de cashback? A gente te
                explica!
            </h2>
            <p class="mb-4">
                Selecionamos algumas das principais perguntas para te ajudar a entender melhor como
                conseguir o seu cashback e aproveitar todas as vantagens!
            </p>
        </div>

        <div v-for="faq in faqs" class="mb-3">
            <h3 class="mb-2">
                <i
                    v-show="isHotsite"
                    class="icon-cr icon-chevron-up hidden-xs-up mr-2"
                    v-if="!faq.expand"
                    @click="faq.expand = true"
                ></i>
                <i
                    v-show="isHotsite"
                    class="icon-cr icon-chevron-down hidden-xs-up mr-2"
                    v-else
                    @click="faq.expand = false"
                ></i>
                {{ faq.title
                }}<span
                    v-show="isHotsite"
                    v-if="!faq.expand"
                    @click="faq.expand = true"
                    class="cursor-pointer ml-2 hidden-xs-down"
                    >(expandir)</span
                >
                <span
                    v-show="isHotsite"
                    v-else
                    @click="faq.expand = false"
                    class="cursor-pointer ml-2 hidden-xs-down"
                    >(ocultar)</span
                >
            </h3>
            <p v-if="faq.expand || !isHotsite" v-for="text in faq.text" v-html="text"></p>
            <div class="h-text-divider mt-3 mb-3"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CashbackFaq',

        props: {
            isHotsite: {
                default: true,
                required: true,
            },
        },

        data() {
            return {
                faqs: [
                    {
                        title: 'O que é o cashback da Chico Rei?',
                        text: [
                            'O Cashback da Chico Rei é a porcentagem de valor recebido de volta após cada compra realizada no site. Cada produto comprado dá direito a uma porcentagem variável, de acordo com as ofertas específicas listadas em <a href="/minha-conta/cashback">https://chicorei.com/minha-conta/cashback</a>.',
                            'O cashback da Chico Rei não pode ser resgatado em dinheiro e nem utilizado em outras plataformas.',
                            `Consulte o regulamento completo em <a href='/minha-conta/cashback'>https://chicorei.com/minha-conta/cashback</a>.`,
                        ],
                        expand: false,
                    },
                    {
                        title: 'Como meu cashback é calculado ?',
                        text: [
                            'Seu cashback é calculado em cima do valor final de cada produto, ou seja, após a aplicação de qualquer outro desconto: promoção, cupom ou cashback recebido anteriormente.',
                            'O cashback de cada compra que você realizar será informado no extrato da sua conta no site, em <a href="/minha-conta/cashback">https://chicorei.com/minha-conta/cashback</a>. Não geram cashback os seguintes produtos: Vale-presente, Disco de Vinil e Embalagem Extra.',
                            'O valor do seu cashback poderá ser utilizado para comprar qualquer produto do site, exceto vale-presente.',
                            'O valor do frete não é contabilizado para gerar cashback.',
                            'O cashback não é aplicável para o pagamento de frete, apenas produtos.',
                        ],
                        expand: false,
                    },
                    {
                        title: 'Qual valor de cashback vou receber ?',
                        text: [
                            `O valor do cashback é variável, de acordo com as ofertas disponíveis nesta página e você pode consultar seu extrato diretamente na sua conta do site, em <a href='/minha-conta/cashback'>https://chicorei.com/minha-conta/cashback</a>.`,
                        ],
                        expand: false,
                    },
                    {
                        title: 'Quando vou receber meu cashback ?',
                        text: [
                            `Você receberá o cashback automaticamente no 10º dia após a entrega do pedido que gerou o saldo. Você pode consultar seu extrato em <a href='/minha-conta/cashback'>https://chicorei.com/minha-conta/cashback</a>.`,
                        ],
                        expand: false,
                    },
                    {
                        title: 'Como consultar meu saldo de cashback ?',
                        text: [
                            `Para consultar seu saldo de cashback, acesse o extrato diretamente na sua conta do site, em <a href='/minha-conta/cashback'>https://chicorei.com/minha-conta/cashback</a>.`,
                        ],
                        expand: false,
                    },
                    {
                        title: 'Como utilizar meu saldo de cashback ?',
                        text: [
                            'Para utilizar seu cashback, é preciso selecionar o saldo disponível na sacola de compras ou no checkout do pedido.',
                            'Você poderá utilizar o saldo do cashback para pagar até 30% do valor da sua compra, desde que esteja dentro da validade de 90 dias após a disponibilização do valor no seu extrato.',
                            'O cashback ofertado é cumulativo com promoções e descontos (o valor do cashback é calculado após serem aplicadas promoções e descontos cabíveis no pedido), mas ele não pode ser utilizado na compra de vale-presente.',
                            'Além disso, não é possível utilizar seu o cashback para pagamento do frete, apenas para o pagamento de produtos, de acordo com as ofertas disponíveis nesta página.',
                        ],
                        expand: false,
                    },
                    {
                        title: 'Qual é o prazo de validade do meu cashback ?',
                        text: [
                            `A validade do seu cashback é de 90 dias após a disponibilização do valor no extrato, em <a href='/minha-conta/cashback'>https://chicorei.com/minha-conta/cashback</a>.`,
                        ],
                        expand: false,
                    },
                    {
                        title: 'Como funcionam as trocas e devoluções com o cashback ?',
                        text: [
                            'Você pode realizar a troca de produtos comprados com saldo de cashback normalmente, observando cada modalidade (troca por tamanho, defeito ou produto incorreto; troca por cupom; devolução):' +
                                `<ul><li>Troca por tamanho, defeito ou produto incorreto: Ao realizar a troca, o valor do cashback recebido no pedido original será suspenso do extrato e reativado no novo pedido gerado pela troca.</li><li>Troca por cupom: Ao realizar a troca por cupom, o valor de cashback gerado no pedido original será cancelado. Ao aplicar o cupom derivado da troca em uma nova compra, o novo cashback será gerado normalmente.</li>
                            <li>Devolução: Em caso de devolução total, o valor do cashback referente ao produto devolvido será cancelado.</li></ul>`,
                            'Em caso de troca ou devolução parcial de produto, o valor do cashback proporcional ao produto devolvido será reembolsado (e o estorno do valor proporcional ao produto pago será feito normalmente).',
                        ],
                        expand: false,
                    },
                ],
            };
        },
    };
</script>
