import { cartService } from '../../../common/service/resource';
import { EventBus, readCookie } from '../../../common/common';

export const cartStore = {
    pendingRequest: null,

    state: {
        updated: false,
        cart: null,
        fetchingCart: false,
        updatingCart: false,
        updatingCartDisplay: true,

        requestSalesRepToSelectCustomer: false,
    },

    createCart(customerId) {
        return new Promise((resolve, reject) => {
            this.state.fetchingCart = true;
            this.state.updatingCartDisplay = true;
            this.state.updated = false;

            const data = new FormData();
            data.append('for_customer_id', customerId);

            cartService
                .createCart(data)
                .then((response) => {
                    initialScope.cart_id = response.data.cart_id;
                    this.fetch()
                        .then(() => resolve(response.data.cart_id))
                        .catch(reject);
                })
                .catch(() => {
                    this.state.fetchingCart = false;
                    this.state.updatingCartDisplay = false;
                    reject();
                });
        });
    },

    changeCart(cartId) {
        return new Promise((resolve, reject) => {
            this.state.fetchingCart = true;
            this.state.updated = false;

            const data = new FormData();
            data.append('cart_id', cartId);

            cartService
                .changeCart(data)
                .then(() => {
                    initialScope.cart_id = cartId;
                    this.fetch().then(resolve).catch(reject);
                })
                .catch(() => {
                    this.state.fetchingCart = false;
                    reject();
                });
        });
    },

    fetch() {
        return new Promise((resolve, reject) => {
            if (this.state.fetchingCart) {
                return;
            }

            if (!initialScope.cart_id) {
                this.state.updatingCartDisplay = false;

                reject();
                return;
            }

            this.state.fetchingCart = true;
            this.state.updated = false;
            cartService
                .get(initialScope.cart_id, {
                    zipCode: initialScope.cep,
                    withDiscounts: 1,
                    withProducts: 1,
                    withGiftOffer: 1,
                    withPromotionAlert: 1,
                    withCustomer: initialScope.IS_SALES_REP ? 1 : 0,
                    freeShippingZip: readCookie('cep'),
                })
                .then((response) => {
                    this.state.updated = true;
                    this.state.cart = response.data.data;
                    this.state.fetchingCart = false;

                    const cartProductsCount = this.state.cart.products.data.reduce(
                        (previous, current) => previous + current.quantity,
                        0
                    );

                    EventBus.$emit('cart-products-count-changed', cartProductsCount);

                    EventBus.$emit('update-loading-cart', false);
                    resolve();
                })
                .catch(reject)
                .then(() => {
                    this.state.fetchingCart = false;
                    this.state.updatingCartDisplay = false;
                });
        });
    },

    fetchCartWithoutProducts() {
        return new Promise((resolve, reject) => {
            if (this.state.cart && this.updated) {
                resolve(this.state.cart);
            } else if (!initialScope.cart_id) {
                resolve(null);
            } else {
                cartService
                    .get(initialScope.cart_id)
                    .then((response) => resolve(response.data.data))
                    .catch(() => reject());
            }
        });
    },

    isCartValid() {
        return new Promise((resolve, reject) => {
            // no need for validate if customer isn't a sales rep
            if (!initialScope.IS_SALES_REP) {
                resolve();
                return;
            }

            this.fetchCartWithoutProducts().then((cart) => {
                if (!cart || !cart.for_customer_id) {
                    this.state.requestSalesRepToSelectCustomer = true;
                    reject();
                } else {
                    resolve();
                }
            });
        });
    },

    retryPendingRequestForCustomer(customerId) {
        if (this.pendingRequest) {
            if (this.pendingRequest.type === 'add-product') {
                this.pendingRequest.request.append('for_customer_id', customerId);
                this.makeAddProductRequest(this.pendingRequest);
            } else if (this.pendingRequest.type === 'update-product') {
                this.pendingRequest.request.for_customer_id = customerId;
                this.makeUpdateProductRequest(this.pendingRequest);
            }
        }
    },

    addProduct(request, openSidebarCart) {
        this.state.updatingCart = true;
        this.state.updatingCartDisplay = true;
        return new Promise((resolve, reject) => {
            this.isCartValid()
                .then(() =>
                    this.makeAddProductRequest({ request, resolve, reject, openSidebarCart })
                )
                .catch(() => {
                    this.pendingRequest = {
                        type: 'add-product',
                        request,
                        resolve,
                        reject,
                    };
                });
        });
    },

    updateProduct(request) {
        this.state.updatingCart = true;
        return new Promise((resolve, reject) => {
            this.isCartValid()
                .then(() => this.makeUpdateProductRequest({ request, resolve, reject }))
                .catch(() => {
                    this.pendingRequest = {
                        type: 'update-product',
                        request,
                        resolve,
                        reject,
                    };
                });
        });
    },

    makeAddProductRequest({ request, resolve, reject, openSidebarCart = true }) {
        cartService
            .addToCart(request)
            .then((response) => {
                resolve(response);

                this.state.updated = false;

                if (openSidebarCart) {
                    this.show();
                }

                initialScope.cart_id = response.data.cart_id;
                this.fetch().catch(() => {});
            })
            .catch(reject)
            .then(() => (this.state.updatingCart = false));
    },

    makeUpdateProductRequest({ request, resolve, reject }) {
        if (!initialScope.cart_id) {
            reject();
            return;
        }

        cartService
            .updateProducts(initialScope.cart_id, request)
            .then((response) => {
                resolve(response);

                this.state.updated = false;
                initialScope.cart_id = response.data.cart_id;
                this.fetch().catch(() => {});
            })
            .catch(reject)
            .then(() => (this.state.updatingCart = false));
    },

    show() {
        EventBus.$emit('open-sidebar', 'cart');
    },

    hide() {
        EventBus.$emit('close-sidebar');
    },
};
