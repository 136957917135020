<template>
    <div>
        <div class="kit-basics hidden-xs-down">
            <div>
                <p class="name-text text-uppercase">
                    Kit 3 Camisetas básicas {{ colorKitName }}<span class="normal"> por </span
                    ><span class="orange-price">{{ $f.formatMoney(product.price) }}</span>
                </p>
                <p class="economize-text text-uppercase">
                    Economize <span>{{ discountPercentage }}%</span>
                </p>
            </div>
            <img :src="product.img_cover + `?auto=compress,format&q=65&fit=crop&w=350&h=200`" />
            <div class="select-variations">
                <p>Selecione as opções desejadas e adicione o kit à sacola:</p>
                <ProductSelectVariations
                    class="mt-3"
                    :product="product"
                    @input="handleSelectedVariation($event)"
                    :is-basic-kit="true"
                    :shop-mode="true"
                >
                </ProductSelectVariations>
                <p v-if="error" class="text-danger">
                    {{ error }}
                </p>
                <button
                    class="d-flex btn btn-lg btn-wide text-uppercase mt-2"
                    style="place-content: center; min-width: 290px"
                    @click="handleAddToCart"
                >
                    <span v-if="!loadingCart">Comprar</span>
                    <div v-else class="my-2 mx cr-spinner-small"></div>
                </button>
            </div>
        </div>
        <div class="kit-basics-mobile hidden-xs-up mt-3">
            <div>
                <p class="name-text text-uppercase text-center">
                    Kit 3 Camisetas básicas {{ colorKitName }}
                </p>
                <p class="economize-text text-uppercase text-center">
                    Economize <span>{{ discountPercentage }}%</span>
                </p>
            </div>
            <div class="d-flex flex-justify-between flex-align-center select-variation">
                <img :src="product.img_thumb + `?auto=compress,format&q=65&fit=crop&w=170&h=182`" />
                <div>
                    <p class="text-center">
                        Leve as 3 por <span>{{ $f.formatMoney(product.price) }}</span>
                    </p>
                    <ProductSelectVariations
                        class="mt-3"
                        :product="product"
                        @input="handleSelectedVariation($event)"
                        :is-basic-kit="true"
                        :shop-mode="true"
                    >
                    </ProductSelectVariations>
                </div>
            </div>
            <p v-if="error" class="text-danger">
                {{ error }}
            </p>
            <button
                class="d-flex btn btn-lg btn-wide text-uppercase mt-2"
                style="place-content: center"
                @click="handleAddToCart"
            >
                <span v-if="!loadingCart">Comprar</span>
                <div v-else class="my-2 mx cr-spinner-small"></div>
            </button>
        </div>
    </div>
</template>

<script>
    import ProductSelectVariations from './ProductSelectVariations.vue';
    import { cartStore } from '../../common/store/cart-store';

    export default {
        name: 'BasicsKit',
        components: { ProductSelectVariations },

        props: {
            product: {
                type: Object,
                required: true,
            },
            price: {
                type: Number,
                required: true,
            },
        },

        data() {
            return {
                loadingCart: false,
                selectedVariation: null,
                error: null,
            };
        },

        computed: {
            discountPercentage() {
                let price_total_individual = 3 * this.price;
                let discount = this.price * 3 - this.product.price;
                return Math.floor((discount / price_total_individual) * 100);
            },

            colorKitName() {
                const colorMap = {
                    75742: 'pretas',
                    138093: 'mesclas',
                    75738: 'brancas',
                };

                return colorMap[this.product.id] || null;
            },
        },

        methods: {
            handleSelectedVariation(variation) {
                if (variation) {
                    this.selectedVariation = variation;
                }
            },

            handleAddToCart() {
                this.error = null;

                if (!this.selectedVariation) {
                    this.error = 'Escolha um tamanho para o kit de camisetas';
                    return;
                }

                this.addToCart();
            },

            getCartBodyRequest() {
                const products = [];
                products.push({
                    product_id: this.product.id,
                    variation_id: this.selectedVariation.variation_id,
                    quantity: 1,
                });

                const data = new FormData();
                data.append('json', '1');
                data.append('products', JSON.stringify(products));
                // data.append('fb_event_id', this.facebookEventId);
                return data;
            },

            addToCart() {
                this.loadingCart = true;
                cartStore
                    .addProduct(this.getCartBodyRequest())
                    .then((response) => {
                        this.$scope.cart_id = response.data.cart_id;
                    })
                    .catch(() => {
                        this.error =
                            'Erro ao adicionar ao carrinho, recarregue a página ou tente novamente';
                        this.loadingCart = false;
                    })
                    .then(() => (this.loadingCart = false));
            },
        },
    };
</script>
