import { AUTHENTICATION_STATUS, CHECKOUT_STEPS } from '../consts';
import { readCookie, setCookie } from '../../../../../common/common';

const authenticationInitialState = {
    requests: 0,
    authenticationStatus: AUTHENTICATION_STATUS.AUTH_OK_STATE,
    fastLoginEmail: '',
};

export default (store) => ({
    state: authenticationInitialState,

    setFastLoginEmail(email) {
        if (this.state.fastLoginEmail === email || !!initialScope.id) {
            return;
        }

        this.state.fastLoginEmail = email;
        if (!this.state.fastLoginEmail) {
            this.setStatus(AUTHENTICATION_STATUS.AUTH_OK_STATE);
        }

        let d = new Date();
        d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
        setCookie('fast_login', email, d);

        return store.cart.fetch().then(() => {
            store.step.next();
        });
    },

    setStatus(status) {
        if (this.state.fastLoginEmail || status === AUTHENTICATION_STATUS.AUTH_OK_STATE) {
            this.state.authenticationStatus = status;
        }
    },

    allowChangeAuth() {
        return !!this.state.fastLoginEmail && !initialScope.id;
    },

    authGuard(cb) {
        if (!!initialScope.id) {
            return cb();
        }

        this.setStatus(AUTHENTICATION_STATUS.AUTH_NEED_LOGIN_TO_EDIT);
        store.step.goTo(CHECKOUT_STEPS.STEP_AUTHENTICATION);
    },
});
