<template>
    <div id="cart-special-options" :class="{ loading: addingOptions }" class="frame mt">
        <div class="section-header mb-4">
            <button
                class="btn cart-gift-button flex-wrap"
                :class="isLateralMenu ? 'w-full' : ''"
                @click="closeCartSpecialOptions()"
            >
                <div class="d-flex flex-align-center flex-justify-center gift-button-div">
                    <i class="icon-cr icon-chevron-left"></i>
                    <div class="text-center">Voltar para a lista de produtos</div>
                </div>
            </button>
        </div>

        <div v-if="!specialOptions" id="cart-special-options-loading">
            <div><!--spinner via CSS--></div>
        </div>
        <div v-else id="cart-special-options-content" class="text-yanone">
            <template v-if="$scope.IS_REGULAR_CUSTOMER">
                <!--                <div class="section-header">
                    <h3>{{ $t('sizeXY', [10, 15]) }}</h3>
                </div>-->
                <ul id="cart-special-options-list" class="cart-products-list">
                    <li
                        v-for="option in specialOptions"
                        v-if="option.produto_id !== 11691"
                        style="padding: 1.5rem 0"
                        class="product-list-item options-list-item mb-0"
                    >
                        <div>
                            <div class="cart-special-options-image">
                                <img
                                    :alt="option.name"
                                    :src="option.image"
                                    :data-value="`{product_id: ${option.produto_id},variation_id:${option.variation_id},quantity:1}`"
                                    :id-value="option.produto_id"
                                    class="img-responsive no-border cursor-pointer"
                                    height="157px"
                                    width="104px"
                                    @click="showImageModal(option.biggerImage)"
                                />
                            </div>
                        </div>
                        <div class="d-flex flex-column flex-justify-between">
                            <div
                                class="mb special-option-text"
                                :class="isLateralMenu ? 'max-fixed' : ''"
                            >
                                <p class="text-bold">
                                    {{ option.name }}
                                </p>
                                <p>
                                    {{ option.size_name }}
                                </p>
                                <div class="d-flex prices-wrap text-bold">
                                    <p>
                                        {{ $f.formatMoney(option.price) }}
                                    </p>
                                </div>
                                <span
                                    class="text-info-3 hidden-xs-up text-uppercase text-bold special-option-stock"
                                    v-if="!option.in_stock"
                                    ><strong>Produção sob demanda</strong>
                                </span>
                                <span
                                    class="hidden-xs-up text-uppercase text-bold special-option-stock"
                                    v-else
                                    >Produto em estoque</span
                                >
                            </div>
                            <div class="">
                                <span
                                    class="text-info-3 hidden-xs-down text-uppercase text-bold special-option-stock"
                                    v-if="!option.in_stock"
                                    ><strong>Produção sob demanda</strong>
                                </span>
                                <span
                                    class="hidden-xs-down text-uppercase text-bold special-option-stock"
                                    v-else
                                    >Produto em estoque</span
                                >
                                <div
                                    class="d-flex flex-align-center"
                                    style="margin-bottom: 0 !important"
                                >
                                    <div class="form-group" style="margin-bottom: 0 !important">
                                        <div class="cr-select mr-2">
                                            <select v-model="option.quantity">
                                                <option v-for="(quantity, key) in 5">
                                                    {{ key + 1 }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div>
                                        <button
                                            class="btn btn-wide mt-0 px-4 special-option-button"
                                            @click="addToCart(option)"
                                        >
                                            ADICIONAR
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </template>

            <Alert ref="cartSpecialOptionsAlert" class="mt" />
        </div>
        <Modal v-model="showImage" class="small" @dismiss="showImage = !showImage">
            <div class="d-flex flex-align-center flex-justify-center card-block h-full">
                <img
                    v-if="imageZoom"
                    id="special-product-image"
                    style="max-width: 100%; max-height: 100%; object-fit: contain"
                    class="lazy d-flex"
                    :src="`${imageZoom}`"
                    :title="imageZoom"
                />
            </div>
        </Modal>
    </div>
</template>

<script>
    import { getScope } from '../../../common/common';
    import Alert from '../common/Alert';
    import { cartService } from '../../../common/service/resource';
    import Modal from '../common/container/Modal.vue';

    export default {
        name: 'CartSpecialOptions',

        components: { Modal, Alert },

        props: {
            showPacking: Boolean,
            maximumPackings: Number,
            isLateralMenu: Boolean,
        },

        data() {
            return {
                specialOptions: null,
                zoomedCard: null,
                addingOptions: false,
                showImage: false,
                imageZoom: null,
            };
        },

        mounted() {
            this.fetchData();
        },

        methods: {
            fetchData: function () {
                this.axios.http
                    .get(this.$scope.API_LAMBDA_URL + '/products/v4/search', {
                        params: {
                            onlyInStock: true,
                            listLocked: true,
                            attributes: 'buyable-product-list',
                            orderBy: 'tipo',
                            wholesaler: !this.$scope.IS_REGULAR_CUSTOMER,
                            categories: '5285',
                            types: [33, 13, 21],
                            perPage: 10,
                            onlyActive: true,
                            tenant: this.$scope.TENANT_IDENTIFIER,
                        },
                    })
                    .then((response) => {
                        this.specialOptions = response.data.hits
                            .filter((option) => !option.is_gift)
                            .map((option) => ({
                                produto_id: option.id,
                                image: `${option.img_cover}?w=104&h=156&auto=compress,format&q=65`,
                                biggerImage: `${option.img_cover}?auto=compress,format&q=65&q=65&w=688&h=1030&fit=crop&crop=top`,
                                name: option.name,
                                price: option.price,
                                type: (option.type || {}).name,
                                type_id: (option.type || {}).id,
                                selected: false,
                                variation_id: option.variations[0].variation_id,
                                size_name: option.variations[0].size_name,
                                quantity: 1,
                                in_stock: option.in_stock,
                            }));
                    })
                    .catch((error) => {
                        this.showErrorAlert('Não foi possível carregar as opções.');
                    });
            },

            buildCartBodyRequest: function (product) {
                return {
                    products: [
                        {
                            product_id: product.produto_id,
                            variation_id: product.variation_id,
                            quantity: product.quantity,
                        },
                    ],
                };
            },

            sendToCart: function (requestBody) {
                return cartService.updateProducts(getScope('cart_id'), requestBody);
            },

            closeCartSpecialOptions: function (refreshCart = false) {
                this.$emit('hide-special-products');
                if (refreshCart) this.$emit('update-cart');
            },

            showErrorAlert: function (message) {
                this.$refs.cartSpecialOptionsAlert.updateAlert(message, true);
            },

            handleCartErrorResponse: function (response) {
                try {
                    response = response.response.data;
                    let message = response.errors[0][0];

                    for (let i = 1; i < response.errors.length; i++) {
                        if (message.indexOf(response.errors[i][0]) < 0) {
                            message += '; ' + response.errors[i][0];
                        }
                    }

                    this.showErrorAlert(message);
                } catch (error) {
                    this.showErrorAlert('Não foi possível adicionar as opções à sacola.');
                }
            },

            addToCart: function (product) {
                this.addingOptions = true;
                const requestBody = this.buildCartBodyRequest(product);
                this.sendToCart(requestBody)
                    .then(() => {
                        this.$emit('special-product-quantity-changed', product, 1);
                        this.closeCartSpecialOptions(true);
                    })
                    .catch(this.handleCartErrorResponse)
                    .then(() => (this.addingOptions = false));
            },

            showImageModal(image) {
                this.showImage = !this.showImage;
                this.imageZoom = `${image.split('?')[0]}?auto=compress&w=300&h=450&q=65`;
            },
        },
    };
</script>
