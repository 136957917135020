export default () => ({
    pt: {
        _product: {
            model: 'Modelo',
            models: 'Modelagens',
            size: 'Tamanho',
            about: 'Detalhes do produto',
            watchVideo: 'Assista a um vídeo sobre o produto',
            related: 'Categorias relacionadas',
            aboutPrint: 'A estampa',
            typeSizes: 'Medidas de {0}',
            sizesGuide: 'Guia de tamanhos', // todo migrar pra marketing?
            sizesGuideDescription:
                'Preparamos um guia completo para você poder decidir melhor qual tamanho comprar.',
            _review: {
                toReview: 'Avaliar',
                review: 'Avaliação',
                reviews: 'Avaliações',
                loadingMany: '@:loading @.lower:(_product._review.reviews)...',
                tellUs: 'Compartilhe sua experiência com esse produto',
                tellUsHtml: `<p>Conta pra gente sua experiência com o produto <strong>{0}</strong>.</p><p>Fale tim tim por tim tim o que achou sobre qualidade, velocidade de entrega, atendimento, etc e tal :)</p>`,
                waitSubmit: 'Aguarde enquanto enviamos o seu comentário...',
                reviewSubmitted:
                    'Obrigado, em poucos minutos seu comentário será listado no produto.',
            },
            _reprint: {
                enterEmail: 'Informe seu email e avisaremos quando estiver disponível:',
                enterSize: 'Informe o tamanho desejado:',
                receiveNews:
                    'Também quero receber informações sobre promoções e lançamentos em meu email',
                reprintSubmitted:
                    'Obrigado, você será avisado quando este produto voltar ao estoque.',
                soldOutClick: '@:_shop._stock.soldOut',
            },
            manufacturer: 'Marca',
            selectManufacturer: 'Selecione a marca',
            selectPhoneModel: 'Selecione seu aparelho',
            selectSize: 'Selecione o tamanho',
            noSizesCheckColors:
                'Nenhum tamanho disponível. Verifique a disponibilidade em outras cores acima.',
            phoneModel: 'Modelo',
            selectOptionAbove: 'Selecione a opção desejada nos campos acima',
            selectDesiredSize: 'Selecione o tamanho desejado',
            colorIsUnavailable: 'A cor {0} não está disponível.',
            kids: 'Infantil',
            accessPage: 'Acessar página do produto',
            deliveryReadyOnly: 'Mostrar somente opções em pronta-entrega',
            toSeeSizesGuide: 'Ver medidas',
            availableInOtherColorsReprint:
                'Disponível em outras cores. Clique para ser avisado quando estiver disponível na cor selecionada.',
            availableInOtherColorsHtml:
                'Tamanho esgotado nesta cor, mas <strong>disponível em outras cores</strong>',
        },
    },
    en: {
        _product: {
            model: 'Modeling',
            models: 'Modelings',
            size: 'Size',
            about: 'About this product',
            watchVideo: 'Watch a video about this product',
            related: 'Related products',
            aboutPrint: 'About the print',
            typeSizes: 'Sizes for {0}',
            sizesGuide: 'Sizes Guide',
            sizesGuideDescription:
                'We have prepared a complete guide so you can better decide which size to buy.',
            _review: {
                toReview: 'Review',
                review: 'Review',
                reviews: 'Reviews',
                loadingMany: '@:loading @.lower:(_product._review.reviews)...',
                tellUs: 'Tell us about your experience with this product',
                tellUsHtml: `<p> Tell us about your experience with the product <strong>{0}</strong>.</p> <p> Share everything you thought about quality, delivery speed, service, etc and such :) </p>`,
                waitSubmit: 'Wait while your review is submitted...',
                reviewSubmitted:
                    'Thank you, in a few minutes your review will be listed on the product.',
            },
            _reprint: {
                enterEmail: "Enter your email and we'll notify you when it's available:",
                enterSize: 'Enter the desired size:',
                receiveNews:
                    'I also want to receive information about promotions and launches in my email',
                reprintSubmitted:
                    'Thank you, you will be notified when this product returns to stock.',
                soldOutClick: '@:_shop._stock.soldOut (click to be notified)',
            },
            manufacturer: 'Manufacturer',
            selectManufacturer: 'Select a manufacturer',
            selectPhoneModel: 'Select your device',
            selectSize: 'Select size',
            noSizesCheckColors: 'No size available. Check availability in some other color above.',
            phoneModel: 'Device',
            selectOptionAbove: 'Select the desired option in the fields above',
            selectDesiredSize: 'Select the desired size',
            colorIsUnavailable: 'The color {0} is not available.',
            kids: 'Kids',
            accessPage: 'Go to product page',
            deliveryReadyOnly: 'Show only delivery ready options',
            toSeeSizesGuide: 'See sizes guide',
            availableInOtherColorsReprint:
                'Available in other colors. Click to be notified when this color becomes available in the selected color.',
            availableInOtherColorsHtml:
                'Sold out in the selected color, but <strong>available in others</strong>',
        },
    },
});
