<script>
    import { EventBus, readUrlQuery } from '../../../common/common';

    export default {
        name: 'ProductWholesalerSizes',

        model: {
            prop: 'sizesQuantities',
        },

        props: {
            product: {
                type: Object,
                required: true,
            },
            sizesQuantities: {
                type: Object,
                default: () => ({}),
            },
        },

        data() {
            return {
                selectedColor: null,
                selectedModeling: null,
                selectedPhoneModel: null,
            };
        },

        watch: {
            selectedColor(to, from) {
                if (from) {
                    this.resetQuantityFields();
                }
            },
        },

        computed: {
            isPhone() {
                return (this.product.type || {}).id === 15;
            },

            colors() {
                const colors = [];
                const selected = {};

                this.product.variations.forEach((variation) => {
                    if (!variation.product_color_id || selected[variation.product_color_id]) return;

                    selected[variation.product_color_id] = true;

                    colors.push({
                        id: variation.product_color_id,
                        name: variation.product_color_name,
                        code: variation.product_color_hexa,
                        fabric_id: variation.fabric_id,
                    });
                });

                return colors.sort((a, b) => {
                    return a.fabric_id === this.product.fabric_id ? -1 : 1;
                });
            },

            modelings() {
                const modelings = [];
                const selected = {};

                if (this.product.is_clothing) {
                    this.product.variations.forEach((variation) => {
                        if (!variation.product_model_id || selected[variation.product_model_id])
                            return;

                        selected[variation.product_model_id] = true;

                        modelings.push({
                            id: variation.product_model_id,
                            name: variation.product_model_name,
                            gender: variation.gender,
                        });
                    });
                }

                return modelings.sort((a, b) => {
                    return a.id < b.id ? -1 : 1;
                });
            },

            sizes() {
                const sizesOrder = [
                    '2',
                    '4',
                    '6',
                    '8',
                    '10',
                    '12',
                    '14',
                    'PP',
                    'P',
                    'M',
                    'G',
                    'GG',
                    '2GG',
                    '3GG',
                    '4GG',
                ];

                return this.product.variations
                    .filter(
                        (variation) =>
                            !(
                                this.selectedColor &&
                                this.selectedColor.id !== variation.product_color_id
                            )
                    )
                    .sort((a, b) =>
                        sizesOrder.indexOf(a.size_name) > sizesOrder.indexOf(b.size_name) ? 1 : -1
                    );
            },

            phoneSizesByModel() {
                if (!this.isPhone) return {};

                const modelSizes = {};

                this.sizes.forEach((variation) => {
                    const model = variation.size_name.split(' ')[0];

                    if (!modelSizes[model]) {
                        modelSizes[model] = [];
                    }

                    modelSizes[model].push(variation);
                });

                return modelSizes;
            },

            phoneModels() {
                return Object.keys(this.phoneSizesByModel).sort();
            },

            selectOptions() {
                if (this.isPhone) {
                    return this.selectedPhoneModel
                        ? this.phoneSizesByModel[this.selectedPhoneModel]
                        : [];
                }

                return this.sizes;
            },
        },

        created() {
            this.resetQuantityFields();

            // Após possuir mais fotos, selecionar a modelagem depois da cor
            let model;
            const gender = readUrlQuery('gender');
            if (this.selectedSize && this.selectedSize.product_model_id) {
                model = this.modelings.find((m) => m.id === this.selectedSize.product_model_id);
            } else if (gender && (gender === 'M' || gender === 'F')) {
                model = this.modelings.find((m) => m.gender === gender);
            }

            if (model) this.setModeling(model);

            if (!this.selectedModeling && this.modelings.length > 0) {
                this.setModeling(this.modelings[0], false);
            }

            let color;
            const fabric = readUrlQuery('fabric');
            if (this.selectedSize && this.selectedSize.fabric_id) {
                color = this.colors.find((c) => c.fabric_id == this.selectedSize.fabric_id);
            } else if (fabric) {
                color = this.colors.find((c) => c.fabric_id == fabric);
            }

            if (color) this.setColor(color);

            if (!this.selectedColor && this.colors.length > 0) {
                this.setColor(this.colors[0], false);
            }
        },

        methods: {
            setColor(color, emitChange = true) {
                this.selectedColor = color;
                if (color && emitChange) {
                    EventBus.$emit('selected-color-change', color);
                }
            },

            setModeling(model, emitChange = true) {
                this.selectedModeling = model;
                if (model && emitChange) {
                    EventBus.$emit('selected-modeling-change', model.id);
                }
            },

            getItemProductionType(item) {
                if (item.quantity > 0) {
                    return item.quantity + ' em estoque';
                } else {
                    return 'Sob Demanda';
                }
            },

            resetQuantityFields() {
                const validatedSizesQuantities = { ...this.sizesQuantities };

                for (const item of this.product.variations) {
                    validatedSizesQuantities[item.variation_id] = 0;
                }

                this.$emit('input', validatedSizesQuantities);
            },
        },
    };
</script>
