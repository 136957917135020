<template>
    <div
        :id="'header-products-wrapper-' + this.name"
        :ref="'headerProductsWrapper' + this.name"
        :class="{
            open: slideMobileDrawerToRight,
            closed: !slideMobileDrawerToRight,
            'hidden-xs': hideMobileDrawer,
            'header-sub-menus-alt': $scope.EXPERIMENT_VAR2 > 0,
            'header-sub-menus': $scope.EXPERIMENT_VAR2 <= 0,
        }"
        class="pos-fixed w-screen h-screen pos-top text-uppercase"
    >
        <div
            id="header-products"
            :class="$scope.EXPERIMENT_VAR2 > 0 ? 'bg-white' : 'bg-default-1'"
            class="pos-fixed h-full flex-column"
        >
            <a v-if="!$scope.EXPERIMENT_VAR2" class="mt" @click="slideMobileDrawerToRight = false"
                ><i class="icon-cr icon-arrow-left mb-2 exit-sub-menu mt-2"></i
            ></a>
            <a v-else class="mt-4 mb-4" @click="slideMobileDrawerToRight = false"
                ><i class="icon-cr icon-chevron-left mr-1 exit-sub-menu"></i>Voltar</a
            >
            <nav v-for="(item, index) in items">
                <div v-if="$scope.EXPERIMENT_VAR2" v-for="thumb in item.thumbs" class="px-2">
                    <a :href="thumb.url" class="d-block pos-relative rounded overflow-hidden mb">
                        <img
                            :alt="thumb.alt"
                            :data-src="`${$scope.CDN_IMAGES}/images/site/header/${thumb.image_url}?auto=compress,format&q=65`"
                            style="max-width: 100%"
                            class="d-block lazy lazy-fade  img-responsive"
                            width="768"
                            height="200"
                        />
                        <div
                            class="pos-absolute pos-bottom pos-right mb-2 mr-2 bg-white rounded px-2 py-1 text-italic text-bold hidden"
                        >
                            {{ thumb.alt }}
                        </div>
                    </a>
                </div>
                <a
                    :href="item.url"
                    :class="{
                        'text-bold': item.is_bold !== '0',
                        'mt-3 pt-4': item.children && index > 0,
                        opened: item.opened,
                    }"
                    class="h5 my-0 text-uppercase"
                >
                    {{ item.title }}
                    <sup v-if="item.is_new === '1'" class="header-product-title-badge"> Novo </sup>
                </a>
                <div
                    v-if="index < items.length - 1 && item.is_bold === '0'"
                    style="border: 1px solid #b3b5b7"
                />
                <template v-for="(child, childIndex) in item.children">
                    <a
                        :href="child.url"
                        :class="{ 'text-bold': child.is_bold !== '0' }"
                        class="h5 my-0"
                    >
                        {{ child.title }}
                        <sup v-if="child.is_new === '1'" class="header-product-title-badge">
                            Novo
                        </sup>
                    </a>
                    <div
                        v-if="childIndex < item.children.length - 1"
                        style="border: 1px solid #b3b5b7"
                    />
                </template>
                <div v-if="$scope.EXPERIMENT_VAR2" v-for="thumb in item.bottomImgs" class="px-2">
                    <a :href="thumb.url" class="d-block pos-relative rounded overflow-hidden mt">
                        <img
                            :alt="thumb.alt"
                            :data-src="`${$scope.CDN_IMAGES}/images/site/header/${thumb.image_url}?auto=compress,format&q=65`"
                            style="max-width: 100%"
                            class="d-block lazy lazy-fade img-responsive"
                            width="768"
                            height="200"
                        />
                        <div
                            class="pos-absolute pos-bottom pos-right mb-2 mr-2 bg-white rounded px-2 py-1 text-italic text-bold hidden"
                        >
                            {{ thumb.alt }}
                        </div>
                    </a>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    import { EventBus, updateLazyLoad } from '../../../common/common';
    import { SHOW_MOBILE_MENU_EVENT } from '../../events';
    import { useSwipe } from '@vueuse/core';

    export default {
        name: 'HeaderMobileChild',

        props: ['items', 'name'],

        data() {
            return {
                slideMobileDrawerToRight: false,
                hideMobileDrawer: true,
            };
        },

        created() {
            EventBus.$on(SHOW_MOBILE_MENU_EVENT + this.name, () => {
                this.slideMobileDrawerToRight = true;
                this.$emit('submenu-enter');
            });
        },
        mounted() {
            this.$nextTick(() => {
                if (this.$refs['headerProductsWrapper' + this.name]) {
                    useSwipe(this.$el, {
                        onSwipeEnd: this.handleSwipe,
                    });
                }
            });

            this.$nextTick(() => updateLazyLoad());
        },
        watch: {
            slideMobileDrawerToRight(newValue, oldValue) {
                if (newValue && !oldValue) {
                    this.hideMobileDrawer = false;
                } else if (!newValue && oldValue) {
                    setTimeout(() => {
                        this.hideMobileDrawer = true;
                        this.$emit('submenu-exit');
                    }, 400);
                }
            },
        },

        methods: {
            handleSwipe(e, direction) {
                if (direction === 'LEFT') {
                    this.slideMobileDrawerToRight = false;
                }
            },
        },
    };
</script>
