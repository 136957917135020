<template>
    <div
        class="header-detail-banners d-flex flex-align-start text-default-4 flex-justify-end overflow-hidden ml-auto"
    >
        <div v-for="thumb in thumbs" class="px-2 hidden-md-down">
            <a :href="thumb.url" class="d-block pos-relative rounded overflow-hidden">
                <img
                    :alt="thumb.alt"
                    :data-src="`${$scope.CDN_IMAGES}/images/site/header/${thumb.image_url}?w=373&auto=compress,format&q=65`"
                    class="d-block lazy lazy-fade"
                />
                <div
                    class="pos-absolute pos-bottom pos-right mb-2 mr-2 bg-white rounded px-2 py-1 text-italic text-bold hidden"
                >
                    {{ thumb.alt }}
                </div>
            </a>
        </div>
    </div>
</template>

<script>
    import { updateLazyLoad } from '../../../common/common';
    import { useSwipe } from '@vueuse/core';
    export default {
        name: 'HeaderDesktopContentThumbs',

        props: {
            thumbs: {
                type: Array,
                required: true,
            },
        },
        mounted() {

            this.$nextTick(() => updateLazyLoad());
        },
    };
</script>
