var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"toggleable",class:{
        open: _vm.open,
        closed: !_vm.open,
    },attrs:{"id":_vm.item.id}},[_c('div',{staticClass:"header-detail content d-flex flex-justify-center"},[(_vm.hasGroups)?_vm._l((_vm.item.children),function(group,index){return _c('div',{staticClass:"header-collections d-flex flex-column",class:{ 'text-uppercase': group.is_fancy !== '1' },style:({
                    flex:
                        !_vm.item.thumbs && ['324', '332', '337', '472'].includes(group.id)
                            ? '0.4 0 0'
                            : group.is_fancy !== '1'
                            ? '0.7 0 0'
                            : `${Math.ceil(group.children.length / 12)} 0 0`,
                })},[(group.title && group.is_fancy !== '1')?[(group.url)?_c('a',{staticClass:"mt-0 mr",class:{
                            'text-bold': group.is_bold === '1',

                            'mb-2': group.is_bold !== '1',
                        },attrs:{"href":group.url},on:{"click":function($event){group.title !== 'Estampadas' && group.title !== 'Com estampa'
                                ? true
                                : _vm.clicOptimize(group.title)}}},[_vm._v("\n                        "+_vm._s(group.title)+"\n                    ")]):_c('p',{staticClass:"mt-0 mr",class:{ 'text-bold': group.children }},[_vm._v("\n                        "+_vm._s(group.title)+"\n                    ")])]:_vm._e(),_vm._v(" "),(group.children)?_c('HeaderDesktopContentItems',{attrs:{"items":group.children,"fancy":group.is_fancy === '1'}}):_vm._e()],2)}):[(_vm.item.children)?_c('HeaderDesktopContentItems',{staticClass:"flex-item-grow",attrs:{"items":_vm.item.children}}):_vm._e()],_vm._v(" "),(_vm.item.thumbs)?_c('HeaderDesktopContentThumbs',{attrs:{"thumbs":_vm.item.thumbs}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }