<template>
    <div class="block">
        <p class="h6 title-infos">
            {{ $t('color') }}:
            <span class="text-bold text-no-bold-mobile" v-if="selectedFabric">{{
                selectedFabric.product_color_name
            }}</span>
        </p>

        <nav class="cr-option-group product-variations-colors cr-option-colors">
            <template v-for="(fabric, index) in fabrics">
                <div
                    :class="{
                        'cr-option-selected with-border':
                            selectedFabric && fabric.fabric_id === selectedFabric.fabric_id,
                        'cr-option-crossed cr-option-disabled': shopMode && !fabric.has_any,
                        'no-border':
                            fabric.color_id !== 3 && fabric.fabric_id !== selectedFabric.fabric_id,
                    }"
                    v-tooltip="getFabricTooltip(fabric)"
                    class="cr-option cr-option-squared"
                    @click="$emit('on-fabric-click', fabric)"
                >
                    <div
                        :style="{
                            'background-color': fabric.product_color_hexa,
                        }"
                        class="product-color-option"
                    />
                    <div v-if="sizeHasFewUnits(fabric)" class="cr-option-description">
                        {{ $tc('_shop._stock.remainX', fabric.quantity) }}
                    </div>
                </div>
                <!-- quebra linha -->
                <div
                    v-if="
                        fabrics.length > (modelings.length > 1 ? 9 : 7) &&
                        index + 1 === Math.ceil(fabrics.length / 2)
                    "
                    class="hidden-md-down"
                    style="flex-basis: 100%"
                />
            </template>
        </nav>
    </div>
</template>

<script>
    export default {
        name: 'ProductVariationsFabrics',

        props: {
            product: {
                type: Object,
                required: true,
            },
            selectedFabric: {
                type: Object,
                required: false,
            },
            selectedSizeId: {
                type: Number,
                required: false,
            },
            shopMode: Boolean,
            sizes: Array,
            fabrics: Array,
            modelings: Array,
        },

        data() {
            return {};
        },

        methods: {
            sizeHasFewUnits(size) {
                return (
                    this.shopMode &&
                    this.selectedSizeId &&
                    !size.virtual_stock &&
                    size.quantity > 0 &&
                    size.quantity < 4 &&
                    this.product.in_stock
                );
            },

            getFabricTooltip(fabric) {
                if (this.shopMode && !fabric.has_any) {
                    return this.$t('_product._reprint.soldOutClick');
                }
                return null;
            },
        },
    };
</script>
