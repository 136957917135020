<template>
    <div>
        <ul v-if="products" ref="list" :class="innerListClass" @scroll="$emit('scroll', $event)">
            <ProductListItem
                v-for="product in limitedProducts"
                :small="small"
                :key="`${product.id}-${gender}`"
                :options="innerItemOptions"
                :product="product"
                :selected-filters="selectedFilters"
                :shorter-image="shorterImage"
                @click="pushItemEvent(product)"
            >
                <template v-slot:below-image>
                    <slot name="below-image" :product="product" />
                </template>
            </ProductListItem>
        </ul>
    </div>
</template>

<script>
    import ProductListItem from '../product/ProductListItem';
    import { updateLazyLoad } from '../../../common/lazyload';

    const DEFAULT_ITEM_OPTIONS = {
        baseUrl: window.url,
        displayName: true,
        displayArt: true,
        displayPrice: true,
        displayColors: false,
        imageWidth: 290,
        imageHeight: 435,
        lang: 'pt',
    };

    export default {
        name: 'ProductCreatorOtherProductsList',

        components: { ProductListItem },

        props: {
            products: {
                type: Array,
                required: true,
            },
            itemOptions: {
                type: Object,
                default: () => DEFAULT_ITEM_OPTIONS,
            },
            sliderOptions: {
                type: Object,
            },
            selectedFilters: {
                type: Object,
            },
            eventName: String,
            currentPage: {
                type: Number,
                default: 0,
            },
            perPage: {
                type: Number,
                default: 0,
            },
            gender: {
                type: Number,
                default: 0,
            },
            listClass: {
                type: String | Object,
                default: 'product-list product-list-5 product-list-gap-y-5',
            },
            shorterImage: Boolean,
            small: Boolean,
            partner: {
                type: Object,
            },
        },

        data() {
            return {
                productsLayer: null,
            };
        },

        watch: {
            products: {
                immediate: true,
                handler(to) {
                    if (!to) return;
                    this.pushListEvent();
                    this.$nextTick(() => {
                        updateLazyLoad();
                    });
                },
            },
        },

        computed: {
            limitedProducts() {
                return (this.options || {}).limit > 0
                    ? this.products.slice(
                          this.options.offset,
                          this.options.offset + this.options.limit
                      )
                    : this.products;
            },

            innerListClass() {
                let classes = { slider: this.sliderOptions };

                if (typeof this.listClass === 'string') {
                    classes[this.listClass] = true;
                } else {
                    classes = { ...classes, ...this.listClass };
                }
                return classes;
            },

            innerItemOptions() {
                const inner = { ...this.itemOptions };

                for (let prop in DEFAULT_ITEM_OPTIONS) {
                    if (DEFAULT_ITEM_OPTIONS.hasOwnProperty(prop) && inner[prop] === undefined) {
                        inner[prop] = DEFAULT_ITEM_OPTIONS[prop];
                    }
                }

                return inner;
            },
        },

        methods: {
            pushListEvent() {
                if (!this.eventName) return;

                window.dataLayer = window.dataLayer || [];

                this.productsLayer = this.products.map((product, index) => {
                    return {
                        name: product.name,
                        id: product.id + '',
                        price: product.price + '',
                        brand: 'Chico Rei',
                        category: product.type.name,
                        variant: '',
                        list: this.eventName,
                        position: this.perPage * (this.currentPage - 1) + index,
                    };
                });

                window.dataLayer.push({
                    event: 'list-products',
                    non_interaction: true,
                    ecommerce: {
                        currencyCode: 'BRL',
                        impressions: this.productsLayer,
                    },
                });
            },

            pushItemEvent(product) {
                if (!this.eventName) return;
                window.dataLayer.push({
                    event: 'productClick',
                    ecommerce: {
                        click: {
                            actionField: { list: this.eventName },
                            products: [
                                this.productsLayer.find((item) => item.id === product.id + ''),
                            ],
                        },
                    },
                });
            },
        },
    };
</script>
