<template>
    <div>
        <a :id="catalogUrl" class="anchor"></a>
        <div class="section-header">
            <img
                v-if="avatar"
                :data-src="`${avatar}?w=100&h=100&auto=compress,format&q=65`"
                class="artist-avatar lazy lazy-fade"
            />
            <h2 class="section-title">
                <a :href="`${aboutUrl || catalogUrl}`">{{ name }}</a>
            </h2>
            <a :href="catalogUrl" class="btn btn-primary btn-sm hidden-xs-down">
                Ver catálogo completo
            </a>
        </div>
        <ProductGroupList
            :class="listClass"
            :products="products"
            :show-only-thumb="showOnlyThumb"
            :show-price="showPrice"
            @scroll="$emit('scroll', $event)"
            :id="`featured-products-hs-${$vnode.key}`"
        />
        <a :href="catalogUrl" class="btn btn-primary btn-wide hidden-xs-up mt">
            Ver catálogo completo
        </a>
    </div>
</template>

<script>
    import ProductGroupList from '../product/ProductGroupList';

    export default {
        name: 'ProductGroup',

        components: { ProductGroupList },

        props: {
            name: {
                type: String,
                required: true,
            },
            catalogUrl: String,
            aboutUrl: String,
            avatar: String,
            products: {
                type: Array,
                required: true,
            },
            showPrice: Boolean,
            showOnlyThumb: Boolean,
            listClass: String | Object,
        },
    };
</script>
