<template>
    <div :class="{ opened: value }" class="cr-modal" @mousedown="onModalClick($event)">
        <div class="cr-modal-dialog">
            <Scrollable class="card overflow-hidden">
                <template v-if="$slots.title" v-slot:fixed-top>
                    <slot name="header">
                        <div
                            :class="{
                                'card-toolbar': showCloseBtn && allowDismiss,
                            }"
                            class="card-block"
                        >
                            <span class="h5 card-title"><slot name="title" /></span>
                            <span
                                v-if="showCloseBtn && allowDismiss"
                                aria-label="Fechar"
                                class="icon-cr icon-close icon-cr-hover"
                                @click.prevent="dismiss"
                            />
                        </div>
                    </slot>
                    <slot name="fixed-top" />
                </template>
                <slot />
                <template v-slot:fixed-bottom>
                    <slot name="fixed-bottom" />
                </template>
            </Scrollable>
        </div>
    </div>
</template>

<script>
    import Scrollable from './Scrollable';

    export default {
        name: 'Modal',

        components: { Scrollable },

        props: {
            showCloseBtn: {
                type: Boolean,
                default: true,
            },
            value: {
                type: Boolean | Object,
                default: false,
            },
            allowDismiss: {
                type: Boolean,
                default: true,
            },
        },

        methods: {
            dismiss() {
                if (!this.allowDismiss) return;

                this.$emit('dismiss');
                this.$emit('input', this.value instanceof Object ? null : false);
            },

            onModalClick(event) {
                if (
                    event.target.classList.contains('cr-modal') ||
                    event.target.classList.contains('cr-modal-dialog')
                ) {
                    event.preventDefault();
                    event.stopPropagation();
                    this.dismiss();
                }
            },
        },
    };
</script>
