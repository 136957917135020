var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.shippingValue && _vm.nationalShipping)?_c('div',[_c('Alert',{attrs:{"alert":_vm.progressBarAlert}}),_vm._v(" "),(_vm.cartHasPrime || _vm.$scope.is_prime)?_c('p',{staticClass:"d-flex flex-align-center"},[_vm._v("\n            Cliente\n            "),_c('img',{staticStyle:{"width":"3.5rem","height":"1rem","vertical-align":"text-bottom"},attrs:{"src":"https://chico-rei.imgix.net/images/site/2021/prime/selo_prime_02_378bcb.svg","alt":"Chico Rei Prime"}}),_vm._v("\n            tem direito a frete grátis, aproveite!\n        ")]):(
                (_vm.remaining <= 0 || _vm.cartHasNoShippingCost) &&
                _vm.nationalShipping &&
                !_vm.loading &&
                !_vm.mini
            )?[_c('p',{staticClass:"text-center"},[_vm._v("\n                Parabéns, você garantiu seu "),_c('strong',[_vm._v("frete grátis")]),_vm._v(" para o CEP\n                "),_c('strong',[_vm._v(_vm._s(_vm.shippingZip))]),_vm._v("! :) "),_c('i',{staticClass:"icon-cr icon-carrier-car"})]),_vm._v(" "),_c('div',{staticClass:"frame-outline py-1"},[_c('div',{staticClass:"progress-scroll"},[_c('div',{style:({ width: '100%' })})])])]:(
                (_vm.remaining <= 0 || _vm.cartHasNoShippingCost) &&
                _vm.nationalShipping &&
                !_vm.loading &&
                _vm.mini
            )?[_c('p',{staticClass:"text-center"},[_vm._v("\n                Parabéns, você garantiu seu "),_c('strong',[_vm._v("frete grátis")]),_vm._v(" para o CEP\n                "),_c('strong',[_vm._v(_vm._s(_vm.shippingZip))]),_vm._v("!"),_c('i',{staticClass:"icon-cr icon-carrier-car"})])]:(!_vm.loading && _vm.mini)?[_c('p',{staticClass:"text-center"},[_vm._v("\n                Faltam "+_vm._s(_vm.$f.formatMoney(_vm.remaining))+" para ganhar frete grátis neste CEP\n                "),_c('i',{staticClass:"icon-cr icon-carrier-car"})])]:(!_vm.loading)?[_c('p',{staticClass:"text-center"},[_vm._v("\n                Faltam "+_vm._s(_vm.$f.formatMoney(_vm.remaining))+" para o "),_c('strong',[_vm._v("FRETE GRÁTIS")]),_vm._v(" para\n                o CEP "),_c('strong',[_vm._v(_vm._s(_vm.shippingZip))]),_vm._v(" "),_c('i',{staticClass:"icon-cr icon-carrier-car"})]),_vm._v(" "),_c('div',{staticClass:"frame-outline py-1"},[_c('div',{staticClass:"progress-scroll"},[_c('div',{style:({ width: _vm.progress + '%' })})])])]:(_vm.loading)?[_c('Spinner')]:_vm._e()],2):(_vm.nationalShipping)?_c('div',{staticClass:"d-flex flex-align-center"},[_c('p',[_c('a',{staticClass:"text-underline",on:{"click":function($event){$event.preventDefault();return _vm.goToShipping.apply(null, arguments)}}},[_vm._v("Preencha seu CEP")]),_vm._v(" e veja\n            como ter "),_c('strong',[_vm._v("FRETE GRÁTIS")])]),_vm._v(" "),_c('i',{staticClass:"icon-cr icon-carrier-car ml-2"})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }