<template>
    <div id="contador">
        <div id="contador-content" class="desktop-v1">
            <span id="contador-title"
                >Todas as camisetas Coleção Milton Nascimento por R$39,90
            </span>
            <div id="contador-timer">
                <span id="contador-timer-text">Acaba em:</span>
                <div id="contador-timer-clock">
                    <div class="clock-section" id="hours">
                        <span class="clock-number">0</span>
                        <span class="clock-number">2</span>
                        <span class="clock-marker">h</span>
                    </div>
                    <div class="clock-section" id="minutes">
                        <span class="clock-number">3</span>
                        <span class="clock-number">7</span>
                        <span class="clock-marker">m</span>
                    </div>
                    <div class="clock-section" id="seconds">
                        <span class="clock-number">1</span>
                        <span class="clock-number">1</span>
                        <span class="clock-marker">s</span>
                    </div>
                </div>
            </div>
            <div id="contador-coupon">
                <span id="contador-coupon-text">Use o cupom:</span>
                <div id="coupon-wrapper">
                    <span id="coupon-wrapper-text">MILTON3990</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ContadorDesktopV1',
    };
</script>
