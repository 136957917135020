import LazyLoad from 'vanilla-lazyload';

const VueLazy = function (Vue) {
    Vue.directive('lazy', {
        inserted(el, binding, vnode) {
            const onLoaded = () => {
                if (binding.value && typeof binding.value === 'function') {
                    binding.value();
                }
            };

            if (vnode.context.$scope.IS_CRAWLER) {
                onLoaded();
            } else {
                let elementId = el.id;

                if (!elementId) {
                    do {
                        elementId = `vue-lazy-${Math.floor(Math.random() * 1000000)}`;
                    } while (document.getElementById(elementId));
                    el.id = elementId;
                }

                let lazy = new LazyLoad({
                    elements_selector: `#${elementId}`,
                    cancel_on_exit: false,
                    callback_enter: function () {
                        // obs@Jorge: não entendo o porquê, mas o timeout impede que dê erro no destroy
                        // fonte: https://github.com/verlok/lazyload/blob/master/demos/destroy.html
                        setTimeout(() => lazy.destroy(), 500);
                        onLoaded();
                    },
                });
            }
        },
    });
};

export default VueLazy;
