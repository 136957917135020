import { rest } from 'msw';

const orderMock = {
    active: false,
    additional_deadline: 6,
    aguardando_produtos: true,
    alerts: {
        alert_stock_error: false,
        alert_delay_error: false,
        alert_discount_date: null,
    },
    allow_devolution: false,
    allow_exchange: false,
    canceled: false,
    carrier_id: 4,
    carrier_name: 'RETIRAR NA CHICO REI',
    carrier_name_customer: 'RETIRAR NA CHICO REI',
    cart_id: 2492902,
    confirmed: false,
    confirmed_date: null,
    count_products: 12,
    customer_id: 201095,
    date: '2021-09-16T17:15:21-03:00',
    deadline: 2,
    deadline_initial: 1,
    delivery_date: null,
    delivery_forecast_final: '2021-09-29T00:00:00-03:00',
    delivery_forecast_initial: '2021-09-28T00:00:00-03:00',
    devolution_date: null,
    exchange_id: null,
    exchangeable_date: null,
    expected_delivery_date: '-0001-11-30T00:00:00-03:06',
    expresso: 0,
    frete_id: null,
    has_invoice: false,
    ignore_exchange_deadline: 0,
    installments: 1,
    interests: '0.0000',
    is_only_virtual_products: false,
    key_fisco: null,
    module: 'boleto_bancario',
    module_id: 237,
    nfe_number: null,
    order_id: 937132,
    organization_id: null,
    payment: 'Boleto Bancario',
    payment_return: null,
    pedido_ref: null,
    quotation_dolar: 3.2727,
    ready_for_shipping_date: null,
    reenvio: false,
    secure_key: 'ae24018baf8c276c2366e37536798dcb',
    sent_date: null,
    shipment_id: null,
    state: 22,
    state_name: 'Aguardando pagamento',
    total_discounts: 0,
    total_expresso: 0,
    total_interests: 0,
    total_paid: 502.8,
    total_products: 502.8,
    total_shipping: 0,
    total_shipping_cost: 0,
    tracking_number: null,
};

const orderItemMock = {
    active: true,
    color_name: 'Preto',
    discount: 0,
    fabric_id: 22,
    gender: 'M',
    gender_name: 'Masculino',
    item_pedido_id: 2588677,
    link: '/camiseta/camiseta-black-heart-12647.html',
    link_rewrite: 'camiseta-black-heart',
    main_fabric_id: 22,
    price: 41.9,
    product_art:
        'https://chico-rei.imgix.net/images/fabric/PT6500_fabric_22_3.jpg?mark-alpha=90&mark-scale=80&mark-align=middle,center&mark=https%3A%2F%2Fchicorei.imgix.net%2F12647%2F11e03f30-0a03-11ea-afde-45def46bbf91.png',
    product_art_thumb: 'https://chicorei.imgix.net/12647/11e03f30-0a03-11ea-afde-45def46bbf91.png',
    product_color_name: 'Preto Noir',
    product_id: 12647,
    product_model_id: 1,
    product_model_name: 'Masculina Gola Olímpica',
    product_name: 'Camiseta Black Heart',
    product_partner_id: 0,
    product_quantity_discount: 0,
    quantity: 2,
    size_name: 'P',
    stock_error: false,
    total_price: 83.8,
    type: 'Camiseta',
    type_id: 1,
    variation_id: 57,
};

const order = [
    rest.get('/customers/:id/orders', (req, res, ctx) => {
        return res(
            ctx.json({
                data: [orderMock],
                meta: {
                    pagination: {
                        current_page: 1,
                        data: [],
                        first_page_url:
                            'http://arcoiro.chicorei.site/customers/201095/orders?page=1',
                        from: null,
                        last_page: 1,
                        last_page_url:
                            'http://arcoiro.chicorei.site/customers/201095/orders?page=1',
                        next_page_url: null,
                        path: 'http://arcoiro.chicorei.site/customers/201095/orders',
                        per_page: 15,
                        prev_page_url: null,
                        to: null,
                        total: 0,
                    },
                },
            })
        );
    }),
    rest.get('/customers/:id/orders/:orderId', (req, res, ctx) => {
        return res(
            ctx.json({
                data: { ...orderMock, itens: { data: [orderItemMock] } },
            })
        );
    }),
];

export default order;
