<template>
    <span :class="[isHome ? 'countdown-2' : 'countdown']">
        <div class="promo-text-ab2-mobile hidden-xs-up" v-if="isHome">
            {{ alt }}
        </div>
        <div class="countdown-container" v-if="isHome">
            <div
                class="countdown-text"
                style="color: white; align-content: flex-end"
                :style="
                    'color: ' +
                    ($scope.active_counter && $scope.active_counter.length > 0
                        ? $scope.active_counter[0].counter_color
                        : '') +
                    ' !important'
                "
            >
                Acaba em:
            </div>
            <div>
                <span v-if="showDays" class="days"
                    >{{ days }}<span class="days-letter">d</span></span
                >
                <span
                    class="hours"
                    :style="
                        'color: ' +
                        ($scope.active_counter && $scope.active_counter.length > 0
                            ? $scope.active_counter[0].counter_color
                            : '') +
                        ' !important'
                    "
                >
                    <span>{{ hours.toString().split('')[0] }}</span>
                    <span>{{ hours.toString().split('')[1] }}</span>
                </span>
                <span
                    class="hours-letter"
                    :style="
                        'background-color: ' +
                        ($scope.active_counter && $scope.active_counter.length > 0
                            ? $scope.active_counter[0].counter_background_color
                            : '') +
                        ' !important; color: ' +
                        ($scope.active_counter && $scope.active_counter.length > 0
                            ? $scope.active_counter[0].counter_color
                            : '') +
                        ' !important'
                    "
                    >h</span
                >
                <span
                    class="minutes"
                    :style="
                        'color: ' +
                        ($scope.active_counter && $scope.active_counter.length > 0
                            ? $scope.active_counter[0].counter_color
                            : '') +
                        ' !important'
                    "
                >
                    <span>{{ minutes.toString().split('')[0] }}</span>
                    <span>{{ minutes.toString().split('')[1] }}</span>
                </span>
                <span
                    class="hours-letter"
                    :style="
                        'background-color: ' +
                        ($scope.active_counter && $scope.active_counter.length > 0
                            ? $scope.active_counter[0].counter_background_color
                            : '') +
                        ' !important; color: ' +
                        ($scope.active_counter && $scope.active_counter.length > 0
                            ? $scope.active_counter[0].counter_color
                            : '') +
                        ' !important'
                    "
                    >m</span
                >
                <span
                    class="seconds"
                    :style="
                        'color: ' +
                        ($scope.active_counter && $scope.active_counter.length > 0
                            ? $scope.active_counter[0].counter_color
                            : '') +
                        ' !important'
                    "
                >
                    <span>{{ seconds.toString().split('')[0] }}</span>
                    <span>{{ seconds.toString().split('')[1] }}</span>
                </span>
                <span
                    class="seconds-letter"
                    :style="
                        'background-color: ' +
                        ($scope.active_counter && $scope.active_counter.length > 0
                            ? $scope.active_counter[0].counter_background_color
                            : '') +
                        ' !important; color: ' +
                        ($scope.active_counter && $scope.active_counter.length > 0
                            ? $scope.active_counter[0].counter_color
                            : '') +
                        ' !important'
                    "
                    >s</span
                >
            </div>
        </div>
        <div v-else>
            <span v-if="showDays" class="days">{{ days }}<span class="days-letter">d</span></span>
            <span class="hours">
                <span>{{ hours.toString().split('')[0] }}</span>
                <span>{{ hours.toString().split('')[1] }}</span>
            </span>
            <span class="hours-letter">h</span>
            <span class="minutes">
                <span>{{ minutes.toString().split('')[0] }}</span>
                <span>{{ minutes.toString().split('')[1] }}</span>
            </span>
            <span class="hours-letter">m</span>
            <span class="seconds">
                <span>{{ seconds.toString().split('')[0] }}</span>
                <span>{{ seconds.toString().split('')[1] }}</span>
            </span>
            <span class="hours-letter">s</span>
        </div>
        <div class="promo-button-ab2-mobile hidden-xs-up" v-if="isHome">Confira</div>
    </span>
</template>

<script>
    export default {
        name: 'Countdown',

        props: {
            deadline: Date,
            showDays: Boolean,
            alt: String,
            isHome: Boolean,
        },

        data() {
            return {
                realDeadline: null, // usado quando a data final do contador é diferente da data final da oferta
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            };
        },

        watch: {
            deadline: {
                immediate: true,
                handler(date) {
                    if (date) {
                        this.realDeadline = date.getTime();
                    }
                },
            },
        },

        mounted() {
            this.update();
            setInterval(this.update.bind(this), 1000);
        },

        methods: {
            getValues() {
                const now = new Date();
                const diff = this.realDeadline ? this.realDeadline - now.getTime() : 0;

                if (diff < 0) {
                    return {
                        hours: 0,
                        minutes: 0,
                        seconds: 0,
                    };
                }

                const hours = Math.floor(diff / (60 * 60 * 1000));

                return {
                    days: Math.floor(diff / (60 * 60 * 1000 * 24)),
                    hours: this.showDays ? hours % 24 : hours,
                    minutes: Math.floor(diff / (60 * 1000)) % 60,
                    seconds: Math.floor(diff / 1000) % 60,
                };
            },
            update() {
                const values = this.getValues();
                this.days = values.days;
                this.hours = values.hours < 10 ? `0${values.hours}` : values.hours;
                this.minutes = values.minutes < 10 ? `0${values.minutes}` : values.minutes;
                this.seconds = values.seconds < 10 ? `0${values.seconds}` : values.seconds;
            },
        },
    };
</script>
