import GlobalScope from './plugin/global-scope';
import Filters from './plugin/filters';
import Tooltip from './directive/tooltip';
import VueLazy from './directive/lazy';
import VueAxios from 'vue-axios';
import { api, http } from '../../common/common';
import Cleave from 'vue-cleave-component';
import VueI18n from 'vue-i18n';
import * as filters from './filters';

const setupVueInstance = (instance) => {
    // Global Scope
    GlobalScope(instance);

    // Filters
    Filters(instance);

    // Tooltips
    Tooltip(instance);
    VueLazy(instance);

    // Axios
    instance.use(VueAxios, {
        http: http,
        api: api,
    });

    // Cleave component - https://github.com/ankurk91/vue-cleave-component
    instance.use(Cleave);

    // i18n
    instance.use(VueI18n);

    // Global Filters
    Object.keys(filters).forEach((key) => {
        instance.filter(key, filters[key]);
    });
};

export default setupVueInstance;
