<template>
    <div :class="[isMobile ? 'hidden-md-up' : 'hidden-md-down']" class="product-price">
        <div class="flex-grid mb-0" style="align-items: center">
            <div :class="{ 'pb-0': !isMobile }" class="flex-xs-6 flex-lg-12 flex-grid text-left">
                <div
                    class="d-flex flex-align-center flex-xs-12 flex-lg-6 pb-0 flex-wrap"
                    :style="{ padding: isMobile ? 0 : 'initial' }"
                    :class="{
                        'd-flex': !isMobile,
                        'product-price-block':
                            product.cashback_percentage > 0 && $scope.IS_REGULAR_CUSTOMER,
                    }"
                >
                    <!-- Preço antigo -->
                    <p
                        v-if="product.price_min > 0 && product.price_min < product.price_max"
                        class="text-secondary mr-2"
                        :class="{
                            h6: (this.product.type || {}).id !== 6,
                            'mb-0': (this.product.type || {}).id == 6,
                            'ml-3': (this.product.type || {}).id == 6 && isMobile,
                        }"
                    >
                        {{ $t('_shop.priceFrom') }}
                    </p>

                    <!-- Se tiver , preço novo -->
                    <p
                        v-if="
                            $scope.IS_REGULAR_CUSTOMER &&
                            product.price_old &&
                            product.price < product.price_old &&
                            (this.product.type || {}).id !== 6
                        "
                        class="h6 text-secondary mx-0 flex-child-fixed hidden-md-down"
                        :class="{
                            'line-through price-old': product.price < product.price_old,
                            'mb-0': isMobile,
                        }"
                    >
                        {{ `${$f.formatMoney(product.price_old)} ` }}
                    </p>
                    <p
                        :class="[
                            isMobile ? 'mb-0 mr-2 price-mobile' : '',
                            (this.product.type || {}).id == 6 ? 'my-0' : '',
                            $scope.IS_REGULAR_CUSTOMER &&
                            product.price_old &&
                            product.price < product.price_old
                                ? 'text-danger-1 flex-child-fixed'
                                : 'text-info-4 ml-0',
                        ]"
                        class="h6 mx-0 ml text-bold"
                    >
                        {{
                            $f.formatMoney(
                                product.price_min > 0 ? product.price_min : product.price
                            )
                        }}
                    </p>

                    <!-- Se tiver , preço novo -->
                    <p
                        v-if="
                            $scope.IS_REGULAR_CUSTOMER &&
                            product.price_old &&
                            product.price < product.price_old
                        "
                        class="h6 text-secondary mx-0 flex-child-fixed hidden-md-up mr-2"
                        :class="{
                            'line-through price-old': product.price < product.price_old,
                            'mb-0 price-old-mobile': isMobile,

                            'mt-2': (this.product.type || {}).id !== 6,
                            'mt-0': (this.product.type || {}).id == 6,
                        }"
                    >
                        {{ `${$f.formatMoney(product.price_old)} ` }}
                    </p>
                    <span
                        v-if="product.cashback_percentage && $scope.IS_REGULAR_CUSTOMER"
                        class="hidden-md-up installment-mobile text-uppercase flex-align-center"
                        style="padding: 0; margin-top: 0.5rem"
                        >{{
                            $t('_shop._price.upToXTimesInterestFreeMobile', [4, installmentPrice])
                        }}</span
                    >
                </div>

                <div
                    :class="{
                        'd-flex pb-0': !isMobile,
                        'installment-mobile': isMobile,
                    }"
                    class="flex-align-center flex-xs-12 flex-lg-6 text-uppercase"
                    v-if="product.price >= 20 && $scope.IS_REGULAR_CUSTOMER"
                >
                    <i class="icon-cr icon-credit-card mr-1 hidden-lg-down"></i>
                    <span class="hidden-md-down">{{
                        $t('_shop._price.upToXTimesInterestFree', [4, installmentPrice])
                    }}</span>
                    <span v-if="!product.cashback_percentage" class="hidden-md-up">{{
                        $t('_shop._price.upToXTimesInterestFreeMobile', [4, installmentPrice])
                    }}</span>
                </div>
                <div
                    v-if="product.cashback_percentage && $scope.IS_REGULAR_CUSTOMER"
                    class="hidden-md-up ml mt-2 mb-2"
                >
                    <div class="text-uppercase cashback-text d-flex flex-align-center">
                        <i class="icon-cr icon-cashback mr-2"></i>
                        {{ product.cashback_percentage }}% de cashback
                    </div>
                </div>
            </div>
            <div class="flex-xs-6 flex-lg-6">
                <!-- Botão de Comprar -->
                <ProductBuyButton
                    class="hidden-md-up"
                    ref="buyButton"
                    style="width: 100%"
                    :is-size-unavailable="showReprintMessage"
                    :product="product"
                    :size-to-buy="sizeToBuy"
                    :text="$scope.IS_REGULAR_CUSTOMER ? $t('_shop.toBuy') : $t('_shop.addToCart')"
                    :wholesaler-sizes-quantities-to-buy="wholesalerSizesQuantitiesToBuy"
                    @request-size="scrollToBuy"
                    @alert="setAlert"
                    @done="reset"
                />
            </div>
        </div>

        <div class="flex-grid mt-2" :class="{ 'px-2': !priceGuarantee }">
            <p
                v-if="priceBadge"
                id="product-price-promo"
                class="badge-danger-1 flex-child-fixed text-regular text-uppercase product-price-promo mb-3"
                :class="[
                    !countdownDeadline ? 'text-center' : '',
                    !priceBadge || (priceBadge && priceBadge.length) > 11
                        ? 'flex-xs-5'
                        : 'flex-xs-4',
                ]"
            >
                <strong>Promo!</strong><br />
                {{ priceBadge }}
            </p>
            <p
                v-if="!priceBadge && newBadge"
                id="product-price-promo"
                class="badge-info-1 flex-child-fixed text-regular text-uppercase text-center product-price-promo flex-xs-4 mb-3"
            >
                {{ newBadge }}
            </p>

            <template v-if="!isMobile">
                <!-- <div class="flex-xs-12">
                    <span class="text-yanone">Oferta garantida por:</span>
                    <Countdown
                        :deadline="new Date($scope.promo_guaranteed)"
                        style="font-size: 1rem"
                        class="text-danger promo_guaranteed"
                    />
                </div> -->
                <!-- <div
                    v-else-if="countdownDeadline"
                    class="countdown-product mb-4"
                    :class="[priceBadge && priceBadge.length > 12 ? 'flex-xs-6' : 'flex-xs-7']"
                >
                    <div class="text-left">
                        <span class="text-yanone text-uppercase">Se encerra em:</span><br />
                        <Countdown
                            :deadline="countdownDeadline"
                            class="text-bold"
                            style="font-size: 1.725rem"
                        />
                    </div>
                </div> -->
                <!-- <template v-else-if="countdownDeadline && !isMobile">
                    <div
                        class="flex-xs-7 countdown-product mb-4"
                        :class="[priceBadge && priceBadge.length > 12 ? 'flex-xs-6' : 'flex-xs-7']"
                    >
                        <div class="text-left">
                            <span class="text-yanone text-uppercase">Se encerra em:</span><br />
                            <Countdown
                                :deadline="countdownDeadline"
                                class="text-bold"
                                style="font-size: 1.725rem"
                            />
                        </div>
                    </div>
                </template> -->

                <div
                    v-if="priceGuarantee || countdownDeadline"
                    class="countdown-product mb-4"
                    :class="{
                        'flex-xs-8 px-4': priceGuarantee,
                        'flex-xs-7': !priceGuarantee && priceBadge && priceBadge.length <= 11,
                        'flex-xs-6': !priceGuarantee && priceBadge && priceBadge.length > 11,
                    }"
                >
                    <div class="text-left">
                        <span class="text-yanone text-uppercase">
                            {{ priceGuarantee ? 'Oferta garantida por:' : 'Se encerra em:' }}
                        </span>
                        <br />
                        <Countdown
                            :deadline="
                                priceGuarantee
                                    ? new Date($scope.promo_guaranteed)
                                    : countdownDeadline
                            "
                            :style="{
                                'font-size': priceGuarantee ? '1.5rem' : '1.725rem',
                            }"
                            class="text-bold"
                        />
                    </div>
                </div>
            </template>

            <div
                v-if="
                    priceBadge &&
                    !priceGuarantee &&
                    !isMobile &&
                    $scope.regulamento &&
                    $scope.PROMO.id_tipo_oferta !== 3
                "
                class="flex-xs-1 pt-3 mt-3 text-danger-1"
                @click="openRegulamento"
            >
                <i class="icon-cr icon-info cursor-pointer"></i>
            </div>
        </div>
        <div>
            <button
                v-if="!$scope.IS_REGULAR_CUSTOMER"
                class="btn-loading btn btn-xl btn-image font-roboto-c hidden-md-down"
                @click="$emit('scroll-to-wholesaler-buy')"
            >
                {{ $t('_shop.toBuy') }}
            </button>
        </div>
    </div>
</template>

<script>
    import Countdown from '../common/Countdown';
    import ProductBuyButton from './ProductBuyButton';
    import { productStore } from '../../common/store/product-store';
    import { cartStore } from '../../common/store/cart-store';
    import LoadingButton from '../common/LoadingButton';
    import { EventBus, scrollToPosition, timeHandler } from '../../../common/common';

    export default {
        name: 'ProductPageRegularPrice',

        components: {
            Countdown,
            ProductBuyButton,
            LoadingButton,
        },

        props: {
            product: {
                type: Object,
                required: true,
            },
            sizeToBuy: {
                type: Object,
                required: false,
            },
            wholesalerSizesQuantitiesToBuy: {
                type: String,
                required: false,
            },
            isMobile: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return {
                productStoreState: productStore.state,
                showFixedFrame: false,
                alert: null,
                cartStoreState: cartStore.state,
                variationId: null,
                countdownDeadline: null,
            };
        },

        created() {
            this.countdownDeadline = this.getCountdownDeadline();
        },

        computed: {
            priceGuarantee() {
                return (
                    this.product.discount &&
                    this.$scope.IS_REGULAR_CUSTOMER &&
                    !this.product.promo_combo &&
                    this.$scope.promo_guaranteed
                );
            },

            showReprintMessage() {
                if (!this.sizeToBuy) {
                    return false;
                }
                return (
                    this.$scope.IS_REGULAR_CUSTOMER &&
                    this.sizeToBuy &&
                    (!this.product.in_stock ||
                        (!this.sizeToBuy.virtual_stock && this.sizeToBuy.quantity === 0))
                );
            },

            priceBadge() {
                if (this.product.promo_combo > 0 && this.displayPromoBadge) {
                    return this.product.promo_name;
                } else if (this.product.discount && this.$scope.IS_REGULAR_CUSTOMER) {
                    return this.product.discount + '% OFF';
                } else {
                    return null;
                }
            },

            newBadge() {
                if (this.product.is_new && !this.product.promo_combo) {
                    return [5, 6, 8, 10, 14, 18, 31, 32, 36, 38, 42].indexOf(
                        (this.product.type || {}).id
                    ) !== -1
                        ? 'Novo'
                        : 'Nova';
                } else {
                    return null;
                }
            },

            displayPromoBadge() {
                return this.$scope.IS_REGULAR_CUSTOMER && !this.product.promo_group;
            },

            installmentPrice() {
                return this.$f.formatMoney(Math.floor((this.product.price / 4) * 100) / 100);
            },
        },
        methods: {
            reset() {
                for (const prop in this.wholesalerSizesQuantitiesToBuy) {
                    if (this.wholesalerSizesQuantitiesToBuy.hasOwnProperty(prop)) {
                        this.wholesalerSizesQuantitiesToBuy[prop] = 0;
                    }
                }
            },

            setAlert(alert) {
                this.alert = alert;
            },

            scrollToBuy() {
                let elById = cr$.byId('product-info').raw;
                const scrollY = Math.floor(
                    elById.getBoundingClientRect().top +
                        (document.body.scrollTop || document.documentElement.scrollTop)
                );
                let offsetY = 75;

                scrollToPosition(0, scrollY - offsetY, 500);
            },

            openRegulamento() {
                EventBus.$emit('open-regulamento');
            },

            getCountdownDeadline() {
                if (
                    this.$scope.PROMO &&
                    this.$scope.PROMO.contador === '1' &&
                    this.product &&
                    this.product.promo_combo > 0 &&
                    this.$scope.IS_REGULAR_CUSTOMER
                ) {
                    const promoStartDate = timeHandler(this.$scope.PROMO.data_inicio).toDate();
                    const promoEndDate = timeHandler(this.$scope.PROMO.data_fim).toDate();

                    if (promoStartDate && promoEndDate) {
                        const now = new Date();
                        const diff = promoEndDate.getTime() - now.getTime();
                        if (
                            promoStartDate < now &&
                            promoEndDate > now &&
                            diff < 2 * 24 * 3600 * 1000
                        ) {
                            return promoEndDate;
                        }
                    }
                }

                return null;
            },
        },
        watch: {
            'productStoreState.sizeToBuy.variation_id'() {
                this.variationId = (this.productStoreState || {}).sizeToBuy
                    ? this.productStoreState.sizeToBuy.variation_id
                    : null;
            },
        },
    };
</script>
