<template>
    <div v-if="$scope.SALES_REP">
        <h5>Atendimento</h5>
        <p>
            Fale com <strong>{{ $scope.SALES_REP.NAME }}</strong
            >:<br />
            <a :href="`tel:${$scope.SALES_REP.PHONE}`">{{
                $f.formatPhone($scope.SALES_REP.PHONE)
            }}</a>
            <br />
            <a :href="`mailto:${$scope.SALES_REP.EMAIL}`">{{ $scope.SALES_REP.EMAIL }}</a>
        </p>
    </div>
    <div v-else>
        <h5>Atendimento</h5>
        <p>
            Fale com nossos especialistas para seu negócio:<br />
            <a href="tel:3232119277">(32) 3211-9277</a><br />
            <a href="mailto:contato@chicorei.com.br">contato@chicorei.com.br</a><br />
        </p>
    </div>
</template>

<script>
    export default {
        el: '#footer-contact-vue',
        name: 'FooterContact',
    };
</script>
