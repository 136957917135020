var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"product-list-item"},[_c('a',{staticClass:"cart-product-info pr",staticStyle:{"width":"100%"},attrs:{"href":_vm.product.link}},[(_vm.imageArt || _vm.image)?_c('div',{staticClass:"flex-child-fixed"},[_c('img',{staticClass:"lazy lazy-fade hidden-xs-down",attrs:{"alt":_vm.product.name,"data-src":_vm.imageArt || _vm.image,"height":"86","width":"57"}}),_vm._v(" "),_c('img',{staticClass:"lazy lazy-fade hidden-xs-up",attrs:{"alt":_vm.product.name,"data-src":_vm.imageArt || _vm.image,"height":"100","width":"85"}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column pl-3 overflow-hidden"},[_c('p',{staticClass:"product-list-item-info text-secondary"},[_vm._v("\n                "+_vm._s(_vm.product.type.name)+"\n            ")]),_vm._v(" "),_c('p',{staticClass:"product-list-item-title text-ellipsis"},[_vm._v(_vm._s(_vm.name))]),_vm._v(" "),(_vm.options.displayPrice)?[(
                        !_vm.product.price_min &&
                        !_vm.product.price_max &&
                        _vm.product.price_old > _vm.product.price &&
                        !_vm.$scope.IS_WHOLESALER &&
                        _vm.options.displayPercent
                    )?_c('p',[_c('del',{staticClass:"text-secondary"},[_vm._v(_vm._s(_vm.$f.formatMoney(_vm.product.price_old)))])]):_vm._e(),_vm._v(" "),_c('p',{class:{
                        'text-accent':
                            _vm.product.price_min > 0 &&
                            _vm.product.price_min < _vm.product.price_max &&
                            _vm.product.price_old > _vm.product.price &&
                            !_vm.$scope.IS_WHOLESALER,
                    }},[(_vm.product.price_min > 0 && _vm.product.price_min < _vm.product.price_max)?_c('span',{staticClass:"product-list-item-price"},[_vm._v("\n                        A partir de R$"+_vm._s(_vm.$f.formatMoney(_vm.product.price_min))+"\n                    ")]):[_c('span',{staticClass:"product-list-item-price"},[_vm._v("R$"+_vm._s(_vm.$f.formatMoney(_vm.product.price)))])],_vm._v(" "),(
                            !_vm.options.hideInstallments &&
                            _vm.product.price_min == _vm.product.price_max &&
                            _vm.product.price >= 20
                        )?_c('span',[_c('span',{staticClass:"hidden-md-down"},[_vm._v(" | ")]),_vm._v(" "),_c('br',{staticClass:"hidden-md-up"}),_vm._v("\n                        4x de R$"+_vm._s(_vm.$f.formatMoney(_vm.product.price / 4))+"\n                    ")]):_vm._e()],2)]:_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }