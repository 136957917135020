<template>
    <div>
        <div class="d-inline-flex flex-justify-between w-full">
            <div class="creator-line d-flex w-full">
                <div v-if="partner.partner_type_id === 2">
                    <img
                        v-if="partner.avatar"
                        :src="`${$scope.CDN_IMAGES}/images/site/parceiros/avatars/${partner.avatar}?w=56&h=56&auto=compress,format&q=65`"
                        alt="Avatar do parceiro"
                        class="lazy artist-avatar mb mr"
                    />
                    <img
                        v-else
                        :src="`${$scope.CDN_IMAGES}/images/site/parceiros/avatars/selo_artista_padrao.svg?w=56&h=56&auto=compress,format&q=65`"
                        alt="Selo artista convidado"
                        class="lazy artist-avatar mb mr"
                    />
                </div>
                <div v-else-if="partner.partner_type_id === 1">
                    <img
                        v-if="partner.logo_image"
                        :src="`${$scope.CDN_IMAGES}/images/site/parceiros/logos/${partner.logo_image}?w=56&h=56&auto=compress,format&q=65`"
                        alt="Logo do parceiro"
                        class="lazy artist-logo mb mr"
                    />
                    <img
                        v-else
                        :src="`${$scope.CDN_IMAGES}/images/site/parceiros/logos/colecao-oficial-selo-padrao.png?w=56&h=56&auto=compress,format&q=65`"
                        alt="Selo coleção oficial Chico Rei"
                        class="lazy artist-logo mb mr"
                    />
                </div>
                <div v-else>
                    <img
                        :src="`${$scope.CDN_IMAGES}/images/site/parceiros/avatar/selo_CREstudio_laranja.png?w=56&h=56&auto=compress,format&q=65`"
                        alt="Selo estúdio Chico Rei"
                        class="lazy artist-logo mb mr"
                    />
                </div>
                <h2 v-if="partner.partner_type_id === 1" class="h6 text-default-5 mb">
                    Mais produtos da coleção oficial <strong>{{ partner.name }}</strong>
                </h2>
                <h2 v-else-if="partner.partner_type_id === 2" class="h6 text-default-5 mb">
                    Mais produtos com artes de <strong>{{ partner.name }}</strong>
                </h2>
                <h2 v-else class="h6 text-default-5 mb">
                    Mais produtos autorais de nossos artistas
                </h2>
            </div>
            <div class="creator-link">
                <a v-if="partner.partner_type_id === 1" :href="`/${partner.url}`" target="_blank"
                    >Confira catálogo completo</a
                >
                <a
                    v-else-if="partner.partner_type_id === 2"
                    :href="`artistas/${partner.url}`"
                    target="_blank"
                    >Confira catálogo completo</a
                >
                <a v-else :href="`/camiseta/estudio`" target="_blank">Confira catálogo completo</a>
            </div>
        </div>
        <div>
            <div>
                <ProductCreatorOtherProductsList
                    id="creator-list"
                    :products="products"
                    :partner="partner"
                    event-name="Product detail similar"
                    list-class="product-horizontal-list product-horizontal-list-5 no-scrollbar"
                    @scroll="scrollEvent = $event"
                />
                <HorizontalScrollbar
                    v-if="products.length > 2"
                    :scroll-event="scrollEvent"
                    class="hidden-not-touch"
                    :id-list="'creator-list'"
                />
            </div>
        </div>
    </div>
</template>
<script>
    import HorizontalScrollbar from '../common/container/HorizontalScrollbar';
    import ProductCreatorOtherProductsList from './ProductCreatorOtherProductsList';
    import ProductList from '../common/ProductList.vue';

    export default {
        name: 'ProductCreatorOtherProducts',

        components: { ProductList, ProductCreatorOtherProductsList, HorizontalScrollbar },

        props: {
            products: {
                type: Array,
                required: true,
            },
            partner: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                scrollEvent: null,
            };
        },
    };
</script>
