<script>
    export default {
        name: 'FieldWrapperProps',

        props: {
            id: String,
            label: String,
            hint: String,
            error: String | Boolean,
            addOnBefore: String,
            addOnAfter: String,
            tooltip: String,
        },
    };
</script>
