<template>
    <div id="cart-products">
        <div
            v-if="$scope.IS_SALES_REP && cart && cart.customer"
            class="frame-outline mb text-yanone text-uppercase"
        >
            <p class="mx-0 my-0">
                <i class="icon-cr icon-indicates" />
                Você está comprando para:
                <strong class="text-info-3">
                    {{ cart.customer.data.full_name }}
                </strong>
            </p>
        </div>

        <div v-if="$scope.IS_SALES_REP && cart" class="frame-outline mb text-yanone text-uppercase">
            <p class="mx-0 my-0">
                <i class="icon-cr icon-carrier-car" />
                <span>
                    Previsão de envio:
                    <strong v-if="cart.fixed_shipping_days" class="text-info-3">
                        {{ $f.formatDate(cart.fixed_shipping_date, 'DD/MM/YY') }}.
                    </strong>
                    <strong v-else class="text-info-3"> Automática. </strong>
                    <a
                        class="text-primary cursor-pointer"
                        @click="$emit('request-shipping-days-modal')"
                    >
                        Clique aqui para alterar.
                    </a>
                </span>
            </p>
        </div>

        <template v-show="!loading">
            <!-- <div class="frame-outline mb text-yanone text-uppercase d-flex">
                <p class="my-0 mr-2">
                    <i class="icon-cr icon-gift"></i>
                </p>
                <p class="mx-0 my-0">
                    <template v-if="$scope.IS_REGULAR_CUSTOMER">
                        <a
                            class="text-accent cursor-pointer"
                            @click="$emit('select-gifts')"
                        >
                            {{ $t('_cart.addWrappingCardsHere') }}
                        </a>
                    </template>
                    <template v-else>
                        <a
                            class="text-accent cursor-pointer"
                            @click="$emit('select-gifts')"
                        >
                            {{ $t('_cart.addWrappingHere') }}
                        </a>
                    </template>
                </p>
            </div> -->

            <!-- <CartDonation
                v-if="enableDonations && $scope.IS_REGULAR_CUSTOMER"
                :loading="loadingDonations"
                :isDonationInCart="isDonationInCart"
                @donation-submitted="addDonation"/> -->

            <!-- <CartKit
                    v-if="enableAddKit && hasMinToKit && $scope.IS_REGULAR_CUSTOMER"
                    :loading="loadingKit"
                    :is-kit-in-cart="isKitInCart"
                    :giftMessage="cart ? cart.gift_message : null"
                    @add-kit="addKit"/> -->

            <!-- <CartSketchbook
               v-if="enableAddFreeSketchbook && hasMinToSketchbook && $scope.IS_REGULAR_CUSTOMER"
               :loading="loadingSketchbook"
               :is-sketchbook-in-cart="isSketchbookInCart"
               @add-sketchbook="addSketchbook"/> -->

            <CartOrganizationDonation
                v-if="organizationsList && cart"
                :loading="loadingOrganizationDonation"
                :organizations="organizationsList"
                :selected-organization="cart.organization_id"
                @organization-selected="changeOrganization"
            />

            <Alert :alert="productsAlert" />
            <template>
                <Alert :alert="noStockAlert" />
            </template>

            <div class="frame-outline px-0">
                <ul class="cart-products-list" style="list-style-type: none">
                    <CartGiftOffer
                        v-if="cart && cart.gift_offer && !isGiftProductInCart"
                        :gift-offer="cart.gift_offer"
                        :is-lateral-menu="isLateralMenu"
                        class="product-list-item"
                        @add-gift="addGiftOffer"
                    />
                    <CartProduct
                        v-for="cartProduct in orderedProducts"
                        :key="`${cartProduct.produto_id}_${cartProduct.variation_id}`"
                        :cart-product="cartProduct"
                        :has-min-to-kit="hasMinToKit"
                        :kit-product-id="kitProductId"
                        :maximum-allowed-packings="cart.maximum_allowed_packings"
                        :special-mug-product-id="specialMugProductId"
                        :is-lateral-menu="isLateralMenu"
                        class="product-list-item"
                        @remove="remove(cartProduct)"
                        @add-one="addOne(cartProduct)"
                        @remove-one="removeOne(cartProduct)"
                        @quantity-change="onTypeProductQuantity(cartProduct, $event)"
                    />
                </ul>
            </div>

            <div
                v-if="chicundumProduct && $scope.IS_REGULAR_CUSTOMER"
                class="my cart-products-banner cursor-pointer"
                @click="addChicundum()"
            >
                <picture>
                    <source
                        :data-srcset="`${$scope.CDN_IMAGES}/images/site/promo-estatica/2020_janeiro_bnrcarrinho_chicundum8_720x100.png`"
                        media="(min-width: 576px)"
                    />
                    <img
                        :data-src="`${$scope.CDN_IMAGES}/images/site/promo-estatica/2020_janeiro_bnrcarrinho_chicundum8_720x100.png`"
                        class="lazy lazy-fade"
                    />
                </picture>
            </div>

            <!--            <div>-->
            <!--                <a href="https://chicorei.com/vapt-vupt" style="text-decoration: none">-->
            <!--                    <div id="cart-loyalty" class="frame-outline mb text-yanone text-uppercase mt" style="background-color: #ff7700; border: none;">-->
            <!--                        <img :data-src="`${$scope.CDN_IMAGES}/images/site/envio-imediato.png?auto=compress,format&q=65`" alt="Envio imediato" class="lazy hidden-md-down" />-->
            <!--                            <p class="mx-0 my-0" style="color: #fff;">-->
            <!--                                <strong>Clique aqui</strong> e confira a nossa seleção de produtos com envio imediato-->
            <!--                                e receba super rápido!-->
            <!--                            </p>-->
            <!--                    </div>-->
            <!--                </a>-->
            <!--            </div>-->

            <!-- <template v-if="$scope.IS_REGULAR_CUSTOMER && loyaltyPoints > 0">
                <div
                    id="cart-loyalty"
                    class="frame-outline mb text-yanone text-uppercase mt"
                >
                    <img
                        :data-src="`${$scope.CDN_IMAGES}/images/site/logo/cartao-fidelidade.svg?auto=compress,format&q=65`"
                        class="lazy hidden-md-down"
                    />
                    <p class="mx-0 my-0">
                        {{
                            $tc(
                                '_cart.collectingLoyaltyPointsX',
                                loyaltyPoints * loyaltyMultiplier
                            )
                        }}
                    </p>
                </div>
            </template> -->
        </template>
    </div>
</template>
<script>
    import { updateLazyLoad } from '../../../common/common';
    import {
        cartService,
        organizationService,
        productService,
    } from '../../../common/service/resource';
    import { cartStore } from '../../common/store/cart-store';
    import Alert from '../common/Alert';
    import CartProduct from './CartProduct';
    import CartOrganizationDonation from './special-promo/CartOrganizationDonation';
    import CartGiftOffer from './special-promo/CartGiftOffer.vue';

    export default {
        name: 'CartProducts',

        components: { CartGiftOffer, CartOrganizationDonation, CartProduct, Alert },

        props: [
            'loading',
            'products',
            'isDonationInCart',
            'cart',
            'loyaltyMultiplier',
            'specialMugProductId',
            'donationProductId',
            'enableDonations',
            'isLateralMenu',
        ],

        data() {
            return {
                productsAlert: null,
                loadingDonations: false,
                chicundumProduct: null,
                // Kit dia das mães
                loadingKit: false,
                enableAddKit: false,
                kitProductId: 12065,
                // Sketchbook gratuito
                enableAddFreeSketchbook: false,
                freeSketchbookId: 11326,
                loadingSketchbook: false,

                organizationsList: null,
                loadingOrganizationDonation: false,
            };
        },

        mounted() {
            updateLazyLoad();
        },

        watch: {
            products: function () {
                this.$nextTick(function () {
                    updateLazyLoad();
                });
            },

            cart(to) {
                if (to && to.allow_organization_selection && !this.organizationsList) {
                    this.fetchOrganizations();
                } else if (to && !to.allow_organization_selection) {
                    this.organizationsList = null;
                }
            },
        },

        computed: {
            orderedProducts() {
                if (!this.products) return null;

                return this.products.sort((a, b) => {
                    return a.product.is_gift ? -1 : 1;
                });
            },

            loyaltyPoints: function () {
                if (!this.products) return 0;

                let points = 0;
                for (let cartProduct of this.products) {
                    const typesNotInFidelity = [20, 12, 13, 16, 29];
                    if (typesNotInFidelity.indexOf((cartProduct.product.type || {}).id) < 0) {
                        points += cartProduct.quantity;
                    }
                }
                return points;
            },

            /*isKitInCart: function () {
                if (!this.products) return false;

                for (let product of this.products) {
                    if (product.produto_id == this.kitProductId) {
                        return true;
                    }
                }
                return false;
            },*/

            isSketchbookInCart: function () {
                if (!this.products) return false;

                for (let product of this.products) {
                    if (product.produto_id == this.freeSketchbookId) {
                        return true;
                    }
                }
                return false;
            },

            hasMinToKit() {
                return !(!this.cart || this.cart.total_products < 30);
            },

            hasMinToSketchbook() {
                if (!this.cart) {
                    return false;
                }

                const minValue = this.isSketchbookInCart ? 208.9 : 150;

                return this.cart.total_products - this.cart.total_discounts >= minValue;
            },
            noStockAlert() {
                if (this.products) {
                    for (let i = 0; i < this.products.length; i++) {
                        if (!this.products[i].has_quantity || !this.products[i].can_be_bought) {
                            return {
                                message:
                                    'Sua sacola possui produtos fora de estoque, para finalizar a compra será necessário removê-lo.',
                                isError: true,
                                timeout: 0,
                            };
                        }
                    }
                }
                return null;
            },

            isGiftProductInCart: function () {
                if (!this.cart || !this.cart.products || !this.cart.gift_offer) return false;

                for (let product of this.cart.products.data) {
                    if (product.produto_id == this.cart.gift_offer.product_id) {
                        return true;
                    }
                }
                return false;
            },
        },

        methods: {
            fetchChicundum: function () {
                productService.get(15416).then((response) => {
                    this.chicundumProduct = response.data;
                });
            },

            fetchOrganizations: function () {
                organizationService.query().then((response) => {
                    this.organizationsList = response.data;
                });
            },

            handleCartApiError: function (response, defaultMessage) {
                try {
                    response = response.response.data;
                    let message = response.errors[0][0];

                    for (let i = 1; i < response.errors.length; i++) {
                        message += ';' + response.errors[i][0];
                        if (i === response.errors.length - 1) {
                            message += '.';
                        }
                    }

                    this.productsAlert = {
                        message: message,
                        isError: true,
                    };
                } catch (error) {
                    this.productsAlert = {
                        message: defaultMessage,
                        isError: true,
                    };
                }
            },

            addOne: function (product) {
                if (
                    product.product.type.id !== 13 ||
                    product.quantity < this.cart.maximum_allowed_packings
                ) {
                    this.updateProductQuantity(product, product.quantity + 1);
                }
            },

            removeOne: function (product) {
                this.updateProductQuantity(product, product.quantity - 1);
            },

            remove: function (product) {
                this.updateProductQuantity(product, 0);
            },

            onTypeProductQuantity: function (product, ev) {
                const typedQuantity = parseInt(ev.target.value);
                const quantity =
                    product.product.type.id === 13
                        ? Math.min(typedQuantity, this.cart.maximum_allowed_packings)
                        : typedQuantity;
                if (quantity !== product.quantity) {
                    this.updateProductQuantity(product, quantity);
                } else {
                    ev.target.value = quantity;
                }
            },

            addGiftOffer: function () {
                this.$emit('changing-products');
                cartStore
                    .updateProduct({
                        products: [
                            {
                                product_id: this.cart.gift_offer.product_id,
                                variation_id: this.cart.gift_offer.cart_product.variation_id,
                                quantity: 1,
                            },
                        ],
                    })
                    .then(() => {
                        this.productsAlert = {
                            message: 'Item adicionado com sucesso :)',
                        };
                        this.$emit('update-cart');
                    })
                    .catch(() => {
                        this.productsAlert = {
                            message: 'Não foi possível adicionar o item. Tente novamente.',
                            isError: true,
                        };
                        this.$emit('done-changing-products');
                    });
            },

            addKit: function (text) {
                this.$emit('changing-products');
                this.loadingKit = true;
                cartStore
                    .updateProduct({
                        products: [
                            {
                                product_id: this.kitProductId,
                                variation_id: 1034,
                                quantity: 1,
                                giftMessage: text,
                            },
                        ],
                    })
                    .then(() => {
                        this.productsAlert = {
                            message: 'Item adicionado com sucesso :)',
                        };
                        this.$emit('update-cart');
                    })
                    .catch(() => {
                        this.productsAlert = {
                            message: 'Não foi possível adicionar o item. Tente novamente.',
                            isError: true,
                        };
                        this.$emit('done-changing-products');
                    })
                    .finally(() => {
                        this.loadingKit = false;
                    });
            },

            addSketchbook: function () {
                this.$emit('changing-products');
                this.loadingSketchbook = true;
                cartStore
                    .updateProduct({
                        products: [
                            {
                                product_id: this.freeSketchbookId,
                                variation_id: 1111,
                                quantity: 1,
                            },
                        ],
                    })
                    .then(() => {
                        this.productsAlert = {
                            message: 'Item adicionado com sucesso :)',
                        };
                        this.$emit('update-cart');
                    })
                    .catch(() => {
                        this.productsAlert = {
                            message: 'Não foi possível adicionar o item. Tente novamente.',
                            isError: true,
                        };
                        this.$emit('done-changing-products');
                    })
                    .finally(() => {
                        this.loadingSketchbook = false;
                    });
            },

            addChicundum: function () {
                this.$emit('changing-products');
                cartStore
                    .updateProduct({
                        products: [
                            {
                                product_id: this.chicundumProduct.id,
                                variation_id: 1034,
                                quantity: 1,
                            },
                        ],
                    })
                    .then(() => {
                        this.productsAlert = {
                            message: 'Item adicionado com sucesso :)',
                        };
                        this.$emit('update-cart');
                        this.$emit('custom-product-added', this.chicundumProduct);
                    })
                    .catch(() => {
                        this.productsAlert = {
                            message: 'Não foi possível adicionar o item. Tente novamente.',
                            isError: true,
                        };
                        this.$emit('done-changing-products');
                    });
            },

            addDonation: function (amount) {
                this.$emit('changing-products');
                this.loadingDonations = true;

                const requestBody = {
                    products: [
                        {
                            product_id: this.donationProductId,
                            name: 'Cartão Projeto Camisetas Mudam o Mundo',
                            variation_id: 1034,
                            quantity: 1,
                            specialPrice: amount,
                        },
                    ],
                };

                cartStore
                    .updateProduct(requestBody)
                    .then(() => {
                        this.productsAlert = {
                            message: 'Doação adicionada com sucesso :)',
                        };
                        this.$emit('update-cart');
                        this.$emit('custom-product-added', requestBody.products[0]);
                    })
                    .catch(() => {
                        this.productsAlert = {
                            message: 'Não foi possível adicionar a doação. Tente novamente.',
                            isError: true,
                        };
                        this.$emit('done-changing-products');
                    })
                    .finally(() => {
                        this.loadingDonations = false;
                    });
            },

            changeOrganization: function (organization_id) {
                this.$emit('changing-products');
                this.loadingOrganizationDonation = true;

                cartService
                    .updateOrganization(this.$scope.cart_id, {
                        organization_id,
                    })
                    .then(() => {
                        this.productsAlert = {
                            message: 'Instituição selecionada com sucesso :)',
                        };
                        this.$emit('update-cart');
                    })
                    .catch(() => {
                        this.productsAlert = {
                            message: 'Não foi possível selecionar a instituição. Tente novamente.',
                            isError: true,
                        };
                        this.$emit('done-changing-products');
                    })
                    .finally(() => {
                        this.loadingOrganizationDonation = false;
                    });
            },

            updateProductQuantity: function (product, requestedQuantity) {
                this.$emit('changing-products');

                const diffQuantity = requestedQuantity - product.quantity;

                cartStore
                    .updateProduct({
                        products: [
                            {
                                product_id: product.produto_id,
                                variation_id: product.variation_id,
                                quantity: diffQuantity,
                            },
                        ],
                    })
                    .then(() => {
                        if (requestedQuantity === 0) {
                            this.productsAlert = {
                                message: 'Item removido com sucesso',
                            };
                        }

                        this.$emit('update-cart');
                        this.$emit('product-quantity-changed', product, diffQuantity);
                    })
                    .catch((response) => {
                        this.$emit('done-changing-products');
                        this.handleCartApiError(
                            response,
                            requestedQuantity === 0
                                ? 'Não foi possível remover o item'
                                : 'Não foi possível alterar a quantidade'
                        );
                    });
            },
        },
    };
</script>
