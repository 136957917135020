<template>
    <div>
        <slot :busy="busy" :fields="fields" :subscribe="subscribe" />
        <Alert v-if="enableAlerts" :alert="newsletterAlert" />
    </div>
</template>

<script>
    import { customerService } from '../../../common/service/resource';
    import Alert from '../common/Alert';

    export default {
        name: 'NewsletterSubscriber',

        components: { Alert },

        props: {
            enableAlerts: {
                type: Boolean,
                default: true,
            },
            fetchCustomer: Boolean,
            successMessage: String,
        },

        data() {
            return {
                newsletterAlert: null,
                busy: false,

                fields: {
                    name: this.$scope.id ? this.$scope.CUSTOMER.NAME : null,
                    email: this.$scope.id ? this.$scope.CUSTOMER.EMAIL : null,

                    promo: true,
                    releases: true,
                    content: true,
                },
            };
        },

        created() {
            if (this.fetchCustomer) this.doFetchCustomer();
        },

        methods: {
            doFetchCustomer() {
                if (!this.$scope.id) return;

                this.busy = true;
                customerService
                    .get(this.$scope.id)
                    .then((response) => {
                        this.fields.promo = response.data.data.news_promo;
                        this.fields.releases = response.data.data.news_colecao;
                        this.fields.content = response.data.data.news_conteudo;
                    })
                    .catch(() => {
                        if (this.enableAlerts)
                            this.newsletterAlert = {
                                message:
                                    'Infelizmente um erro ocorreu. Por favor, tente novamente mais tarde.',
                                isError: true,
                            };
                    })
                    .then(() => (this.busy = false));
            },

            subscribe() {
                this.busy = true;

                let promise;
                if (this.$scope.id && this.$scope.CUSTOMER.EMAIL === this.fields.email) {
                    promise = customerService.update(this.$scope.id, {
                        news_promo: this.fields.promo,
                        news_colecao: this.fields.releases,
                        news_conteudo: this.fields.content,
                        unsubscribe: !(
                            this.fields.promo ||
                            this.fields.releases ||
                            this.fields.content
                        ),
                    });
                } else {
                    promise = this.axios.api.post('/get_response/subscribe', {
                        name: this.fields.name,
                        email: this.fields.email,
                        newsPromo: this.fields.promo,
                        newsColecao: this.fields.releases,
                        newsConteudo: this.fields.content,
                        page_subscribe: '/newsletter',
                        frequency: 1,
                    });
                }

                promise
                    .then(() => this.onNewsletterSuccess())
                    .catch((error) => this.onNewsletterFailed(error))
                    .then(() => (this.busy = false));
            },

            onNewsletterSuccess() {
                if (this.enableAlerts)
                    this.newsletterAlert = {
                        message:
                            this.successMessage ||
                            (this.fetchCustomer && this.$scope.id
                                ? 'Preferências atualizadas com sucesso'
                                : 'Oba! Você receberá nos próximos minutos o cupom de desconto em seu e-mail'),
                    };
            },

            onNewsletterFailed(error) {
                if (this.enableAlerts)
                    this.newsletterAlert = {
                        message:
                            error.response.data.message || 'Não foi possível assinar a newsletter',
                        isError: true,
                    };
            },
        },
    };
</script>
