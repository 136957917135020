<template>
    <div id="cart-kit" :class="{ loading }" class="collapsible opened">
        <div class="frame-outline px-0 py-0 mb">
            <p id="cart-kit-call" class="mx-0 my-0">
                <a href="/dia-das-maes" target="_blank">
                    <img
                        :data-src="`${$scope.CDN_IMAGES}/images/site/promos/2019_abril_bnrdiadasmaes.png?auto=compress,format&q=65`"
                        alt="Promoção Dia das Mães"
                        class="lazy img-responsive center-block"
                    />
                </a>
            </p>

            <div class="px py text-yanone text-uppercase">
                <p :class="{ opened: !showKitForm }" class="mx-0 my-0 collapsible">
                    <i class="icon-cr icon-heart"></i>
                    {{ isKitInCart ? 'Visualize a mensagem do cartão' : 'Adicione o Kit' }}
                    <a class="text-accent cursor-pointer" @click="showKitForm = true">
                        clicando aqui.
                    </a>
                </p>

                <div :class="{ opened: showKitForm }" class="collapsible">
                    <div class="d-flex">
                        <label for="cardText">Mensagem do cartão:</label>
                        <span class="text-secondary text-right ml-auto"
                            >Restam {{ 1100 - text.length }} caracteres</span
                        >
                    </div>
                    <textarea
                        id="cardText"
                        v-model="text"
                        name="cardText"
                        rows="5"
                        style="font-size: 1.2rem"
                        @keyup="text = text.length > 1100 ? text.slice(0, 1100) : text"
                    ></textarea>
                    <div class="text-right">
                        <button class="btn" @click="submit()">
                            {{ isKitInCart ? 'Editar' : 'Incluir' }}
                        </button>
                        <button class="btn btn-light" @click="showKitForm = false">
                            {{ isKitInCart ? 'Fechar' : 'Cancelar' }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CartKit',

        props: ['isKitInCart', 'giftMessage', 'loading'],

        data() {
            return {
                showKitForm: false,
                text: '',
            };
        },

        watch: {
            loading(newVal) {
                if (!newVal) this.showKitForm = false;
            },
            giftMessage: {
                handler: function (val) {
                    if (val) this.text = val;
                    else this.text = '';
                },
                immediate: true,
            },
        },

        methods: {
            submit: function () {
                this.$emit('add-kit', this.text);
            },
        },
    };
</script>
