<template>
    <div id="product-advantages" class="d-flex" style="user-select: none">
        <div class="product-advantages-container d-flex">
            <span class="advantages-title text-bold">Troca Grátis</span>
            <span class="advantages-content">Sua primeira troca no site é grátis</span>
        </div>
        <div class="product-advantages-container d-flex">
            <span class="advantages-title text-bold">Frete Grátis</span>
            <div
                class=""
                v-if="
                    $scope.CONFIG.FRETE_VALOR_FIXO_HABILITADO &&
                    $scope.CONFIG.FRETE_VALOR_FIXO === 0
                "
            >
                <span>{{ $t('_shop._advantages.freeShippingTitle') }} </span>
                <span
                    >(<a class="advantages-content text-underline">{{
                        $t('_shop._advantages.freeShippingAnyPurchase')
                    }}</a
                    >)</span
                >
            </div>
            <div class="" v-else>
                <span>Frete grátis a partir de R${{ $scope.CONFIG.VALOR_FRETE_GRATIS }}* </span>
                <span
                    >(<a class="advantages-content text-underline" @click="showRegulamento = true"
                        >Consulte condições</a
                    >)</span
                >
            </div>
        </div>

        <ShippingRules v-model="showRegulamento" />
    </div>
</template>

<script>
    import ShippingRules from './ShippingRules.vue';

    export default {
        name: 'ProductAdvantages',
        components: { ShippingRules },

        data() {
            return { showRegulamento: false };
        },
    };
</script>
