<template>
    <div>
        <div>
            <div
                id="contador"
                :style="{
                    'background-color':
                        this.isMobile || this.poupup ? '#fff' : this.params.background_color,
                }"
                v-if="!this.hidePopup && !(this.isMobile && this.poupup)"
                :class="[
                    this.poupup ? 'countdown-popup' : '',
                    this.poupup && this.params.not_countdown ? 'not_countdown' : '',
                    this.params.img_popup ? 'count-popup-img' : '',
                ]"
            >
                <a :href="this.link" v-if="this.params.img_popup">
                    <img :data-src="this.params.img_popup" class="lazy lazy-fade" />
                </a>

                <div
                    id="contador-content"
                    :class="
                        (this.isMobile || this.poupup ? 'mobile' : 'desktop') +
                        (this.viewButton ? '-v2' : '-v1')
                    "
                >
                    <div
                        :id="this.isMobile || this.poupup ? 'contador-mobile-wrapper-primary' : ''"
                        v-if="!this.params.img_popup"
                    >
                        <span
                            id="contador-title"
                            :style="{
                                color:
                                    this.isMobile || this.poupup
                                        ? '#201f1e'
                                        : this.params.text_color,
                            }"
                            v-html="this.offerTitle"
                        >
                        </span>
                        <a
                            class="cursor-pointer"
                            style="
                                position: absolute;
                                color: #797978;
                                top: 0.25rem;
                                right: 0.5rem;
                                font-weight: 700;
                            "
                            @click="closeCountdown()"
                            v-if="this.poupup && this.params.not_countdown"
                            >X</a
                        >
                        <div
                            id="contador-coupon"
                            v-if="(this.isMobile || this.poupup) && this.discount"
                        >
                            <span id="contador-coupon-text" :style="{ color: '#201f1e' }"
                                >Use o cupom:</span
                            >
                            <div id="coupon-wrapper">
                                <span
                                    id="coupon-wrapper-text"
                                    :class="{ 'coupon-smaller': discount.name.length > 10 }"
                                    >{{ this.discount.name }}</span
                                >
                            </div>
                            <a
                                @click="showRegulation = true"
                                v-if="!this.viewButton && this.params.regulation"
                                :style="{ color: '#201f1e' }"
                                class="mt-3"
                            >
                                Ver regras
                            </a>
                        </div>
                    </div>

                    <div
                        :id="
                            this.isMobile || this.poupup ? 'contador-mobile-wrapper-secondary' : ''
                        "
                        :style="{
                            'background-color':
                                this.isMobile || this.poupup
                                    ? this.params.background_color_mobile
                                    : '',
                        }"
                        :class="[this.params.img_popup ? 'count-popup-full' : '']"
                        v-if="!this.poupup || !this.params.not_countdown"
                    >
                        <a
                            class="cursor-pointer"
                            style="
                                position: absolute;
                                color: #797978;
                                top: 0.25rem;
                                right: 0.5rem;
                                font-weight: 700;
                            "
                            @click="closeCountdown()"
                            >X</a
                        >
                        <div id="contador-timer">
                            <span
                                id="contador-timer-text"
                                :style="{
                                    color:
                                        this.isMobile || this.poupup
                                            ? this.params.text_color_mobile
                                            : this.params.text_color,
                                }"
                                >Acaba em:</span
                            >
                            <div id="contador-timer-clock">
                                <div class="clock-section" id="hours">
                                    <span
                                        class="clock-number"
                                        :style="{
                                            'background-color':
                                                this.isMobile || this.poupup
                                                    ? this.params.background_accent_color_mobile
                                                    : this.params.background_accent_color,
                                            color:
                                                this.isMobile || this.poupup
                                                    ? this.params.text_color_counter_mobile
                                                    : this.params.text_color_counter,
                                        }"
                                        >{{
                                            hours >= 10 ? hours.toString().substring(0, 1) : 0
                                        }}</span
                                    >
                                    <span
                                        class="clock-number"
                                        :style="{
                                            'background-color':
                                                this.isMobile || this.poupup
                                                    ? this.params.background_accent_color_mobile
                                                    : this.params.background_accent_color,
                                            color:
                                                this.isMobile || this.poupup
                                                    ? this.params.text_color_counter_mobile
                                                    : this.params.text_color_counter,
                                        }"
                                        >{{
                                            hours >= 10
                                                ? hours.toString().substring(1, 2)
                                                : hours.toString().substring(0, 1)
                                        }}</span
                                    >
                                    <span
                                        class="clock-marker"
                                        :style="{
                                            color:
                                                this.isMobile || this.poupup
                                                    ? this.params.text_color_mobile
                                                    : this.params.text_color,
                                        }"
                                        >h</span
                                    >
                                </div>
                                <div class="clock-section" id="minutes">
                                    <span
                                        class="clock-number"
                                        :style="{
                                            'background-color':
                                                this.isMobile || this.poupup
                                                    ? this.params.background_accent_color_mobile
                                                    : this.params.background_accent_color,
                                            color:
                                                this.isMobile || this.poupup
                                                    ? this.params.text_color_counter_mobile
                                                    : this.params.text_color_counter,
                                        }"
                                        >{{
                                            minutes >= 10 ? minutes.toString().substring(0, 1) : 0
                                        }}</span
                                    >
                                    <span
                                        class="clock-number"
                                        :style="{
                                            'background-color':
                                                this.isMobile || this.poupup
                                                    ? this.params.background_accent_color_mobile
                                                    : this.params.background_accent_color,
                                            color:
                                                this.isMobile || this.poupup
                                                    ? this.params.text_color_counter_mobile
                                                    : this.params.text_color_counter,
                                        }"
                                        >{{
                                            minutes >= 10
                                                ? minutes.toString().substring(1, 2)
                                                : minutes.toString().substring(0, 1)
                                        }}</span
                                    >
                                    <span
                                        class="clock-marker"
                                        :style="{
                                            color:
                                                this.isMobile || this.poupup
                                                    ? this.params.text_color_mobile
                                                    : this.params.text_color,
                                        }"
                                        >m</span
                                    >
                                </div>
                                <div class="clock-section" id="seconds">
                                    <span
                                        class="clock-number"
                                        :style="{
                                            'background-color':
                                                this.isMobile || this.poupup
                                                    ? this.params.background_accent_color_mobile
                                                    : this.params.background_accent_color,
                                            color:
                                                this.isMobile || this.poupup
                                                    ? this.params.text_color_counter_mobile
                                                    : this.params.text_color_counter,
                                        }"
                                        >{{
                                            seconds >= 10 ? seconds.toString().substring(0, 1) : 0
                                        }}</span
                                    >
                                    <span
                                        class="clock-number"
                                        :style="{
                                            'background-color':
                                                this.isMobile || this.poupup
                                                    ? this.params.background_accent_color_mobile
                                                    : this.params.background_accent_color,
                                            color:
                                                this.isMobile || this.poupup
                                                    ? this.params.text_color_counter_mobile
                                                    : this.params.text_color_counter,
                                        }"
                                        >{{
                                            seconds >= 10
                                                ? seconds.toString().substring(1, 2)
                                                : seconds.toString().substring(0, 1)
                                        }}</span
                                    >
                                    <span
                                        class="clock-marker"
                                        :style="{
                                            color:
                                                this.isMobile || this.poupup
                                                    ? this.params.text_color_mobile
                                                    : this.params.text_color,
                                        }"
                                        >s</span
                                    >
                                </div>
                            </div>
                        </div>
                        <a
                            :href="this.link + '?cd_off=1'"
                            v-if="this.isMobile || (this.poupup && this.viewButton)"
                            :style="this.poupup ? 'width: 100%' : ''"
                        >
                            <div
                                id="contador-button"
                                :style="{
                                    'background-color': this.params.text_color_mobile,
                                    color: this.params.background_color_mobile,
                                }"
                            >
                                <span id="contador-button-text">Confira</span>
                            </div>
                        </a>
                    </div>

                    <div
                        id="contador-coupon"
                        v-if="!this.isMobile && !this.poupup && this.discount"
                    >
                        <div :id="this.viewButton ? 'coupon-content-wrapper' : ''">
                            <span
                                id="contador-coupon-text"
                                :style="{
                                    color: this.isMobile
                                        ? this.params.text_color_mobile
                                        : this.params.text_color,
                                }"
                                >Use o cupom:</span
                            >
                            <div
                                id="coupon-wrapper"
                                :style="{
                                    'border-color': this.isMobile
                                        ? this.params.text_color_mobile
                                        : this.params.text_color,
                                }"
                            >
                                <span
                                    id="coupon-wrapper-text"
                                    :style="{
                                        color: this.isMobile
                                            ? this.params.text_color_mobile
                                            : this.params.text_color,
                                    }"
                                    >{{ this.discount.name }}</span
                                >
                            </div>
                            <a
                                @click="showRegulation = true"
                                v-if="!this.viewButton && this.params.regulation"
                                :style="{ color: this.params.text_color }"
                                class="mt-3 text-underline"
                                >Ver regras</a
                            >
                        </div>
                        <a :href="this.link + '?cd_off=1'" v-if="this.viewButton">
                            <div
                                id="contador-button"
                                :style="{
                                    'background-color':
                                        this.isMobile || this.poupup
                                            ? this.params.text_color_mobile
                                            : this.params.text_color,
                                    color:
                                        this.isMobile || this.poupup
                                            ? this.params.background_color_mobile
                                            : this.params.background_color,
                                }"
                            >
                                <span id="contador-button-text">Confira</span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <Modal v-if="this.params.regulation" v-model="showRegulation" class="modal-regulamento">
                <div class="card-block">
                    <h2>Regulamento</h2>
                    <div v-html="this.params.regulation"></div>
                </div>
                <div class="card-block text-right">
                    <button class="btn" type="button" @click="showRegulation = false">
                        Fechar
                    </button>
                </div>
            </Modal>
        </div>
    </div>
</template>

<script>
    import Modal from './container/Modal.vue';
    import { readCookie, resizeOrientationAware, setCookie } from '../../../common/common';

    export default {
        name: 'CountdownFixed',
        components: { Modal },
        props: {
            deadline: Date,
            showDays: Boolean,
            coupon: null,
            offerTitle: null,
            discount: null,
            link: null,
            viewButton: true,
            params: null,
            poupup: false,
            hidePopup: false,
        },
        data() {
            return {
                isMobile: false,
                realDeadline: null, // usado quando a data final do contador é diferente da data final da oferta
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
                showRegulation: false,
                resizeAwareRemoveCb: null,
            };
        },
        watch: {
            deadline: {
                immediate: true,
                handler(date) {
                    if (date) {
                        this.realDeadline = date.getTime();
                    }
                },
            },
        },

        mounted() {
            this.resizeAwareRemoveCb = resizeOrientationAware(() => {
                this.isMobile = window.innerWidth < 768;
            }, true);

            this.update();
            setInterval(this.update.bind(this), 1000);
        },

        beforeDestroy() {
            this.resizeAwareRemoveCb();
        },

        methods: {
            getValues() {
                const now = new Date();
                const diff = this.realDeadline ? this.realDeadline - now.getTime() : 0;

                if (diff < 0) {
                    return {
                        hours: 0,
                        minutes: 0,
                        seconds: 0,
                    };
                }

                const hours = Math.floor(diff / (60 * 60 * 1000));

                return {
                    days: Math.floor(diff / (60 * 60 * 1000 * 24)),
                    hours: this.showDays ? hours % 24 : hours,
                    minutes: Math.floor(diff / (60 * 1000)) % 60,
                    seconds: Math.floor(diff / 1000) % 60,
                };
            },
            update() {
                const values = this.getValues();
                this.days = values.days;
                this.hours = values.hours;
                this.minutes = values.minutes;
                this.seconds = values.seconds;
            },
            closeCountdown() {
                let d = new Date();
                d.setTime(d.getTime() + 1 * 4 * 60 * 60 * 1000);
                setCookie('counter_popup', '1', d.toUTCString(), '/'); //Esconde por 5 horas
                setCookie('not_count_shipping', '1', d.toUTCString(), '/'); //Esconde por 5 horas

                setCookie('not_count_daybreak', '1', d.toUTCString(), '/'); //Esconde por 5 horas
                this.hidePopup = true;
            },
        },
    };
</script>
