export default function (Vue) {
    const scope = Object.assign({}, window.initialScope);
    Vue.scope = scope;

    Object.defineProperties(Vue.prototype, {
        scope: {
            get() {
                return scope;
            },
        },

        $scope: {
            get() {
                return scope;
            },
        },
    });
}
