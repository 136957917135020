var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[_vm.isMobile ? 'content hidden-md-up container-tile-mobile' : 'hidden-md-down pl-0']},[_c('div',{attrs:{"id":"product-name-container"}},[_c('h1',{staticClass:"text-bold text-uppercase",class:[
                _vm.isMobile ? 'product-title-mobile content' : 'product-title-desktop mb-0 mt-0 pt-0',
                _vm.isMobile && _vm.name.length >= 18 ? 'mb-0' : '',
            ],attrs:{"id":"product-title","itemprop":"name"}},[(!_vm.isMobile)?_c('span',{attrs:{"id":"product-name"}},[_vm._v(_vm._s(_vm.name))]):_vm._e(),_vm._v(" "),(_vm.isMobile)?_c('span',[_vm._v(_vm._s(_vm.name))]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"mb widget-star-reviews cursor-pointer",class:[
            _vm.isMobile ? 'content' : 'mb-0',
            _vm.name.length >= 18 ? 'widget-star-reviews-line' : '',
        ],on:{"click":function($event){return _vm.scrollToReviews()}}},[_c('avaliacoes-show-stars',{attrs:{"stars-rating":_vm.product.review_rating_mean}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }