import { CHECKOUT_STEPS } from '../consts';

const stepInitialState = {
    active: CHECKOUT_STEPS.STEP_INITIALIZATION,
};

export default (store) => ({
    state: stepInitialState,

    getStepModule(step) {
        switch (step) {
            case CHECKOUT_STEPS.STEP_ADDRESS:
                return store.address;
            case CHECKOUT_STEPS.STEP_CARRIER:
                return store.carrier;
            case CHECKOUT_STEPS.STEP_PAYMENT:
                return store.payment;
            default:
                return null;
        }
    },

    next() {
        switch (this.state.active) {
            case CHECKOUT_STEPS.STEP_INITIALIZATION:
                if (this.isStepAuthenticationValid()) {
                    // carrinhos com endereço já selecionado vão direto pra entrega
                    this.goTo(CHECKOUT_STEPS.STEP_ADDRESS);
                } else {
                    this.goTo(CHECKOUT_STEPS.STEP_AUTHENTICATION);
                }
                break;
            case CHECKOUT_STEPS.STEP_AUTHENTICATION:
                if (this.isStepAddressValid()) {
                    // carrinhos com endereço já selecionado vão direto pra entrega
                    this.goTo(CHECKOUT_STEPS.STEP_CARRIER);
                } else {
                    this.goTo(CHECKOUT_STEPS.STEP_ADDRESS);
                }
                break;
            case CHECKOUT_STEPS.STEP_ADDRESS:
                if (store.state.cart.cart.is_only_virtual_products) {
                    // carrinhos com apenas itens virtuais não precisam de entrega
                    this.goTo(CHECKOUT_STEPS.STEP_PAYMENT);
                } else {
                    this.goTo(CHECKOUT_STEPS.STEP_CARRIER);
                }
                break;
            case CHECKOUT_STEPS.STEP_CARRIER:
                this.goTo(CHECKOUT_STEPS.STEP_PAYMENT);
                break;
        }
    },

    goTo(step) {
        if (
            step === CHECKOUT_STEPS.STEP_PAYMENT &&
            store.state.payment.selectedMethod &&
            store.state.payment.selectedMethod.module !== 'gratis'
        ) {
            store.payment.reset();
        }

        if (this.state.active === step) {
            return;
        }

        // Verifica requisitos de cada etapa
        // Se necessário retrocede na etapa pra que o usuário complete as informações
        if (step > CHECKOUT_STEPS.STEP_AUTHENTICATION && !this.isStepAuthenticationValid()) {
            this.goTo(CHECKOUT_STEPS.STEP_AUTHENTICATION);
            return;
        }

        if (step > CHECKOUT_STEPS.STEP_ADDRESS && !this.isStepAddressValid()) {
            this.goTo(CHECKOUT_STEPS.STEP_ADDRESS);
            return;
        }
        if (step > CHECKOUT_STEPS.STEP_CARRIER && !this.isStepCarrierValid()) {
            this.goTo(CHECKOUT_STEPS.STEP_CARRIER);
            return;
        }

        const previousActiveStep = this.state.active;

        switch (step) {
            case CHECKOUT_STEPS.STEP_CARRIER:
                if (!store.state.cart.cart.is_only_virtual_products) {
                    this.state.active = CHECKOUT_STEPS.STEP_CARRIER;
                } else {
                    this.goTo(CHECKOUT_STEPS.STEP_PAYMENT);
                }
                break;
            default:
                this.state.active = step;
        }

        const previousModule = this.getStepModule(previousActiveStep);
        if (previousModule && previousModule.cancelRequest) {
            previousModule.cancelRequest();
        }
    },

    isStepAuthenticationValid() {
        const customer = (store.state.cart.cart || {}).customer;
        return initialScope.id || (customer && customer.data);
    },

    isStepAddressValid() {
        return !!(((store.state.cart.cart || {}).address || {}).data || {}).zip_code;
    },

    isStepCarrierValid() {
        return (
            (store.state.cart.cart || {}).is_only_virtual_products ||
            (store.state.cart.cart || {}).carrier_id
        );
    },

    isStepPaymentValid() {
        return (
            (store.state.cart.cart || {}).total_paid <= 0 ||
            (store.state.payment.selectedMethod &&
                (store.state.payment.selectedMethod.module !== 'masterpass' ||
                    store.state.payment.masterpassIsReady) &&
                (store.state.payment.selectedMethod.module !== 'visa_checkout' ||
                    store.state.payment.visaCheckoutIsReady))
        );
    },
});
