export const catalogStore = {
    state: Object.assign(
        {},
        {
            state: 'VueApp',
            loading: false,
        },
        window.vueInitialData
    ),

    buildUrl(param, id, link, remove = false, currentUrl = undefined) {
        let url = new URL(currentUrl || window.location.href);

        // Remove parâmetro de paginação para voltar para a primeira página
        url.searchParams.delete('page');

        // Remove ou adiciona parâmetro informado
        if (remove) {
            url.searchParams.delete(param);
        } else if (
            !this.state.selected.pretty_url ||
            (this.state.selected.pretty_url &&
                (param === 'inPhysicalStock' || param === 'multipleFabrics'))
        ) {
            // Utiliza URL amiga passada como parâmetro se for
            url.searchParams.set(param, id);
        }

        if (currentUrl) {
            return url.toString();
        }

        let joinPath = function (path, val, i, array) {
            if (path === undefined) return val;
            if (val === undefined) return path;

            if (
                remove &&
                ((param === 'tipo' && i === 0) ||
                    (param === 'categoria' && i === 1) ||
                    (param === 'modelagem' && i === 2) ||
                    (param === 'cor' && i === 3) ||
                    (param === 'tamanho' && i === 4) ||
                    (param === 'preco' && i === 5))
            )
                return path;

            return path + '/' + val;
        };

        url.pathname = this.state.selected.pretty_url
            ? [
                  param === 'tipo'
                      ? remove
                          ? this.state.selected.type_main_url
                          : link
                      : this.state.selected.type_url,
                  param === 'categoria' ? link : this.state.selected.category_url,
                  param === 'modelagem' ? link : this.state.selected.model_url,
                  param === 'cor' ? link : this.state.selected.color_url,
                  param === 'tamanho' ? link : this.state.selected.size_url,
                  param === 'preco' ? link : this.state.selected.price_url,
              ].reduce(joinPath) + '/'
            : url.pathname;

        return url.toString();
    },

    fetch(axios, url) {
        url = new URL(url);
        this.fetchState(
            axios,
            location.origin + url.pathname + url.search,
            url.pathname + url.search
        );
    },

    fetchState(axios, request, pushHistory) {
        this.state.loading = true;

        axios.http
            .get(request, {
                params: {
                    json: 1,
                },
            })
            .then((response) => {
                this.state.products = Object.assign({}, response.data.products);
                this.state.selected = Object.assign({}, response.data.selected);
                this.state.meta = Object.assign({}, response.data.meta);
                this.state.topContent = response.data.topContent;

                if (response.data.filters) {
                    this.state.filters = Object.assign({}, response.data.filters);
                }

                history.pushState(null, null, pushHistory);
            })
            .catch(() => (this.showErrorMessage = true))
            .then(() => (this.state.loading = false));
    },
};
