import { disableTooltip, initTooltip } from '../../../common/cr-components';
import cr$ from '../../../common/crquery';

// todo: não está reativo, teria que remover o listener do
//  mouseenter e inicializar a tooltip de novo no update()

const putTooltipAttrs = (crTriggerEl, binding) => {
    crTriggerEl.attr('data-toggle', 'cr-tooltip');
    crTriggerEl.attr('data-text', binding.value);
    crTriggerEl.attr('data-placement', binding.arg || 'top');
};

const Tooltip = function (Vue) {
    Vue.directive('tooltip', {
        inserted: function (el, binding) {
            if (!binding.value) return;

            const crTriggerEl = cr$.byEl(el);
            putTooltipAttrs(crTriggerEl, binding);
            initTooltip(el);
        },

        update: function (el, binding) {
            const crTriggerEl = cr$.byEl(el);

            if (!binding.value) {
                crTriggerEl.removeAttr('title');
                disableTooltip(el);
            } else {
                putTooltipAttrs(crTriggerEl, binding);
                initTooltip(el);
            }
        },
    });
};

export default Tooltip;
