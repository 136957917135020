import { CART_EMPTY, CART_NOT_EMPTY } from './popups-manager';
import { api } from './common';

const sharedHtml = (id, headline, headlineObs) => `
    <div id="${id}" class="popup">
        <div class="cr-modal-dialog px-0 py-0">
            <div class="card">
                <div class="popup-image aspect aspect-xs-21-9">
                    <img data-src="https://s3.amazonaws.com/chico-rei/images/site/popups/newsletter-2.png"
                         class="lazy lazy-fade">
                     <a id="${id}-close" class="icon-cr icon-close cursor-pointer"></a>
                </div>
                <div class="card-block">
                    <div id="popup-top-description" class="frame mb mb">
                        <h2 class="mx-0 my-0">Só <span class="text-accent">notícia boa</span><span class="hidden-xs-down"> pra você!</span></h2>
                        <p class="text-yanone mx-0 my-0">Fique por dentro de tudo o que rola na Chico: conteúdos exclusivos,
                            promoções e
                            muita novidade</p>
                    </div>
                    <div id="${id}-alert" class="collapsible">
                        <div id="${id}-alert-msg" class="alert-danger mb"></div>
                    </div>
                    <div class="d-flex">
                        <div id="${id}-discount" class="frame hidden-xs-down mr">
                            <div>
                                <h3 class="mx-0 my-0">${headline}</h3>
                                ${
                                    headlineObs
                                        ? '<p class="text-yanone mx-0 my-0">' + headlineObs + '</p>'
                                        : ''
                                }
                            </div>
                        </div>
                        <form id="${id}-form">
                            <input type="text" placeholder="Nome" name="name" id="${id}-name">
                            <div class="form-inline mt">
                                <input type="email" name="email" placeholder="E-mail" autocomplete="email" id="${id}-email" required>
                                <input type="submit" class="btn" value="Cadastrar">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
`;

const surveyHtml = `
    <div id="popup-survey" class="popup">
        <div class="cr-modal-dialog px-0 py-0">
            <div class="card">
                <div class="popup-image aspect aspect-xs-21-9 hidden-xs-down">
                    <img data-src="https://s3.amazonaws.com/chico-rei/images/site/popups/pesquisa-2.png"
                         class="lazy lazy-fade">
                     <a id="popup-survey-close" class="icon-cr icon-close cursor-pointer"></a>
                </div>
                <div class="card-block">
                    <div class="frame mb">
                        <h2 class="mx-0 my-0">Uai, mas <span class="text-accent">já vai?</span></h2>
                        <p class="text-yanone mx-0 my-0">Por quê? Conta pra gente:</p>
                    </div>
                    <div id="popup-survey-alert" class="collapsible">
                        <div id="popup-survey-alert-msg" class="alert-danger mb"></div>
                    </div>
                    <form id="popup-survey-form" class="frame-outline">
                        <div id="popup-survey-reasons">
                            <div class="d-flex">
                                <div>
                                    <input type="checkbox" class="cr-checkbox" id="leaving-reason-searching" value="Apenas pesquisando">
                                    <label for="leaving-reason-searching">Apenas pesquisando</label>
                                </div>
                                <div>
                                    <input type="checkbox" class="cr-checkbox" id="leaving-reason-expensive" value="Achei caro">
                                    <label for="leaving-reason-expensive">Achei caro</label>
                                </div>
                                <div>
                                    <input type="checkbox" class="cr-checkbox" id="leaving-reason-undecided" value="Fiquei com dúvidas">
                                    <label for="leaving-reason-undecided">Fiquei com dúvidas</label>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div>
                                    <input type="checkbox" class="cr-checkbox" id="leaving-reason-finish-later" value="Pretendo finalizar depois">
                                    <label for="leaving-reason-finish-later">Pretendo finalizar depois</label>
                                </div>
                                <div>
                                    <input type="checkbox" class="cr-checkbox" id="leaving-reason-long-deadline" value="Achei o prazo longo">
                                    <label for="leaving-reason-long-deadline">Achei o prazo longo</label>
                                </div>
                                <div>
                                    <input type="checkbox" class="cr-checkbox" id="leaving-reason-other" value="Outro">
                                    <label for="leaving-reason-other">Outro</label>
                                </div>
                            </div>
                        </div>
                        <div class="spaced-dots"></div>
                        <p>Receba conteúdo exclusivo por e-mail:</p>
                        <div class="form-inline">
                            <input type="email" name="email" autocomplete="email" id="popup-survey-email" required>
                            <input type="submit" class="btn" value="Confirmar">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
`;

const notificationHtml = (id) => `
<div id="${id}" class="notification open">
    <div class="d-flex" style="align-items: center">
        <p class="h4 mx-0 my-0">Fique por dentro das novidades!</p>
        <i id="${id}-close" class="icon-cr icon-cr-hover icon-close flex-child-fixed"></i>
    </div>
    <p>Assine nossa newsletter e receba ofertas, novidades e cultura em primeira mão:</p>
    <div id="${id}-alert" class="collapsible">
        <div id="${id}-alert-msg" class="alert-danger mb"></div>
    </div>
    <form id="${id}-form" class="form-inline">
        <label>E-mail</label>
        <input id="${id}-email" type="text" class="input-sm" autocomplete="email"/>
        <input type="submit" class="btn btn-sm" value="Assinar"/>
    </form>
</div>
`;

const sharedOnShow = (elementId, formUrl, requestBodyBuilder) => {
    const popupElement = document.getElementById(elementId);
    const alertEl = document.getElementById(`${elementId}-alert`);
    const alertMsgEl = document.getElementById(`${elementId}-alert-msg`);
    const form = document.getElementById(`${elementId}-form`);
    const submitEl = form.querySelector('[type=submit]');

    const onSubmitForm = (e) => {
        e.preventDefault();
        e.stopPropagation();

        alertEl.classList.remove('opened');
        submitEl.setAttribute('disabled', '');

        api.post(formUrl, requestBodyBuilder(e))
            .then((response) => {
                alertMsgEl.innerText = 'Informações enviadas com sucesso :)';
                alertMsgEl.classList.remove('alert-danger');
                alertMsgEl.classList.add('alert-success');
                alertEl.classList.add('opened');
            })
            .catch((error) => {
                let errorMessage = 'Ocorreu um erro ao cadastrar.';
                if (((error.response || {}).data || {}).message) {
                    errorMessage = error.response.data.message;
                }

                alertMsgEl.innerText = errorMessage;
                alertEl.classList.add('opened');
                submitEl.removeAttribute('disabled');
            });
    };

    const closePopup = () => {
        popupElement.classList.remove('opened');
        setTimeout(() => popupElement.remove(), 150); // 50ms a mais que a transição de opacity do modal
    };

    popupElement.classList.add('opened');

    setTimeout(() => {
        document.getElementById(`${elementId}-close`).onclick = closePopup;
    }, 1000); // deixa a popup aberta por pelo menos 1s

    if (form.addEventListener) {
        form.addEventListener('submit', onSubmitForm, false);
    } else if (form.attachEvent) {
        form.attachEvent('onsubmit', onSubmitForm);
    }
};

const getNewsletterFormValues = (popupId) => {
    return () => ({
        email: document.getElementById(popupId + '-email').value,
        name: (document.getElementById(popupId + '-name') || {}).value,
        newsColecao: 1,
        newsPromo: 1,
        newsConteudo: 1,
    });
};
const getSurveyFormValues = (event) => {
    const form = event.target;
    const checkboxes = form.querySelectorAll('.cr-checkbox');

    let reasons = [];

    for (const checkbox of checkboxes) {
        if (checkbox.checked) reasons.push(checkbox.value + ';');
    }

    return {
        email: document.getElementById('popup-survey-email').value,
        content: { reasons },
    };
};

const onShowAffiliatesPopup = () =>
    sharedOnShow(
        'popup-affiliates',
        '/dito/subscribe',
        getNewsletterFormValues('popup-affiliates')
    );
//const onShowNewsletterPopup = () => sharedOnShow('popup-newsletter', window.location.protocol + '//' + window.location.host + '/admin/api/dito/subscribe', getNewsletterFormValues('popup-newsletter'));
const onShowNewsletterNotification = () =>
    sharedOnShow(
        'notification-newsletter',
        '/dito/subscribe',
        getNewsletterFormValues('notification-newsletter')
    );
const onShowSurveyPopup = () =>
    sharedOnShow('popup-survey', '/surveys/1/responses', getSurveyFormValues);

export let affiliatesPopup = {
    id: 1,
    html: sharedHtml('popup-affiliates', 'Ativar<br/>desconto!'),
    onShow: onShowAffiliatesPopup,
    priority: 1,
    triggers: {
        utm: true,
    },
    devices: {
        desktop: true,
        mobile: true,
    },
    criteria: {
        logged: false,
        wholesaler: false,
        paths: ['/camiseta/.*?-\\d+.html', '/promo'],
        activePromo: true,
    },
    except: {
        utms: ['veglobal', 'dito'],
        paths: ['/sacola/.+', '/entrar/.+'], // páginas após o carrinho
    },
    expireAfter: {
        days: 30,
    },
};

export let newsletterNotification = {
    id: 2,
    html: notificationHtml('notification-newsletter'),
    onShow: onShowNewsletterNotification,
    priority: 2,
    triggers: {
        exitIntent: true,
    },
    devices: {
        desktop: true,
        mobile: false,
    },
    criteria: {
        logged: false,
        wholesaler: false,
        cart: {
            status: CART_EMPTY,
        },
    },
    except: {
        popupsBefore: [1],
        paths: ['/sacola', '/minha-conta', '/entrar/.+'],
        utms: ['veglobal', 'dito'],
    },
    expireAfter: {
        days: 30,
    },
};

export let surveyPopup = {
    id: 3,
    html: surveyHtml,
    onShow: onShowSurveyPopup,
    priority: 3,
    triggers: {
        exitIntent: true,
    },
    devices: {
        desktop: true,
    },
    criteria: {
        logged: false,
        wholesaler: false,
        cart: {
            status: CART_NOT_EMPTY,
        },
        idleTime: {
            hours: 2,
        },
    },
    except: {
        utms: ['veglobal'],
        paths: ['/sacola/.+', '/entrar/.+'], // páginas após o carrinho e login
        popupsBefore: [1, 2],
    },
    expireAfter: {
        days: 30,
    },
};

export let debugPopup = {
    id: -1,
    html: notificationHtml('notification-newsletter'),
    onShow: onShowNewsletterNotification,
    priority: -1,
    triggers: {
        utm: true,
        exitIntent: true,
    },
    devices: {
        desktop: true,
        mobile: true,
    },
    criteria: {},
    except: {
        paths: ['/sacola/.+', '/entrar/.+'],
    },
};
