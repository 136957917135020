<template>
    <div :class="[isMobile ? 'content hidden-md-up container-tile-mobile' : 'hidden-md-down pl-0']">
        <div id="product-name-container">
            <!-- Titulo do produto -->
            <h1
                id="product-title"
                class="text-bold text-uppercase"
                :class="[
                    isMobile ? 'product-title-mobile content' : 'product-title-desktop mb-0 mt-0 pt-0',
                    isMobile && name.length >= 18 ? 'mb-0' : '',
                ]"
                itemprop="name"
            >
                <span id="product-name" v-if="!isMobile">{{ name }}</span>
                <span v-if="isMobile">{{ name }}</span>
            </h1>
        </div>
        <div
            class="mb widget-star-reviews cursor-pointer"
            @click="scrollToReviews()"
            :class="[
                isMobile ? 'content' : 'mb-0',
                name.length >= 18 ? 'widget-star-reviews-line' : '',
            ]"
        >
            <avaliacoes-show-stars class :stars-rating="product.review_rating_mean" />
        </div>
    </div>
</template>
<script>
    import AvaliacoesShowStars from '../avaliacoes/AvaliacoesShowStars.vue';

    export default {
        name: 'ProductTitle',
        components: { AvaliacoesShowStars },
        props: {
            product: {
                type: Object,
                required: true,
            },
            isMobile: {
                type: Boolean,
                required: true,
            },
        },

        computed: {
            name() {
                return this.product.name;
            },
        },
        methods: {
            scrollToReviews() {
                cr$.byId('avaliacoes').scrollIntoView();
            },
        },
    };
</script>
