<template>
    <div>
        <div :class="{ opened: !submitting && !submitted }" class="collapsible">
            <label
                for="product-review"
                v-html="$t('_product._review.tellUsHtml', [product.name])"
            />
            <textarea id="product-review" v-model="comment"></textarea>

            <Alert :alert="alert" />

            <div class="d-flex flex-spaced-fixed mt">
                <button class="btn btn-lg btn-light flex-item-grow" @click="close">
                    {{ $t('toCancel') }}
                </button>
                <button class="btn btn-lg flex-item-grow" @click="submit">
                    {{ $t('toSend') }}
                </button>
            </div>
        </div>

        <div :class="{ opened: submitting }" class="collapsible">
            <div class="alert">
                <div class="d-flex flex-spaced-fixed">
                    <div class="cr-spinner-small"></div>
                    <p>{{ $t('_product._review.waitSubmit') }}</p>
                </div>
            </div>
        </div>

        <div :class="{ opened: submitted }" class="collapsible">
            <div class="alert-success mb"></div>

            <button class="btn btn-lg btn-wide" @click="close">OK</button>
        </div>
    </div>
</template>

<script>
    import Alert from '../common/Alert';
    import { productService } from '../../../common/service/resource';

    export default {
        name: 'CreateReview',

        props: {
            product: {
                type: Object,
                required: true,
                validator(value) {
                    return (
                        value === null ||
                        (value.id &&
                            typeof value.id === 'number' &&
                            value.name &&
                            typeof value.name === 'string')
                    );
                },
            },
        },

        components: { Alert },

        data() {
            return {
                comment: '',
                alert: null,
                submitting: false,
                submitted: false,
            };
        },

        methods: {
            submit() {
                this.submitting = true;

                productService
                    .addComment(this.product.id, {
                        comment: this.comment,
                        customer_id: this.$scope.CUSTOMER.ID,
                    })
                    .then(() => {
                        this.submitting = false;
                        this.submitted = true;
                    })
                    .catch((response) => {
                        this.submitting = false;
                        this.alert = {
                            message: response.response.data.message,
                            isError: true,
                        };
                    });
            },

            close() {
                this.$emit('done');
            },
        },
    };
</script>
