<template>
    <ul v-if="products" class="cart-products-list">
        <CartRecommendationsListItem
            v-for="product in products"
            :key="`${product.id}-${gender}`"
            :options="itemOptions"
            :product="product"
            @click="pushItemEvent(product)"
        />
    </ul>
</template>

<script>
    import ProductList from '../../common/ProductList';
    import CartRecommendationsListItem from './CartRecommendationsListItem';

    export default {
        name: 'CartRecommendationsList',

        extends: ProductList,

        components: { CartRecommendationsListItem },
    };
</script>
