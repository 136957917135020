<template>
    <div v-if="products && products.length > 0" id="cart-recommendations">
        <h3>{{ $t('_cart.youMayAlsoLike') }}</h3>
        <CartRecommendationsList :item-options="itemOptions" :products="products" />
    </div>
</template>

<script>
    import CartRecommendationsList from './CartRecommendationsList';

    export default {
        name: 'CartRecommendations',

        components: { CartRecommendationsList },

        data() {
            return {
                products: null,
                itemOptions: {
                    baseUrl: window.url,
                    imageWidth: 247,
                    imageHeight: 371,
                    displayPercent: false,
                    lang: 'pt',
                    hideInstallments: true,
                },
            };
        },

        created() {
            this.fetchData();
        },

        methods: {
            fetchData() {
                this.axios.http
                    .get('/recommendation.php', {
                        params: {
                            scenario: 'cart',
                            rotationRate: 0.1,
                            minRelevance: 'medium',
                            list: 'Recomendações Carrinho',
                            count: 8,
                        },
                    })
                    .then((response) => {
                        if (response.data.products) this.products = response.data.products.data;
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
            },
        },
    };
</script>
