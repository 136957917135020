<template>
    <div
        v-if="modelings && selectedModeling"
        class="d-flex w-full"
        id="sizes-table-wrapper"
        :key="selectedModeling.id"
    >
        <div class="d-flex w-full" id="sizes-table-content">
            <div v-if="selectedModeling.image_url" class="d-flex table-size-img-background">
                <div id="sizes-table-title" class="hidden-md-down">
                    <h2
                        class="h6 text-bold text-default-5 text-uppercase font-roboto-c grayColor my-0 hidden-md-down"
                    >
                        Tabela de tamanhos:
                    </h2>
                </div>

                <div id="img-wrapper" class="d-flex">
                    <img
                        :alt="$t('_product.typeSizes', [(product.type || {}).name])"
                        :class="{ 'center-block': !modelings }"
                        :data-src="`${$scope.CDN_IMAGES}/${selectedModeling.image_url}?auto=compress,format&q=65`"
                        class="lazy img-responsive d-block mx-auto"
                        id="size-img"
                    />
                </div>

                <ProductSizesSelectTable
                    v-if="modelings.length > 1"
                    :observations="observations"
                    :product="product"
                    :modelings="modelings"
                    :selected-modeling="selectedModeling"
                    :product-type-id="product.type.id"
                    v-model="selectedModeling"
                    class="hidden-md-up"
                />
            </div>
            <div v-if="modelings" class="size-table d-flex w-full">
                <ProductSizesSelectTable
                    v-if="modelings.length > 1"
                    :observations="observations"
                    :product="product"
                    :modelings="modelings"
                    :selected-modeling="selectedModeling"
                    :product-type-id="product.type.id"
                    v-model="selectedModeling"
                    class="hidden-md-down"
                />

                <div
                    :class="{ 'flex-md-12': observations || product.type.id === 1 }"
                    class="flex-xs-12"
                >
                    <table id="tableModeling">
                        <thead>
                            <tr>
                                <th></th>
                                <th
                                    v-for="(dimension, index) in selectedModeling.sizes[0]
                                        .dimensions"
                                    class="text-center"
                                >
                                    <span class="hidden-xs-down"
                                        >{{ index + 1 }}.{{ dimension.label }}</span
                                    >
                                    <span class="hidden-xs-up"
                                        >{{ index + 1 }}.{{ dimension.shortLabel }}</span
                                    >
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="size in selectedModeling.sizes">
                                <td class="title">{{ size.name }}</td>
                                <td v-for="dimension in size.dimensions" class="text-center">
                                    {{ dimension.value }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="observations || product.type.id === 1" class="flex-xs-12 flex-lg-5">
                    <ul class="no-list info-size-table d-flex">
                        <!-- <li class="list-size-table d-flex flex-align-center cotton">
                            <i class="icon-cr icon-t-shirt list-icons" />
                            <p>
                                {{ observations[0] }}
                            </p>
                        </li> -->
                        <li class="list-size-table d-flex flex-align-center">
                            <i class="icon-cr icon-quality list-icons" />
                            <p>
                                {{ observations[1] }}
                            </p>
                        </li>
                        <!--                    Retira link para guia de tamanhos temporariamente, até criarem as novas imagens e vídeos-->
                        <li
                            class="list-size-table d-flex flex-align-center"
                            v-if="product.type.id === 1"
                        >
                            <i class="icon-cr icon-measurements list-icons" />
                            <p>
                                {{ 'Ainda com dúvidas? Confira nosso ' }}
                                <a
                                    class="text-primary text-bold text-underline link-size-guide"
                                    href="/guia-de-tamanhos"
                                >
                                    Guia de tamanhos</a
                                >
                            </p>
                        </li>
                        <li
                            class="list-size-table d-flex flex-align-center"
                            v-if="product.type.id === 1"
                        >
                            <i class="icon-cr icon-t-shirt list-icons" />
                            Devido ao corte artesanal, as medidas deste produto podem variar em até
                            3 cm.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { EventBus, updateLazyLoad } from '../../../common/common';
    import { productModelService } from '../../../common/service/resource';
    import ProductSizesSelectTable from '../product/ProductSizesSelectTable.vue';

    export default {
        name: 'ProductSizesTable',
        components: {
            ProductSizesSelectTable,
        },
        props: {
            product: {
                type: Object,
                required: true,
            },
            gridLayout: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                observations: (this.product.type || {}).observations
                    ? this.product.type.observations
                          .split(';')
                          .map((o) => o.trim())
                          .filter((o) => o.length > 0)
                    : null,
                selectedModeling: null,
                modelings: null,
                distinctModels: {},
                sizeNames: {},
            };
        },

        created() {
            this.distinctModels = this.product.variations.reduce((modelings, variation) => {
                if (variation.product_model_id) {
                    if (!modelings[variation.product_model_id]) {
                        modelings[variation.product_model_id] = new Set();
                    }

                    modelings[variation.product_model_id].add(variation.size_id);
                    this.sizeNames[variation.size_id] = variation.size_name;
                }

                return modelings;
            }, {});

            EventBus.$on('selected-modeling-change', this.goToModel);

            this.fetchProductModels();
        },

        watch: {
            selectedModeling() {
                this.$nextTick(() => updateLazyLoad());
            },

            observations: {
                immediate: true,
                handler(to) {},
            },
        },

        methods: {
            fetchProductModels() {
                let modelings = [];
                let filterIds = Object.keys(this.distinctModels);

                if (!filterIds || filterIds.length === 0) return;

                productModelService.query({ filterIds }).then((response) => {
                    response.data.data.forEach((model) => {
                        modelings.push({
                            id: model.id,
                            name: model.name,
                            image_url: model.dimension_image_url,
                            sizes: model.dimensions
                                .sort((a, b) => a.size.order - b.size.order)
                                .reduce((all, dimension) => {
                                    const size = {
                                        name: this.sizeNames[dimension.size_id],
                                        dimensions: [],
                                    };

                                    if (dimension.height) {
                                        size.dimensions.push({
                                            label: 'Comprimento',
                                            shortLabel: 'C',
                                            value: dimension.height,
                                        });
                                    }

                                    if (dimension.width) {
                                        size.dimensions.push({
                                            label: 'Largura',
                                            shortLabel: 'L',
                                            value: dimension.width,
                                        });
                                    }

                                    if (dimension.length) {
                                        size.dimensions.push({
                                            label: 'Comprimento',
                                            shortLabel: 'C',
                                            value: dimension.length,
                                        });
                                    }

                                    if (dimension.waist) {
                                        size.dimensions.push({
                                            label: 'Cintura',
                                            shortLabel: 'Cint',
                                            value: dimension.waist,
                                        });
                                    }

                                    if (dimension.hip) {
                                        size.dimensions.push({
                                            label: 'Quadril',
                                            shortLabel: 'Quadril',
                                            value: dimension.hip,
                                        });
                                    }

                                    if (dimension.sleeve_length) {
                                        size.dimensions.push({
                                            label: 'Manga',
                                            shortLabel: 'M',
                                            value: dimension.sleeve_length,
                                        });
                                    }

                                    if (dimension.sleeve_circumference) {
                                        size.dimensions.push({
                                            label: 'Circunf.',
                                            shortLabel: 'Circ',
                                            value: dimension.sleeve_circumference,
                                        });
                                    }

                                    if (dimension.leg_circumference) {
                                        size.dimensions.push({
                                            label: 'Circunf. Perna',
                                            shortLabel: 'Circ Perna',
                                            value: dimension.leg_circumference,
                                        });
                                    }

                                    if (this.distinctModels[model.id].has(dimension.size_id))
                                        all.push(size);

                                    return all;
                                }, []),
                        });
                    });

                    if (modelings.length > 0) {
                        this.modelings = modelings;
                        this.selectedModeling = this.modelings[0];
                    }
                });
            },

            goToModel(modelId) {
                if (this.modelings) {
                    this.selectedModeling =
                        this.modelings.find((m) => m.id === modelId) || this.selectedModeling;
                }
            },
        },
    };
</script>
