<template>
    <li class="product-list-item">
        <a :href="product.link" class="cart-product-info pr" style="width: 100%">
            <div v-if="imageArt || image" class="flex-child-fixed">
                <img
                    :alt="product.name"
                    :data-src="imageArt || image"
                    class="lazy lazy-fade hidden-xs-down"
                    height="86"
                    width="57"
                />

                <img
                    :alt="product.name"
                    :data-src="imageArt || image"
                    class="lazy lazy-fade hidden-xs-up"
                    height="100"
                    width="85"
                />
            </div>

            <div class="d-flex flex-column pl-3 overflow-hidden">
                <p class="product-list-item-info text-secondary">
                    {{ product.type.name }}
                </p>

                <p class="product-list-item-title text-ellipsis">{{ name }}</p>

                <template v-if="options.displayPrice">
                    <p
                        v-if="
                            !product.price_min &&
                            !product.price_max &&
                            product.price_old > product.price &&
                            !$scope.IS_WHOLESALER &&
                            options.displayPercent
                        "
                    >
                        <del class="text-secondary">{{ $f.formatMoney(product.price_old) }}</del>
                    </p>

                    <p
                        :class="{
                            'text-accent':
                                product.price_min > 0 &&
                                product.price_min < product.price_max &&
                                product.price_old > product.price &&
                                !$scope.IS_WHOLESALER,
                        }"
                    >
                        <span
                            v-if="product.price_min > 0 && product.price_min < product.price_max"
                            class="product-list-item-price"
                        >
                            A partir de R${{ $f.formatMoney(product.price_min) }}
                        </span>

                        <template v-else>
                            <span class="product-list-item-price"
                                >R${{ $f.formatMoney(product.price) }}</span
                            >
                        </template>

                        <span
                            v-if="
                                !options.hideInstallments &&
                                product.price_min == product.price_max &&
                                product.price >= 20
                            "
                        >
                            <span class="hidden-md-down">&nbsp;|&nbsp;</span>
                            <br class="hidden-md-up" />
                            4x de R${{ $f.formatMoney(product.price / 4) }}
                        </span>
                    </p>
                </template>
            </div>
        </a>
    </li>
</template>

<script>
    import ProductListItem from '../../product/ProductListItem';

    export default {
        name: 'CartRecommendationsListItem',

        extends: ProductListItem,

        computed: {
            name() {
                let textToReplace;
                switch ((this.product.type || {}).id) {
                    case 15:
                        textToReplace = 'Capa';
                        break;
                    case 31:
                        textToReplace = 'Avental';
                        break;
                    case 32:
                        textToReplace = 'Óculos De Sol';
                        break;
                    default:
                        textToReplace = (this.product.type || {}).name;
                }
                return this.product.name.replace(textToReplace + ' ', '');
            },
        },
    };
</script>
