import { CHECKOUT_STEPS } from '../consts';

const paymentInitialState = {
    description: null,
    selectedMethod: null,
    payloadBuilder: null,
    masterpassIsReady: false,
    visaCheckoutIsReady: false,
};

export default (store) => ({
    state: paymentInitialState,

    reset() {
        this.state.selectedMethod = null;
        this.description = null;
        this.selectedMethod = null;
        this.payloadBuilder = null;
    },

    select(method) {
        if (store.state.step.active !== CHECKOUT_STEPS.STEP_PAYMENT && method.module !== 'gratis') {
            return;
        }

        this.state.selectedMethod = method;
        store.step.next();
    },
});
