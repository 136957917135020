<template>
    <div class='product-list-item mb' :class="[!isLateralMenu ? 'product-list-item-top-border' : '']">
        <li>
            <div class='cart-product-info'>
                <div
                    class='pos-relative'
                    v-if='!isLateralMenu'
                    @click="$emit('zoom')"
                >
                    <a
                        v-if='image'
                        :href='link'
                        class='flex-child-fixed border-radius overflow-hidden'
                    >
                        <img
                            :alt='cartProduct.product.name'
                            :data-src='`${image}&w=156&h=233&fit=crop`'
                            class='lazy lazy-fade hidden-xs-down'
                            height='233'
                            width='156'
                        />
                        <img
                            :alt='cartProduct.product.name'
                            :data-src='`${image}&w=104&h=156&fit=crop`'
                            class='lazy lazy-fade hidden-xs-up'
                            height='157'
                            width='104'
                        />
                    </a>
                    <!--                    <div-->
                    <!--                        class="pos-absolute pos-right pos-top px-2 py-1 text-black d-inline-block rounded hidden-xs-down"-->
                    <!--                    >-->
                    <!--                        <button @click="addWishList()" style="all: unset">-->
                    <!--                            <i class="icon-cr icon-wishlist accent mr-1" />-->
                    <!--                        </button>-->
                    <!--                    </div>-->
                </div>
                <div
                    v-else
                    @click="$emit('zoom')"
                >
                    <a
                        v-if='image'
                        :href='link'
                        class='flex-child-fixed border-radius overflow-hidden'
                    >
                        <img
                            :alt='cartProduct.product.name'
                            :data-src='`${image}&w=104&h=156&fit=crop`'
                            class='lazy lazy-fade'
                            height='157'
                            width='104'
                        />
                    </a>
                </div>


                <div class='d-flex flex-column pl-4'>
                    <div class='d-flex flex-justify-between flex-align-center mr-2' :class="[!isLateralMenu ? 'mt-1' : '']">
                        <div>
                            <a v-if="$scope.REQUEST_URI === '/sacola'" :href='link' class='product-list-item-title'>
                                <strong>{{ cartProduct.product.name }}</strong>
                            </a>
                            <a v-else :href='link' class='product-list-item-title'>
                                {{ cartProduct.product.name }}
                            </a>
                        </div>
                    </div>


                    <div>
                        <p class='product-list-item-subtitle'>
                            <template
                                v-if="
                                cartProduct.size !== 'Padrão' &&
                                (cartProduct.product.type || {}).url !==
                                    'vale-presente'
                            "
                            >
                            <span>
                                {{
                                    (cartProduct.product.type || {}).url ===
                                    'assinatura'
                                        ? $t('period')
                                        : ''
                                }}
                            </span>
                            </template>
                            <div class='hidden-xs-up product-list-item-subtitle'>
                                <template v-if='cartProduct.product_model_name'>
                                    <a class='ellipsis-text'> {{ cartProduct.product_model_name }}&nbsp</a>
                                </template>
                                <template v-if="cartProduct.size !== 'Padrão'">
                                    <a>{{ cartProduct.size }}</a>
                                </template>
                                <template
                                    v-if="
                                            (cartProduct.product.type || {}).url ===
                                            'assinatura'
                                        "
                                >
                                    <br />
                                    <a
                                        class='text-accent'
                                        href='central-de-ajuda/#reamaze#0#/kb/prime'
                                    >
                                        {{ $t('checkTerms') }}
                                    </a>
                                </template>
                            </div>
                            <div class='hidden-xs-down product-list-item-subtitle'>
                                <template v-if='cartProduct.product_model_name'>
                                    {{ cartProduct.product_model_name }}&nbsp
                                </template>
                                <template v-if="cartProduct.size !== 'Padrão'">
                                    <span>{{ cartProduct.size }}</span>
                                </template>
                                <br>
                                <template v-if='cartProduct.product_color_name && !isLateralMenu'>
                                    <div class='d-flex flex-align-center mb-3 mt-3'>
                                        <div
                                            class='badge-product-color mr-2 no-border'
                                            :style="{'background-color': cartProduct.product_color_hexa}">
                                        </div>
                                        <span class='color-name'>{{ cartProduct.product_color_name }}</span>
                                    </div>
                                </template>
                                <template
                                    v-if="(cartProduct.product.type || {}).url ==='assinatura'"
                                >
                                    <br />
                                    <a
                                        class='text-accent'
                                        href='central-de-ajuda/#reamaze#0#/kb/prime'
                                    >
                                        {{ $t('checkTerms') }}
                                    </a>
                                </template>
                            </div>
                        </p>
                    </div>

                    <div :class="[!isLateralMenu ? 'mobile-cart-deadline' : '']">
                        <span style='font-size: 14px'>{{ cartProduct.product_color_name }}</span>
                    </div>

                    <div :class="[!isLateralMenu ? 'mobile-cart-deadline' : ''] ">
                        <p v-if='!cartProduct.can_be_bought' class='badge-danger hidden-mobile'>
                            <strong class='text-danger-3 text-uppercase' style='font-size: 12px'>{{ $t('unavailable')
                                }}</strong>
                        </p>
                        <div v-else-if="(cartProduct.product.type || {}).url !==
                                    'vale-presente' &&
                                (cartProduct.product.type || {}).url !==
                                    'assinatura'" class='product-list-item-info mt-3'>
                            <strong class='text-info-3 text-uppercase date-production' style='font-size: 12px'
                                    v-if='!(cartProduct.quantity <= cartProduct.in_stock) && ((!cartProduct.virtual_stock && cartProduct.total_stock > 1) || cartProduct.virtual_stock) && cartProduct.has_quantity'>{{ $t('toOrder')
                                }}</strong>
                            <div style='font-size: 12px'>
                                <template
                                    v-if="
                                (cartProduct.product.type || {}).url !==
                                    'vale-presente' &&
                                (cartProduct.product.type || {}).url !==
                                    'assinatura' &&
                                cartProduct.product.virtual_stock &&
                                cartProduct.quantity > cartProduct.in_stock
                                && cartProduct.has_quantity"
                                >
                                    <p v-if='showProductionDate' class='date-production'>
                                        Produção após o seu pedido, até
                                        {{
                                            cartProduct.production_date
                                                | formatDate('DD/MM')
                                        }}
                                    </p>
                                </template>

                                <!--Em estoque-->
                                <p v-else-if='cartProduct.quantity <= cartProduct.in_stock'
                                   class='text-uppercase text-bold'>
                                    {{ $t('_shop._stock.inStock') }}
                                </p>

                                <!--Últimas 2 unidades-->
                                <template
                                    v-if='
                                cartProduct.product.is_active &&
                                cartProduct.total_stock >= 1 &&
                                cartProduct.total_stock <= 2 &&
                                cartProduct.product.in_stock
                            '
                                ><p
                                    v-if='cartProduct.total_stock === 1'
                                    class='badge-primary'
                                >
                                    {{ $tc('_shop._stock.lastUnit', 1) }}
                                </p>
                                    <p v-else class='badge-primary'>
                                        {{ $tc('_shop._stock.lastUnits', 2) }}
                                    </p>
                                </template>

                                <!--Quantidade não disponível-->
                                <p
                                    v-if='
                                (cartProduct.product.is_active &&
                                    !cartProduct.has_quantity) ||
                                !cartProduct.product.in_stock
                            '
                                    class='badge-danger'
                                >
                                    {{ $t('_shop._stock.qtyUnavailable') }}
                                </p>
                                <p
                                    v-else-if='!cartProduct.product.is_active'
                                    class='badge-danger'
                                >
                                    {{ $t('unavailable') }}
                                </p>
                            </div>

                            <p
                                v-if='
                                cartProduct.produto_id === kitProductId &&
                                !hasMinToKit
                            '
                                class='badge-danger'
                            >
                                {{ $t('_cart.onlyOrdersAboveX', [$f.formatMoney(30)]) }}
                            </p>

                            <p
                                v-if='
                                cartProduct.produto_id === specialMugProductId &&
                                !cart.gift_message
                            '
                                class='badge-danger'
                            >
                                {{ $t('_cart.enterCustomName') }}
                            </p>
                        </div>
                    </div>


                    <div class='d-flex mt-2 flex-align-center select-and-prices'
                         :class="[isLateralMenu ? 'flex-justify-between' : '']">
                        <template v-if='!$scope.IS_REGULAR_CUSTOMER'>
                            <i
                                class='icon-cr icon-less icon-cr-hover hidden-md-down'
                                :title="$tc('_cart.toRemoveXItems', 1)"
                                @click="$emit('remove-one')"
                            />
                            <input
                                :max='maximumQuantity'
                                :value='cartProduct.quantity'
                                class='my-0 hidden-md-down text-center'
                                type='text'
                                @change="$emit('quantity-change', $event)"
                            />
                            <i
                                :class='{
                                        busy:
                                            maximumQuantity &&
                                            cartProduct.quantity >= maximumQuantity,
                                    }'
                                class='icon-cr icon-plus icon-cr-hover hidden-md-down'
                                :title="$tc('_cart.toAddXItems', 1)"
                                @click="$emit('add-one')"
                            />
                        </template>
                        <div
                            v-else-if='((!cartProduct.virtual_stock && cartProduct.total_stock > 0) || cartProduct.virtual_stock)'
                            class='cr-select my-0'>
                            <select
                                :data-produto-id='cartProduct.product.id'
                                :data-quantity='cartProduct.quantity'
                                :data-variation-id='cartProduct.variation_id'

                                class='select-mobile mt-0'
                                @change="$emit('quantity-change', $event)"
                            >
                                <option
                                    v-for='index in maximumQuantity'
                                    :selected='cartProduct.quantity === index'
                                    :value='index'
                                >
                                    {{ index }}
                                </option>
                            </select>
                        </div>
                        <div class='d-flex prices-wrap'>
                            <!--Price Old-->
                            <p v-if='isLateralMenu && cartProduct.product.price_old > 0 &&
                            cartProduct.product.price_old > cartProduct.product.price &&
                            cartProduct.product.price_old > cartProduct.total_price
                            && cartProduct.product.is_active'
                               class='text-default-3 line-through ml-3 mr-2'
                               style='font-size: 14px;'>{{ $f.formatMoney(cartProduct.product.price_old) }}</p>

                            <p v-else-if='isLateralMenu && cartProduct.product.is_gift && cartProduct.product.price <= 1'
                               class='text-default-3 line-through ml-3 mr-2'
                               style='font-size: 14px;'>{{ $f.formatMoney(cartProduct.product.price) }}</p>

                            <p :class="[!isLateralMenu ? 'hidden-md-up ml-2' : '']" class='product-list-item-price'
                               style='font-size: 14px'>
                                <!--Price-->
                                <strong v-if='cartProduct.product.is_gift && cartProduct.product.price <= 1'>
                                    Grátis
                                </strong>
                                <strong v-else>{{ $f.formatMoney(cartProduct.total_price) }}</strong>
                            </p>
                        </div>

                        <div v-if='!isLateralMenu' class='hidden-mobile'>
                            <div class='d-flex flex-align-center'>
                                <a
                                    class='cart-product-remove icon-cr icon-cr-hover icon-trash'
                                    :class='{"ml-3": ((!cartProduct.virtual_stock && cartProduct.total_stock > 0) || cartProduct.virtual_stock)}'
                                    :title="$t('_cart.toRemoveItem')"
                                    @click="$emit('remove')"
                                >

                                </a>
                            </div>
                            <!--                            <div>
                                                            <i class="icon-cr icon-trash"></i>
                                                        </div>-->

                        </div>
                    </div>

                    <!--Prazo de envio-->
                    <p v-if='!cartProduct.can_be_bought' class='badge-danger hidden-mobile '
                       :class="[isLateralMenu ? 'hidden-lateral-cart' : '']">
                        <strong class='text-danger-3 text-uppercase' style='font-size: 14px'>{{ $t('unavailable')
                            }}</strong>
                    </p>

                    <div v-else-if="(cartProduct.product.type || {}).url !==
                                    'vale-presente' &&
                                (cartProduct.product.type || {}).url !==
                                    'assinatura'" class='product-list-item-info mt-3 hidden-mobile'
                         :class="[isLateralMenu ? 'hidden-lateral-cart' : '']">
                        <strong class='text-info-3 text-uppercase date-production' style='font-size: 14px'
                                v-if='!(cartProduct.quantity <= cartProduct.in_stock) && ((!cartProduct.virtual_stock && cartProduct.total_stock > 1) || cartProduct.virtual_stock) &&  cartProduct.has_quantity'>{{ $t('toOrder')
                            }}</strong>
                        <div class='hidden-xs-down'>
                            <template
                                v-if="
                                (cartProduct.product.type || {}).url !==
                                    'vale-presente' &&
                                (cartProduct.product.type || {}).url !==
                                    'assinatura' &&
                                cartProduct.product.virtual_stock &&
                                cartProduct.quantity > cartProduct.in_stock &&
                                cartProduct.has_quantity
                            "
                            >
                                <p v-if='showProductionDate' class='date-production'>

                                    Produção após o seu pedido, até
                                    {{
                                        cartProduct.production_date
                                            | formatDate('DD/MM')
                                    }}

                                </p>
                            </template>

                            <!--Em estoque-->
                            <p v-else-if='cartProduct.quantity <= cartProduct.in_stock'
                               class='text-uppercase text-bold'>
                                {{ $t('_shop._stock.inStock') }}
                            </p>

                            <!--Últimas 2 unidades-->
                            <template
                                v-if='
                                cartProduct.product.is_active &&
                                cartProduct.total_stock >= 1 &&
                                cartProduct.total_stock <= 2 &&
                                cartProduct.product.in_stock
                            '
                            ><p
                                v-if='cartProduct.total_stock === 1'
                                class='badge-primary'
                            >
                                {{ $tc('_shop._stock.lastUnit', 1) }}
                            </p>
                                <p v-else class='badge-primary'>
                                    {{ $tc('_shop._stock.lastUnits', 2) }}
                                </p>
                            </template>

                            <!--Quantidade não disponível-->
                            <p
                                v-if='
                                (cartProduct.product.is_active &&
                                    !cartProduct.has_quantity) ||
                                !cartProduct.product.in_stock
                            '
                                class='badge-danger'
                            >
                                {{ $t('_shop._stock.qtyUnavailable') }}
                            </p>
                            <p
                                v-else-if='!cartProduct.product.is_active'
                                class='badge-danger'
                            >
                                {{ $t('unavailable') }}
                            </p>
                        </div>

                        <p
                            v-if='
                                cartProduct.produto_id === kitProductId &&
                                !hasMinToKit
                            '
                            class='badge-danger'
                        >
                            {{ $t('_cart.onlyOrdersAboveX', [$f.formatMoney(30)]) }}
                        </p>

                        <p
                            v-if='
                                cartProduct.produto_id === specialMugProductId &&
                                !cart.gift_message
                            '
                            class='badge-danger'
                        >
                            {{ $t('_cart.enterCustomName') }}
                        </p>
                    </div>

                </div>
            </div>
            <div :class="[!isLateralMenu ? 'mobile-cart-deadline' : '']">
                <div
                    class='pos-absolute pos-right px-1 py-1 pos-top'
                >
                    <a
                        class='cart-product-remove icon-cr icon-cr-hover icon-trash'
                        :title="$t('_cart.toRemoveItem')"
                        @click="$emit('remove')"
                    >
                    </a>
                </div>
            </div>
            <div :class="[isLateralMenu ? 'hidden-lateral-cart' : 'hidden-md-down']">
                <div class='d-flex flex-align-center mt-2'>
                    <!--Price Old-->
                    <p v-if='cartProduct.product.price_old > 0 && cartProduct.product.price_old > cartProduct.product.price &&
                    cartProduct.product.price_old > cartProduct.total_price
                     && cartProduct.product.is_active'
                       class='text-default-3 line-through'
                       :class="[!isLateralMenu ? 'hidden-md-down' : '']"
                       style='font-size: 14px;'>{{ $f.formatMoney(cartProduct.product.price_old) }}</p>

                    <p v-else-if='cartProduct.product.is_gift && cartProduct.product.price <= 1'
                       class='text-default-3 line-through'
                       :class="[!isLateralMenu ? 'hidden-md-down' : '']"
                       style='font-size: 14px;'>{{ $f.formatMoney(cartProduct.product.price) }}</p>

                    <!--Price-->
                    <strong class='ml-3' style='font-size: 14px;'
                            v-if='cartProduct.product.is_gift && cartProduct.product.price <= 1 && cartProduct.product.is_active'>
                        Grátis </strong>
                    <strong class='ml-3' style='font-size: 14px;'
                            v-else-if='cartProduct.product.is_active'>{{ $f.formatMoney(cartProduct.total_price)
                        }}</strong>
                </div>
            </div>
        </li>
    </div>
</template>

<script>
    import { appendQueryString } from '../../../common/common';
    import { customerService } from '../../../common/service/resource';

    export default {
        name: 'CartProduct',

        props: {
            cartProduct: {
                type: Object,
                required: true,
            },
            isLateralMenu: {
                type: Boolean,
                default: false,
            },
            kitProductId: Number,
            specialMugProductId: Number,
            hasMinToKit: Boolean,
            maximumAllowedPackings: Number,
        },

        data() {
            return {
                productionDate: null,
                submitting: false,
                submitted: false,
            };
        },

        computed: {
            showProductionDate() {
                return this.cartProduct.production_date && (this.cartProduct.product.is_active && (this.cartProduct.has_quantity || this.cartProduct.product.in_stock));
            },

            isSpecialProduct() {
                return (
                    [
                        this.donationProductId,
                        this.kitProductId,
                        this.specialMugProductId,
                    ].indexOf(this.cartProduct.produto_id) >= 0
                );
            },

            link() {
                return !this.cartProduct.product.block_page
                    ? this.cartProduct.product.link
                    : '/' + (
                    this.cartProduct.product.super_type && this.cartProduct.product.super_type.url !== ""
                        ? this.cartProduct.product.super_type.url
                        : (this.cartProduct.product.type && this.cartProduct.product.type.url !== ""
                                ? this.cartProduct.product.type.url
                                : ''
                        )
                );
            },

            image() {
                return this.cartProduct.product_image ? appendQueryString(
                    this.cartProduct.product_image,
                    {
                        auto: 'compress,format',
                        q: 65,
                    }
                ) : null;
            },

            maximumQuantity() {
                let max = this.cartProduct.product.type.id === 13 && this.maximumAllowedPackings
                    ? this.maximumAllowedPackings
                    :
                    (this.cartProduct.total_stock < 25 ? this.cartProduct.total_stock : 25);

                return this.cartProduct.quantity > max ? this.cartProduct.quantity : max;
            },

            addWishList() {
                customerService
                    .addWishlistProduct(this.$scope.id, {
                        product_id: this.cartProduct.product.id,
                        variation_id: this.cartProduct.variation_id,
                    })
                    .then(() => {
                        this.submitted = true;
                    })
                    .catch((response) => {
                        this.alert = {
                            message:
                                response.response.data.message ||
                                'Não foi possível adicionar à lista de desejos',
                            isError: true,
                        };
                    });
            },

        },
    };
</script>
