<template>
    <div>
        <slot :products="products" />
    </div>
</template>

<script>
    import { productService } from '../../../common/service/resource';
    import RequestsEmitter from '@bit/chicorei.chicomponents.util.requests-emitter';

    export default {
        name: 'FeaturedProductsFetcher',

        mixins: [RequestsEmitter],

        props: {
            partnerId: Number,
            categoryId: Number,
            typeId: Number,
            sortByReleased: Boolean,
            limit: {
                type: Number,
                default: 6,
            },
        },

        data() {
            return {
                products: null,
            };
        },

        created() {
            this.fetchProducts();
        },

        methods: {
            fetchProducts() {
                this.exposedRequests++;
                productService
                    .getFeatured({
                        partnerId: this.partnerId,
                        categoryId: this.categoryId,
                        typeId: this.typeId,
                        limit: this.limit,
                        sortByReleased: this.sortByReleased,
                    })
                    .then((response) => (this.products = response.data))
                    .catch(() => this.$emit('error'))
                    .then(() => this.exposedRequests--);
            },
        },
    };
</script>
