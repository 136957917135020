var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$scope.CUSTOMER)?_c('div',{staticClass:"header"},[_c('div',{staticClass:"h-full flex-column"},[_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),(_vm.$scope.IS_REGULAR_CUSTOMER)?_c('nav',{staticClass:"header-drawer-items"},[_vm._m(2)]):_vm._e(),_vm._v(" "),(_vm.$scope.IS_REGULAR_CUSTOMER)?_c('nav',{staticClass:"header-drawer-items"},[_vm._m(3)]):_vm._e(),_vm._v(" "),_c('hr'),_vm._v(" "),_vm._m(4),_vm._v(" "),_vm._m(5),_vm._v(" "),_c('nav',{staticClass:"header-drawer-items"},[_c('a',{staticClass:"h5 my-0 text-uppercase pl-6",attrs:{"href":'/logout.php' +
                    (_vm.$scope.REQUEST_URI ? `?back=${_vm.$scope.REQUEST_URI.substr(1)}` : '')}},[_vm._v("Sair")])])]),_vm._v(" "),(!_vm.$scope.IS_REGULAR_CUSTOMER)?_c('div',{staticClass:"pl-6"},[_vm._v("\n        Contato: \n        "),(_vm.$scope.SALES_REP)?_c('a',{attrs:{"href":`tel:${(_vm.$scope.SALES_REP || {}).PHONE}`}},[_vm._v("\n            "+_vm._s(_vm.$f.formatPhone(_vm.$scope.SALES_REP.PHONE))+"\n        ")]):_c('a',{attrs:{"href":"tel:32999200409"}},[_vm._v(" (32) 99920-0409 ")]),_vm._v("\n         | "),_c('a',{attrs:{"href":"mailto:lojista@chicorei.com.br"}},[_vm._v("lojista@chicorei.com.br")])]):_vm._e()]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"header-drawer-items"},[_c('a',{staticClass:"h5 my-0 text-uppercase text-bold",attrs:{"href":"/minha-conta/meus-dados"}},[_c('span',{staticClass:"icon-cr icon-indicates mr-3"}),_vm._v(" Meus Dados")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"header-drawer-items"},[_c('a',{staticClass:"h5 my-0 text-uppercase text-bold",attrs:{"href":"/minha-conta/pedidos"}},[_c('span',{staticClass:"icon-cr icon-carrier mr-3"}),_vm._v(" Meus pedidos")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"h5 my-0 text-uppercase text-bold",attrs:{"href":"/minha-conta/trocas-devolucoes"}},[_c('span',{staticClass:"icon-cr icon-exchange mr-3"}),_vm._v(" Trocas e Devoluções")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"h5 my-0 text-uppercase text-bold",attrs:{"href":"/minha-conta/cashback"}},[_c('span',{staticClass:"icon-cr icon-cashback mr-3"}),_vm._v("\n                Meu Cashback")])
},function (){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"header-drawer-items"},[_c('a',{staticClass:"h5 my-0 text-uppercase pl-6",attrs:{"href":"/minha-conta/wishlist"}},[_vm._v("Lista de Desejos")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"header-drawer-items"},[_c('a',{staticClass:"h5 my-0 text-uppercase pl-6",attrs:{"href":"/minha-conta/cupons"}},[_vm._v("Cupons")])])
}]

export { render, staticRenderFns }