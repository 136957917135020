export const createStore = (options) => {
    const { modules, ...store } = options;

    if (!store.state) {
        store.state = {};
    }

    for (const moduleName in modules) {
        if (modules.hasOwnProperty(moduleName)) {
            const module = modules[moduleName](store); // todo melhorar nome

            for (const prop in module) {
                if (module.hasOwnProperty(prop)) {
                    if (prop === 'state') {
                        if (!store.state[moduleName]) {
                            store.state[moduleName] = {};
                        }

                        store.state[moduleName] = module[prop];
                    } else if (prop !== 'name') {
                        if (!store[moduleName]) {
                            store[moduleName] = {};
                        }

                        store[moduleName][prop] = module[prop].bind(module);
                    }
                }
            }
        }
    }

    return store;
};
