<template>
    <div>
        <div :class="{ 'line-clamp-3 line-clamp-1-mobile': !expand }">
            <slot />
        </div>
        <a class="cursor-pointer text-bold text-underline" @click="expand = !expand">
            <span>
                {{ expand ? 'Menos' : 'Mais' }}
            </span>
        </a>
    </div>
</template>

<script>
    export default {
        name: 'CollapsedContent',

        data() {
            return {
                expand: false,
            };
        },
    };
</script>
