import { cartService } from '../../../../../common/service/resource';
import { CHECKOUT_STEPS } from '../consts';
import axios from 'axios';

const CancelToken = axios.CancelToken;

const carrierInitialState = {
    requests: 0,
    selectShippingCancel: null,
    initialForecast: null,
    finalForecast: null,
    error: null,
    cancelToken: null,
    carrierId: null,
};

export default (store) => ({
    state: carrierInitialState,

    cancelRequest() {
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel();
            this.state.cancelToken = undefined;
        }
    },

    select(quotation, shipping) {
        if (store.state.step.active !== CHECKOUT_STEPS.STEP_CARRIER) {
            return;
        }

        this.state.error = null;
        this.state.requests++;

        this.cancelRequest();
        this.state.cancelToken = CancelToken.source();

        this.state.initialForecast = null;
        this.state.finalForecast = null;
        this.state.carrierId = shipping.carrier_id ? shipping.carrier_id : null;

        cartService
            .update(
                store.state.cart.cart.id,
                {
                    cotacaoId: quotation.id,
                    cotacaoResponseId: shipping.id,
                    expresso: shipping.expressEnabled,
                    addressDeliveryId: store.state.cart.cart.address.data.address_id,
                    promotionalCard: this.promotionalCard ? this.promotionalCard : 0,
                    withCustomer: true,
                },
                {
                    headers: {
                        'cr-api-fast-auth': store.state.authentication.fastLoginEmail,
                    },
                    cancelToken: this.state.cancelToken.token,
                }
            )
            .then((response) => {
                if (store.state.step.active !== CHECKOUT_STEPS.STEP_CARRIER) {
                    return;
                }

                if (store.state.cart.cart.total_products !== response.data.data.total_products) {
                    store.cart.fetch().then(() => store.step.next());
                } else {
                    this.state.initialForecast = shipping.actual_delivery_forecast_initial;
                    this.state.finalForecast = shipping.actual_delivery_forecast;

                    store.cart.updateStateWithResponse(response.data.data);

                    window.dataLayer.push({
                        event: 'listPaymentsCheckout',
                        ecommerce: {
                            checkout: {
                                actionField: { step: 3 },
                            },
                        },
                    });

                    store.step.next();
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                }

                this.state.error = {
                    msg: '_checkout._shipping.errorSavingCarrier',
                    i18n: true,
                };
            })
            .then(() => this.state.requests--);
    },

    addPrime() {
        this.state.error = null;
        this.state.requests++;

        this.cancelRequest();
        this.state.cancelToken = CancelToken.source();

        cartService
            .addPrime(store.state.cart.cart.id, {
                cancelToken: this.state.cancelToken.token,
            })
            .then(() => store.cart.fetch())
            .catch(
                (error) =>
                    (this.state.error = {
                        msg: error.response.data.message,
                        i18n: false,
                    })
            )
            .then(() => this.state.requests--);
    },

    removePrime() {
        this.state.error = null;
        this.state.requests++;

        this.cancelRequest();
        this.state.cancelToken = CancelToken.source();

        cartService
            .removePrime(store.state.cart.cart.id, {
                cancelToken: this.state.cancelToken.token,
            })
            .then(() => store.cart.fetch())
            .catch(
                (error) =>
                    (this.state.error = {
                        msg: error.response.data.message,
                        i18n: false,
                    })
            )
            .then(() => this.state.requests--);
    },
});
