var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block"},[_c('p',{staticClass:"h6 title-infos"},[_vm._v("\n        "+_vm._s(_vm.$t('color'))+":\n        "),(_vm.selectedFabric)?_c('span',{staticClass:"text-bold text-no-bold-mobile"},[_vm._v(_vm._s(_vm.selectedFabric.product_color_name))]):_vm._e()]),_vm._v(" "),_c('nav',{staticClass:"cr-option-group product-variations-colors cr-option-colors"},[_vm._l((_vm.fabrics),function(fabric,index){return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getFabricTooltip(fabric)),expression:"getFabricTooltip(fabric)"}],staticClass:"cr-option cr-option-squared",class:{
                    'cr-option-selected with-border':
                        _vm.selectedFabric && fabric.fabric_id === _vm.selectedFabric.fabric_id,
                    'cr-option-crossed cr-option-disabled': _vm.shopMode && !fabric.has_any,
                    'no-border':
                        fabric.color_id !== 3 && fabric.fabric_id !== _vm.selectedFabric.fabric_id,
                },on:{"click":function($event){return _vm.$emit('on-fabric-click', fabric)}}},[_c('div',{staticClass:"product-color-option",style:({
                        'background-color': fabric.product_color_hexa,
                    })}),_vm._v(" "),(_vm.sizeHasFewUnits(fabric))?_c('div',{staticClass:"cr-option-description"},[_vm._v("\n                    "+_vm._s(_vm.$tc('_shop._stock.remainX', fabric.quantity))+"\n                ")]):_vm._e()]),_vm._v(" "),(
                    _vm.fabrics.length > (_vm.modelings.length > 1 ? 9 : 7) &&
                    index + 1 === Math.ceil(_vm.fabrics.length / 2)
                )?_c('div',{staticClass:"hidden-md-down",staticStyle:{"flex-basis":"100%"}}):_vm._e()]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }