<template>
    <div v-if="$scope.CUSTOMER" class="header">
        <div class="h-full flex-column">
            <nav class="header-drawer-items">
                <a href="/minha-conta/meus-dados" class="h5 my-0 text-uppercase text-bold">
                    <span class="icon-cr icon-indicates mr-3"></span> Meus Dados</a
                >
            </nav>
            <nav class="header-drawer-items">
                <a href="/minha-conta/pedidos" class="h5 my-0 text-uppercase text-bold">
                    <span class="icon-cr icon-carrier mr-3"></span> Meus pedidos</a
                >
            </nav>
            <nav class="header-drawer-items" v-if="$scope.IS_REGULAR_CUSTOMER">
                <a href="/minha-conta/trocas-devolucoes" class="h5 my-0 text-uppercase text-bold">
                    <span class="icon-cr icon-exchange mr-3"></span> Trocas e Devoluções</a
                >
            </nav>
            <nav class="header-drawer-items" v-if="$scope.IS_REGULAR_CUSTOMER">
                <a href="/minha-conta/cashback" class="h5 my-0 text-uppercase text-bold">
                    <span class="icon-cr icon-cashback mr-3"></span>
                    Meu Cashback</a
                >
            </nav>
            <hr />
            <nav class="header-drawer-items">
                <a href="/minha-conta/wishlist" class="h5 my-0 text-uppercase pl-6"
                    >Lista de Desejos</a
                >
            </nav>
            <nav class="header-drawer-items">
                <a href="/minha-conta/cupons" class="h5 my-0 text-uppercase pl-6">Cupons</a>
            </nav>

            <nav class="header-drawer-items">
                <a
                    :href="
                        '/logout.php' +
                        ($scope.REQUEST_URI ? `?back=${$scope.REQUEST_URI.substr(1)}` : '')
                    "
                    class="h5 my-0 text-uppercase pl-6"
                    >Sair</a
                >
            </nav>
        </div>

        <div v-if="!$scope.IS_REGULAR_CUSTOMER" class="pl-6">
            Contato:&nbsp;
            <a :href="`tel:${($scope.SALES_REP || {}).PHONE}`" v-if="$scope.SALES_REP">
                {{ $f.formatPhone($scope.SALES_REP.PHONE) }}
            </a>
            <a v-else href="tel:32999200409"> (32) 99920-0409 </a>
            &nbsp;|&nbsp;<a href="mailto:lojista@chicorei.com.br">lojista@chicorei.com.br</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SideMyaccount',

        components: {},

        data() {
            return {};
        },

        mounted() {},

        watch: {},

        computed: {},

        methods: {},
    };
</script>
