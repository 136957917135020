var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:'headerProductsWrapper' + this.name,staticClass:"pos-fixed w-screen h-screen pos-top text-uppercase",class:{
        open: _vm.slideMobileDrawerToRight,
        closed: !_vm.slideMobileDrawerToRight,
        'hidden-xs': _vm.hideMobileDrawer,
        'header-sub-menus-alt': _vm.$scope.EXPERIMENT_VAR2 > 0,
        'header-sub-menus': _vm.$scope.EXPERIMENT_VAR2 <= 0,
    },attrs:{"id":'header-products-wrapper-' + this.name}},[_c('div',{staticClass:"pos-fixed h-full flex-column",class:_vm.$scope.EXPERIMENT_VAR2 > 0 ? 'bg-white' : 'bg-default-1',attrs:{"id":"header-products"}},[(!_vm.$scope.EXPERIMENT_VAR2)?_c('a',{staticClass:"mt",on:{"click":function($event){_vm.slideMobileDrawerToRight = false}}},[_c('i',{staticClass:"icon-cr icon-arrow-left mb-2 exit-sub-menu mt-2"})]):_c('a',{staticClass:"mt-4 mb-4",on:{"click":function($event){_vm.slideMobileDrawerToRight = false}}},[_c('i',{staticClass:"icon-cr icon-chevron-left mr-1 exit-sub-menu"}),_vm._v("Voltar")]),_vm._v(" "),_vm._l((_vm.items),function(item,index){return _c('nav',[_vm._l((item.thumbs),function(thumb){return (_vm.$scope.EXPERIMENT_VAR2)?_c('div',{staticClass:"px-2"},[_c('a',{staticClass:"d-block pos-relative rounded overflow-hidden mb",attrs:{"href":thumb.url}},[_c('img',{staticClass:"d-block lazy lazy-fade img-responsive",staticStyle:{"max-width":"100%"},attrs:{"alt":thumb.alt,"data-src":`${_vm.$scope.CDN_IMAGES}/images/site/header/${thumb.image_url}?auto=compress,format&q=65`,"width":"768","height":"200"}}),_vm._v(" "),_c('div',{staticClass:"pos-absolute pos-bottom pos-right mb-2 mr-2 bg-white rounded px-2 py-1 text-italic text-bold hidden"},[_vm._v("\n                        "+_vm._s(thumb.alt)+"\n                    ")])])]):_vm._e()}),_vm._v(" "),_c('a',{staticClass:"h5 my-0 text-uppercase",class:{
                    'text-bold': item.is_bold !== '0',
                    'mt-3 pt-4': item.children && index > 0,
                    opened: item.opened,
                },attrs:{"href":item.url}},[_vm._v("\n                "+_vm._s(item.title)+"\n                "),(item.is_new === '1')?_c('sup',{staticClass:"header-product-title-badge"},[_vm._v(" Novo ")]):_vm._e()]),_vm._v(" "),(index < _vm.items.length - 1 && item.is_bold === '0')?_c('div',{staticStyle:{"border":"1px solid #b3b5b7"}}):_vm._e(),_vm._v(" "),_vm._l((item.children),function(child,childIndex){return [_c('a',{staticClass:"h5 my-0",class:{ 'text-bold': child.is_bold !== '0' },attrs:{"href":child.url}},[_vm._v("\n                    "+_vm._s(child.title)+"\n                    "),(child.is_new === '1')?_c('sup',{staticClass:"header-product-title-badge"},[_vm._v("\n                        Novo\n                    ")]):_vm._e()]),_vm._v(" "),(childIndex < item.children.length - 1)?_c('div',{staticStyle:{"border":"1px solid #b3b5b7"}}):_vm._e()]}),_vm._v(" "),_vm._l((item.bottomImgs),function(thumb){return (_vm.$scope.EXPERIMENT_VAR2)?_c('div',{staticClass:"px-2"},[_c('a',{staticClass:"d-block pos-relative rounded overflow-hidden mt",attrs:{"href":thumb.url}},[_c('img',{staticClass:"d-block lazy lazy-fade img-responsive",staticStyle:{"max-width":"100%"},attrs:{"alt":thumb.alt,"data-src":`${_vm.$scope.CDN_IMAGES}/images/site/header/${thumb.image_url}?auto=compress,format&q=65`,"width":"768","height":"200"}}),_vm._v(" "),_c('div',{staticClass:"pos-absolute pos-bottom pos-right mb-2 mr-2 bg-white rounded px-2 py-1 text-italic text-bold hidden"},[_vm._v("\n                        "+_vm._s(thumb.alt)+"\n                    ")])])]):_vm._e()})],2)})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }