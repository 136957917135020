<template>
    <Modal :allow-dismiss="false" :value="show" @input="show = $event">
        <template v-slot:title>Para qual cliente você está comprando?</template>
        <div class="card-block">
            <Spinner v-if="requests > 0" />
            <ul v-else-if="customers" class="list-group list-group-hover">
                <li v-for="customer in customers" @click="submit(customer)">
                    {{ customer.full_name }}
                </li>
            </ul>
            <p v-else>Não foi possível carregar os clientes</p>
        </div>
    </Modal>
</template>

<script>
    import { cartStore } from '../../common/store/cart-store';
    import Modal from '../common/container/Modal';
    import { customerService } from '../../../common/service/resource';
    import Spinner from '@bit/chicorei.chicomponents.feedback.spinner';

    export default {
        name: 'SalesRepCartCustomerManager',

        components: { Spinner, Modal },

        data() {
            return {
                requests: 0,
                customers: null,
                cartStoreState: cartStore.state,
            };
        },

        computed: {
            show: {
                get() {
                    return this.cartStoreState.requestSalesRepToSelectCustomer;
                },

                set(val) {
                    this.cartStoreState.requestSalesRepToSelectCustomer = val;
                },
            },
        },

        watch: {
            show(to) {
                if (to && !this.customers) this.fetchCustomers();
            },
        },

        methods: {
            fetchCustomers() {
                this.requests++;
                customerService
                    .getRepresentedCustomers(this.$scope.id, { paginate: 0 })
                    .then((response) => {
                        this.customers = response.data.data;
                    })
                    .catch()
                    .then(() => this.requests--);
            },

            submit(customer) {
                this.requests++;
                cartStore.retryPendingRequestForCustomer(customer.id);
                this.show = false;
            },
        },
    };
</script>
