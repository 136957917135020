<script>
    import { EventBus } from '../../../common/common';
    import HeaderDesktopContent from './HeaderDesktopContent';
    import cr$ from '../../../common/crquery';
    import { CLOSE_DESKTOP_MENU_EVENT } from '../../events';
    import HeaderRelativeSection from '../header/HeaderRelativeSection';

    export default {
        el: '#header-desktop-nav',

        name: 'HeaderMenu',

        components: { HeaderDesktopContent, HeaderRelativeSection },

        data() {
            return {
                isMounted: false,
                items: this.$scope.DESKTOP_MENU,
                openedItem: null,
                cancelOpenItem: false,
                debug: false,
            };
        },

        created() {
            EventBus.$on(CLOSE_DESKTOP_MENU_EVENT, () => this.closeItem());
        },

        mounted() {
            this.isMounted = true;
        },

        methods: {
            getItem(id) {
                return this.items.find((i) => id === parseInt(i.id));
            },

            openItem(id) {
                const item = this.getItem(id);
                this.cancelOpenItem = false;
                setTimeout(() => {
                    if (!this.cancelOpenItem) {
                        this.openedItem = item;
                    } else {
                        this.cancelOpenItem = false;
                    }
                }, 100);
            },

            closeItem() {
                if (this.debug) return;

                this.cancelOpenItem = true;
                this.openedItem = null;
            },

            closeMenu() {
                this.closeItem();
                cr$.byId('header-desktop').removeClass('do-slide');
            },
        },
    };
</script>
