<template>
    <div
        id="header-products-wrapper"
        ref="headerProductsWrapper"
        :class="{
            open: slideMobileDrawerToRight,
            closed: !slideMobileDrawerToRight,
            'hidden-xs': hideMobileDrawer,
        }"
        class="pos-fixed w-screen h-screen pos-top"
    >
        <div
            id="header-mobile-overlay"
            class="header-overlay"
            @click="slideMobileDrawerToRight = false"
        ></div>
        <div
            id="header-products"
            :class="$scope.EXPERIMENT_VAR2 > 0 ? 'bg-white' : 'bg-default-1'"
            class="pos-fixed h-full flex-column"
        >
            <div v-if="$scope.EXPERIMENT_VAR2">
                <div class="d-flex flex-align-center py-2 text-white bg-default-1 px">
                    <a
                        class="header-mobile-logo header-logo-cr logo-cr-text mr-auto pos-relative pos-center-x"
                        href="/"
                        title="Chico Rei"
                    ></a>
                    <a class="pos-relative pos-right" @click="slideMobileDrawerToRight = false">
                        <i class="icon-cr icon-close"></i>
                    </a>
                </div>
                <div class="d-flex flex-justify-between mx-2 mt-4 mb-3 pl-4 pr-4">
                    <div class="flex-xs-4 text-uppercase d-flex">
                        <a
                            @click="!$scope.CUSTOMER ? openLogin() : null"
                            :href="$scope.CUSTOMER ? '/minha-conta/meus-dados' : null"
                        >
                            <span class="ml-2 link-menu-mobile">Conta</span>
                        </a>
                    </div>
                    <span class="vertical-line"></span>
                    <div class="flex-xs-4 text-uppercase d-flex">
                        <a
                            @click="!$scope.CUSTOMER ? openLogin() : null"
                            :href="$scope.CUSTOMER ? '/minha-conta/pedidos' : null"
                        >
                            <span class="ml-2 link-menu-mobile">Pedidos</span>
                        </a>
                    </div>
                    <span
                        class="vertical-line"
                        v-if="$scope.EXPERIMENT_VAR2 && $scope.IS_REGULAR_CUSTOMER"
                    ></span>
                    <div
                        class="flex-xs-4 text-uppercase d-flex"
                        v-if="$scope.EXPERIMENT_VAR2 && $scope.IS_REGULAR_CUSTOMER"
                    >
                        <a
                            @click="!$scope.CUSTOMER ? openLogin() : null"
                            :href="$scope.CUSTOMER ? '/minha-conta/wishlist' : null"
                        >
                            <span class="ml-2 link-menu-mobile">Cashback</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="bg-default-1" v-else>
                <div class="d-flex flex-align-center text-white cashback-block">
                    <a
                        class="header-logo-cr logo-cr-circle mr-auto"
                        style="width: 1.75rem; height: 1.75rem"
                        href="/"
                        title="Chico Rei"
                    ></a>
                    <a class="pos-relative pos-right" @click="slideMobileDrawerToRight = false">
                        <i class="icon-cr icon-close"></i>
                    </a>
                </div>
                <div>
                    <div
                        v-if="$scope.CUSTOMER"
                        class="text-uppercase d-flex flex-column flex-align-start cashback-block"
                    >
                        <h5 class="text-bold">Olá, {{ customerName }} :)</h5>
                        <div v-if="$scope.cashback_available && $scope.IS_REGULAR_CUSTOMER">
                            <p>
                                Cashback disponível:
                                <span class="cashback-value">{{
                                    $f.formatMoney($scope.cashback_available)
                                }}</span>
                            </p>
                        </div>
                    </div>
                    <div
                        v-if="showLinksMenu || !$scope.EXPERIMENT_VAR2"
                        class="d-flex flex-justify-between mx-2 mt-4 mb-3 pl-4 pr-4 pb-3"
                    >
                        <div class="text-uppercase d-flex">
                            <a
                                @click="!$scope.CUSTOMER ? openLogin() : null"
                                :href="$scope.CUSTOMER ? '/minha-conta/meus-dados' : null"
                            >
                                <span class="ml-2 link-menu-mobile">Conta</span>
                            </a>
                        </div>
                        <span class="vertical-line"></span>
                        <div class="text-uppercase d-flex">
                            <a
                                @click="!$scope.CUSTOMER ? openLogin() : null"
                                :href="$scope.CUSTOMER ? '/minha-conta/pedidos' : null"
                            >
                                <span class="ml-2 link-menu-mobile">Pedidos</span>
                            </a>
                        </div>
                        <span
                            class="vertical-line"
                            v-if="$scope.cashback_available && $scope.IS_REGULAR_CUSTOMER"
                        ></span>
                        <div
                            class="text-uppercase d-flex"
                            v-if="$scope.cashback_available && $scope.IS_REGULAR_CUSTOMER"
                        >
                            <a
                                @click="!$scope.CUSTOMER ? openLogin() : null"
                                :href="$scope.CUSTOMER ? '/minha-conta/cashback' : null"
                            >
                                <span class="ml-2 link-menu-mobile">Cashback</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!--header-items-mobile-->
            <nav v-for="(group, index) in items" class="header-drawer-items">
                <a
                    :href="group.url ? group.url : null"
                    :class="{
                        'text-bold': group.is_bold !== '0',
                        'header-slide-title pos-relative': !!group.children,
                        opened: group.opened,
                    }"
                    @click="group.children ? openSubMenu(group.id) : null"
                    class="h5 my-0 text-uppercase"
                    >{{ group.title }}
                    <sup v-if="group.is_new === '1'" class="header-product-title-badge">
                        Novo
                    </sup></a
                >

                <HeaderMobileChild
                    :items="group.children"
                    v-if="group.children"
                    :name="group.id"
                    @submenu-exit="
                        selectedSubMenu = null;
                        showLinksMenu = true;
                    "
                    @submenu-enter="showLinksMenu = false"
                />
            </nav>
            <nav class="header-drawer-items" v-if="$scope.CUSTOMER">
                <a
                    :href="
                        '/logout.php' +
                        ($scope.REQUEST_URI ? `?back=${$scope.REQUEST_URI.substr(1)}` : '')
                    "
                    class="h5 my-0 text-uppercase"
                    >Sair</a
                >
            </nav>
            <div class="mt-4">
                <Advantages />
            </div>
        </div>
    </div>
</template>

<script>
    import { EventBus, updateLazyLoad } from '../../../common/common';
    import { SHOW_MOBILE_MENU_EVENT } from '../../events';
    import HeaderMobileChild from '../header/HeaderMobileChild.vue';
    import Advantages from '../common/Advantages';
    import { useSwipe } from '@vueuse/core';

    export default {
        el: '#header-mobile-nav',

        name: 'HeaderMobile',
        components: {
            HeaderMobileChild,
            Advantages,
        },
        data() {
            return {
                items: this.$scope.MOBILE_MENU,

                isMounted: false,

                slideMobileDrawerToRight: false,
                hideMobileDrawer: true,
                selectedSubMenu: null,
                showLinksMenu: true,
            };
        },

        computed: {
            customerName() {
                return (this.$scope.CUSTOMER || {}).FIRST_NAME;
            },
        },

        created() {
            EventBus.$on(SHOW_MOBILE_MENU_EVENT, () => (this.slideMobileDrawerToRight = true));
        },

        mounted() {
            if (this.$refs.headerProductsWrapper) {
                useSwipe(this.$el, {
                    onSwipeEnd: this.handleSwipe,
                });
            }

            this.$nextTick(() => updateLazyLoad());

            this.isMounted = true;
        },

        methods: {
            openLogin: function () {
                cr$.byId('button-open-login').click();
            },
            openSubMenu(idMenu) {
                this.selectedSubMenu = idMenu;
                EventBus.$emit(SHOW_MOBILE_MENU_EVENT + idMenu);
            },
            handleSwipe(e, direction) {
                if (direction === 'LEFT' && !this.selectedSubMenu) {
                    this.slideMobileDrawerToRight = false;
                }
            },
        },

        watch: {
            slideMobileDrawerToRight(newValue, oldValue) {
                if (newValue && !oldValue) {
                    this.hideMobileDrawer = false;
                } else if (!newValue && oldValue) {
                    setTimeout(() => (this.hideMobileDrawer = true), 500);
                }
            },
        },
    };
</script>
