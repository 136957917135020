<template>
    <div class="side-search">
        <div class="div-card mt-2">
            <form
                accept-charset="UTF-8"
                role="search"
                :action="url"
                @mouseenter="mouseIsOver = true"
                @mouseleave="mouseIsOver = false"
            >
                <div>
                    <div class="d-flex flex-align-center">
                        <label class="mx-0 my-0 flex-item-grow">
                            <input
                                type="search"
                                name="query"
                                maxlength="500"
                                accesskey="s"
                                placeholder="O que está procurando?"
                                autocomplete="off"
                                spellcheck="false"
                                dir="auto"
                                :value="searchQuery"
                                @input="(e) => (searchQuery = e.target.value.trim())"
                                @focus="searchFocus = true"
                                @blur="searchFocus = false"
                                @keyup.enter="$emit('input-enter', $event)"
                                @keyup.up="$emit('input-up')"
                                @keyup.down="$emit('input-down')"
                                class="search-mobile-input text-uppercase"
                                ref="searchbox"
                            />
                        </label>
                    </div>

                    <search :show="true" :search-query="searchQuery" :is-mobile="isMobile"></search>
                </div>
            </form>

            <!--            <i class="header-search-icon icon-cr icon-search" v-show="!searchFocus && searchQuery.length == 0"></i>-->
            <i
                class="header-search-clear"
                @click="clearSearch"
                v-show="searchFocus || searchQuery.length > 0"
                style="display: none"
            ></i>

            <div
                class="header-search-overlay hidden-xs"
                style="display: none"
                v-show="searchFocus || (mouseIsOver && searchQuery.length > 0) || debugMode"
                @click="clearSearch"
            ></div>
        </div>
    </div>
</template>

<script>
    import search from './Search.vue';

    export default {
        name: 'SideSearch',

        components: {
            search,
        },

        props: {
            isMobile: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                url: this.$scope.URL + 'search',
                searchQuery: '',
                searchFocus: false,
                mouseIsOver: false,
                debugMode: false,
            };
        },

        watch: {},

        mounted() {
            this.$nextTick(() => {
                this.$refs.searchbox.focus();
            });
        },

        methods: {
            clearSearch() {
                this.searchQuery = '';
            },
        },
    };
</script>
