<template>
    <div class="product-combo">
        <!--        <ProductPriceFrame label="Preço normal" :price="regularPrice" />-->
        <p class="h4 text-bold hidden-xs-down">
            {{ $f.formatMoney(regularPrice) }}
        </p>

        <!--        <div class="product-combo-divider text-secondary text-uppercase text-bold">-->
        <!--            {{ $t('or') }}-->
        <!--        </div>-->
        <p class="text-uppercase hidden-xs-down">{{ $t('or') }}</p>

        <div class="price-in-combo">
            <h3 class="h3 text-bold text-primary">
                {{ $f.formatMoney(product.promo_combo_unit_price) }}
            </h3>
            <div class="d-flex flex-justify-between flex-align-center">
                <p>
                    <i class="icon-cr icon-credit-card text-primary mr-2"></i>Até 4x
                    {{ $f.formatMoney(product.promo_combo_unit_price / 4) }} sem juros
                </p>
                <p class="text-primary text-uppercase text-bold" style="font-size: 12px">
                    Comprando no combo
                    <i class="icon-cr icon-info cursor-pointer" @click="openRegulamento"></i>
                </p>
            </div>
        </div>

        <!--        <ProductPriceFrame-->
        <!--            primary-->
        <!--            label="Preço no combo"-->
        <!--            :price="product.promo_combo_unit_price"-->
        <!--            :description="product.promo_instruction"-->
        <!--        />-->
    </div>
</template>

<script>
    import { productStore } from '../../common/store/product-store';
    import ProductPriceFrame from './ProductPriceFrame';
    import { EventBus } from '../../../common/common';

    export default {
        name: 'ProductPageComboPrice',

        components: { ProductPriceFrame },

        data() {
            return {
                productStoreState: productStore.state,
            };
        },

        computed: {
            product() {
                return this.productStoreState.product;
            },

            regularPrice() {
                return this.product.price_min > 0 ? this.product.price_min : this.product.price;
            },
        },

        methods: {
            openRegulamento() {
                EventBus.$emit('open-regulamento');
            },
        },
    };
</script>
