var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-inline-flex w-full"},[(_vm.products)?_c('ul',{ref:"list",class:_vm.innerListClass,on:{"scroll":function($event){return _vm.$emit('scroll', $event)}}},[_vm._l((_vm.limitedProducts),function(product,index){return [(_vm.showBanner && _vm.getBannerByIndex(index))?[_c('div',{staticClass:"product-list-item banner"},[_c('div',{staticClass:"banner-wrapper overflow-hidden aspect bg-default-1"},[_c('a',{attrs:{"href":_vm.getBannerByIndex(index).redirect_link,"target":"_blank"}},[_c('div',{staticClass:"pos-relative"},[_c('picture',[_c('source',{attrs:{"srcset":`${_vm.$scope.CDN_IMAGES}/images/site/banners/${
                                            _vm.getBannerByIndex(index).desktop_banner
                                        }?auto=compress,format&q=65`,"media":"(min-width: 576px)"}}),_vm._v(" "),_c('img',{staticClass:"lazy lazy-fade w-full",attrs:{"data-src":`${_vm.$scope.CDN_IMAGES}/images/site/banners/${
                                            _vm.getBannerByIndex(index).mobile_banner
                                        }?auto=compress,format&q=65`,"border":"0","itemprop":"image","src":""}})])])])])])]:_vm._e(),_vm._v(" "),[_c('ProductListItem',{key:_vm.getListKeyFor(product),style:({
                        opacity:
                            _vm.addingProduct && product.id !== _vm.addingProduct.id ? '0.3' : null,
                    }),attrs:{"small":_vm.small,"eager-load-image":_vm.ignoreLazyLoadFirstImages && index <= 1,"options":_vm.innerItemOptions,"product":product,"selected-filters":_vm.selectedFilters,"shorter-image":_vm.shorterImage,"id":product.id + `-productRef`,"is-fast-buying":_vm.addingProduct && _vm.addingProduct.id == product.id},on:{"click":function($event){return _vm.pushItemEvent(product)},"show-add-to-cart-mobile":function($event){return _vm.$emit('show-add-to-cart-mobile', $event)}},scopedSlots:_vm._u([{key:"below-image",fn:function(){return [_vm._t("below-image",null,{"product":product})]},proxy:true},{key:"list-add-to-cart-desktop",fn:function({ selectedFabricId }){return [_vm._t("list-add-to-cart-desktop",null,{"selectedFabricId":selectedFabricId,"product":product})]}}],null,true)})]]})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }