<template>
    <div :class="{ busy: loadingShipping }">
        <div v-if="$scope.IS_REGULAR_CUSTOMER">
            <FreeShippingProgress
                v-if="cart && !cart.is_only_virtual_products"
                :cart="cart"
                :is-lateral-menu="isLateralMenu"
                :is-mobile="isMobile"
                :loading="loading || loadingShipping"
                :national-shipping="nationalShipping"
                class="mb-3"
                :calculated-shipping-obj="freeShippingCalcObj"
                :mini="true"
            />
        </div>
        <div :class="{ 'shake-input': shakeInput }">
            <Alert :alert="alert" />
            <div class="d-flex flex-align-center">
                <input
                    type="text"
                    :placeholder="PlaceHolderShipping"
                    class="text-uppercase input-cart input-btn-internal placeholder-AB"
                    v-if="nationalShipping"
                    v-model="cep"
                    id="cep"
                    ref="cepInput"
                    v-mask="'#####-###'"
                    maxlength="9"
                    pattern="\d*"
                    :disabled="loadingShipping || (!nationalShipping && !country)"
                    @blur="
                        $emit('blur');
                        PlaceHolderShipping = 'Calcular Frete e Prazo';
                    "
                    @focus="
                        $emit('focus');
                        PlaceHolderShipping = 'Digite seu Cep';
                    "
                    @keyup.enter="calculateShipping(null)"
                />

                <label v-else class="cr-select cr-select-country">
                    <select
                        id="country"
                        v-model="country"
                        :disabled="loadingShipping"
                        @change="
                            country && !nationalShipping ? calculateShipping(null, true) : null
                        "
                    >
                        <option selected>Selecione o país</option>
                        <option v-for="country in countries" :value="country">
                            {{ country.name }}
                        </option>
                    </select>
                </label>

                <button
                    :disabled="
                        loadingShipping ||
                        (!nationalShipping && !country) ||
                        (nationalShipping && (!cep || cep.length < 9))
                    "
                    class="btn btn-select-country"
                    v-if="!nationalShipping"
                    @click="calculateShipping(null, true)"
                >
                    {{ $t('calculate') }}
                </button>

                <button
                    @click="calculateShipping(null)"
                    v-if="nationalShipping"
                    class="btn-internal-input-ab"
                    :style="[
                        isLateralMenu ? { height: '46px' } : {},
                        !isMobile ? { height: '40px' } : {},
                    ]"
                    :disabled="loadingShipping || (!nationalShipping && !country)"
                >
                    <span>Calcular</span>
                </button>
            </div>
        </div>

        <div class="mt" v-if="!nationalShipping">
            <a
                class="text-yanone text-accent text-left cursor-pointer"
                @click="toggleNationalShipping()"
            >
                {{ changeShippingTypeLabel }}
            </a>
        </div>

        <div
            :class="{ opened: showShippingDetails && !showShippingForm }"
            class="collapsible text-yanone opened"
        >
            <ul
                class="checkout-options mt-2 mb-4"
                :class="{
                    hidden:
                        cepNotFound ||
                        loadingShipping ||
                        (!nationalShipping && !country) ||
                        (nationalShipping && (!cep || cep.length < 9)) ||
                        !regularShipping.options ||
                        !regularShipping.options.length,
                }"
            >
                <li v-for="(option, index) in filteredShippingOptions">
                    <!--                    <input-->
                    <!--                        :id="`carrier-${option.carrier_id}`"-->
                    <!--                        :checked="(selectedCarrier && selectedCarrier.carrier_id === option.carrier_id) || (!selectedCarrier && index === 0)"-->
                    <!--                        class="cr-radio"-->
                    <!--                        type="radio"-->
                    <!--                    />-->
                    <label
                        :for="`carrier-${option.carrier_id}`"
                        @click="selectCarrier(option)"
                        class="flex-grid"
                    >
                        <div class="checkout-option-content flex-xs-9 flex-md-8">
                            <div class="mx-0">
                                <img
                                    v-if="option.prime"
                                    :src="`${$scope.CDN_IMAGES}/images/site/2021/prime/selo_prime_02_378bcb.svg`"
                                    alt="Chico Rei Prime"
                                    class="checkout-option-content-img"
                                />
                                <p class="my-0">
                                    <span>({{ option.name }})</span>
                                    <span
                                        v-if="
                                            (option.deadline > 0 && option.carrier_id === 4) ||
                                            option.carrier_id === 81027
                                        "
                                    >
                                        <span
                                            v-html="
                                                $t('untilDateXHtml', [
                                                    getIsoFormattedFinalForecast(option.forecast),
                                                ])
                                            "
                                        />

                                        <span v-if="option.carrier_id === 81027"
                                            ><br />{{ option.store.address }} -
                                            {{ option.store.number }},
                                            {{ option.store.neighborhood }},
                                            {{ option.store.city }}
                                        </span>
                                    </span>
                                    <span
                                        v-else-if="option.deadline > 0 && option.carrier_id !== 3"
                                    >
                                        <span
                                            v-html="
                                                $t('dateToXHtml', [
                                                    getIsoFormattedFinalForecast(option.forecast),
                                                ])
                                            "
                                        />
                                    </span>
                                </p>
                            </div>

                            <p
                                v-if="option.expressEnabled && option.actual_additional_price > 0"
                                class=""
                            >
                                {{ $t('_shop.withExpressProduction') }}
                            </p>
                        </div>
                        <p class="flex-xs-3 flex-md-4 price-carrier text-right">
                            <span
                                v-if="
                                    (option.price === 0 || option.prime) &&
                                    option.carrier_id !== 3 &&
                                    option.actual_additional_price === 0
                                "
                                class="free-price"
                            >
                                {{ $t('free') }}
                            </span>
                            <span v-else-if="option.price + option.actual_additional_price > 0">
                                {{ $f.formatMoney(option.price) }}
                            </span>
                        </p>
                    </label>
                </li>
            </ul>
            <div
                v-if="enableExpressShipping && expressShipping.available"
                id="cart-shipping-express"
                class="d-flex mt"
            >
                <h4>
                    Receba seu pedido até {{ expressShipping.days }} dias antes por mais
                    {{ $f.formatMoney(expressShipping.price) }}.
                    <span v-if="this.expressShipping.obs != null"
                        ><br />* Indisponível para a opção {{ expressShipping.obs }}</span
                    >
                </h4>
                <button class="btn btn-light ml-auto" @click="toggleExpressShipping()">
                    {{ expressShipping.enabled ? 'Desativar' : 'Ativar' }}
                </button>
            </div>
            <div
                v-else-if="
                    !hasPickupStore &&
                    showSpecialPriceMessage &&
                    !(
                        cepNotFound ||
                        loadingShipping ||
                        (!nationalShipping && !country) ||
                        (nationalShipping && (!cep || cep.length < 9))
                    ) &&
                    regularShipping.options.length > 0
                "
                class="alert-success mt-4"
            >
                <p>
                    Aproveite a oferta de frete reduzido para esta compra e troque seu frete grátis
                    por uma modalidade mais rápida, com desconto! 😉
                </p>
            </div>

            <div
                v-if="
                    showMessageInStock &&
                    !(
                        cepNotFound ||
                        loadingShipping ||
                        (!nationalShipping && !country) ||
                        (nationalShipping && (!cep || cep.length < 9))
                    ) &&
                    regularShipping.options.length > 0
                "
                class="alert-success mt-2 message-in-stock-cart-test-ab"
                id="message-in-stock-cart-test-ab"
            >
                <a href="/vapt-vupt" class="text-underline">
                    <p>Precisa de um prazo menor? Confira outros produtos com envio imediato</p>
                </a>
            </div>
        </div>
        <div :class="{ hidden: shippingAdditionalDeadline == null }">
            <p>Prazo adicional: {{ shippingAdditionalDeadline }} dias úteis</p>
            <p>
                Para viabilizarmos uma produção mais consciente e exclusiva, alguns produtos de seu
                pedido serão produzidos após finalizar a sua compra, por isso o prazo adicional
                acima ;)
            </p>
            <p>
                O prazo total é de até {{ shippingAdditionalDeadline }} dias úteis + prazo do frete
                escolhido.
            </p>
        </div>
        <div :class="{ hidden: !cepNotFound }" class="alert-danger mt-3">
            <span>CEP não encontrado ou indisponível para entrega.</span>
            <a
                href="http://www.buscacep.correios.com.br/sistemas/buscacep/"
                rel="noopener"
                target="_blank"
            >
                Verifique o CEP correto aqui.
            </a>
        </div>
    </div>
</template>

<script>
    import Spinner from '@bit/chicorei.chicomponents.feedback.spinner';
    import { EventBus, getScope, readCookie, setCookie, timeHandler } from '../../../common/common';
    import { carrierService, countryService } from '../../../common/service/resource';
    import Alert from '../common/Alert';
    import store from '../../common/store/checkout';
    import FreeShippingProgress from './FreeShippingProgress.vue';

    export default {
        name: 'CartShipping',

        components: { FreeShippingProgress, Alert, Spinner },

        props: [
            'enableExpressShipping',
            'cartHasPrime',
            'showMessageInStock',
            'isLateralMenu',
            'cart',
            'loading',
            'isMobile',
        ],

        data() {
            let countryId = getScope('country_id') || 245;
            countryId = parseInt(countryId);

            EventBus.$emit('national-shipping-changed', countryId === 245);

            return {
                alert: null,

                nationalShipping: countryId === 245,
                showShippingForm: false,
                showShippingDetails: false,
                PlaceHolderShipping: 'Calcular Frete e Prazo',
                loadingShipping: false,
                selectedCarrier: null,

                cep: readCookie('cep'),
                country: { id_country: countryId },
                countries: [],

                showJfMessage: null,
                showSpecialPriceMessage: false,
                hasPrimeQuotation: false,
                hasFreePrice: false,

                regularShipping: {
                    options: [],
                },
                expressShipping: {
                    available: false,
                    enabled: readCookie('express') > 0,
                    days: null,
                    price: null,
                    obs: null,
                    options: [],
                },

                shippingAdditionalDeadline: null,
                cepNotFound: false,

                freeShippingCalcObj: null,

                shakeInput: false,
            };
        },

        mounted() {
            // carrega os países
            if (!this.nationalShipping && (!this.countries || this.countries.length === 0))
                this.fetchCountries();
        },

        watch: {
            cep(to, from) {
                this.calculateShipping();
            },

            cart() {
                this.resetShipping();
            },

            showShippingForm(newValue, oldValue) {
                if (newValue && !oldValue) {
                    this.$nextTick(() => {
                        if (this.$refs.cepInput) this.$refs.cepInput.focus();
                    });
                }
            },
        },

        computed: {
            filteredShippingOptions() {
                return this.regularShipping.options
                    .filter(
                        (c) =>
                            c.fastest || c.cheapest || c.carrier_id === 81027 || c.carrier_id === 4
                    )
                    .map((c) => {
                        let name = c.name;

                        if (c.cheapest) {
                            name = 'MAIS BARATO';
                        }

                        if (c.fastest) {
                            name = c.cheapest ? 'MAIS BARATO E MAIS RÁPIDO' : 'MAIS RÁPIDO';
                        }

                        return { ...c, name };
                    });
            },

            showShippingFormLabel() {
                if (this.showShippingDetails) {
                    if (this.nationalShipping && this.cep) {
                        return 'Mudar CEP';
                    } else if (!this.nationalShipping && this.country) {
                        return 'Mudar país';
                    }
                }

                return 'Calcular frete';
            },

            changeShippingTypeLabel() {
                return this.nationalShipping
                    ? 'Sua entrega é fora do Brasil?'
                    : 'Sua entrega é no Brasil?';
            },

            destinationLabel() {
                return this.nationalShipping ? this.cep : this.country.name;
            },

            hasPickupStore() {
                return this.regularShipping.options.some((option) => option.carrier_id === 81027);
            },
        },

        created() {
            EventBus.$on('change-carrier-international', this.toggleNationalShipping);
            EventBus.$on('calculate-shipping', this.focusCepInput);
        },

        beforeDestroy() {
            EventBus.$off('change-carrier-international', this.toggleNationalShipping);
            EventBus.$off('calculate-shipping', this.focusCepInput);
        },

        methods: {
            focusCepInput() {
                this.shakeInput = true;

                this.$nextTick(() => {
                    if (this.$refs.cepInput) this.$refs.cepInput.focus();
                });

                setTimeout(() => {
                    this.shakeInput = false;
                }, 1000);
            },

            resetShipping() {
                this.regularShipping.options.length = 0;
                this.expressShipping.options.length = 0;
                this.freeShippingCalcObj = null;
                this.cepNotFound = false;
            },

            toggleShippingForm() {
                this.showShippingForm = !this.showShippingForm;
            },

            toggleExpressShipping() {
                this.expressShipping.enabled = !this.expressShipping.enabled;
                setCookie('express', this.expressShipping.enabled ? 1 : 0);
            },

            toggleNationalShipping() {
                this.nationalShipping = !this.nationalShipping;

                if (!this.nationalShipping && (!this.countries || this.countries.length === 0)) {
                    this.cep = null;
                    this.regularShipping.options.length = 0;
                    this.expressShipping.options.length = 0;
                    this.fetchCountries();
                } else {
                    this.regularShipping.options.length = 0;
                    this.expressShipping.options.length = 0;
                    this.country = this.countries.filter((c) => c.id_country === 245)[0];
                }

                EventBus.$emit('national-shipping-changed', this.nationalShipping);

                this.focusCepInput();
            },

            fetchCountries() {
                this.$emit('loading-cart');
                countryService
                    .query({ active: true, allow_shipping: 1 })
                    .then((response) => {
                        this.countries = response.data.data;
                        const countryFound = this.countries.filter(
                            (c) => c.id_country === (this.country || {}).id_country
                        );

                        if (countryFound[0]) {
                            this.country = countryFound[0];
                        }
                        this.$emit('done-update-cart');
                    })
                    .catch((error) => {
                        this.$refs.shippingAlert.shippingAlert(
                            'Não foi póssível carregar as opções de entrega',
                            true
                        );
                        this.$emit('done-update-cart');
                    });
            },

            calculateShipping(cep = null, fromInternational = false) {
                this.cep = cep ? cep : this.cep;
                setCookie('cep', this.cep);

                if (this.loadingShipping) return;

                if (
                    !this.nationalShipping &&
                    fromInternational &&
                    this.country &&
                    this.country.id_country === 245
                ) {
                    this.toggleNationalShipping();
                    return;
                }

                if (
                    (!this.nationalShipping && this.country && this.country.id_country !== 245) ||
                    (this.nationalShipping && this.cep && this.cep.length >= 9)
                ) {
                    this.loadingShipping = true;

                    this.resetShipping();

                    this.$nextTick(() => {
                        if (this.$refs.cepInput) this.$refs.cepInput.blur();
                    });

                    this.getCarriers()
                        .then((data) => {
                            if (
                                data.data.data != undefined &&
                                data.data.data.responses != undefined
                            ) {
                                let expressShippingDays = null;
                                let expressShippingPrice = null;
                                let expressShippingObs = null;
                                let additionalDeadline = 0;
                                let it = 0;
                                const responses = data.data.data.responses;

                                window.dataLayer.push({ event: 'optimize.activate_carrier' });

                                this.showJfMessage = data.data.data.is_jf;
                                this.showSpecialPriceMessage = false;
                                this.hasFreePrice = false;
                                this.hasPrimeQuotation = false;

                                this.$emit('is-jf-zip', data.data.data.is_jf);

                                for (let value of responses) {
                                    if (value.carrier_id != 3) {
                                        this.expressShipping.available = false;

                                        if (value.express !== undefined) {
                                            this.expressShipping.available = true;

                                            if (expressShippingDays < value.express.days_before)
                                                expressShippingDays = value.express.days_before;

                                            expressShippingPrice = value.express.additional_price;
                                        } else {
                                            expressShippingObs +=
                                                (expressShippingObs ? ',' : '') +
                                                (value.prime ? 'Prime' : value.name);
                                        }

                                        if (this.expressShipping.available) {
                                            this.expressShipping.options.push({
                                                isPrime: value.prime,
                                                isFree: false,
                                                actual_additional_price: value.additional_price,
                                                forecast: value.express.delivery_forecast_final,
                                                forecast_initial:
                                                    value.express.delivery_forecast_initial,
                                                name: !value.prime ? value.name : '',
                                                price: value.price,
                                            });
                                        }

                                        this.hasPrimeQuotation =
                                            this.hasPrimeQuotation || value.prime;
                                        this.hasFreePrice =
                                            this.hasFreePrice ||
                                            (value.price === 0 && value.carrier_id !== 4);
                                        this.showSpecialPriceMessage =
                                            this.showSpecialPriceMessage ||
                                            (value.price === 0 &&
                                                value.carrier_id !== 4 &&
                                                responses[0] &&
                                                !responses[0].fastest);

                                        this.regularShipping.options.push({
                                            isPrime: value.prime,
                                            deadline: value.deadline,
                                            carrier_id: value.carrier_id,
                                            actual_additional_price: 0,
                                            isFree: value.price === 0 && value.carrier_id !== 3,
                                            forecast: value.delivery_forecast_final,
                                            forecast_initial: value.delivery_forecast_initial,
                                            name: !value.prime ? value.name : '',
                                            price: value.price,
                                            store: value.store,
                                            cheapest: value.cheapest,
                                            fastest: value.fastest,
                                        });
                                        if (it === 0) {
                                            this.selectedCarrier = value;
                                        }
                                        it += 1;
                                    }

                                    if (value.additional_deadline > 99) {
                                        additionalDeadline = value.additional_deadline;
                                    }
                                }

                                this.shippingAdditionalDeadline =
                                    additionalDeadline > 99 ? additionalDeadline : null;

                                if (this.expressShipping.available) {
                                    this.expressShipping.days = expressShippingDays;
                                    this.expressShipping.price = expressShippingPrice;
                                    this.expressShipping.obs = expressShippingObs;
                                }

                                this.freeShippingCalcObj = {
                                    is_jf: data.data.data.is_jf,
                                    min_shipping: data.data.data.min_shipping,
                                    cep: data.data.data.formated_cep,
                                };

                                this.$emit('free-shipping-calc-obj', this.freeShippingCalcObj);
                            } else {
                                this.cepNotFound = true;
                            }

                            this.showShippingForm = false;
                            this.loadingShipping = false;
                            this.showShippingDetails = true;

                            this.$emit('shipping-min-value', data.data.data.min_shipping);
                        })
                        .catch((response) => {
                            this.loadingShipping = false;
                            this.showShippingForm = true;
                            this.showShippingDetails = true;

                            this.handleShippingApiError(
                                response,
                                'Não foi possível calcular as opções de entrega'
                            );
                        });
                }
            },

            getIsoFormattedFinalForecast(forecast) {
                let date = new Date(forecast);
                return this.$f.formatDate(date, this.$t('_date.ddOfMmmm'));
            },

            selectCarrier(carrier) {
                this.selectedCarrier = carrier;
                this.alert = null;
                this.$emit('success', this.quotation, this.selectedCarrier);
            },

            getCarriers() {
                setCookie('cep', this.cep);
                setCookie('country_id', this.country ? this.country.id_country : 245);

                const params = {
                    cart: getScope('cart_id'),
                    cep: this.cep,
                    pais: this.country.id_country,
                    default_group: !this.$scope.IS_REGULAR_CUSTOMER ? 3 : null,
                };

                return carrierService.query(params);
            },

            handleShippingApiError(response, defaultMessage) {
                try {
                    this.alert = {
                        message: response.response.data.message,
                        isError: true,
                        timeout: 15000,
                    };
                } catch (error) {
                    this.alert = {
                        message: defaultMessage,
                        isError: true,
                        timeout: 15000,
                    };
                }
            },
        },
    };
</script>
