<template>
    <div>
        <h3>O que é o Cashback da Chico Rei</h3>

        <p>
            Cashback é a porcentagem de valor recebido de volta após cada compra realizada no site.
            Cada produto comprado dá direto a porcentagem disponível em
            <a href="/cashback" target="_blank" class='text-underline'>https://chicorei.com/cashback</a> e na página do
            próprio produto. O cashback da Chico Rei <strong>não</strong> pode ser resgatado em dinheiro e nem
            utilizado em outras plataformas.
        </p>

        <h3>Como funciona o Cashback da Chico Rei</h3>

        <p>
            <ul>
                <li>
                    O valor do cashback gerado será calculado em cima do valor final de cada produto, ou
            seja, após a aplicação de qualquer outro desconto: promoção, cupom ou cashback recebido
            anteriormente.
                </li>
                <li>
                    O valor do cashback é variável, de acordo com as ofertas específicas
            listadas em <a href="/cashback" target="_blank" class='text-underline'>https://chicorei.com/cashback</a>.
                </li>
                <li>
                    O cashback de cada compra será informado no extrato do cliente no site, em
                    <a href="/minha-conta/cashback" target="_blank" class='text-underline'>https://chicorei.com/minha-conta/cashback</a>.
                </li>
                <li>
                    A validade do cashback é de <strong>90 dias</strong> após a
                    disponibilização do valor no extrato.
                </li>
                <li>
                    <strong>Não</strong> geram cashback os seguintes produtos: Vale-presente, Disco de Vinil e Embalagem Extra.
                </li>
                <li>
                    O valor de cashback poderá ser utilizado para comprar qualquer produto do site, exceto vale-presente.
                </li>
                <li>
                    O cashback é cumulativo com promoções do site.
                </li>
                <li>
                    O valor do frete <strong>não</strong> é contabilizado para gerar cashback.
                </li>
                <li>
                    O cashback não é aplicável para o pagamento de frete, apenas produtos.
                </li>
                <li>
                    Só é permitido utilizar o <strong>valor total</strong> do cashback disponível para realizar uma compra. Caso o
            valor da compra seja menor que o valor total do cashback, o saldo remanescente poderá
            ser utilizado em um novo pedido, desde que esteja dentro da validade de 90 dias após a
            disponibilização do valor no extrato.
                </li>
                <li>
                    O saldo do cashback poderá ser utilizado para pagar, no máximo, 30% do pedido em que for utilizado.
                </li>
                <li>
                    Na sacola de compras ou no checkout do pedido é
            preciso selecionar se deseja utilizar o saldo de cashback disponível no pagamento da
            compra.
                </li>
                <li>
                    Pedidos que forem alterados após a confirmação da compra sofrerão reajuste do
                    cashback, de acordo com as regras de quando o pedido foi realizado.
                </li>
            </ul>
        </p>

        <h3>Como recebo meu cashback</h3>

        <p>
            Após a confirmação da compra, o cashback ficará pendente e aparecerá em seu extrato com
            o status de <strong>“aguardando confirmação”</strong>. O status será alterado para <strong>disponível</strong> no 10º dia
            após a entrega do pedido que gerou o valor. Para consultar o extrato acesse
            <a href="/minha-conta/cashback" target="_blank" class='text-underline'>https://chicorei.com/minha-conta/cashback</a>.
        </p>

        <h3>Cashback em Trocas e Devoluções</h3>

        <p>
            A troca de produtos comprados com saldo de cashback pode ser realizada normalmente,
            observando cada modalidade (troca por tamanho, defeito ou produto incorreto; troca por
            cupom; devolução):
        </p>

        <p>
            <ul>
                <li>
                    Troca por tamanho, defeito ou produto incorreto<br/>
                    Ao realizar a troca, o valor do cashback recebido no pedido original será suspenso do extrato e reativado no novo pedido gerado pela troca.
                </li>
                <li>
                    Troca por cupom<br/>
                    Ao realizar a troca por cupom, o valor de cashback gerado no pedido original será cancelado. Ao aplicar o cupom derivado da troca em uma nova compra, o novo cashback será gerado normalmente.
                </li>
                <li>
                    Devolução<br/> Em caso de devolução, o valor do cashback referente ao produto devolvido será cancelado.
                </li>
            </ul>
        </p>
        <p>
            Em caso de troca ou devolução parcial de produto, o valor do cashback proporcional ao
            produto devolvido será reembolsado (e o estorno do valor proporcional ao produto pago
            será feito normalmente).
        </p>
    </div>
</template>

<script>
    export default {
        name: 'CashbackTerms',
    };
</script>
