<template>
    <div v-if="sizesQuantities" id="product-wholesaler-sizes" class="d-flex flex-spaced-fixed">
        <SelectField
            v-if="isPhone"
            v-model="selectedPhoneModel"
            class="flex-child-fixed"
            :label="$t('_product.manufacturer')"
        >
            <option :value="null">
                {{ $t('_product.selectManufacturer') }}
            </option>
            <option v-for="model in phoneModels" :value="model">
                {{ model }}
            </option>
        </SelectField>
        <SelectField
            v-model="selectedSize"
            :disabled="isPhone && !selectedPhoneModel"
            :label="isPhone ? $t('_product.phoneModel') : ''"
            class="flex-child-fixed"
        >
            <option v-if="isPhone" :value="null">
                {{ $t('_product.selectPhoneModel') }}
            </option>
            <option v-else :value="null">
                {{ $t('_product.selectSize') }}
            </option>
            <option v-for="item in selectOptions" :disabled="isSizeDisabled(item)" :value="item">
                {{ item.size_name }}
                <span v-if="!product.in_stock || (!item.virtual_stock && item.quantity === 0)"
                    >&nbsp;-&nbsp;{{ $t('_shop._stock.soldOut') }}</span
                >
                <span v-else>&nbsp;- {{ getItemProductionType(item) }}</span>
                <span v-if="product.price_min > 0">
                    &nbsp;-&nbsp;
                    <span
                        v-if="item.price_old"
                        v-html="
                            $t('_shop._price.fromToHtml', [
                                $f.formatMoney(item.price_old),
                                $f.formatMoney(item.price),
                            ])
                        "
                    />
                    <span v-else-if="item.price">{{ $f.formatMoney(item.price) }}</span>
                    <span v-else>{{ $f.formatMoney(product.price_old) }}</span>
                </span>
            </option>
        </SelectField>

        <div v-if="selectedSize" class="form-group flex-child-fixed">
            <label>{{ $t('quantity') }}:</label>
            <ProductWholesalerSizeField
                v-model="sizesQuantities[selectedSize.variation_id]"
                :product="product"
                :size="selectedSize"
            />
        </div>
    </div>
</template>

<script>
    import ProductWholesalerSizes from './ProductWholesalerSizes';
    import ProductWholesalerSizeField from './wholesaler/ProductWholesalerSizeField';
    import SelectField from '@bit/chicorei.chicomponents.input.select-field';
    import NumberField from '@bit/chicorei.chicomponents.input.number-field';

    export default {
        extends: ProductWholesalerSizes,

        name: 'ProductWholesalerSingleSize',

        components: { ProductWholesalerSizeField, NumberField, SelectField },

        data() {
            return {
                selectedSize: null,
            };
        },

        watch: {
            selectedPhoneModel() {
                if (this.isPhone) {
                    this.selectedSize = null;
                }
            },

            selectedSize() {
                for (let variationId in this.sizesQuantities) {
                    if (this.sizesQuantities.hasOwnProperty(variationId)) {
                        this.sizesQuantities[variationId] = 0;
                    }
                }
            },
        },

        methods: {
            isSizeDisabled(size) {
                return !this.product.in_stock || (size.quantity === 0 && !size.virtual_stock);
            },
        },
    };
</script>
