var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:{ show: _vm.open && _vm.actualTab },attrs:{"id":"sidebar-overlay"},on:{"click":_vm.hide}}),_vm._v(" "),_c('div',{ref:"sidebarWrapper",class:{ open: _vm.open && _vm.actualTab },attrs:{"id":"sidebar-content"}},[_c('div',{staticClass:"pos-fixed side-title",style:({ width: _vm.sidebarWidth })},[_c('div',{staticClass:"d-flex flex-justify-between flex-align-center"},[(_vm.actualTab)?_c('div',{staticClass:"d-flex flex-align-center"},[(_vm.actualTab.icon)?_c('span',{staticClass:"icon-cr mr-2",class:_vm.actualTab.icon}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"pt-1 text-uppercase",class:{
                            'text-bold':
                                _vm.$scope.CUSTOMER &&
                                _vm.actualTab &&
                                _vm.actualTab.component === 'SideMyaccount',
                        }},[_vm._v(_vm._s(_vm.actualTab.title))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex-align-center pt-2"},[_c('a',{staticClass:"ml-4",on:{"click":_vm.hide}},[_c('i',{staticClass:"icon-cr icon-close"})])])]),_vm._v(" "),(
                    _vm.actualTab &&
                    _vm.actualTab.component === 'SideMyaccount' &&
                    _vm.$scope.CUSTOMER &&
                    _vm.$scope.cashback_available &&
                    _vm.$scope.IS_REGULAR_CUSTOMER
                )?_c('div',{staticClass:"cashback-text"},[_vm._v("\n                cashback disponível:"),_c('span',{staticClass:"cashback-value"},[_vm._v("\n                    "+_vm._s(_vm.$f.formatMoney(_vm.$scope.cashback_available)))])]):_vm._e()]),_vm._v(" "),(_vm.actualTab)?_c(_vm.actualTab.component,{tag:"component",class:[
                _vm.actualTab.component === `SideMyaccount`
                    ? 'side-real-content-my-account'
                    : 'side-real-content',
            ],attrs:{"is-mobile":_vm.isMobile,"is-lateral-menu":true}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }