<template>
    <div>
        <div v-if="!this.isMobile">
            <ContadorDesktopV1 v-if="model === 'v1'" />
            <ContadorDesktopV2 v-if="model === 'v2'" />
        </div>
        <div v-if="this.isMobile">
            <ContadorMobileV1 v-if="model === 'v1'" />
            <ContadorMobileV2 v-if="model === 'v2'" />
        </div>
    </div>
</template>

<script>
    import ContadorDesktopV1 from './ContadorDesktopV1.vue';
    import ContadorDesktopV2 from './ContadorDesktopV2.vue';
    import ContadorMobileV1 from './ContadorMobileV1.vue';
    import ContadorMobileV2 from './ContadorMobileV2.vue';
    import { resizeOrientationAware } from '../../../common/common';

    export default {
        name: 'Contador',
        components: { ContadorDesktopV1, ContadorDesktopV2, ContadorMobileV1, ContadorMobileV2 },
        props: {
            model: { type: String, default: 'v1' },
        },
        data() {
            return {
                isMobile: false,
                resizeAwareRemoveCb: null,
            };
        },

        mounted() {
            this.resizeAwareRemoveCb = resizeOrientationAware(() => {
                this.isMobile = window.innerWidth < 768;
            }, true);
        },

        beforeDestroy() {
            this.resizeAwareRemoveCb();
        },

        methods: {},
    };
</script>
