const pinchZoom = (imageElement, initialScale) => {
    let start = {};
    let posX = 0;
    let posY = 0;
    let last_posX = 0;
    let last_posY = 0;
    let max_pos_x = 0;
    let max_pos_y = 0;
    let scale = initialScale;
    let last_scale = initialScale;

    // Calcula a distância entre os dois dedos (coordenadas {x , y})
    function getDistance(p1, p2) {
        let x = p2.pageX - p1.pageX,
            y = p2.pageY - p1.pageY;

        return Math.sqrt(x * x + y * y);
    }

    imageElement.addEventListener('touchstart', (event) => {
        event.preventDefault();
        start.x = event.touches[0].pageX;
        start.y = event.touches[0].pageY;
        if (event.touches.length === 2) {
            start.x = (event.touches[0].pageX + event.touches[1].pageX) / 2;
            start.y = (event.touches[0].pageY + event.touches[1].pageY) / 2;
            start.distance = getDistance(event.touches[0], event.touches[1]);
        }
    });

    imageElement.addEventListener('touchmove', (event) => {
        let deltaX = event.touches[0].pageX - start.x;
        let deltaY = event.touches[0].pageY - start.y;

        //pan
        posX = last_posX + deltaX;
        posY = last_posY + deltaY;

        if (imageElement.parentElement.offsetWidth < scale * imageElement.offsetWidth) {
            let parent_max_pos_x = Math.max(
                0,
                (scale * imageElement.offsetWidth - imageElement.parentElement.offsetWidth) / 2
            );
            let image_max_pos_x = Math.ceil(
                (Math.abs(scale - initialScale) * imageElement.offsetWidth) / 2
            );
            max_pos_x = Math.min(parent_max_pos_x, image_max_pos_x);
        } else {
            max_pos_x = 0;
        }

        if (imageElement.parentElement.offsetHeight < scale * imageElement.offsetHeight) {
            let parent_max_pos_y = Math.max(
                0,
                (scale * imageElement.offsetHeight - imageElement.parentElement.offsetHeight) / 2
            );
            let image_max_pos_y = Math.ceil(
                (Math.abs(scale - initialScale) * imageElement.offsetHeight) / 2
            );
            max_pos_y = Math.min(parent_max_pos_y, image_max_pos_y);
        } else {
            max_pos_y = 0;
        }

        if (posX > max_pos_x) {
            posX = max_pos_x;
        }
        if (posX < -max_pos_x) {
            posX = -max_pos_x;
        }
        if (posY > max_pos_y) {
            posY = max_pos_y;
        }
        if (posY < -max_pos_y) {
            posY = -max_pos_y;
        }

        if (event.touches.length === 2) {
            event.preventDefault(); // Prevent page scroll

            // Seta distância percorrida entre os dois touchs como um multiplicador para escala da imagem
            // No IOS isso é feito automaticamente segundo: https://developer.apple.com/documentation/webkitjs/touchevent/1632169-scale
            if (event.scale) {
                scale = event.scale;
            } else {
                const deltaDistance = getDistance(event.touches[0], event.touches[1]);
                scale = deltaDistance / start.distance;
                scale = Math.max(initialScale, last_scale * scale);
            }
        }

        // Transforma imagem baseado nos cálculos
        const transform =
            `translate3d(calc(-${50}% + ${posX}px), calc(-${50}%  + ${posY}px), 0) ` +
            `scale3d(${scale}, ${scale}, 1)`;

        if (transform) {
            imageElement.style.transform = transform;
            imageElement.style.WebkitTransform = transform;
        }
    });

    // Atribui valores quando o touch acaba (se não a imagem resetaria a cada movimento)
    imageElement.addEventListener('touchend', (event) => {
        event.preventDefault();
        last_posX = posX < max_pos_x ? posX : max_pos_x;
        last_posY = posY < max_pos_y ? posY : max_pos_y;
        last_scale = scale;
    });
};

export default pinchZoom;
