var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[(!this.hidePopup && !(this.isMobile && this.poupup))?_c('div',{class:[
                this.poupup ? 'countdown-popup' : '',
                this.poupup && this.params.not_countdown ? 'not_countdown' : '',
                this.params.img_popup ? 'count-popup-img' : '',
            ],style:({
                'background-color':
                    this.isMobile || this.poupup ? '#fff' : this.params.background_color,
            }),attrs:{"id":"contador"}},[(this.params.img_popup)?_c('a',{attrs:{"href":this.link}},[_c('img',{staticClass:"lazy lazy-fade",attrs:{"data-src":this.params.img_popup}})]):_vm._e(),_vm._v(" "),_c('div',{class:(this.isMobile || this.poupup ? 'mobile' : 'desktop') +
                    (this.viewButton ? '-v2' : '-v1'),attrs:{"id":"contador-content"}},[(!this.params.img_popup)?_c('div',{attrs:{"id":this.isMobile || this.poupup ? 'contador-mobile-wrapper-primary' : ''}},[_c('span',{style:({
                            color:
                                this.isMobile || this.poupup
                                    ? '#201f1e'
                                    : this.params.text_color,
                        }),attrs:{"id":"contador-title"},domProps:{"innerHTML":_vm._s(this.offerTitle)}}),_vm._v(" "),(this.poupup && this.params.not_countdown)?_c('a',{staticClass:"cursor-pointer",staticStyle:{"position":"absolute","color":"#797978","top":"0.25rem","right":"0.5rem","font-weight":"700"},on:{"click":function($event){return _vm.closeCountdown()}}},[_vm._v("X")]):_vm._e(),_vm._v(" "),((this.isMobile || this.poupup) && this.discount)?_c('div',{attrs:{"id":"contador-coupon"}},[_c('span',{style:({ color: '#201f1e' }),attrs:{"id":"contador-coupon-text"}},[_vm._v("Use o cupom:")]),_vm._v(" "),_c('div',{attrs:{"id":"coupon-wrapper"}},[_c('span',{class:{ 'coupon-smaller': _vm.discount.name.length > 10 },attrs:{"id":"coupon-wrapper-text"}},[_vm._v(_vm._s(this.discount.name))])]),_vm._v(" "),(!this.viewButton && this.params.regulation)?_c('a',{staticClass:"mt-3",style:({ color: '#201f1e' }),on:{"click":function($event){_vm.showRegulation = true}}},[_vm._v("\n                            Ver regras\n                        ")]):_vm._e()]):_vm._e()]):_vm._e(),_vm._v(" "),(!this.poupup || !this.params.not_countdown)?_c('div',{class:[this.params.img_popup ? 'count-popup-full' : ''],style:({
                        'background-color':
                            this.isMobile || this.poupup
                                ? this.params.background_color_mobile
                                : '',
                    }),attrs:{"id":this.isMobile || this.poupup ? 'contador-mobile-wrapper-secondary' : ''}},[_c('a',{staticClass:"cursor-pointer",staticStyle:{"position":"absolute","color":"#797978","top":"0.25rem","right":"0.5rem","font-weight":"700"},on:{"click":function($event){return _vm.closeCountdown()}}},[_vm._v("X")]),_vm._v(" "),_c('div',{attrs:{"id":"contador-timer"}},[_c('span',{style:({
                                color:
                                    this.isMobile || this.poupup
                                        ? this.params.text_color_mobile
                                        : this.params.text_color,
                            }),attrs:{"id":"contador-timer-text"}},[_vm._v("Acaba em:")]),_vm._v(" "),_c('div',{attrs:{"id":"contador-timer-clock"}},[_c('div',{staticClass:"clock-section",attrs:{"id":"hours"}},[_c('span',{staticClass:"clock-number",style:({
                                        'background-color':
                                            this.isMobile || this.poupup
                                                ? this.params.background_accent_color_mobile
                                                : this.params.background_accent_color,
                                        color:
                                            this.isMobile || this.poupup
                                                ? this.params.text_color_counter_mobile
                                                : this.params.text_color_counter,
                                    })},[_vm._v(_vm._s(_vm.hours >= 10 ? _vm.hours.toString().substring(0, 1) : 0))]),_vm._v(" "),_c('span',{staticClass:"clock-number",style:({
                                        'background-color':
                                            this.isMobile || this.poupup
                                                ? this.params.background_accent_color_mobile
                                                : this.params.background_accent_color,
                                        color:
                                            this.isMobile || this.poupup
                                                ? this.params.text_color_counter_mobile
                                                : this.params.text_color_counter,
                                    })},[_vm._v(_vm._s(_vm.hours >= 10
                                            ? _vm.hours.toString().substring(1, 2)
                                            : _vm.hours.toString().substring(0, 1)))]),_vm._v(" "),_c('span',{staticClass:"clock-marker",style:({
                                        color:
                                            this.isMobile || this.poupup
                                                ? this.params.text_color_mobile
                                                : this.params.text_color,
                                    })},[_vm._v("h")])]),_vm._v(" "),_c('div',{staticClass:"clock-section",attrs:{"id":"minutes"}},[_c('span',{staticClass:"clock-number",style:({
                                        'background-color':
                                            this.isMobile || this.poupup
                                                ? this.params.background_accent_color_mobile
                                                : this.params.background_accent_color,
                                        color:
                                            this.isMobile || this.poupup
                                                ? this.params.text_color_counter_mobile
                                                : this.params.text_color_counter,
                                    })},[_vm._v(_vm._s(_vm.minutes >= 10 ? _vm.minutes.toString().substring(0, 1) : 0))]),_vm._v(" "),_c('span',{staticClass:"clock-number",style:({
                                        'background-color':
                                            this.isMobile || this.poupup
                                                ? this.params.background_accent_color_mobile
                                                : this.params.background_accent_color,
                                        color:
                                            this.isMobile || this.poupup
                                                ? this.params.text_color_counter_mobile
                                                : this.params.text_color_counter,
                                    })},[_vm._v(_vm._s(_vm.minutes >= 10
                                            ? _vm.minutes.toString().substring(1, 2)
                                            : _vm.minutes.toString().substring(0, 1)))]),_vm._v(" "),_c('span',{staticClass:"clock-marker",style:({
                                        color:
                                            this.isMobile || this.poupup
                                                ? this.params.text_color_mobile
                                                : this.params.text_color,
                                    })},[_vm._v("m")])]),_vm._v(" "),_c('div',{staticClass:"clock-section",attrs:{"id":"seconds"}},[_c('span',{staticClass:"clock-number",style:({
                                        'background-color':
                                            this.isMobile || this.poupup
                                                ? this.params.background_accent_color_mobile
                                                : this.params.background_accent_color,
                                        color:
                                            this.isMobile || this.poupup
                                                ? this.params.text_color_counter_mobile
                                                : this.params.text_color_counter,
                                    })},[_vm._v(_vm._s(_vm.seconds >= 10 ? _vm.seconds.toString().substring(0, 1) : 0))]),_vm._v(" "),_c('span',{staticClass:"clock-number",style:({
                                        'background-color':
                                            this.isMobile || this.poupup
                                                ? this.params.background_accent_color_mobile
                                                : this.params.background_accent_color,
                                        color:
                                            this.isMobile || this.poupup
                                                ? this.params.text_color_counter_mobile
                                                : this.params.text_color_counter,
                                    })},[_vm._v(_vm._s(_vm.seconds >= 10
                                            ? _vm.seconds.toString().substring(1, 2)
                                            : _vm.seconds.toString().substring(0, 1)))]),_vm._v(" "),_c('span',{staticClass:"clock-marker",style:({
                                        color:
                                            this.isMobile || this.poupup
                                                ? this.params.text_color_mobile
                                                : this.params.text_color,
                                    })},[_vm._v("s")])])])]),_vm._v(" "),(this.isMobile || (this.poupup && this.viewButton))?_c('a',{style:(this.poupup ? 'width: 100%' : ''),attrs:{"href":this.link + '?cd_off=1'}},[_c('div',{style:({
                                'background-color': this.params.text_color_mobile,
                                color: this.params.background_color_mobile,
                            }),attrs:{"id":"contador-button"}},[_c('span',{attrs:{"id":"contador-button-text"}},[_vm._v("Confira")])])]):_vm._e()]):_vm._e(),_vm._v(" "),(!this.isMobile && !this.poupup && this.discount)?_c('div',{attrs:{"id":"contador-coupon"}},[_c('div',{attrs:{"id":this.viewButton ? 'coupon-content-wrapper' : ''}},[_c('span',{style:({
                                color: this.isMobile
                                    ? this.params.text_color_mobile
                                    : this.params.text_color,
                            }),attrs:{"id":"contador-coupon-text"}},[_vm._v("Use o cupom:")]),_vm._v(" "),_c('div',{style:({
                                'border-color': this.isMobile
                                    ? this.params.text_color_mobile
                                    : this.params.text_color,
                            }),attrs:{"id":"coupon-wrapper"}},[_c('span',{style:({
                                    color: this.isMobile
                                        ? this.params.text_color_mobile
                                        : this.params.text_color,
                                }),attrs:{"id":"coupon-wrapper-text"}},[_vm._v(_vm._s(this.discount.name))])]),_vm._v(" "),(!this.viewButton && this.params.regulation)?_c('a',{staticClass:"mt-3 text-underline",style:({ color: this.params.text_color }),on:{"click":function($event){_vm.showRegulation = true}}},[_vm._v("Ver regras")]):_vm._e()]),_vm._v(" "),(this.viewButton)?_c('a',{attrs:{"href":this.link + '?cd_off=1'}},[_c('div',{style:({
                                'background-color':
                                    this.isMobile || this.poupup
                                        ? this.params.text_color_mobile
                                        : this.params.text_color,
                                color:
                                    this.isMobile || this.poupup
                                        ? this.params.background_color_mobile
                                        : this.params.background_color,
                            }),attrs:{"id":"contador-button"}},[_c('span',{attrs:{"id":"contador-button-text"}},[_vm._v("Confira")])])]):_vm._e()]):_vm._e()])]):_vm._e(),_vm._v(" "),(this.params.regulation)?_c('Modal',{staticClass:"modal-regulamento",model:{value:(_vm.showRegulation),callback:function ($$v) {_vm.showRegulation=$$v},expression:"showRegulation"}},[_c('div',{staticClass:"card-block"},[_c('h2',[_vm._v("Regulamento")]),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(this.params.regulation)}})]),_vm._v(" "),_c('div',{staticClass:"card-block text-right"},[_c('button',{staticClass:"btn",attrs:{"type":"button"},on:{"click":function($event){_vm.showRegulation = false}}},[_vm._v("\n                    Fechar\n                ")])])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }