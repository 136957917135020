<template>
    <div id="product-labels" v-if="items && items.length > 0">
        <div v-for="item in items">
            <div class="text-center">
                <img
                    :src="[item.src]"
                    :alt="[item.alt]"
                    v-tooltip="'Produto vegano'"
                    class="mt-2"
                />
            </div>
        </div>
        <hr class="hidden-xs-up" />
    </div>
</template>

<script>
    export default {
        name: 'ProductLabels',

        data() {
            const data = { items: [] };

            if (this.$scope.ELASTIC_PRODUCT.is_clothing) {
                data.items.push({
                    src: this.$scope.CDN_IMAGES + '/images/site/label-vegan.png',
                    alt: this.$t('_shop._labels.vegan'),
                });
            }

            return data;
        },
    };
</script>
