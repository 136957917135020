// Declaração das constantes de causa de troca para facilitar o entendimento e import
const CAUSE_SIZE = 1; // Tamanho
const CAUSE_MODEL = 2; // Modelo
const CAUSE_DEFECT = 3; // Defeito
const CAUSE_WRONG_PRODUCTS = 4; // Envio Errado
const CAUSE_RETURN = 5; // Devolução
const CAUSE_GAVE_UP = 6; // Desistência
export default {
    CAUSE_SIZE: CAUSE_SIZE,
    CAUSE_MODEL: CAUSE_MODEL,
    CAUSE_DEFECT: CAUSE_DEFECT,
    CAUSE_WRONG_PRODUCTS: CAUSE_WRONG_PRODUCTS,
    CAUSE_RETURN: CAUSE_RETURN,
    CAUSE_GAVE_UP: CAUSE_GAVE_UP,
};
