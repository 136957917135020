<template>
    <div v-if="products && products.length > 0" class="content" id="products-history-ab">
        <h2 class="h6 text-bold text-default-5 mb">
            <span class="hidden-xs-down">
                <span v-if="$scope.CUSTOMER">{{ $scope.CUSTOMER.FIRST_NAME }},</span>
                {{ $t('history.lastSeenLong') }}
            </span>

            <span class="hidden-xs-up">{{ $t('history.lastSeen') }}</span>
        </h2>
        <ProductList
            small
            id="history-list"
            :item-options="itemOptions"
            :products="products"
            event-name="Histórico de produtos"
            list-class="product-horizontal-list product-horizontal-list-8 no-scrollbar"
            @scroll="scrollEvent = $event"
        />
        <HorizontalScrollbar
            v-if="products.length > 2"
            :scroll-event="scrollEvent"
            class="hidden-not-touch"
            :id-list="'history-list'"
        />
    </div>
</template>

<script>
    import { updateLazyLoad } from '../../../common/common';
    import HorizontalScrollbar from './container/HorizontalScrollbar';
    import ProductList from './ProductList';

    export default {
        name: 'History',

        components: { HorizontalScrollbar, ProductList },

        props: {
            stylizeTitle: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                scrollEvent: null,
                products: null,
                itemOptions: {
                    hideInstallments: true,
                    displayPrice: false,
                    displayModel: true,
                    displayPercent: false,
                    displayName: false,
                    hidePromoBadge: true,
                },
                sliderOptions: {
                    slidesPerView: 2,
                    enableBullets: true,
                    fitSlidesToParent: true,
                    onNavigate: () => updateLazyLoad(),
                    responsive: [
                        {
                            minWidth: 768,
                            settings: {
                                slidesPerView: 4,
                            },
                        },
                        {
                            minWidth: 992,
                            settings: {
                                slidesPerView: 7,
                            },
                        },
                    ],
                },
            };
        },

        created() {
            this.fetch();
        },

        methods: {
            fetch() {
                this.axios.http
                    .get('/history.php', {
                        params: { count: 8 },
                    })
                    .then((response) => {
                        if (response.data.products) this.products = response.data.products.data;
                    })
                    .catch((error) => console.error(error));
            },
        },
    };
</script>
