<template>
    <Modal :value="value" @input="$emit('input', $event)">
        <template v-slot:title>Pronta-entrega</template>
        <div class="card-block">
            <p>
                Nossas camisetas são <strong>produzidas sob demanda</strong>, ou seja, de acordo com
                que os pedidos são realizados.
            </p>
            <p>
                Porém, ao marcar a caixinha para exibir somente opções em pronta-entrega, você
                poderá escolher dentre os tamanhos que já temos prontos em nosso estoque, o que
                significa um
                <strong>prazo de entrega menor para você</strong> :)
            </p>
        </div>
    </Modal>
</template>

<script>
    import Modal from '../common/container/Modal';

    export default {
        name: 'DeliverReadyInfoModal',
        components: { Modal },
        props: {
            value: {
                default: false,
            },
        },
    };
</script>
