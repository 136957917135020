var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$scope.IS_WHOLESALER || _vm.$scope.IS_SALES_REP)?_c('p',{staticClass:"product-list-item-price"},[_vm._v("\n        R$"+_vm._s(_vm.formatPrice(_vm.product.price))+"\n    ")]):(_vm.$scope.IS_REGULAR_CUSTOMER)?[(_vm.isCombo)?[_c('p',{staticClass:"text-bold"},[_vm._v("\n                "+_vm._s(_vm.$f.formatMoney(_vm.product.price))+"\n                "),(_vm.isCombo)?_c('span',[_vm._v(_vm._s(_vm.$t('or').toLowerCase()))]):_vm._e()]),_vm._v(" "),_c('p',{staticClass:"product-list-item-price text-primary",class:{ 'edit-iOS': _vm.isIOS }},[_c('span',{attrs:{"content":"BRL","itemprop":"priceCurrency"}},[_vm._v(" R$ ")]),_vm._v(" "),_c('span',{attrs:{"content":_vm.product.promo_combo_unit_price,"itemprop":"price"}},[_vm._v("\n                    "+_vm._s(_vm.formatPrice(_vm.product.promo_combo_unit_price))+"\n                ")])]),_vm._v(" "),_c('p',{staticClass:"text-info-4"},[_vm._v(_vm._s(_vm.product.promo_instruction))])]:[_c('div',{staticClass:"d-flex flex-align-center flex-justify-start"},[(_vm.hasDiscount && (!_vm.options || !_vm.options.hidePriceOld))?_c('p',[_c('del',[_vm._v("R$ "+_vm._s(_vm.formatPrice(_vm.product.price_old)))])]):_vm._e(),_vm._v(" "),(_vm.product.price_min > 0 && _vm.product.price_min < _vm.product.price_max)?_c('div',[_c('p',{staticClass:"text-bold text-secondary"},[_vm._v("A partir de")]),_vm._v(" "),_c('p',{staticClass:"product-list-item-price text-default-5",class:{ 'ml-3': _vm.hasDiscount, 'edit-iOS': _vm.isIOS }},[_c('span',{attrs:{"content":"BRL","itemprop":"priceCurrency"}},[_vm._v(" R$ ")]),_vm._v(" "),_c('span',{attrs:{"content":_vm.product.price_min,"itemprop":"price"}},[_vm._v("\n                            "+_vm._s(_vm.formatPrice(_vm.product.price_min))+"\n                        ")])])]):_c('p',{staticClass:"product-list-item-price text-default-5",class:{
                        'ml-3': _vm.hasDiscount && (!_vm.options || !_vm.options.hidePriceOld),
                        'edit-iOS': _vm.isIOS,
                    }},[_c('span',{attrs:{"content":"BRL","itemprop":"priceCurrency"}},[_vm._v(" R$ ")]),_vm._v(" "),_c('span',{attrs:{"content":_vm.product.price,"itemprop":"price"}},[_vm._v("\n                        "+_vm._s(_vm.formatPrice(_vm.product.price))+"\n                    ")])]),_vm._v(" "),(
                        (!_vm.options || !_vm.options.hideInstallments) &&
                        _vm.product.price_min == _vm.product.price_max &&
                        _vm.product.price >= 20 &&
                        !_vm.hasDiscount &&
                        _vm.isMobile
                    )?_c('p',{staticClass:"ml-4 text-default-3 hidden-xs"},[_vm._v("\n                    4x "+_vm._s(_vm.$f.formatMoney(_vm.product.price / 4))+"\n                ")]):(
                        (!_vm.options || !_vm.options.hideInstallments) &&
                        _vm.product.price_min == _vm.product.price_max &&
                        _vm.product.price >= 20 &&
                        !_vm.isMobile
                    )?_c('p',{staticClass:"ml-4 text-default-3 hidden-xs"},[_vm._v("\n                    4x "+_vm._s(_vm.$f.formatMoney(_vm.product.price / 4))+"\n                ")]):_vm._e()])]]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }