import Vue from '../vue/common/vue-common';
import History from '../vue/components/common/History';
import Lazy from '../vue/components/common/Lazy';
import general from '../vue/i18n/general';
import locale from '../vue/i18n/locale';
import './../vue/header-menu';
import {
    addDataLayer,
    EventBus,
    getScope,
    readCookie,
    readUrlQuery,
    reamazeClickHandler,
    setCookie,
} from './common';
import './header';
import './polyfill';
import { affiliatesPopup, surveyPopup } from './popups';
import PopupsManager from './popups-manager';
import cr$ from './crquery';
import VueMask from 'v-mask';
import HeaderSidebar from '../vue/components/header/HeaderSidebar.vue';
import LazyLoad from 'vanilla-lazyload';
import FooterNewsletter from '../vue/components/footer/FooterNewsletter.vue';
import FooterContact from '../vue/components/footer/FooterContact.vue';
import throttle from 'lodash/throttle';
import { CLOSE_DESKTOP_MENU_EVENT } from '../vue/events';
import { initModals } from './cr-components';

Vue.use(VueMask);

window.cr$ = cr$;

PopupsManager.add(affiliatesPopup);
PopupsManager.add(surveyPopup);

cr$.ready(function () {
    // LGPD
    const POLICY_VERSION = 'v1';
    const confirmedPolicyVersion = readCookie('_chPolicy');
    const policyInfoPopUp = cr$.byId('policyInfoPopUp');

    if (policyInfoPopUp.exists() && confirmedPolicyVersion !== POLICY_VERSION) {
        policyInfoPopUp.css('display', 'block');

        cr$.byId('confirmPolicyButton').click(() => {
            policyInfoPopUp.css('display', 'none');
            let d = new Date();
            d.setTime(d.getTime() + 25 * 30 * 24 * 60 * 60 * 1000);
            setCookie('_chPolicy', POLICY_VERSION, d.toUTCString(), '/');
        });
    }

    window.dataLayer = window.dataLayer || [];

    let customerId = getScope('id');
    let hashEmail = getScope('email_hash');
    let email = getScope('email');
    let userName = getScope('user_name');
    let userAvatar = getScope('user_avatar');
    let reamazeAuthKey = getScope('reamaze_authKey');
    let isRegularCustomer = getScope('is_regular_customer');
    let isSalesRep = getScope('is_sales_rep');

    if (isRegularCustomer) {
        const historyEl = cr$.byId('product-history-list-container');
        if (historyEl.exists()) {
            requestIdleCallback(() => {
                new Vue({
                    el: historyEl.raw,
                    i18n: locale(general()),
                    data() {
                        return {
                            stylizeTitle: window.location.pathname !== '/',
                        };
                    },
                    template: `
                        <Lazy>
                            <History :stylize-title="stylizeTitle"/>
                        </Lazy>`,
                    components: { Lazy, History },
                });
            });
        }
    }

    addDataLayer(
        customerId,
        email,
        hashEmail,
        userName,
        userAvatar,
        reamazeAuthKey,
        !isRegularCustomer ? 2 : 1
    );

    if (customerId) {
        requestIdleCallback(() => {
            cr$.byClass('logged').removeClass('hidden');
            cr$.byClass('desloged').addClass('hidden');
            if (!isRegularCustomer) {
                cr$.byClass('hidden-wholesaler').addClass('hidden');
                cr$.byClass('visible-wholesaler').removeClass('hidden');
            }
            if (isSalesRep) {
                cr$.byClass('hidden-sales-rep').addClass('hidden');
                cr$.byClass('visible-sales-rep').removeClass('hidden');
            }
            const userNameElement = cr$.byId('header-user-name').raw;
            if (
                userNameElement &&
                (!userNameElement.innerText || userNameElement.innerText.length === 0)
            ) {
                userNameElement.innerText = decodeURI(userName).split('+').join(' ');
            }
        });
    }

    cr$.byId('top-promo-image').click(function () {
        const el = cr$.byEl(this),
            name = el.attr('data-name');

        if (name && name.length > 0)
            dataLayer.push({
                event: 'promotionClick',
                ecommerce: {
                    promoClick: {
                        promotions: [
                            {
                                id: el.attr('data-id'), // Name or ID is required.
                                name: name,
                                creative: el.attr('data-creative'),
                                position: el.attr('data-position'),
                            },
                        ],
                    },
                },
            });
    });

    // Sidebar init
    requestIdleCallback(() => {
        if (cr$.byId('header-sidebar').exists()) {
            new Vue(HeaderSidebar);
        }
    });

    // Sidebar cart open
    cr$.byClass('button-open-cart').click(function (e) {
        EventBus.$emit('open-sidebar', 'cart');
    });

    // Sidebar search open
    cr$.byClass('button-open-search').click(function (e) {
        EventBus.$emit('open-sidebar', 'search');
    });

    // Siderbar login open
    let alreadyAddedScripts = false;
    cr$.byId('button-open-login').click(function (e) {
        if (!alreadyAddedScripts) {
            alreadyAddedScripts = true;

            let scriptElementFacebook = document.createElement('script');
            scriptElementFacebook.setAttribute(
                'src',
                'https://connect.facebook.net/pt_BR/sdk.js#xfbml=1&version=v3.3&appId=416962708352552'
            );
            scriptElementFacebook.setAttribute('type', 'text/javascript');
            scriptElementFacebook.setAttribute('crossorigin', 'anonymous');
            document.body.appendChild(scriptElementFacebook);
        }

        let scriptElementGmail = document.createElement('script');
        scriptElementGmail.setAttribute('src', 'https://accounts.google.com/gsi/client?hl=pt');
        scriptElementGmail.setAttribute('type', 'text/javascript');
        document.body.appendChild(scriptElementGmail);

        e.stopPropagation();
        e.preventDefault();

        EventBus.$emit('open-sidebar', 'login');
    });

    // Sidebar my account open
    cr$.byId('button-open-my-account').click(function (e) {
        EventBus.$emit('open-sidebar', 'my-account');
    });

    cr$.byId('button-open-about').click(function (e) {
        EventBus.$emit('open-sidebar', 'about');
    });

    // Shipping Rules Home open
    cr$.byId('button-open-free-shipping').click(function (e) {
        EventBus.$emit('open-shipping-rules', 'shipping-rules');
    });

    // Bag Count Products
    EventBus.$on('cart-products-count-changed', (newCout) => {
        cr$.byClass('header-cart-counter').each((el) => {
            const element = cr$.byEl(el);
            element.html(newCout);

            if (newCout > 0) {
                element.show();
            } else {
                element.hide();
            }
        });
    });

    // Reamaze click
    const reamazeElm = cr$.byId('olark-tab');
    reamazeElm.click(reamazeClickHandler);

    cr$.byId('onlineChatReamaze').click(() => reamazeElm.click());

    PopupsManager.triggerPageEnter();

    const fromQuery = readUrlQuery('from');
    if (fromQuery && (fromQuery === 'fb' || fromQuery === 'go') && window.ga) {
        window.ga('set', 'referrer', 'https://chicorei.com');
    }

    cr$.byId('404-button').click(function (e) {
        window.location.href = '/';
    });

    // Add Cupom listner
    document.addEventListener('click', function (event) {
        if (!event.target.classList.contains('add-cupom-click')) return;

        event.preventDefault();
        event.stopPropagation();

        const cupom = event.target.getAttribute('data-cupom-name');

        let d = new Date();
        d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000); // 1 mes
        setCookie('voucher', cupom, d.toUTCString(), '/');

        EventBus.$emit('open-sidebar', 'cart');
    });

    const logoAnimationEl = cr$.byId('logo-mobile-animation');
    if (logoAnimationEl.exists()) {
        let isShowingLogo = false;
        const offsetToChangeLogo = 50; // pixels
        window.addEventListener(
            'scroll',
            throttle(
                () => {
                    if (window.innerWidth > 768) return;

                    const scroll = document.body.scrollTop || document.documentElement.scrollTop;

                    if (scroll > offsetToChangeLogo && !isShowingLogo) {
                        //Scroll para baixo -> logo circle
                        logoAnimationEl.scrollTo(0, logoAnimationEl.raw.scrollHeight);
                        isShowingLogo = true;
                    } else if (scroll < offsetToChangeLogo && isShowingLogo) {
                        //Scroll para cima -> logo Chico Rei
                        logoAnimationEl.scrollTo(0, 0);
                        isShowingLogo = false;
                    }
                },
                500,
                {
                    leading: true,
                    trailing: true,
                }
            ),
            { passive: true }
        );
    }

    // Footer
    if (cr$.byId('footer-newsletter-vue').exists()) {
        let lazy = new LazyLoad({
            elements_selector: '#footer-newsletter-vue',
            cancel_on_exit: false,
            callback_enter: () => {
                new Vue(FooterNewsletter);
                setTimeout(() => lazy.destroy(), 500);
            },
        });
    }

    if (getScope('IS_WHOLESALER') && cr$.byId('footer-contact-vue').exists()) {
        let lazy = new LazyLoad({
            elements_selector: '#footer-contact-vue',
            cancel_on_exit: false,
            callback_enter: () => {
                new Vue(FooterContact);
                setTimeout(() => lazy.destroy(), 500);
            },
        });
    }

    if (cr$.byClass('collapsible-button').exists()) {
        cr$.byClass('collapsible-button').on('click', function (e) {
            e.stopPropagation();
            this.classList.toggle('active');
            let content = this.nextElementSibling;
            if (content.style.maxHeight) {
                content.style.maxHeight = null;
            } else {
                content.style.maxHeight = content.scrollHeight * 2 + 'px';
            }
        });
    }
});
