<template>
    <div class="product-price-frame" :class="{ 'product-price-frame-primary': primary }">
        <div
            class="product-price-frame-badge d-inline-block px-2 text-white text-uppercase"
            :class="{ 'bg-primary-5': primary }"
        >
            {{ label }}
        </div>
        <div class="product-price-frame-content py-2 d-flex" :class="{ 'text-primary': primary }">
            <div class="flex-item-grow pl-2 pr text-nowrap">
                <div class="h2 my-0 text-bold">
                    {{ $f.formatMoney(price) }}
                </div>
                <div>
                    Até 4x
                    {{ $f.formatMoney(price / 4) }} sem juros
                </div>
            </div>
            <div v-if="description" class="flex-item-grow pr-2 pl-2">
                {{ description }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProductPriceFrame',

        props: {
            price: {
                type: Number,
                required: true,
            },
            label: {
                type: String,
                required: true,
            },
            description: String,
            primary: Boolean,
        },
    };
</script>
