<script>
    export default {
        name: 'RenderNotifier',

        render(createElement) {
            if (!this.$slots.default) {
                return null;
            }

            if (this.$slots.default.length > 1) {
                // create wrapper with our class (template must have only one root element)
                return createElement(
                    'div',
                    { attrs: { class: 'vue-rendered' } },
                    this.$slots.default
                );
            } else {
                // add class to the child
                const child = this.$slots.default[0];
                if (!child.data) child.data = {};
                if (!child.data.class) child.data.class = {};
                child.data.class = { 'vue-rendered': true };

                return this.$slots.default;
            }
        },
    };
</script>