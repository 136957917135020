<template>
    <div>
        <div :class="{ opened: !submitting && !submitted }" class="collapsible">
            <Alert :alert="alert" />

            <ProductVariations
                v-model="sizeToWish"
                :product="product"
                :wishlist-mode="true"
                :label="$t('_shop._wishlist.selectSize')"
            />

            <button :disabled="!sizeToWish" class="btn btn-wide mt" @click="submit">
                {{ $t('toAdd') }}
            </button>
        </div>

        <div :class="{ opened: submitting }" class="collapsible">
            <div class="d-flex flex-spaced-fixed">
                <div class="cr-spinner-small"></div>
                <p>{{ $t('wait') }}...</p>
            </div>
        </div>

        <div :class="{ opened: submitted }" class="collapsible">
            <div class="alert-success mb">
                <p
                    v-html="
                        $t('_shop._wishlist.successfullyAddedHtml', {
                            link: '/minha-conta/wishlist',
                        })
                    "
                />
                <p v-if="showStockWarning">
                    {{ $t('_shop._wishlist.stockWarning') }}
                </p>
            </div>

            <button class="btn btn-wide" @click="$emit('done')">Ok</button>
        </div>
    </div>
</template>

<script>
    import { customerService } from '../../../common/service/resource';
    import Alert from '../common/Alert';
    import { productStore } from '../../common/store/product-store';
    import ProductVariations from './ProductVariations';

    export default {
        name: 'ProductWishlist',

        components: {
            Alert,
            ProductVariations,
        },

        data() {
            return {
                productStoreState: productStore.state,

                submitting: false,
                submitted: false,

                alert: null,

                showStockWarning: false,
            };
        },

        mounted() {
            // se o produto contém um único tamanho, já adiciona à wishlist
            if (this.product.variations.length === 1) {
                this.sizeToWish = this.product.variations[0];
                this.submit();
            }
        },

        computed: {
            product() {
                return this.productStoreState.product;
            },

            sizeToWish: {
                get() {
                    return this.productStoreState.sizeToWish;
                },

                set(val) {
                    this.productStoreState.sizeToWish = val;
                },
            },
        },

        methods: {
            submit() {
                this.submitting = true;
                customerService
                    .addWishlistProduct(this.$scope.id, {
                        product_id: this.product.id,
                        variation_id: this.sizeToWish.variation_id,
                    })
                    .then(() => {
                        window.dataLayer.push({
                            event: 'addToWishlist',
                            product_gender: this.sizeToWish.gender,
                            product_size: this.sizeToWish.size_name,
                        });

                        window.dataLayer.push({
                            event: 'add_to_wishlist',
                            product_gender: this.sizeToWish.gender,
                            product_size: this.sizeToWish.size_name,
                            ecommerce_v4: {
                                items: [
                                    {
                                        item_id: this.product.id,
                                        item_name: this.product.name,
                                        price: this.product.price,
                                        item_brand: 'Chico Rei',
                                        item_category: this.product.type.name,
                                        item_category2: this.sizeToWish.gender,
                                        item_category3: this.sizeToWish.size_name,
                                        quantity: 1,
                                    },
                                ],
                            },
                        });

                        this.submitted = true;
                    })
                    .catch((response) => {
                        this.alert = {
                            message:
                                ((response.response || {}).data || {}).message ||
                                'Não foi possível adicionar à lista de desejos',
                            isError: true,
                        };
                    })
                    .then(() => (this.submitting = false));
            },
        },
    };
</script>
