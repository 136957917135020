import Vue from './common/vue-common';
import HeaderMenu from './components/header/HeaderMenu';
import cr$ from '../common/crquery';
import HeaderMobile from './components/header/HeaderMobile';

cr$.ready(() =>
    requestIdleCallback(() => {
        if (cr$.byId('header-desktop-nav').exists()) {
            new Vue(HeaderMenu);
        }
        if (cr$.byId('header-mobile-nav').exists()) {
            new Vue(HeaderMobile);
        }
    })
);
