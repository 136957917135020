<template>
    <div id="cart-special-mug">
        <div :class="{ opened: loading }" class="collapsible">
            <div class="alert">
                <div class="d-flex flex-spaced-fixed">
                    <div class="cr-spinner-small"></div>
                    <p>Por favor, aguarde...</p>
                </div>
            </div>
        </div>
        <div :class="{ opened: !loading }" class="collapsible">
            <div class="frame-outline px-0 py-0 mb">
                <p id="cart-kit-call" class="mx-0 my-0">
                    <picture>
                        <source
                            :data-srcset="`${$scope.CDN_IMAGES}/images/site/promos/2019_maio_bnr_11anoscr_720x100.png?auto=compress,format&q=65`"
                            media="(min-width: 576px)"
                        />
                        <img
                            :data-src="`${$scope.CDN_IMAGES}/images/site/promos/2019_maio_bnr_11anoscr_500x130_2.png?auto=compress,format&q=65`"
                            alt="11 Anos Chico Rei: Caneca personalizada"
                            class="lazy img-responsive center-block"
                        />
                    </picture>
                </p>

                <div class="px py text-yanone text-uppercase">
                    <p :class="{ opened: !showMugForm }" class="mx-0 my-0 collapsible">
                        <i class="icon-cr icon-mug"></i>
                        <template v-if="isMugInCart && giftMessage">
                            Sua caneca será personalizada com a frase:
                            <b>"{{ giftMessage.toUpperCase() }} MUDA O MUNDO"</b>.<a
                                class="text-accent cursor-pointer"
                                style="margin-left: 10px"
                                @click="showMugForm = true"
                                >Editar</a
                            >
                        </template>
                        <template v-else>
                            Você ganhou uma
                            <a
                                class="text-accent cursor-pointer"
                                href="https://chicorei.com/caneca/caneca-criatividade-muda-o-mundo-12505.html"
                                target="_blank"
                                >Caneca Criatividade muda o Mundo</a
                            >
                            personalizada!
                            <br />
                            <a
                                class="text-accent cursor-pointer"
                                style="margin-left: 32px"
                                @click="showMugForm = true"
                            >
                                Clique aqui para incluir na sua sacola.
                            </a>
                        </template>
                    </p>

                    <Alert :alert="alert" />

                    <div :class="{ opened: showMugForm }" class="collapsible">
                        <p class="mt-0">
                            <i class="icon-cr icon-mug"></i>
                            Sua caneca será personalizada com a frase:
                            <b v-if="text">"{{ text.toUpperCase() }} MUDA O MUNDO"</b>
                            <b v-else="">"CHICO REI MUDA O MUNDO" (altere o texto abaixo)</b>
                        </p>
                        <div class="form-inline">
                            <label>Digite seu texto aqui:</label>
                            <input
                                v-model="text"
                                min="1"
                                placeholder="Exemplo: Chico Rei"
                                step="1"
                                type="text"
                                @keyup="text = text.length > 15 ? text.slice(0, 15) : text"
                                @keyup.enter="submit"
                            />
                            <button :disabled="text.length <= 0" class="btn" @click="submit">
                                {{ isMugInCart ? 'Alterar' : 'Salvar' }}
                            </button>
                            <button class="btn btn-light" @click="showMugForm = false">
                                {{ isMugInCart ? 'Cancelar' : 'Cancelar' }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { cartService } from '../../../../common/service/resource';
    import Alert from '../../common/Alert';

    export default {
        name: 'CartSpecialMug',
        components: { Alert },
        props: ['isMugInCart', 'giftMessage', 'specialMugProductId', 'startFormOpened'],

        data() {
            return {
                showMugForm: this.startFormOpened,
                text: '',
                loading: false,
                alert: null,
            };
        },

        watch: {
            giftMessage: {
                handler: function (val) {
                    if (val) this.text = val;
                    else this.text = '';
                },
                immediate: true,
            },
        },

        methods: {
            submit() {
                this.$emit('changing-products');
                this.loading = true;
                cartService
                    .updateProducts(this.$scope.cart_id, {
                        products: [
                            {
                                product_id: this.specialMugProductId,
                                variation_id: 1110,
                                quantity: 1,
                                giftMessage: this.text,
                            },
                        ],
                    })
                    .then(() => {
                        this.alert = {
                            message: `Caneca ${
                                this.isMugInCart ? 'editada' : 'adicionada'
                            } com sucesso :)`,
                        };
                        this.$emit('special-mug-added');
                        this.$emit('update-cart');
                        this.showMugForm = false;
                    })
                    .catch(() => {
                        this.alert = {
                            message: `Não foi possível ${
                                this.isMugInCart ? 'editar' : 'adicionar'
                            } a caneca. Tente novamente.`,
                            isError: true,
                        };
                        this.$emit('done-changing-products');
                    })
                    .then(() => {
                        this.loading = false;
                    });
            },
        },
    };
</script>
