export default (function () {
    var h = {
        currency: {
            symbol: '$',
            format: '%s%v',
            decimal: '.',
            thousand: ',',
            precision: 2,
            grouping: 3,
        },
        number: { precision: 0, grouping: 3, thousand: ',', decimal: '.' },
    };
    var o = Array.prototype.map,
        k = Array.isArray,
        g = Object.prototype.toString;

    function d(q) {
        return !!(q === '' || (q && q.charCodeAt && q.substr));
    }

    function l(q) {
        return k ? k(q) : g.call(q) === '[object Array]';
    }

    function p(q) {
        return g.call(q) === '[object Object]';
    }

    function i(r, q) {
        var s;
        for (s in q) {
            if (q.hasOwnProperty(s)) {
                if (r[s] == null) {
                    r[s] = q[s];
                }
            }
        }
        return r;
    }

    function a(v, u, t) {
        var s = [],
            r,
            q;
        if (!v) {
            return s;
        }
        if (o && v.map === o) {
            return v.map(u, t);
        }
        for (r = 0, q = v.length; r < q; r++) {
            s[r] = u.call(t, v[r], r, v);
        }
        return s;
    }

    function m(r, q) {
        r = Math.round(Math.abs(r));
        return isNaN(r) ? q : r;
    }

    function f(r) {
        var q = h.currency.format;
        if (typeof r === 'function') {
            r = r();
        }
        if (d(r) && r.match('%v')) {
            return {
                pos: r,
                neg: r.replace('-', '').replace('%v', '-%v'),
                zero: r,
            };
        } else {
            if (!r || !r.pos || !r.pos.match('%v')) {
                return !d(q)
                    ? q
                    : (h.currency.format = {
                          pos: q,
                          neg: q.replace('%v', '-%v'),
                          zero: q,
                      });
            }
        }
        return r;
    }

    function j(t, q) {
        if (l(t)) {
            return a(t, function (u) {
                return j(u, q);
            });
        }
        t = t || 0;
        q = q || '.';
        var s = new RegExp('[^0-9-' + q + ']', ['g']),
            r = parseFloat(('' + t).replace(s, '').replace(q, '.'));
        return !isNaN(r) ? r : 0;
    }

    function c(s, q) {
        q = m(q, h.number.precision);
        var r = Math.pow(10, q);
        return (Math.round(s * r) / r).toFixed(q);
    }

    function e(s, v, x, u) {
        if (l(s)) {
            return a(s, function (z) {
                return e(z, v, x, u);
            });
        }
        var y, q;
        q = p(v) ? v : { precision: v, thousand: x, decimal: u };
        q = i(q, h.number);
        s = j(s);
        q.precision = m(q.precision);
        var t = s < 0 ? '-' : '',
            r = parseInt(c(Math.abs(s || 0), q.precision), 10) + '',
            w = r.length > 3 ? r.length % 3 : 0;
        return (
            t +
            (w ? r.substr(0, w) + q.thousand : '') +
            r.substr(w).replace(/(\d{3})(?=\d)/g, '$1' + q.thousand) +
            (q.precision ? q.decimal + c(Math.abs(s), q.precision).split('.')[1] : '')
        );
    }

    function n(s, r, u, y, t, x) {
        if (l(s)) {
            return a(s, function (z) {
                return n(z, r, u, y, t, x);
            });
        }
        s = j(s);
        var q = i(
                p(r)
                    ? r
                    : {
                          symbol: r,
                          precision: u,
                          thousand: y,
                          decimal: t,
                          format: x,
                      },
                h.currency
            ),
            w = f(q.format),
            v = s > 0 ? w.pos : s < 0 ? w.neg : w.zero;
        return v
            .replace('%s', q.symbol)
            .replace('%v', e(Math.abs(s), m(q.precision), q.thousand, q.decimal));
    }

    function b(x, s, v, A, u, z) {
        if (!x) {
            return [];
        }
        var q = i(
                p(s)
                    ? s
                    : {
                          symbol: s,
                          precision: v,
                          thousand: A,
                          decimal: u,
                          format: z,
                      },
                h.currency
            ),
            y = f(q.format),
            w = y.pos.indexOf('%s') < y.pos.indexOf('%v') ? true : false,
            r = 0,
            t = a(x, function (E, C) {
                if (l(E)) {
                    return b(E, q);
                } else {
                    E = j(E);
                    var B = E > 0 ? y.pos : E < 0 ? y.neg : y.zero,
                        D = B.replace('%s', q.symbol).replace(
                            '%v',
                            e(Math.abs(E), m(q.precision), q.thousand, q.decimal)
                        );
                    if (D.length > r) {
                        r = D.length;
                    }
                    return D;
                }
            });
        return a(t, function (C, B) {
            if (d(C) && C.length < r) {
                return w
                    ? C.replace(q.symbol, q.symbol + new Array(r - C.length + 1).join(' '))
                    : new Array(r - C.length + 1).join(' ') + C;
            }
            return C;
        });
    }

    return {
        settings: h,
        formatMoney: n,
        formatNumber: e,
        formatColumn: b,
        toFixed: c,
        unformat: j,
    };
})();
