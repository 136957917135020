<template>
    <nav
        class="d-flex flex-column flex-wrap overflow-hidden header-collections text-uppercase"
        v-if="items !== null"
    >
        <template v-if="fancy">
            <div class="d-flex" style="height: fit-content">
                <div class="text-right">
                    <HeaderDesktopContentItem
                        v-for="item in firstHalf"
                        :key="item.id"
                        :item="item"
                    />
                </div>
                <div class="divider mr-5 ml-5"></div>
                <div class="text-left">
                    <HeaderDesktopContentItem
                        v-for="item in secondHalf"
                        :key="item.id"
                        :item="item"
                    />
                </div>
            </div>
        </template>
        <template v-else>
            <HeaderDesktopContentItem v-for="item in items" :key="item.id" :item="item" />
        </template>
    </nav>
</template>

<script>
    import HeaderDesktopContentItem from './HeaderDesktopContentItem.vue';

    export default {
        name: 'HeaderDesktopContentItems',
        components: { HeaderDesktopContentItem },

        props: {
            items: {
                type: Array,
                required: true,
            },
            fancy: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            firstHalf() {
                if (!this.fancy) {
                    return null;
                }

                const half = Math.ceil(this.items.length / 2);
                return this.items.slice(0, half);
            },

            secondHalf() {
                if (!this.fancy) {
                    return null;
                }

                const half = Math.ceil(this.items.length / 2);
                return this.items.slice(half, this.items.length);
            },
        },
    };
</script>
