var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-price",class:[_vm.isMobile ? 'hidden-md-up' : 'hidden-md-down']},[_c('div',{staticClass:"flex-grid mb-0",staticStyle:{"align-items":"center"}},[_c('div',{staticClass:"flex-xs-6 flex-lg-12 flex-grid text-left",class:{ 'pb-0': !_vm.isMobile }},[_c('div',{staticClass:"d-flex flex-align-center flex-xs-12 flex-lg-6 pb-0 flex-wrap",class:{
                    'd-flex': !_vm.isMobile,
                    'product-price-block':
                        _vm.product.cashback_percentage > 0 && _vm.$scope.IS_REGULAR_CUSTOMER,
                },style:({ padding: _vm.isMobile ? 0 : 'initial' })},[(_vm.product.price_min > 0 && _vm.product.price_min < _vm.product.price_max)?_c('p',{staticClass:"text-secondary mr-2",class:{
                        h6: (this.product.type || {}).id !== 6,
                        'mb-0': (this.product.type || {}).id == 6,
                        'ml-3': (this.product.type || {}).id == 6 && _vm.isMobile,
                    }},[_vm._v("\n                    "+_vm._s(_vm.$t('_shop.priceFrom'))+"\n                ")]):_vm._e(),_vm._v(" "),(
                        _vm.$scope.IS_REGULAR_CUSTOMER &&
                        _vm.product.price_old &&
                        _vm.product.price < _vm.product.price_old &&
                        (this.product.type || {}).id !== 6
                    )?_c('p',{staticClass:"h6 text-secondary mx-0 flex-child-fixed hidden-md-down",class:{
                        'line-through price-old': _vm.product.price < _vm.product.price_old,
                        'mb-0': _vm.isMobile,
                    }},[_vm._v("\n                    "+_vm._s(`${_vm.$f.formatMoney(_vm.product.price_old)} `)+"\n                ")]):_vm._e(),_vm._v(" "),_c('p',{staticClass:"h6 mx-0 ml text-bold",class:[
                        _vm.isMobile ? 'mb-0 mr-2 price-mobile' : '',
                        (this.product.type || {}).id == 6 ? 'my-0' : '',
                        _vm.$scope.IS_REGULAR_CUSTOMER &&
                        _vm.product.price_old &&
                        _vm.product.price < _vm.product.price_old
                            ? 'text-danger-1 flex-child-fixed'
                            : 'text-info-4 ml-0',
                    ]},[_vm._v("\n                    "+_vm._s(_vm.$f.formatMoney(
                            _vm.product.price_min > 0 ? _vm.product.price_min : _vm.product.price
                        ))+"\n                ")]),_vm._v(" "),(
                        _vm.$scope.IS_REGULAR_CUSTOMER &&
                        _vm.product.price_old &&
                        _vm.product.price < _vm.product.price_old
                    )?_c('p',{staticClass:"h6 text-secondary mx-0 flex-child-fixed hidden-md-up mr-2",class:{
                        'line-through price-old': _vm.product.price < _vm.product.price_old,
                        'mb-0 price-old-mobile': _vm.isMobile,

                        'mt-2': (this.product.type || {}).id !== 6,
                        'mt-0': (this.product.type || {}).id == 6,
                    }},[_vm._v("\n                    "+_vm._s(`${_vm.$f.formatMoney(_vm.product.price_old)} `)+"\n                ")]):_vm._e(),_vm._v(" "),(_vm.product.cashback_percentage && _vm.$scope.IS_REGULAR_CUSTOMER)?_c('span',{staticClass:"hidden-md-up installment-mobile text-uppercase flex-align-center",staticStyle:{"padding":"0","margin-top":"0.5rem"}},[_vm._v(_vm._s(_vm.$t('_shop._price.upToXTimesInterestFreeMobile', [4, _vm.installmentPrice])))]):_vm._e()]),_vm._v(" "),(_vm.product.price >= 20 && _vm.$scope.IS_REGULAR_CUSTOMER)?_c('div',{staticClass:"flex-align-center flex-xs-12 flex-lg-6 text-uppercase",class:{
                    'd-flex pb-0': !_vm.isMobile,
                    'installment-mobile': _vm.isMobile,
                }},[_c('i',{staticClass:"icon-cr icon-credit-card mr-1 hidden-lg-down"}),_vm._v(" "),_c('span',{staticClass:"hidden-md-down"},[_vm._v(_vm._s(_vm.$t('_shop._price.upToXTimesInterestFree', [4, _vm.installmentPrice])))]),_vm._v(" "),(!_vm.product.cashback_percentage)?_c('span',{staticClass:"hidden-md-up"},[_vm._v(_vm._s(_vm.$t('_shop._price.upToXTimesInterestFreeMobile', [4, _vm.installmentPrice])))]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.product.cashback_percentage && _vm.$scope.IS_REGULAR_CUSTOMER)?_c('div',{staticClass:"hidden-md-up ml mt-2 mb-2"},[_c('div',{staticClass:"text-uppercase cashback-text d-flex flex-align-center"},[_c('i',{staticClass:"icon-cr icon-cashback mr-2"}),_vm._v("\n                    "+_vm._s(_vm.product.cashback_percentage)+"% de cashback\n                ")])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"flex-xs-6 flex-lg-6"},[_c('ProductBuyButton',{ref:"buyButton",staticClass:"hidden-md-up",staticStyle:{"width":"100%"},attrs:{"is-size-unavailable":_vm.showReprintMessage,"product":_vm.product,"size-to-buy":_vm.sizeToBuy,"text":_vm.$scope.IS_REGULAR_CUSTOMER ? _vm.$t('_shop.toBuy') : _vm.$t('_shop.addToCart'),"wholesaler-sizes-quantities-to-buy":_vm.wholesalerSizesQuantitiesToBuy},on:{"request-size":_vm.scrollToBuy,"alert":_vm.setAlert,"done":_vm.reset}})],1)]),_vm._v(" "),_c('div',{staticClass:"flex-grid mt-2",class:{ 'px-2': !_vm.priceGuarantee }},[(_vm.priceBadge)?_c('p',{staticClass:"badge-danger-1 flex-child-fixed text-regular text-uppercase product-price-promo mb-3",class:[
                !_vm.countdownDeadline ? 'text-center' : '',
                !_vm.priceBadge || (_vm.priceBadge && _vm.priceBadge.length) > 11
                    ? 'flex-xs-5'
                    : 'flex-xs-4',
            ],attrs:{"id":"product-price-promo"}},[_c('strong',[_vm._v("Promo!")]),_c('br'),_vm._v("\n            "+_vm._s(_vm.priceBadge)+"\n        ")]):_vm._e(),_vm._v(" "),(!_vm.priceBadge && _vm.newBadge)?_c('p',{staticClass:"badge-info-1 flex-child-fixed text-regular text-uppercase text-center product-price-promo flex-xs-4 mb-3",attrs:{"id":"product-price-promo"}},[_vm._v("\n            "+_vm._s(_vm.newBadge)+"\n        ")]):_vm._e(),_vm._v(" "),(!_vm.isMobile)?[(_vm.priceGuarantee || _vm.countdownDeadline)?_c('div',{staticClass:"countdown-product mb-4",class:{
                    'flex-xs-8 px-4': _vm.priceGuarantee,
                    'flex-xs-7': !_vm.priceGuarantee && _vm.priceBadge && _vm.priceBadge.length <= 11,
                    'flex-xs-6': !_vm.priceGuarantee && _vm.priceBadge && _vm.priceBadge.length > 11,
                }},[_c('div',{staticClass:"text-left"},[_c('span',{staticClass:"text-yanone text-uppercase"},[_vm._v("\n                        "+_vm._s(_vm.priceGuarantee ? 'Oferta garantida por:' : 'Se encerra em:')+"\n                    ")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('Countdown',{staticClass:"text-bold",style:({
                            'font-size': _vm.priceGuarantee ? '1.5rem' : '1.725rem',
                        }),attrs:{"deadline":_vm.priceGuarantee
                                ? new Date(_vm.$scope.promo_guaranteed)
                                : _vm.countdownDeadline}})],1)]):_vm._e()]:_vm._e(),_vm._v(" "),(
                _vm.priceBadge &&
                !_vm.priceGuarantee &&
                !_vm.isMobile &&
                _vm.$scope.regulamento &&
                _vm.$scope.PROMO.id_tipo_oferta !== 3
            )?_c('div',{staticClass:"flex-xs-1 pt-3 mt-3 text-danger-1",on:{"click":_vm.openRegulamento}},[_c('i',{staticClass:"icon-cr icon-info cursor-pointer"})]):_vm._e()],2),_vm._v(" "),_c('div',[(!_vm.$scope.IS_REGULAR_CUSTOMER)?_c('button',{staticClass:"btn-loading btn btn-xl btn-image font-roboto-c hidden-md-down",on:{"click":function($event){return _vm.$emit('scroll-to-wholesaler-buy')}}},[_vm._v("\n            "+_vm._s(_vm.$t('_shop.toBuy'))+"\n        ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }